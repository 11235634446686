import React from 'react';
import {Link} from 'react-router-dom';

export default React.memo(function Logo() {
  return (
    <div className="kt-header__brand ">
      <div className="kt-header__brand-logo" style={{display: 'block'}}>
        <Link to="/">
          <img
            alt="logo"
            src={require('../../../assets/images/logo.jpg')}
            width="40%"
          />
        </Link>
      </div>
    </div>
  );
});
