import blogDao from '../../../../../../dao/blogDao';
import BlogPostConfig from '../../../addoreditpost/BlogPostConfig';

const getDisplaySettings = async (func) => {
  const tmp = await blogDao.post
    .queryAllPosts(BlogPostConfig.blogDisplayLangs)
    .then((data) => {
      return {
        business: Object.keys(data.business).reduce(
          (o, lang) => {
            o[lang] = o[lang].map((i) => ({value: i.postHandle, label: i.title}));
            return o;
          },
          {...data.business}
        ),
        consumer: Object.keys(data.consumer).reduce(
          (o, lang) => {
            o[lang] = o[lang].map((i) => ({value: i.postHandle, label: i.title}));
            return o;
          },
          {...data.consumer}
        ),
      };
    });
  func(tmp);
};

export {getDisplaySettings};
