import React from 'react';
import {Trans} from 'react-i18next';
import classnames from 'classnames';
import './FeaturedHeader.css';
import globals from '../../../utils/config/globals';

export default function StoryCard({width, img, checkBox = [], ul = [], type}) {
  return (
    <div
      className={classnames({
        'pf-success-story-card': true,
      })}
    >
      <div className="pf-success-story-card-image">
        <img style={{width: '100%'}} alt="" src={img} />
      </div>
      <div className="mobile-line" />
      <div className="pf-success-story-card-title">
        <Trans i18nKey="business:p.b.s.favesbeauty.card.title">
          Services enabled with PERFECT.
        </Trans>
      </div>
      <div className="pf-success-story-list-block">
        {checkBox.map((s) => {
          const tmp = globals.story.serviceTypes.filter((c) => c.value === s)[0]
            ? globals.story.serviceTypes.filter((c) => c.value === s)[0].label
            : globals.story.productTypes.filter((c) => c.value === s)[0] &&
              globals.story.productTypes.filter((c) => c.value === s)[0].label;
          return (
            <div className="checkbox-story">
              <input type="checkbox" checked={true} />
              <label className="checkbox-story-label">{tmp}</label>
            </div>
          );
        })}
      </div>
      <ul style={{paddingLeft: width > 800 && '1em'}}>
        {ul.map((s) => (
          <li className="pf-success-story-card-list">{s}</li>
        ))}
      </ul>
      {type === 'smb' && (
        <div>
          <a
            href="https://www.perfectcorp.com/business/user/free-trial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="pf-success-stroy-contact-button">
              <Trans i18nKey="business:p.b.s.smb.trial.text">
                Start free trial
              </Trans>
            </div>
          </a>
          <a
            href="https://www.perfectcorp.com/business/contact-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="pf-success-story-smb-contact-btn">
              <Trans i18nKey="business:p.b.s.services.contact.sales">
                Contact Sales
              </Trans>
            </div>
          </a>
        </div>
      )}
      {type === 'enterprise' && (
        <a
          href="https://www.perfectcorp.com/business/contact-us"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="pf-success-stroy-contact-button">
            <Trans i18nKey="business:p.b.s.services.contact.sales">
              Contact Sales
            </Trans>
          </div>
        </a>
      )}
    </div>
  );
}
