import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

i18n
  .use(detector)
  .use(initReactI18next)
  .on('initialized', () => i18n.changeLanguage('en'))
  .init({
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'resource',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    nsSeperator: false,
    keySeparator: false,
    ns: ['resource', 'validation'],
    react: {
      wait: true,
    },
    resources: {
      en: {
        resource: require('./en'),
      },
      dev: {
        resource: require('./dev'),
      },
    },
  });

export default i18n;
