import React from 'react';
import {Formik, Form, Field} from 'formik';
import yup from '../../../utils/yup/index';
import dao from '../../../dao/customerSettingsDao';
import {toast} from 'react-toastify';
import InputBox from '../../../components/formik/InputBox';
import {Trans} from 'react-i18next';

export default function AddOrEditWhitelistModal({listAPI}) {
  const formikConfig = {
    validationSchema: yup.object().shape({
      email: yup.string().required(),
    }),
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    onSubmit: (values, {setSubmitting}) => {
      dao.createWhitelist(values).then((res) => {
        if (res.status === 'success') {
          toast.success('Create whitelist success');
          listAPI();
        } else {
          toast.error('Failed to create whitelist');
        }
      });
    },
  };

  return (
    <Formik {...formikConfig}>
      {(props) => (
        <Form>
          <div className="row">
            <label className="col-2 ">
              <Trans i18nKey="p.s.c.add.whitelist">Whitelist Email</Trans>
              <span className="required" style={{color: 'red'}}>
                *
              </span>
            </label>
            <div className="col-8">
              <Field
                type="text"
                name="email"
                className="form-control"
                component={InputBox}
              />
            </div>
            <div className="col-2 text-center">
              <button className="btn btn-primary" type="submit">
                Add
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
