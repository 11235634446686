import {get, listAll, post, postWithoutResponseHandle} from './utils';

const customerSettingsDao = {
  listCustomers: params =>
    get('/smb-admin/customer/list-customers.action', params),
  listAllCustomers: params =>
    listAll(customerSettingsDao.listCustomers, params),
  enableSkuAddOn: params =>
    post('/smb-admin/customer/change-add-ons-status.action', params),
  listWhitelist: (params) =>
    get('/smb-admin/customer/list-whitelist.action', params),
  createWhitelist: (params) =>
    post('/smb-admin/customer/create-whitelist.action', params),
  updateWhitelist: (params) =>
    post('/smb-admin/customer/update-whitelist.action', params),
  deleteWhitelist: (params) =>
    post('/smb-admin/customer/delete-whitelist.action', params),
};

export default customerSettingsDao;
