import React, {useRef} from 'react';
import {useEffect, useState} from 'react';
import projectDao from '../../../dao/projectDao';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import '../banner/index.css';

export default function ProjectPage({pfFormId}) {
  const ref = useRef(null);
  const [projects, setProjects] = useState('');
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    projectDao.queryProject({formId: pfFormId}).then((r) => {
      setProjects(_get(r, 'projectItems', []));
      setIsLoading(true);
    });
  }, [pfFormId]);

  const frame = document.getElementById('projectbased');
  const target =
    process.env.REACT_APP_FRONTEND_URL === 'https://www.perfectcorp.com'
      ? 'https://stage.perfectcorp.com'
      : 'https://demo.perfectcorp.com';

  if (frame) {
    frame.addEventListener('load', () => {
      console.log('pfFormId in admin: ', pfFormId);
      frame.contentWindow.postMessage(pfFormId, target);
    });
  }

  if (_isEmpty(projects)) return <div />;

  return (
    <div style={{marginTop: '-70px'}}>
      <div className="blog-post--title">Items of Project</div>
      {!_isEmpty(projects) &&
        projects.map((s, i) => (
          <div
            key={`project${i}`}
            className="post-button"
            style={{color: i === index ? '#f23b77' : '#212529'}}
            onClick={() => setIndex(i)}
          >
            {s.name}
          </div>
        ))}
      <iframe
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
        ref={ref}
        id="projectbased"
        style={{width: '100%', height: '100vh'}}
        frameBorder="0"
        scrolling="auto"
        className="iframe-full-height"
        src={`${projects[index].url}?pfFormId=${pfFormId}`}
        allow="camera;microphone"
      />
    </div>
  );
}
