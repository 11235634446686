import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import courseDao from '../../../../dao/courseDao';
import {
  DropdownFilter,
  TextFilter,
} from '../../../../components/datatable/DataFilter';
import globals from '../../../../utils/config/globals';
import {Link} from 'react-router-dom';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import FormattedDate from '../../../../components/FormattedDate';
import DataTable from '../../../../components/datatable/DataTable';
import ViewSession from '../../../../components/course/view';

export default function ListSessions() {
  const {search} = useLocation();
  const param = new URLSearchParams(search);
  const courseId = param.get('courseId');
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    courseDao.listSessions({courseId}).then((r) => {
      setSessions(r);
      setIsLoading(false);
    });
  }, [courseId, reload]);

  function publishSession(sessionId) {
    courseDao
      .publishSession(sessionId)
      .then((r) => setReload((prev) => prev + 1));
  }

  function takeDownSession(sessionId) {
    courseDao
      .takeDownSession(sessionId)
      .then((r) => setReload((prev) => prev + 1));
  }

  function deleteSession(sessionId) {
    courseDao
      .deleteSession(sessionId)
      .then((r) => setReload((prev) => prev + 1));
  }

  const columns = [
    {
      Header: 'Session Image',
      accessor: 'sessionImageUrl',
      disableSortBy: true,
      Cell: ({cell: {value}}) => (
        <div className="text-center">
          <img
            alt="cover"
            src={value || require('../../../../assets/images/noimage.png')}
            style={{width: '100px'}}
          />
        </div>
      ),
      style: {width: '10%'},
    },
    {
      Header: 'Session Title',
      accessor: (d) => d,
      disableSortBy: false,
      Cell: ({cell: {value}}) => (
        <>
          <ViewSession sessionId={value.id} sessionTitle={value.sessionTitle} />
          <br />
          <div>
            <span
              style={{
                fontSize: '12px',
                color: '#6c757d',
                wordBreak: 'break-word',
              }}
            >
              ({value.course.courseName} - {value.course.languageType})
            </span>
          </div>
        </>
      ),
      style: {width: '25%'},
      Filter: TextFilter,
    },
    {
      Header: 'Speakers',
      accessor: (d) => d,
      disableSortBy: true,
      Cell: ({cell: {value}}) => (
        <ul>
          {value &&
            value.sessionSpeakers.map((v) => <li>{v.speaker.speakerName}</li>)}
        </ul>
      ),
      style: {width: '15%'},
    },
    {
      Header: 'Session Date',
      accessor: 'sessionStartDate',
      disableSortBy: false,
      Cell: ({cell: {value}}) => <FormattedDate time={value} type={'short'} />,
      style: {width: '10%'},
    },
    {
      Header: 'Status',
      accessor: 'sessionStatus',
      disableSortBy: false,
      Cell: ({cell: {value}}) => value,
      style: {width: '10%'},
      Filter: DropdownFilter,
      options: globals.sessionStatus,
    },
    {
      Header: 'Modification',
      accessor: 'lastModified',
      Cell: ({cell: {value}}) => <FormattedDate time={value} type={'long'} />,
      style: {width: '10%'},
    },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: (d) => d,
      disableSortBy: true,
      Cell: ({cell: {value}}) => (
        <>
          {value.ableToEdit && (
            <Link
              to={'/masterseries/edit-session?sessionId=' + value.id}
              className="btn btn-sm btn-primary kt-margin-r-10 kt-margin-b-10 nowrap"
            >
              <i className="fa fa-edit" /> Edit
            </Link>
          )}
          {value.ableToPublish && (
            <ConfirmDialog confirmCallback={() => publishSession(value.id)}>
              <button
                type="button"
                className="btn btn-sm kt-margin-r-10 kt-margin-b-10 btn-outline-success nowrap"
              >
                <i className="fa flaticon2-paperplane" /> Publish
              </button>
            </ConfirmDialog>
          )}
          {value.ableToTakeDown && (
            <ConfirmDialog confirmCallback={() => takeDownSession(value.id)}>
              <button
                type="button"
                className="btn btn-sm btn-outline-dark kt-margin-r-10 kt-margin-b-10 nowrap"
              >
                <i className="fa flaticon-edit-1" /> TakeDown
              </button>
            </ConfirmDialog>
          )}
          {value.ableToDelete && (
            <ConfirmDialog confirmCallback={() => deleteSession(value.id)}>
              <button
                type="button"
                className="btn btn-sm btn-outline-danger kt-margin-r-10 kt-margin-b-10 nowrap"
              >
                <i className="fa fa-times" /> Delete
              </button>
            </ConfirmDialog>
          )}
        </>
      ),
      style: {width: '40%'},
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex">
          <Link
            className="btn btn-danger kt-margin-b-10 kt-margin-r-10"
            to="/masterseries/add-session"
          >
            <i className="fa fa-plus" /> Add Session
          </Link>
        </div>
      </div>
      <DataTable
        loading={isLoading}
        columns={columns}
        data={sessions}
        initState={{
          sortBy: {id: 'lastModified', desc: true},
        }}
      />
    </div>
  );
}
