import _isNull from 'lodash/isNull';
import _get from 'lodash/get';

const seoUtils = {
  getCleanedValues: (values = {}, isYce = false) => {
    return {
      meta: {
        sourceId: values.sourceId,
        metaType: values.metaType,
        title: values.title,
        description: values.description,
        ogTitle: values.ogTitle,
        ogDescription: values.ogDescription,
        author: values.author,
        enableHowTo: values.enableHowTo,
        enableFaq: values.enableFaq,
        enableVideo: values.enableVideo,
        isSoftwareSchema: values.isSoftwareSchema,
        howToSupply: values.howToSupply,
        howToTool: values.howToTool,
        howToTotalTime: _isNull(_get(values, 'howToTotalTime'))
          ? 0
          : _get(values, 'howToTotalTime'),
        videoLink: values.videoLink,
        videoCover: values.videoCover,
        videoDuration: _isNull(_get(values, 'videoDuration'))
          ? 0
          : _get(values, 'videoDuration'),
        serpSteps: values.serpSteps,
        serpFaqs: values.serpFaqs,
      },
      image: _get(values, 'imageUrl', ''),
      isYce,
    };
  },
};

export default seoUtils;
