import React, {useEffect, useState} from 'react';
import courseDao from '../../../../dao/courseDao';
import {TextFilter} from '../../../../components/datatable/DataFilter';
import FormattedDate from '../../../../components/FormattedDate';
import {Link} from 'react-router-dom';
import DataTable from '../../../../components/datatable/DataTable';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';

export default function ListSpeakers() {
  const [speakers, setSpeakers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    courseDao.listSpeakers().then((r) => {
      setSpeakers(r);
      setIsLoading(false);
    });
  }, []);

  function deleteSpeaker(speakerId) {
    courseDao.deleteSpeaker(speakerId).then((r) => {
      courseDao.listSpeakers().then((r) => {
        setSpeakers(r);
      });
    });
  }

  const columns = [
    {
      Header: 'Speaker Image',
      accessor: 'speakerImageUrl',
      disableSortBy: true,
      Cell: ({cell: {value}}) => (
        <div className="text-center">
          <img
            alt="cover"
            src={value || require('../../../../assets/images/noimage.png')}
            style={{width: '80px', borderRadius: '50%'}}
          />
        </div>
      ),
      style: {width: '10%'},
    },
    {
      Header: 'Speaker Name',
      accessor: (d) => d,
      disableSortBy: false,
      Cell: ({cell: {value}}) => (
        <>
          <div>{value.speakerName}</div>
          <br />
          <div>
            <span style={{fontSize: '12px', color: '#6c757d'}}>
              Position: {value.speakerPosition}
            </span>
          </div>
        </>
      ),
      style: {width: '20%'},
      Filter: TextFilter,
    },
    {
      Header: 'Brand Name',
      accessor: 'brandName',
      disableSortBy: false,
      Cell: ({cell: {value}}) => value,
      style: {width: '15%'},
      Filter: TextFilter,
    },
    {
      Header: '# of Sessions Used',
      accessor: 'numOfSessionsUsed',
      disableSortBy: false,
      Cell: ({cell: {value}}) => value,
      style: {width: '15%'},
    },
    {
      Header: 'Creation',
      accessor: 'createdTime',
      Cell: ({cell: {value}}) => <FormattedDate time={value} type={'short'} />,
      style: {width: '10%'},
    },
    {
      Header: 'Modification',
      accessor: 'lastModified',
      Cell: ({cell: {value}}) => <FormattedDate time={value} type={'long'} />,
      style: {width: '10%'},
    },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: (d) => d,
      disableSortBy: true,
      Cell: ({cell: {value}}) => (
        <>
          <Link
            to={'/masterseries/edit-speaker?speakerId=' + value.id}
            className="btn btn-sm btn-primary kt-margin-r-10 kt-margin-b-10 nowrap"
          >
            <i className="fa fa-edit" /> Edit
          </Link>
          {value.numOfSessionsUsed === 0 && (
            <ConfirmDialog confirmCallback={() => deleteSpeaker(value.id)}>
              <button
                type="button"
                className="btn btn-sm btn-outline-danger kt-margin-r-10 kt-margin-b-10 nowrap"
              >
                <i className="fa fa-times" /> Delete
              </button>
            </ConfirmDialog>
          )}
        </>
      ),
      style: {width: '20%'},
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex">
          <Link
            className="btn btn-danger kt-margin-b-10 kt-margin-r-10"
            to="/masterseries/add-speaker"
          >
            <i className="fa fa-plus" /> Add Speaker
          </Link>
        </div>
      </div>
      <DataTable
        loading={isLoading}
        columns={columns}
        data={speakers}
        initState={{
          sortBy: {id: 'lastModified', desc: true},
        }}
      />
    </div>
  );
}
