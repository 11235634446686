import React, {useEffect} from 'react';
import classnames from 'classnames';
import courseDao from '../../dao/courseDao';
import _find from 'lodash/find';

export default function RadioButton({
  field,
  form: {touched, errors},
  label,
  isLabelBold,
  setLabelLength = false,
  labelLength = 100,
  value,
  disabled = false,
  onClick = () => {},
  ...props
}) {
  return (
    <label
      className={classnames({'kt-radio': true, 'kt-font-boldest': isLabelBold})}
    >
      <input
        {...props}
        {...field}
        type="radio"
        name={field.name}
        value={value}
        checked={field.value === value}
        disabled={disabled}
        onClick={onClick}
      />
      {setLabelLength ? (
        <div
          style={
            props.disabled
              ? {opacity: '0.2', width: `${labelLength}px`}
              : {width: `${labelLength}px`}
          }
        >
          {label}
        </div>
      ) : (
        <div style={props.disabled ? {opacity: '0.2'} : {}}>{label}</div>
      )}
      <span />
    </label>
  );
}
