import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {openMenu} from '../sidebar/reducer';
import {Link} from 'react-router-dom';
import classnames from 'classnames';
import Logo from './Logo';
import User from './User';
import Logout from './Logout';
import Strapi from "./Strapi";

export default React.memo(
  connect(
    state => ({
      admin: state.components.auth.admin,
    }),
    dispatch => bindActionCreators({openMenu}, dispatch)
  )(function Header({openMenu}) {
    const [topBarOn, setTopBarOn] = useState(false);
    return (
      <div
        className={classnames({
          'kt-header__topbar--mobile-on': topBarOn,
        })}
      >
        <div className="kt-header kt-grid__item kt-header--fixed">
          <div className="kt-container">
            <Logo />
            <div className="kt-header__topbar">
              <Strapi />
              <User />
              <Logout />
            </div>
          </div>
        </div>
        <div className="kt-header-mobile kt-header-mobile--fixed">
          <div className="kt-header-mobile__logo">
            <Link to="/">
              <img
                alt="Logo"
                src={require('../../../assets/images/logo.jpg')}
                width="40%"
              />
            </Link>
          </div>
          <div className="kt-header-mobile__toolbar">
            <button
              className="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
              onClick={() => openMenu()}
            >
              <span />
            </button>
            <button
              className={classnames({
                'kt-header-mobile__toolbar-topbar-toggler': true,
                'kt-header-mobile__toolbar-topbar-toggler--active': topBarOn,
              })}
              onClick={() => setTopBarOn(!topBarOn)}
            >
              <i className="flaticon-more-1" />
            </button>
          </div>
        </div>
      </div>
    );
  })
);
