import React, {useEffect, useState} from 'react';

export default function Footer() {
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const handleScroll = () => setScrollTopVisible(window.scrollY > 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  return (
    <div className="kt-footer kt-grid__item">
      <div className="kt-container">
        <div className="kt-footer__wrapper">
          <div className="kt-footer__copyright">
            {1900 + new Date().getYear()}&nbsp;&copy;&nbsp;
            <a
              href={process.env.REACT_APP_FRONTEND_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="kt-link"
            >
              Management Console by Perfect Corp.
            </a>
          </div>
          {scrollTopVisible && (
            <button
              className="scroll-to-top"
              style={{display: 'block'}}
              onClick={() => scrollToTop()}
            >
              <i className="fa fa-arrow-circle-up" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
