import React from 'react';
import ReactPaginate from 'react-paginate';
import '../../src/assets/styles/components/paginate.css';

export default function Paginate({
  grid,
  clickCallback,
  selectCallback,
  classname,
  selectShow = true,
}) {
  if (!grid || !grid.totalSize || grid.totalSize === 1) {
    return <div />;
  }
  return (
    <div className={`row ${classname}`}>
      <div className="col-4">
        Showing {(grid.pageIndex - 1) * grid.pageSize + 1} to{' '}
        {grid.pageIndex === grid.pages && grid.totalSize}
        {grid.pageIndex !== grid.pages &&
          grid.pageIndex * grid.pageSize} of {grid.totalSize} records
      </div>
      <div className="col-8">
        <div
          id="react-paginate"
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            float: 'right',
          }}
        >
          {selectShow && (
            <div className="dataTables_length" style={{display: 'flex'}}>
              <label style={{padding: '5px 5px 0 0'}}>Display</label>
              <select
                value={grid.pageSize}
                name="pageSize"
                className="custom-select custom-select-sm"
                onChange={(e) => {
                  selectCallback(Number(e.target.value));
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
              <label style={{padding: '5px 0 0 5px'}}>records</label>
            </div>
          )}
          <div className="dataTables_paginate paging_bootstrap_full_number">
            <ReactPaginate
              forcePage={grid.pageIndex - 1}
              previousLabel={<i className="fa fa-angle-left" />}
              nextLabel={<i className="fa fa-angle-right" />}
              breakLabel={<i className="fa fa-ellipsis-h" />}
              pageCount={grid.pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={clickCallback}
              activeClassName={'active'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
