import {convertNodeToElement} from 'react-html-parser';

const blogPostConfig = {
  transformPostContent: (node, index) => {
    return convertNodeToElement(
      node,
      index,
      blogPostConfig.transformPostContent
    );
  },
  strapiTypes: [
    {label: 'Banners', value: 'banners'},
    // {label: 'Text Image Banners', value: 'text-image-banners'},
    // {label: 'Video Banners', value: 'video-banners'},
  ],
  tagsList: [
    {
      title: 'B2BorB2C',
      data: [
        {label: ' ', value: '*'},
        {label: 'B2B', value: 'B2B'},
        {label: 'B2C', value: 'B2C'},
      ],
    },
    {
      title: 'Languages',
      data: [
        {label: ' ', value: '*'},
        {label: 'ALL', value: 'ALL'},
        {label: 'ENU', value: 'ENU'},
        {label: 'DEU', value: 'DEU'},
        {label: 'ESP', value: 'ESP'},
        {label: 'FRA', value: 'FRA'},
        {label: 'ITA', value: 'ITA'},
        {label: 'JPN', value: 'JPN'},
        {label: 'KOR', value: 'KOR'},
        {label: 'PTB', value: 'PTB'},
        {label: 'CHS', value: 'CHS'},
        {label: 'CHT', value: 'CHT'},
      ],
    },
    {
      title: 'Products',
      data: [
        {label: '', value: '*'},
        {label: 'ALL', value: 'ALL'},
        {label: 'YMK', value: 'YMK'},
        {label: 'YCP', value: 'YCP'},
        {label: 'YCV', value: 'YCV'},
        {label: 'Virtual Makeup', value: 'Virtual Makeup'},
        {label: 'YouCam Tutorial', value: 'YouCam Tutorial'},
        {label: 'Shade Finder', value: 'Shade Finder'},
        {label: 'Face Analyzer', value: 'Face Analyzer'},
        {label: 'Skin Analysis', value: 'Skin Analysis'},
      ],
    },
    {
      title: 'ButtonType',
      data: [
        {label: '', value: '*'},
        {label: 'Mid-Download', value: 'Mid-Download'},
        {label: 'Bottom-Download', value: 'Bottom-Download'},
      ],
    },
  ],
  contentButtons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    '|',
    'subscript',
    'superscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'image',
    'video',
    'table',
    'link',
    '|',
    'align',
    'fullsize',
    'insertTableOfContent',
    'openStrapi',
    'uploadVideo',
    'insertButton',
    'insertPros',
  ],
  annotationButtons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    'brush',
  ],
  postContent: {
    fullsize: false,
    readonly: false,
    allowResizeY: false,
    height: '500px',
    image: {
      openOnDblClick: true,
      editSrc: true,
      useImageEditor: true,
      editTitle: true,
      editAlt: true,
      editLink: true,
      editSize: true,
      editBorderRadius: true,
      editMargins: true,
      editClass: true,
      editStyle: true,
      editId: true,
      editAlign: true,
      showPreview: true,
      selectImageAfterClose: true,
    },
    url: '',
    draggableTags: ['img'],
    uploader: {
      url: '',
      insertImageAsBase64URI: true,
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
    },
  },
  annotation: {
    fullsize: false,
    readonly: false,
    allowResizeY: false,
    height: '500px',
  },
  controls: {
    fontsize: {
      list: [
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '20',
        '22',
        '24',
        '26',
        '28',
        '30',
        '32',
        '34',
        '36',
        '40',
        '48',
        '72',
        '96',
      ],
    },
  },
  blogDisplayLangs: [
    'chs',
    'cht',
    'deu',
    'enu',
    'esp',
    'fra',
    'ita',
    'jpn',
    'kor',
    'ptb',
  ],
};

export default blogPostConfig;
