import React from 'react';
import {Trans} from 'react-i18next';
import AsyncListCustomers from './listcustomers/async';
import AsyncListReports from './reports/listreports/async';
import AsyncListAffiliations from './affiliations/listaffiliations/async';
import AsyncAddOrEditAffiliation from './affiliations/addoreditaffiliation/async';
import {Navigate} from 'react-router-dom';

const parent = {
  title: <Trans i18nKey="p.shopify.listcustomers.title">Shopify</Trans>,
  path: '/shopify',
};
const reportParent = {
  title: <Trans i18nKey="p.shopify.listreports.title">Reports</Trans>,
  path: '/shopify/list-reports',
};
const affiliationParent = {
  title: <Trans i18nKey="p.shopify.listaffiliations.title">Affiliations</Trans>,
  path: '/shopify/list-affiliations',
};
export default [
  {
    path: '/shopify/list-customers',
    element: <AsyncListCustomers />,
    handle: {
      title: <Trans i18nKey="p.shopify.listcustomers.title">Shopify</Trans>,
      subtitle: (
        <Trans i18nKey="p.shopify.listcustomers.customers.title">
          Customers
        </Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/shopify/list-reports',
    element: <AsyncListReports />,
    handle: {
      title: <Trans i18nKey="p.shopify.listreports.title">Reports</Trans>,
      subtitle: (
        <Trans i18nKey="p.shopify.listreportstitle.title">Reports</Trans>
      ),
      breadcrumbs: [reportParent],
    },
  },
  {
    path: '/shopify/list-affiliations',
    element: <AsyncListAffiliations />,
    handle: {
      title: (
        <Trans i18nKey="p.shopify.listaffiliations.title">Affiliations</Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.shopify.listaffiliations.title">Affiliations</Trans>
      ),
      breadcrumbs: [affiliationParent],
    },
  },
  {
    path: '/shopify/add-whitelist',
    element: <AsyncAddOrEditAffiliation />,
    handle: {
      title: (
        <Trans i18nKey="p.s.videos.addoreditaffiliation.add.title">
          Add Whitelist
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.s.videos.addoreditaffiliation.add.title">
          Add Whitelist
        </Trans>
      ),
      breadcrumbs: [affiliationParent],
    },
  },
  {
    path: '/shopify',
    element: <Navigate to="/shopify/list-customers" />,
  },
];
