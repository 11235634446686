import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form, Formik} from 'formik';
import {useNavigate} from 'react-router';
import {
  signIn,
  signInFail,
  signInSuccess,
  reset,
} from '../../pages/auth/signin/reducer';
import {setAdminSummary} from './reducers';
import {Trans} from 'react-i18next';
import classnames from 'classnames';
import yup from '../../utils/yup';
import InputBox from '../../components/formik/InputBox';

const SignIn = ({closeModal}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.components.auth.admin);
  const error = useSelector((state) => state.pages.auth.signin.error);

  const modalFormikConfig = {
    enableReinitialize: true,
    initialValues: {email: '', password: ''},
    validationSchema: yup.object().shape({
      email: yup.string().max(200).email().required(),
      password: yup.string().required(),
    }),

    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true);
      try {
        const adm = await dispatch(signIn(values.email, values.password));
        dispatch(setAdminSummary(adm));
        dispatch(signInSuccess());
        closeModal();
      } catch (error) {
        dispatch(signInFail());
      } finally {
        setSubmitting(false);
      }
    },
  };

  useEffect(() => {
    if (admin && admin.adm) {
      navigate('/');
      return;
    }
    dispatch(reset());
  }, [admin, dispatch, navigate]);

  return (
    <Formik {...modalFormikConfig}>
      {(formikProps) => (
        <Form className="kt-form">
          <div className="row justify-content-center">
            <div className="col-3">
              <div className="layout-main_logo">
                <img
                  alt="pf_logo"
                  src={require('../../assets/images/pf_logo.png')}
                  width="100%"
                />
              </div>
              <div className={classnames({'admin__sign-in-fail': error})}>
                <div className="kt-portlet">
                  <div className="kt-portlet__body">
                    <div className="form-group">
                      <label style={{fontWeight: 600}}>
                        <Trans i18nKey="p.a.s.signin.email.address">
                          Email Address
                        </Trans>
                      </label>
                      <Field
                        type="email"
                        name="email"
                        autoComplete="username"
                        component={InputBox}
                      />
                    </div>
                    <div className="form-group">
                      <label style={{fontWeight: 600}}>
                        <Trans i18nKey="p.a.s.signin.password">Password</Trans>
                      </label>
                      <Field
                        type="password"
                        name="password"
                        autoComplete="current-password"
                        component={InputBox}
                      />
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className={classnames({
                          btn: true,
                          'btn-primary': true,
                          'btn-elevate': true,
                          'kt-login__btn-primary': true,
                          'kt-spinner': true,
                          'kt-spinner-v2': true,
                          'kt-spinner--center': true,
                          'kt-spinner--sm': true,
                          'kt-spinner--warning': formikProps.isSubmitting,
                          'signin-main_button-detail': true,
                        })}
                        disabled={formikProps.isSubmitting}
                      >
                        <div
                          style={{
                            visibility: formikProps.isSubmitting
                              ? 'hidden'
                              : 'visible',
                          }}
                        >
                          <Trans i18nKey="p.a.s.signin.sign.in">Sign In</Trans>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignIn;
