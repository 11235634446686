export default function PreviewBanner({cmsId, type, languageType}) {
  const langs = {
    CHT: '/zh-tw',
    CHS: '/zh-cn',
    FRA: '/fr',
    ESP: '/es',
    PTB: '/pt',
    ITA: '/it',
    DEU: '/de',
    JPN: '/ja',
    KOR: '/ko',
    ENU: '',
  };

  const productUrl = {
    PRODUCT_BACKGROUND: '/business/products/virtual-background',
    PRODUCT_FACE_RESHAPE: '/business/products/face-reshaping',
    PRODUCT_PERSONALITY: '/business/products/ai-personality',
    PRODUCT_VIRTUAL_MAKEUP: '/business/products/virtual-makeup',
  };
  return (window.location = `${process.env.REACT_APP_FRONTEND_URL}${langs[languageType]}${productUrl[type]}?cmsId=${cmsId}`);
}
