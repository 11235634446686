import React, {useState} from 'react';
import Slider from 'react-rangeslider';
import {SketchPicker} from 'react-color';
import adminUtils from '../../utils/adminUtils';
import classnames from 'classnames';
import _get from 'lodash/get';
import './index.css';

const sliderChange = (v, type, field, form) => {
  const rgb = adminUtils.hexToRgb(field.value);
  rgb[type] = v;
  form.setFieldValue(field.name, adminUtils.rgbToHex(rgb));
};

const rgbSlider = (rgb, type, field, form) => {
  return (
    <Slider
      className={`rangeslider-horizontal-${type}`}
      value={rgb[type]}
      min={0}
      max={255}
      onChange={(v) => {
        sliderChange(v, type, field, form);
      }}
    />
  );
};

const rgbInputBox = (rgb, type, field, form) => {
  const isInvalid =
    _get(form.touched, field.name) && _get(form.errors, field.name);
  return (
    <input
      type="number"
      className={classnames({
        'form-control': true,
        'border-0': !isInvalid,
        'is-invalid': isInvalid,
      })}
      style={{
        width: '75px',
        height: 'calc(1.5em +0.65em+ 2px)',
      }}
      value={rgb[type]}
      min={0}
      max={255}
      onChange={(v) => {
        sliderChange(Number(v.target.value), type, field, form);
      }}
    />
  );
};

export default function ColorField({field, form}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const rgb = adminUtils.hexToRgb(field.value);
  const isInvalid =
    _get(form.touched, field.name) && _get(form.errors, field.name);
  return (
    <div>
      <div className="bg-grey-steel">
        <div
          style={{
            alignItems: 'center',
            border: '1px solid #ebedf2',
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
          }}
        >
          <div style={{display: 'flex'}}>
            <input
              type="text"
              className={classnames({
                'form-control': true,
                'border-0': !isInvalid,
                'is-invalid': isInvalid,
              })}
              min={1}
              maxLength={7}
              style={{width: '7rem'}}
              value={field.value}
              onChange={(v) => {
                form.setFieldValue(field.name, v.target.value);
              }}
            />
            <div style={{flexGrow: 1}}>
              <div
                className="col-12 color-dot"
                style={{
                  width: '30px',
                  height: '30px',
                  backgroundColor: field.value,
                }}
                onClick={() => setIsOpen(true)}
              />
              {isOpen && (
                <div className="color-picker__popover">
                  <div
                    className="color-picker__cover"
                    onClick={() => setIsOpen(false)}
                  />
                  <SketchPicker
                    color={field.value}
                    width={300}
                    disableAlpha={true}
                    onChangeComplete={(color) =>
                      form.setFieldValue(field.name, color.hex)
                    }
                    presetColors={[
                      '#D0021B',
                      '#F5A623',
                      '#F8E71C',
                      '#8B572A',
                      '#7ED321',
                      '#417505',
                      '#BD10E0',
                      '#9013FE',
                      '#4A90E2',
                      '#50E3C2',
                      '#B8E986',
                      '#000000',
                      '#4A4A4A',
                      '#9B9B9B',
                      '#FFFFFF',
                      '#9013fe',
                      '#4a90e2',
                      '#f23b77',
                      '#1454ff',
                      '#4339db',
                    ]}
                  />
                </div>
              )}
            </div>
            <span
              style={{
                alignSelf: 'stretch',
                backgroundColor: 'hsl(0, 0%, 80%)',
                marginBottom: '8px',
                marginTop: '8px',
                width: '1px',
              }}
            />
            <div
              className="arrow"
              style={{
                display: 'flex',
                padding: '0.65rem 0.8rem',
                alignItems: 'center',
                alignSelf: 'stretch',
                flexShrink: 0,
                boxSizing: 'border-box',
              }}
            >
              {!isExpand && (
                <i
                  className="flaticon2-right-arrow"
                  onClick={() => setIsExpand(true)}
                />
              )}
              {isExpand && (
                <i
                  className="flaticon2-down-arrow"
                  onClick={() => setIsExpand(false)}
                />
              )}
            </div>
          </div>

          {isExpand && (
            <div>
              <div
                style={{
                  display: 'flex',
                  borderTop: '1px solid rgb(235, 237, 242)',
                }}
              >
                {rgbInputBox(rgb, 'r', field, form)}

                <div style={{flexGrow: 1, margin: '-3px 20px'}}>
                  {rgbSlider(rgb, 'r', field, form)}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  borderTop: '1px solid rgb(235, 237, 242)',
                }}
              >
                {rgbInputBox(rgb, 'g', field, form)}

                <div style={{flexGrow: 1, margin: '-3px 20px'}}>
                  {rgbSlider(rgb, 'g', field, form)}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  borderTop: '1px solid rgb(235, 237, 242)',
                }}
              >
                {rgbInputBox(rgb, 'b', field, form)}

                <div style={{flexGrow: 1, margin: '-3px 20px'}}>
                  {rgbSlider(rgb, 'b', field, form)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {form.errors && form.errors[field.name] && (
        <div className="image-invalid-feedback">{form.errors[field.name]}</div>
      )}
    </div>
  );
}
