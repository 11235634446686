import _forEach from 'lodash/forEach';
import _keys from 'lodash/keys';
import _map from 'lodash/map';
import _get from 'lodash/get';
import globals from '../../utils/config/globals';
import _join from 'lodash/join';
import _find from 'lodash/find';

const displayConfig = {
  POPULAR_MAX_COUNT: 6,
  TOP_MAX_COUNT: 3,
  EMPTY_VALUE: 0,
};

export const categoryList = [
  {label: 'success-story', value: 'SUCCESS_STORY'},
  {label: 'makeup', value: 'MAKEUP'},
  {label: 'ai-skincare', value: 'AI_SKINCARE'},
  {label: 'hair', value: 'HAIR'},
  {label: 'general', value: 'GENERAL'},
  {label: 'on-demand-beauty-tech', value: 'ON_DEMAND_BEAUTY_TECH'},
  {label: 'retail', value: 'RETAIL'},
  {label: 'commerce', value: 'COMMERCE'},
  {label: 'photo-editing', value: 'PHOTO_EDITING'},
  {label: 'video-editing', value: 'VIDEO_EDITING'},
  {label: 'selfie-editing', value: 'SELFIE_EDITING'},
  {label: 'nail', value: 'NAIL'},
  {label: 'online-photo-editing', value: 'ONLINE_PHOTO_EDITING'},
  {label: 'generative-AI', value: 'GENERATIVE_AI'},
];

const utils = {
  author: {
    getDisplayNames: (postAuthors = []) =>
      _map(postAuthors, (item) => _get(item, 'author.displayName', '')),
  },
  keyword: {
    joinKeywords: (postKeywords = [], seperator = ',') => {
      const keywords = _map(postKeywords, (item) =>
        _get(item, 'keyword.title', '')
      );
      return _join(keywords, seperator);
    },
  },
  display: {
    getPopularMaxCount: () => displayConfig.POPULAR_MAX_COUNT,
    getEmptyValue: () => displayConfig.EMPTY_VALUE,
    postsToIds: (posts) => {
      const ids = {};
      _forEach(_keys(posts), (lang) => {
        ids[lang] = _map(_get(posts, `[${lang}]`), (post) =>
          _get(post, 'id', displayConfig.EMPTY_VALUE)
        );
      });
      return ids;
    },
    initPopularIds: () => {
      const popularIds = {};
      globals.blogLanguages.forEach(
        (lang) =>
          (popularIds[lang.value] = Array(displayConfig.POPULAR_MAX_COUNT).fill(
            displayConfig.EMPTY_VALUE
          ))
      );
      return popularIds;
    },
  },
  displayTop: {
    getTopMaxCount: () => displayConfig.TOP_MAX_COUNT,
    getEmptyValue: () => displayConfig.EMPTY_VALUE,
    initTopIds: () => {
      const topIds = {};
      globals.blogLanguages.forEach(
        (lang) =>
          (topIds[lang.value] = Array(displayConfig.TOP_MAX_COUNT).fill(
            displayConfig.EMPTY_VALUE
          ))
      );
      return topIds;
    },
  },
  post: {
    getStatusConfig: (status) => _find(globals.blogStatus, {value: status}),
  },
  getLanguageLabel: (value) => {
    const language = _find(globals.blogLanguages, {value});
    return _get(language, 'label', '');
  },
  getYceLang: (languageType = 'enu') => {
    const languageCode = [
      {language: 'zh-tw', langCode: 'cht'},
      {language: 'zh-cn', langCode: 'chs'},
      {language: 'en-us', langCode: 'enu'},
      {language: 'es', langCode: 'esp'},
      {language: 'pt', langCode: 'ptb'},
      {language: 'it', langCode: 'ita'},
      {language: 'de', langCode: 'deu'},
      {language: 'ja', langCode: 'jpn'},
      {language: 'ko', langCode: 'kor'},
      {language: 'fr', langCode: 'fra'},
    ];
    return (
      languageCode.filter((s) => s.langCode === languageType)[0].language ||
      'en-us'
    );
  },
};

export default utils;
