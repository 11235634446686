import React, {useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import ConfirmDialogModal from '../../../components/dialogs/ConfirmDialogModal';

export default function ConfirmDialogButton(props) {
  const {callback, className, children, title, dropdown} = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <ConfirmDialogModal
        confirmCallback={callback}
        title={title}
        open={open}
        setOpen={setOpen}
      />
      {!dropdown ? (
        <button
          {...props}
          onClick={() => setOpen(true)}
          type="button"
          className={`btn btn-sm nowrap kt-margin-r-10 kt-margin-b-10 ${className}`}
        >
          {children}
        </button>
      ) : (
        <Dropdown.Item onClick={() => setOpen(true)}>
          <div className={`${className}`}>{children}</div>
        </Dropdown.Item>
      )}
    </>
  );
}
