import React from 'react';
import {Navigate, useOutlet, useMatches} from 'react-router-dom';
import Header from './header';
import Sidebar from './sidebar';
import PageHeader from './pageheader';
import Footer from './footer';
import {useSelector} from 'react-redux';
import Loader from '../spinner/Loader';

export const ProtectedDefaultLayout = () => {
  const matches = useMatches();
  const outlet = useOutlet();
  const auth = useSelector((state) => state.components.auth.admin);
  const isAuthCheck = useSelector((state) => state.components.auth.authCheck);

  if (!isAuthCheck) {
    return <Loader />;
  }

  if (!auth || auth.admin) {
    return <Navigate to="/sign-in" />;
  }

  const title = matches
    .filter((match) => Boolean(match.handle && match.handle.title))
    .map((match) => match.handle.title)[0];

  return (
    <>
      <div className="kt-grid kt-grid--hor kt-grid--root">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
            <Header />
            <div className="kt-container kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-grid--stretch">
              <Sidebar />
              <div className="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                  <PageHeader title={title} breadcrumbs={[]} />
                  <div className="kt-portlet__body">{outlet}</div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
