import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty'; // only detect null or undefined

const utils = {
  omit: (v) => {
    const vv = _omitBy(v, _isUndefined);
    return _omitBy(vv, (v) => !v);
  },

  omitNil: (v) => {
    return _omitBy(v, _isNil);
  },

  hexToRgb: (hex) => {
    const r = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return r
      ? {
          r: parseInt(r[1], 16),
          g: parseInt(r[2], 16),
          b: parseInt(r[3], 16),
        }
      : '';
  },
  rgbToHex: ({r, g, b}) =>
    '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1),
  getOptionName: (options, val) => {
    const obj = options.find((o) => o.value === val) || null;
    return obj == null ? '' : obj.label;
  },
  loadBaModule: () => {
    const script = document.createElement('script');
    script.src =
      '//plugins-media.perfectcorp.com/c1020408/sdk.js?apiKey=sUJG8Q2JS7enUP2K4SCSJA';
    script.async = true;
    document.body.appendChild(script);
  },
  getNestedObjValue: (nestedObj = {}, path) => {
    if (_isEmpty(nestedObj)) return;
    return path.split('.').reduce((obj, key) => obj && obj[key], nestedObj);
  },
  transformArrayToObject(array = []) {
    let result = {};
    if (array.length === 0) return {};
    array.forEach((item) => {
      const {id, value} = item;
      result[id] = value;
    });
    return result;
  },
  clearPrevAPI: (id, api, timeout = 500) => {
    clearTimeout(id);
    return setTimeout(() => {
      api();
    }, timeout);
  },
};

export default utils;
