import authDao from '../../../dao/authDao';

const RESET = 'pages/auth/signin/RESET';
const SIGN_IN = 'pages/auth/signin/SIGN_IN';
const SIGN_IN_SUCCESS = 'pages/auth/signin/SIGN_IN_SUCCESS';
const SIGN_IN_FAIL = 'pages/auth/signin/SIGN_IN_FAIL';

const initialState = {
  error: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET:
      return {...initialState};
    case SIGN_IN:
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case SIGN_IN_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export function reset() {
  return {type: RESET};
}

export function signIn(email, password) {
  return dispatch => {
    dispatch({type: SIGN_IN});

    return authDao.signIn({email: email, password: password});
  };
}

export function signInSuccess() {
  return {
    type: SIGN_IN_SUCCESS,
  };
}

export function signInFail() {
  return {
    type: SIGN_IN_FAIL,
  };
}
