import React, {useState} from 'react';
import {Formik, Field, Form} from 'formik';
import classnames from 'classnames';
import uploadDao from '../../dao/uploadDao';
import {toast} from 'react-toastify';
import FileBox from '../../components/filebox/FileBox';
export default function UploadFile() {
  const [fileUrl, setFileUrl] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const [fileName, setFileName] = useState('');

  const maxSize = '10485760';
  const formikConfig = {
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      if (!values.files) {
        toast.error('please upload a file first', {autoClose: 3000});
      } else {
        setSubmitting(true);
        const formData = new FormData();
        formData.append('pathType', 'GENERAL');
        formData.append('file', values.files);
        uploadDao
          .uploadpdf(formData)
          .then((res) => {
            setFileUrl(res);
          })
          .then(() => {
            setSubmitted(true);
            document.getElementById('filePicker').value = '';
            setSubmitting(false);
          });
      }
    },
  };

  return (
    <div>
      <Formik {...formikConfig}>
        {(formikProps) => (
          <Form id="join-Form">
            <div className={'form-group row'}>
              <Field
                name={'files'}
                id="files"
                maxSize="10485760"
                component={FileBox}
                accept={'application/pdf'}
                uploadButtonText={'upload PDF'}
                displayText={
                  '*Edit the file name as link path before uploading. File size <= 10MB'
                }
                onFileChange={(file) => {
                  if (file.size > maxSize) {
                    toast.error('檔案過大', {autoClose: 3000});
                  } else {
                    setSubmitted(false);
                    formikProps.setFieldValue('files', file);
                    setFileName(file.name);
                  }
                }}
                fileName={!submitted ? fileName : ''}
              />
            </div>

            {!submitted ? (
              <div>
                <button
                  type="submit"
                  className={classnames({
                    btn: true,
                    'btn-primary': true,
                    'kt-margin-r-10': true,
                    'kt-margin-b-10': true,
                    'kt-spinner': true,
                    'kt-spinner-v2': true,
                    'kt-spinner--center': true,
                    'kt-spinner--sm': true,
                    'kt-spinner--warning': formikProps.isSubmitting,
                  })}
                  style={{flex: '0 0 300px', marginLeft: 135}}
                >
                  <div
                    style={{
                      visibility: formikProps.isSubmitting
                        ? 'hidden'
                        : 'visible',
                    }}
                  >
                    Submit
                  </div>
                </button>
              </div>
            ) : (
              <div>
                {' '}
                <div style={{flex: '0 0 300px'}}></div>{' '}
                <div style={{marginLeft: 135}}>pdf url: {fileUrl}</div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
