export default (t) => [
  {
    id: 1000,
    title: t('u.r.router.menu.1000.title', 'Home'),
    items: [
      {
        id: 1100,
        title: t('u.r.router.menu.1000.title', 'Home'),
        link: '/',
        icon: 'la la-home',
        items: [],
      },
    ],
  },
  {
    id: 20000,
    title: t('u.r.router.menu.20000.title', 'admin management'),
    privilegeType: 'P100_ADM_PRIVILEGES',
    items: [
      {
        id: 20100,
        icon: 'la la-trophy',
        title: t('u.r.router.menu.20100.title', 'Admin Management'),
        link: '/admin/list-admin',
      },
    ],
  },
  {
    id: 4000,
    title: t('u.r.router.menu.4000.title', 'Partners'),
    privilegeType: 'P103_ADM_PARTNERS',
    items: [
      {
        id: 4100,
        title: t('u.r.router.menu.4000.title', 'Partners'),
        link: '/partners/list-partners',
        icon: 'la la-user-plus',
        items: [],
      },
    ],
  },
  {
    id: 5000,
    title: t('u.r.router.menu.5000.title', 'Success Story'),
    privilegeType: 'P104_ADM_STORIES',
    items: [
      {
        id: 5100,
        title: t('u.r.router.menu.5000.title', 'Success Story'),
        link: '/stories/list-stories',
        icon: 'la la-trophy',
        items: [],
      },
    ],
  },
  {
    id: 11000,
    title: t('u.r.router.menu.11000.title', 'Blog'),
    privilegeType: 'P110_BLOG',
    items: [
      {
        id: 11100,
        title: t('u.r.router.menu.11000.title', 'Blog'),
        privilegeType: 'P110_BLOG',
        icon: 'la la-newspaper-o',
        link: '/blog/post/list-posts',
        items: [
          {
            id: 11110,
            title: t('u.r.router.menu.11100.title', 'Posts'),
            link: '/blog/post/list-posts',
          },
          {
            id: 11120,
            title: t('u.r.router.menu.11200.title', 'Authors'),
            link: '/blog/author/list-authors',
          },
          {
            id: 11140,
            privilegeType: 'P122_DEAD_LINK_WHITELISTS',
            title: t('u.r.router.menu.11400.title', 'Dead Link Whitelists'),
            link: '/blog/dead-link/list-dead-link-whitelists',
          },
        ],
      },
    ],
  },
  {
    id: 14000,
    title: t('u.r.router.menu.14000.title', 'SEO Meta Settings'),
    privilegeType: 'P113_SEO_META',
    items: [
      {
        id: 14100,
        title: t('u.r.router.menu.14100.title', 'SEO Meta Settings'),
        privilegeType: 'P113_SEO_META',
        link: '/seo/list-meta',
        icon: 'la la-file-code-o',
        items: [],
      },
    ],
  },
  {
    id: 16000,
    title: t('u.r.router.menu.16000.title', 'Master Series'),
    privilegeType: 'P115_MASTER_SERIES',
    items: [
      {
        id: 16100,
        title: t('u.r.router.menu.16100.title', 'Master Series'),
        privilegeType: 'P115_MASTER_SERIES',
        icon: 'la la-plug',
        link: '/masterseries/list-courses',
        items: [
          {
            id: 16110,
            title: t('u.r.router.menu.16110.title', 'Courses'),
            link: '/masterseries/list-courses',
          },
          {
            id: 16120,
            title: t('u.r.router.menu.16120.title', 'Speakers'),
            link: '/masterseries/list-speakers',
          },
          {
            id: 16130,
            title: t('u.r.router.menu.16130.title', 'Sessions'),
            link: '/masterseries/list-sessions',
          },
        ],
      },
    ],
  },
  {
    id: 3000,
    title: t('u.r.router.menu.3000.title', 'News'),
    privilegeType: 'P101_ADM_NEWS',
    items: [
      {
        id: 3100,
        title: t('u.r.router.menu.3000.title', 'News'),
        link: '/news/list-news',
        icon: 'la la-newspaper-o',
        items: [],
      },
    ],
  },
  {
    id: 6000,
    title: t('u.r.router.menu.6000.title', 'Videos'),
    privilegeType: 'P105_ADM_VIDEOS',
    items: [
      {
        id: 6100,
        title: t('u.r.router.menu.6000.title', 'Videos'),
        link: '/videos/list-videos',
        icon: 'la la-youtube',
        items: [
          {
            id: 6110,
            title: 'Posts',
            link: '/videos/list-videos',
          },
          {
            id: 6120,
            title: 'Featured',
            link: '/videos/list-featured',
          },
        ],
      },
    ],
  },
  {
    id: 7000,
    title: t('u.r.router.menu.7000.title', 'Color Reports'),
    privilegeType: 'P106_COLOR_REPORTS',
    items: [
      {
        id: 7100,
        title: t('u.r.router.menu.7000.title', 'Color Reports'),
        link: '/reports/list-reports',
        icon: 'la la-file-pdf-o',
        items: [],
      },
    ],
  },
  {
    id: 15000,
    title: t('u.r.router.menu.15000.title', 'Awards'),
    privilegeType: 'P114_AWARDS',
    items: [
      {
        id: 15100,
        title: t('u.r.router.menu.15100.title', 'Awards'),
        privilegeType: 'P114_AWARDS',
        link: '/awards/list-awards',
        icon: 'la la-star-o',
        items: [],
      },
    ],
  },
  {
    id: 2000,
    title: t('u.r.router.menu.2000.title', 'FAQ'),
    privilegeType: 'P102_ADM_FAQS',
    items: [
      {
        id: 2100,
        title: t('u.r.router.menu.2000.title', 'FAQ'),
        link: '/faqs/list-faqs',
        icon: 'la la-lightbulb-o',
        items: [],
      },
    ],
  },
  {
    id: 8000,
    title: t('u.r.router.menu.8000.title', 'App FAQ'),
    privilegeType: 'P108_APP_FAQS',
    items: [
      {
        id: 8100,
        title: t('u.r.router.menu.8000.title', 'App FAQ'),
        link: '/appfaqs/list-faqs',
        icon: 'la la-info-circle',
        items: [
          {
            id: 8110,
            title: 'FAQs',
            link: '/appfaqs/list-faqs',
          },
          {
            id: 8120,
            title: 'Categories',
            link: '/appfaqs/list-categories',
          },
        ],
      },
    ],
  },
  {
    id: 10000,
    title: t('u.r.router.menu.10000.title', 'Shopify'),
    privilegeType: 'P109_SHOPIFY',
    items: [
      {
        id: 10100,
        title: t('u.r.router.menu.10000.title', 'Shopify'),
        link: '/shopify/list-customers',
        icon: 'la la-shopping-cart',
        items: [
          {
            id: 10110,
            title: t('u.r.router.menu.10110.title', 'Customers'),
            link: '/shopify/list-customers',
          },
          {
            id: 10120,
            title: t('u.r.router.menu.10120.title', 'Report'),
            link: '/shopify/list-reports',
          },
          {
            id: 10130,
            title: t('u.r.router.menu.10130.title', 'Affiliations'),
            link: '/shopify/list-affiliations',
          },
        ],
      },
    ],
  },
  {
    id: 12000,
    title: t('u.r.router.menu.12000.title', 'BA 1 on 1'),
    privilegeType: 'P111_BA_1ON1',
    items: [
      {
        id: 12100,
        title: t('u.r.router.menu.12100.title', 'BA 1 on 1'),
        link: '/ba-1on1',
      },
    ],
  },
  {
    id: 13000,
    title: t('u.r.router.menu.13000.title', 'Customer Settings'),
    privilegeType: 'P112_CUSTOMER_SETTINGS',
    items: [
      {
        id: 13100,
        title: t('u.r.router.menu.13100.title', 'Customer Settings'),
        privilegeType: 'P112_CUSTOMER_SETTINGS',
        link: '/customer-settings',
        icon: 'la la-user',
        items: [
          {
            id: 13110,
            title: t('u.r.router.menu.13100.title', 'Customer Settings'),
            link: '/customer-settings',
          },
          {
            id: 13120,
            title: t('u.r.router.menu.13110.title', 'Whitelist Settings'),
            link: '/customer-settings/whitelist-settings',
          },
        ],
      },
    ],
  },
  {
    id: 19000,
    title: t('u.r.router.menu.19000.title', 'Online Service'),
    privilegeType: 'P119_ONLINE_SERVICE',
    items: [
      {
        id: 19000,
        title: t('u.r.router.menu.19000.title', 'Online Service'),
        privilegeType: 'P119_ONLINE_SERVICE',
        icon: 'fa fa-globe',
        link: '/onlineservice/list-customers',
        items: [
          {
            id: 19100,
            title: t('u.r.router.menu.19100.title', 'Customers'),
            link: '/onlineservice/list-customers',
          },
          {
            id: 19200,
            title: t('u.r.router.menu.19200.title', 'Subscriptions'),
            link: '/onlineservice/list-subscriptions',
          },
          {
            id: 19300,
            title: t('u.r.router.menu.19300.title', 'Technical Support'),
            privilegeType: 'P123_TECHNICAL_SUPPORT',
            link: '/onlineservice/technical-support',
          },
        ],
      },
    ],
  },
  {
    id: 17000,
    title: t('u.r.router.menu.17000.title', 'UPLOAD FILE'),
    privilegeType: 'P116_UPLOAD_FILE',
    items: [
      {
        id: 17100,
        title: t('u.r.router.menu.17100.title', 'Upload File'),
        privilegeType: 'P116_UPLOAD_FILE',
        icon: 'fa fa-upload',
        link: '/upload/pdf',
        items: [
          {
            id: 17110,
            title: t('u.r.router.menu.17100.title', 'PDF'),
            link: '/upload/pdf',
          },
        ],
      },
    ],
  },
  {
    id: 18000,
    title: t('u.r.router.menu.18000.title', 'project msr'),
    privilegeType: 'P117_PROJECT_MSR',
    items: [
      {
        id: 18100,
        title: t('u.r.router.menu.18100.title', 'Project MSR'),
        privilegeType: 'P117_PROJECT_MSR',
        icon: 'fa fa-user',
        link: '/project-msr',
        items: [
          {
            id: 18110,
            title: t('u.r.router.menu.18100.title', 'Project MSR'),
            link: '/project-msr',
          },
        ],
      },
    ],
  },
];
