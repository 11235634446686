import React from 'react';
import {Trans} from 'react-i18next';
import {Navigate} from 'react-router-dom';
import AsyncListFAQs from './listfaqs/async';
import AsyncListFAQCategories from './listcategories/async';
import AsyncAddOrEditFAQCategory from './addoreditcategory/async';

const parent = {
  title: <Trans i18nKey="p.a.appfaqs.listfaqs.title">App FAQs</Trans>,
  path: '/appfaqs',
};
export default [
  {
    path: '/appfaqs/list-faqs',
    element: <AsyncListFAQs />,
    handle: {
      title: <Trans i18nKey="p.a.appfaqs.listfaqs.title">App FAQs</Trans>,
      subtitle: <Trans i18nKey="p.a.appfaqs.listfaqs.title">App FAQs</Trans>,
      breadcrumbs: [parent],
    },
  },
  {
    path: '/appfaqs/list-categories',
    element: <AsyncListFAQCategories />,
    handle: {
      title: (
        <Trans i18nKey="p.a.appfaqs.listcategory.list.title">
          List Category
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.a.appfaqs.listcategory.list.title">
          List Category
        </Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/appfaqs/add-category',
    element: <AsyncAddOrEditFAQCategory />,
    handle: {
      title: (
        <Trans i18nKey="p.a.appfaqs.addoreditcategory.edit.title">
          Add Category
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.a.appfaqs.addoreditcategory.edit.title">
          Add Category
        </Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/appfaqs/edit-category',
    element: <AsyncAddOrEditFAQCategory />,
    handle: {
      title: (
        <Trans i18nKey="p.a.appfaqs.addoreditcategory.edit.title">
          Edit Category
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.a.appfaqs.addoreditcategory.add.title">
          Edit Category
        </Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/appfaqs',
    element: <Navigate to="/appfaqs/list-faqs" />,
  },
];
