import React from 'react';
import {Trans} from 'react-i18next';
import {Navigate} from 'react-router-dom';
import AsyncListPartners from './listpartners/async';

const parent = {
  title: <Trans i18nKey="p.n.news.listpartners.title">Partners</Trans>,
  path: '/partners',
};
export default [
  {
    path: '/partners/list-partners',
    element: <AsyncListPartners />,
    handle: {
      title: <Trans i18nKey="p.n.partners.listpartners.title">Partners</Trans>,
      subtitle: (
        <Trans i18nKey="p.n.partners.listpartners.title">Partners</Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/partners',
    element: <Navigate to="/partners/list-partners" />,
  },
];
