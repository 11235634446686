import React from 'react';
import {ButtonGroup, DropdownButton} from 'react-bootstrap';

export default function ActionButtonGroups({dropdown, value, isConverting}) {
  const buttons = value.filter(
    (b, index) => !dropdown[index] && b.availability
  );
  const dropdownButtons = value.filter(
    (b, index) => dropdown[index] && b.availability
  );

  return (
    <>
      {buttons.map((btn) => (
        <div className="" style={{verticalAlign: 'middle', width: '100%'}}>
          {btn.item}
        </div>
      ))}
      <div style={{verticalAlign: 'middle', width: '100%'}}>
        <DropdownButton
          variant="secondary"
          className="kt-margin-r-10 kt-margin-b-10 nowrap outline-success"
          as={ButtonGroup}
          title="Actions"
          disabled={isConverting}
          style={{cursor: isConverting ? 'not-allowed' : 'pointer'}}
        >
          {dropdownButtons.map((btn) => btn.item)}
        </DropdownButton>
      </div>
    </>
  );
}
