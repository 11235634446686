import React from 'react';
import {Trans} from 'react-i18next';
import _sortBy from 'lodash/sortBy';

export default {
  languages: [
    {value: 'ind', label: 'Bahasa Indonesia'},
    {value: 'msa', label: 'Bahasa Malaysia'},
    {value: 'deu', label: 'Deutsch'},
    {value: 'enu', label: 'English'},
    {value: 'esp', label: 'Español'},
    {value: 'fra', label: 'Français'},
    {value: 'ita', label: 'Italiano'},
    {value: 'nld', label: 'Nederlands'},
    {value: 'ptb', label: 'Português'},
    {value: 'rus', label: 'Русский'},
    {value: 'tur', label: 'Türkçe'},
    {value: 'cht', label: '正體中文'},
    {value: 'chs', label: '简体中文'},
    {value: 'jpn', label: '日本語'},
    {value: 'kor', label: '한국어'},
    {value: 'tha', label: 'ภาษาไท'},
    {value: 'ukr', label: 'Ukraine (Україна)'},
    {value: 'vie', label: 'Vietnamese'},
    {value: 'ces', label: 'Czech'},
    {value: 'hun', label: 'Hungarian'},
    {value: 'pol', label: 'Polish'},
    {value: 'rum', label: 'Romanian'},
    {value: 'bul', label: 'Bulgarian'},
    {value: 'dan', label: 'Danmark'},
    {value: 'fin', label: 'Finland'},
    {value: 'nor', label: 'Norwegian'},
    {value: 'swe', label: 'Sweden'},
    {value: 'ara', label: 'العربية'},
  ],
  newsLanguages: [
    {value: 'enu', label: 'English', i18n: 'en'},
    {value: 'cht', label: '正體中文', i18n: 'tw'},
    {value: 'chs', label: '简体中文', i18n: 'cn'},
    {value: 'jpn', label: '日本語', i18n: 'ja'},
    {value: 'kor', label: '한국어', i18n: 'ko'},
    {value: 'deu', label: 'Deutsch', i18n: 'de'},
    {value: 'fra', label: 'Français', i18n: 'fr'},
    {value: 'esp', label: 'Español', i18n: 'es'},
    {value: 'ptb', label: 'Português', i18n: 'pt'},
    {value: 'ita', label: 'Italiano', i18n: 'it'},
  ],
  newsTypes: [
    {value: 'NEWS', label: 'NEWS', i18n: 'en'},
    {value: 'MEDIA', label: 'MEDIA', i18n: 'en'},
  ],
  storyLanguages: [
    {value: 'enu', label: 'English', i18n: 'en'},
    {value: 'cht', label: '正體中文', i18n: 'tw'},
    {value: 'chs', label: '简体中文', i18n: 'cn'},
    {value: 'jpn', label: '日本語', i18n: 'ja'},
    {value: 'kor', label: '한국어', i18n: 'ko'},
    {value: 'deu', label: 'Deutsch', i18n: 'de'},
    {value: 'fra', label: 'Français', i18n: 'fr'},
    {value: 'esp', label: 'Español', i18n: 'es'},
    {value: 'ptb', label: 'Português', i18n: 'pt'},
    {value: 'ita', label: 'Italiano', i18n: 'it'},
  ],
  storyTypes: [
    {value: 'ENTERPRISE', label: 'ENTERPRISE', i18n: 'en'},
    {value: 'SMB', label: 'SMB', i18n: 'en'},
  ],
  seoMetaTypes: [
    {value: 'NEWS', label: 'News', i18n: 'en'},
    {value: 'BLOG', label: 'Blog', i18n: 'en'},
    {value: 'VIDEO', label: 'Video', i18n: 'en'},
    {value: 'STORY', label: 'Story', i18n: 'en'},
    {value: 'PAGE', label: 'Page', i18n: 'en'},
  ],
  reportLanguages: [
    {value: 'enu', label: 'English', i18n: 'en'},
    {value: 'cht', label: '正體中文', i18n: 'tw'},
    {value: 'chs', label: '简体中文', i18n: 'cn'},
    {value: 'jpn', label: '日本語', i18n: 'ja'},
    {value: 'kor', label: '한국어', i18n: 'ko'},
    {value: 'deu', label: 'Deutsch', i18n: 'de'},
    {value: 'fra', label: 'Français', i18n: 'fr'},
    {value: 'esp', label: 'Español', i18n: 'es'},
    {value: 'ita', label: 'Italiano', i18n: 'it'},
  ],
  blogLanguages: [
    {value: 'enu', label: 'English', i18n: 'en'},
    {value: 'cht', label: '正體中文', i18n: 'tw'},
    {value: 'chs', label: '简体中文', i18n: 'zh-CN'},
    {value: 'jpn', label: '日本語', i18n: 'jp'},
    {value: 'fra', label: 'Français', i18n: 'fr'},
    {value: 'kor', label: '한국어', i18n: 'ko'},
    {value: 'deu', label: 'Deutsch', i18n: 'de'},
    {value: 'esp', label: 'Español', i18n: 'es'},
    {value: 'ita', label: 'Italiano', i18n: 'it'},
    {value: 'ptb', label: 'Português', i18n: 'pt'},
  ],
  awardsLanguages: [
    {value: 'enu', label: 'English', i18n: 'en'},
    {value: 'cht', label: '正體中文', i18n: 'tw'},
    {value: 'chs', label: '简体中文', i18n: 'zh-CN'},
    {value: 'jpn', label: '日本語', i18n: 'jp'},
    {value: 'fra', label: 'Français', i18n: 'fr'},
    {value: 'kor', label: '한국어', i18n: 'ko'},
    {value: 'deu', label: 'Deutsch', i18n: 'de'},
    {value: 'esp', label: 'Español', i18n: 'es'},
    {value: 'ita', label: 'Italiano', i18n: 'it'},
  ],
  courseLanguages: [
    {value: 'enu', label: 'English', i18n: 'en'},
    {value: 'cht', label: '正體中文', i18n: 'tw'},
    {value: 'chs', label: '简体中文', i18n: 'zh-CN'},
    {value: 'jpn', label: '日本語', i18n: 'jp'},
    {value: 'fra', label: 'Français', i18n: 'fr'},
    {value: 'kor', label: '한국어', i18n: 'ko'},
    {value: 'deu', label: 'Deutsch', i18n: 'de'},
    {value: 'esp', label: 'Español', i18n: 'es'},
    {value: 'ita', label: 'Italiano', i18n: 'it'},
  ],
  blogFocus: [
    {code: 'BF_BUSINESS_FOCUS', label: 'Business Focus', value: 'business'},
    {code: 'CF_CONSUMER_FOCUS', label: 'Consumer Focus', value: 'consumer'},
    {code: 'YUC_YCE_USECASE', label: 'YCE UseCase', value: 'yce'},
  ],
  faqCategories: [
    {value: 'SubscriptionManagement', label: 'Subscription Management'},
    {value: 'AccountPayments', label: 'Account & Payments'},
    {value: 'TroubleShooting', label: 'TroubleShooting'},
    {value: 'IntegrationOfYoucam', label: 'Integration of YouCam for Web'},
    {value: 'ShopifyApp', label: 'Shopify Application'},
    {value: 'Partner', label: 'Partner'},
    {value: 'Terminology', label: 'Terminology'},
    {value: 'PlanPricingPage', label: 'PlanPricing Page_Makeup'},
    {value: 'PlanPricingPageForEyewear', label: 'PlanPricing Page_Eyewear'},
    {
      value: 'PlanPricingPageForSkincarePro',
      label: 'PlanPricing Page_SkincarePro',
    },
    {value: 'SkincarePro', label: 'Skincare Pro'},
    {value: 'YceWeb', label: 'Yce Web'},
    {value: 'ShopifyAppEyewear', label: 'Shopify App Eyewear'},
    {
      value: 'PlanPricingPageForSkincareProSimulation',
      label: 'PlanPricing Page SkincarePro Simulation',
    },
  ],
  newsCategories: [
    {value: 'Business', label: 'Business'},
    {value: 'Consumer', label: 'Consumer'},
  ],
  newsStatus: [
    {value: 'DRAFT', label: 'Draft'},
    {value: 'REVIEWING', label: 'Reviewing'},
    {value: 'PUBLISHED', label: 'Published'},
    {value: 'REJECTED', label: 'Rejected'},
    {value: 'TAKEN_DOWN', label: 'Taken Down'},
  ],
  storyStatus: [
    {value: 'DRAFT', label: 'Draft'},
    {value: 'REVIEWING', label: 'Reviewing'},
    {value: 'PUBLISHED', label: 'Published'},
    {value: 'REJECTED', label: 'Rejected'},
    {value: 'TAKEN_DOWN', label: 'Taken Down'},
  ],
  blogStatus: [
    {value: 'DRAFT', label: 'Draft', color: 'black'},
    {value: 'REVIEWING', label: 'Reviewing', color: 'darkorange'},
    {value: 'PUBLISHED', label: 'Published', color: 'lightseagreen'},
    {value: 'REJECTED', label: 'Rejected', color: 'red'},
    {value: 'TAKEN_DOWN', label: 'Taken Down', color: 'slategrey'},
  ],
  reportStatus: [
    {value: 'DRAFT', label: 'Draft'},
    {value: 'REVIEWING', label: 'Reviewing'},
    {value: 'PUBLISHED', label: 'Published'},
    {value: 'REJECTED', label: 'Rejected'},
    {value: 'TAKEN_DOWN', label: 'Taken Down'},
  ],
  partnerStatus: [
    {value: 'APPROVED', label: 'Approved'},
    {value: 'REJECTED', label: 'Rejected'},
    {value: 'SUBMITTED', label: 'Submitted'},
  ],
  awardsStatus: [
    {value: 'DRAFT', label: 'Draft'},
    {value: 'REVIEWING', label: 'Reviewing'},
    {value: 'PUBLISHED', label: 'Published'},
    {value: 'REJECTED', label: 'Rejected'},
    {value: 'TAKEN_DOWN', label: 'Taken Down'},
  ],
  courseType: [
    {value: 'VIRTUAL_EVENT', label: 'Virtual Event'},
    {value: 'EDUCATION', label: 'Education'},
    {value: 'WEBINAR', label: 'Webinar'},
  ],
  pageType: [
    {value: 'EVENT_PAGE', label: 'Event Page'},
    {value: 'REPLAY_PAGE', label: 'Replay Page'},
  ],
  registerType: [
    {value: 'register', label: 'Register'},
    {value: 'pardot', label: 'Pardot'},
  ],
  sessionStatus: [
    {value: 'DRAFT', label: 'Draft'},
    {value: 'REVIEWING', label: 'Reviewing'},
    {value: 'PUBLISHED', label: 'Published'},
    {value: 'REJECTED', label: 'Rejected'},
    {value: 'TAKEN_DOWN', label: 'Taken Down'},
  ],
  businessTypes: [
    {
      value: 'MAKEUP',
      label: (
        <Trans i18nKey="u.c.config.globals.business.type.makeup">Makeup</Trans>
      ),
    },
    {
      value: 'SKINCARE',
      label: (
        <Trans i18nKey="u.c.config.globals.business.type.skincare">
          Skincare
        </Trans>
      ),
    },
    {
      value: 'COLORLENS',
      label: (
        <Trans i18nKey="u.c.config.globals.business.type.color.lenses">
          Color Lenses
        </Trans>
      ),
    },
    {
      value: 'EYEWEAR',
      label: (
        <Trans i18nKey="u.c.config.globals.business.type.eyewear">
          Eyewear
        </Trans>
      ),
    },
    {
      value: 'HAIRRELATED',
      label: (
        <Trans i18nKey="u.c.config.globals.business.type.hair.related">
          Hair Related
        </Trans>
      ),
    },
    {
      value: 'JEWELRY',
      label: (
        <Trans i18nKey="u.c.config.globals.business.type.jewelry">
          Jewelry
        </Trans>
      ),
    },
    {
      value: 'OTHERS',
      label: (
        <Trans i18nKey="u.c.config.globals.business.type.others">Others</Trans>
      ),
    },
  ],
  numberOfEmployees: [
    {
      value: 'C050',
      label: (
        <Trans i18nKey="u.c.config.globals.employee.c050">
          1 - 50 employees
        </Trans>
      ),
    },
    {
      value: 'C100',
      label: (
        <Trans i18nKey="u.c.config.globals.employee.c100">
          51 - 100 employees
        </Trans>
      ),
    },
    {
      value: 'C200',
      label: (
        <Trans i18nKey="u.c.config.globals.employee.c200">
          101 - 200 employees
        </Trans>
      ),
    },
    {
      value: 'C300',
      label: (
        <Trans i18nKey="u.c.config.globals.employee.c300">
          201 - 300 employees
        </Trans>
      ),
    },
    {
      value: 'C301500',
      label: (
        <Trans i18nKey="u.c.config.globals.employee.c301500">
          301 - 500 employees
        </Trans>
      ),
    },
    {
      value: 'C999',
      label: (
        <Trans i18nKey="u.c.config.globals.employee.c999">500+ employees</Trans>
      ),
    },
  ],
  video: {
    productTypes: _sortBy(
      [
        {
          value: 'P01_VIRTUAL_MAKEUP',
          label: 'AI-Powered Virtual Makeup',
        },
        {
          value: 'P02_AI_SMART_SHADE_FINDER',
          label: 'AI Smart Shade Finder',
        },
        {
          value: 'P03_AI_FACIAL_RECOGNITION',
          label: 'AI Facial Recognition',
        },
        {
          value: 'P04_AR_SKIN_DIAGNOSTIC',
          label: 'AR Skin Diagnostic',
        },
        {
          value: 'P05_IN_STORE_BARCODE_TRY_ON',
          label: 'In-store Barcode Try on',
        },
        {
          value: 'P06_BEAUTY_ADVISOR_1_ON_1',
          label: 'Beauty Advisor 1-on-1',
        },
        {
          value: 'P07_LIVE_AR_FOR_CORPORATE_TRAINING',
          label: 'Live AR For Corporate Training',
        },
        {
          value: 'P08_LIVE_STREAMING_FOR_BRANDS',
          label: 'Live Streaming For Brands',
        },
        {
          value: 'P09_LIVE_HAIR_COLOR',
          label: 'Live Hair Color',
        },
        {
          value: 'P10_VIRTUAL_ACCESSORIES',
          label: 'Hat and Hairband Virtual Try-On',
        },
        {
          value: 'P11_PRODUCT_RECOMMENDATION',
          label: 'AI Product Recommendation',
        },
        {
          value: 'P13_YOUCAM_TUTORIAL',
          label: 'YouCam Tutorial',
        },
        {
          value: 'P14_VIRTUAL_EYEWEAR',
          label: 'Virtual Try-On for Glasses',
        },
        {
          value: 'P15_FACE_ANALYZER',
          label: 'AI Face Analyzer',
        },
        {
          value: 'P16_AI_BEARD_DYE',
          label: 'AI Beard Dye and Beard Style',
        },
      ],
      ['label']
    ),
    videoTypes: [
      {
        label: 'Intro',
        value: 'INTRO',
      },
      {
        label: 'Demo',
        value: 'DEMO',
      },
      {
        label: 'Interview',
        value: 'INTERVIEW',
      },
      {
        label: 'Webinar',
        value: 'WEBINAR',
      },
    ],
    serviceTypes: _sortBy(
      [
        {
          value: 'S01_IN_STORE_CONSULTATION',
          label: 'In-Store Consultation',
        },
        {
          value: 'S02_YOUCAM_FOR_WEB',
          label: 'YouCam for Web',
        },
        {
          value: 'S03_MOBILE_SDK',
          label: 'Mobile SDK',
        },
        {
          value: 'S04_FACEBOOK_AR_CHATBOT',
          label: 'Facebook AR Chatbot',
        },
        {
          value: 'S05_WECHAT_MINI_PROGRAM',
          label: 'WeChat Mini Program',
        },
        {
          value: 'S06_YOUCAM_MAKEUP_APP_LISTING',
          label: 'YouCam Makeup App Listing',
        },
        {
          value: 'S07_AR_GOOGLE_YOUTUBE',
          label: 'Google/YouTube AR',
        },
        {
          value: 'S08_AR_SNAPCHAT',
          label: 'SnapChat AR',
        },
        {
          value: 'S09_AR_INSTAGRAM',
          label: 'Instagram AR',
        },
        {
          value: 'S10_TAOBAO_MINI_PROGRAM',
          label: 'Taobao Mini Program',
        },
        {
          value: 'S11_DOUYIN_MINI_PROGRAM',
          label: 'Douyin Mini Program',
        },
      ],
      ['label']
    ),
    languages: [
      {label: 'English', value: 'enu'},
      {label: '日本語', value: 'jpn'},
      {label: '正體中文', value: 'cht'},
      {label: '简体中文', value: 'chs'},
      {label: 'Français', value: 'fra'},
      {label: 'Deutsch', value: 'deu'},
      {label: '한국어', value: 'kor'},
      {label: 'Español', value: 'esp'},
      {label: 'Italiano', value: 'ita'},
    ],
  },
  auth: {
    privilegeTypes: [
      {value: 'P100_ADM_PRIVILEGES', label: 'P100_ADM_PRIVILEGES'},
      {value: 'P101_ADM_NEWS', label: 'P101_ADM_NEWS'},
      {value: 'P102_ADM_FAQS', label: 'P102_ADM_FAQS'},
      {value: 'P103_ADM_PARTNERS', label: 'P103_ADM_PARTNERS'},
      {value: 'P104_ADM_STORIES', label: 'P104_ADM_STORIES'},
      {value: 'P105_ADM_VIDEOS', label: 'P105_ADM_VIDEOS'},
      {value: 'P106_COLOR_REPORTS', label: 'P106_COLOR_REPORTS'},
      {value: 'P107_ADM_BTF', label: 'P107_ADM_BTF'},
      {value: 'P108_APP_FAQS', label: 'P108_APP_FAQS'},
      {value: 'P109_SHOPIFY', label: 'P109_SHOPIFY'},
      {value: 'P110_BLOG', label: 'P110_BLOG'},
      {value: 'P111_BA_1ON1', label: 'P111_BA_1ON1'},
      {value: 'P112_CUSTOMER_SETTINGS', label: 'P112_CUSTOMER_SETTINGS'},
      {value: 'P113_SEO_META', label: 'P113_SEO_META'},
      {value: 'P114_AWARDS', label: 'P114_AWARDS'},
      {value: 'P115_MASTER_SERIES', label: 'P115_MASTER_SERIES'},
      {value: 'P116_UPLOAD_FILE', label: 'P116_UPLOAD_FILE'},
      {value: 'P117_PROJECT_MSR', label: 'P117_PROJECT_MSR'},
      {value: 'P118_PRERENDER_TOOL', label: 'P118_PRERENDER_TOOL'},
      {value: 'P119_ONLINE_SERVICE', label: 'P119_ONLINE_SERVICE'},
      {value: 'P120_STRAPI_ADMIN', label: 'P120_STRAPI_ADMIN'},
      {value: 'P121_EXTEND_END_DATE', label: 'P121_EXTEND_END_DATE'},
      {value: 'P122_DEAD_LINK_WHITELISTS', label: 'P122_DEAD_LINK_WHITELISTS'},
      {value: 'P123_TECHNICAL_SUPPORT', label: 'P123_TECHNICAL_SUPPORT'},
    ],
  },
  story: {
    productTypes: [
      {
        value: 'P01_VIRTUAL_MAKEUP',
        label: 'AI-Powered Virtual Makeup',
      },
      {
        value: 'P02_AI_SMART_SHADE_FINDER',
        label: 'AI Smart Shade Finder',
      },
      {
        value: 'P03_AI_FACIAL_RECOGNITION',
        label: 'AI Facial Recognition',
      },
      {
        value: 'P04_AR_SKIN_DIAGNOSTIC',
        label: 'AR Skin Diagnostic',
      },
      {
        value: 'P05_IN_STORE_BARCODE_TRY_ON',
        label: 'In-Store Barcode Try On',
      },
      {
        value: 'P06_BEAUTY_ADVISOR_1_ON_1',
        label: 'Beauty Advisor 1-on-1',
      },
      {
        value: 'P07_LIVE_AR_FOR_CORPORATE_TRAINING',
        label: 'Live AR for Corporate Training',
      },
      {
        value: 'P08_LIVE_STREAMING_FOR_BRANDS',
        label: 'Live Streaming for Brands',
      },
      {
        value: 'P09_LIVE_HAIR_COLOR',
        label: 'Live Hair Color',
      },
      {
        value: 'P10_VIRTUAL_ACCESSORIES',
        label: 'Hat and Headband Virtual Try-On',
      },
      {
        value: 'P11_PRODUCT_RECOMMENDATION',
        label: 'AI Product Recommendation',
      },
      {
        value: 'P13_YOUCAM_TUTORIAL',
        label: 'YouCam Tutorial',
      },
      {
        value: 'P14_VIRTUAL_EYEWEAR',
        label: 'Virtual Try-On for Glasses',
      },
      {
        value: 'P15_FACE_ANALYZER',
        label: 'AI Face Analyzer',
      },
      {
        value: 'P16_AI_BEARD_DYE',
        label: 'AI Beard Dye and Beard Style',
      },
      {
        value: 'P17_VIRTUAL_TRY_ON_FOR_NAILS',
        label: 'Virtual Try-On for Nails',
      },
      {
        value: 'P18_AR_VIRTUAL_RING_TRY_ON',
        label: 'AR Virtual Ring Try-On',
      },
      {
        value: 'P19_AR_BRACELET_VIRTUAL_TRY_ON',
        label: 'AR Bracelet Virtual Try-On',
      },
      {
        value: 'P20_AR_WATCH_VIRTUAL_TRY_ON',
        label: 'AR Watch Virtual Try-On',
      },
      {
        value: 'P21_AR_EARRING_VIRTUAL_TRY_ON',
        label: 'AR Earring Virtual Try-On',
      },
      {
        value: 'P22_AI_NECKLACE_VIRTUAL_TRY_ON',
        label: 'AI Necklace Virtual Try-On',
      },
      {
        value: 'P23_AI_VIRTUAL_BACKGROUND_CHANGER',
        label: 'AI Virtual Background Changer',
      },
      {
        value: 'P24_AI_FACE_RESHAPE_SIMULATOR',
        label: 'AI Face Reshape Simulator',
      },
      {
        value: 'P25_AI_PERSONALITY_FINDER',
        label: 'AI Personality Finder',
      },
      {
        value: 'P26_AR_HAIRSTYLE_VIRTUAL_TRY_ON',
        label: 'AR Hairstyle Virtual Try-On',
      },
      {
        value: 'P27_AI_SKIN_EMULATION',
        label: 'AI Skin Emulation',
      },
    ],
    serviceTypes: [
      {
        value: 'S01_IN_STORE_CONSULTATION',
        label: 'In-Store Consultation',
      },
      {
        value: 'S02_YOUCAM_FOR_WEB',
        label: 'YouCam for Web',
      },
      {
        value: 'S03_MOBILE_SDK',
        label: 'Mobile SDK',
      },
      {
        value: 'S04_FACEBOOK_AR_CHATBOT',
        label: 'Facebook AR Chatbot',
      },
      {
        value: 'S05_WECHAT_MINI_PROGRAM',
        label: 'WeChat Mini Program',
      },
      {
        value: 'S06_YOUCAM_MAKEUP_APP_LISTING',
        label: 'YouCam Makeup App Listing',
      },
      {
        value: 'S07_AR_GOOGLE_YOUTUBE',
        label: 'Google/YouTube AR',
      },
      {
        value: 'S08_AR_SNAPCHAT',
        label: 'SnapChat AR',
      },
      {
        value: 'S09_AR_INSTAGRAM',
        label: 'Instagram AR',
      },
      {
        value: 'S10_TAOBAO_MINI_PROGRAM',
        label: 'Taobao Mini Program',
      },
      {
        value: 'S11_DOUYIN_MINI_PROGRAM',
        label: 'Douyin Mini Program',
      },
    ],
  },
  blog: {
    focuses: [
      {
        value: 'BF_BUSINESS_FOCUS',
        label: 'Business focus',
      },
      {
        value: 'CF_CONSUMER_FOCUS',
        label: 'Consumer focus',
      },
      {
        value: 'YUC_YCE_USECASE',
        label: 'YCE Use Case',
      },
    ],
    blogTypes: _sortBy([
      {
        id: 0,
        value: 'BF_BUSINESS_FOCUS',
        label: 'Business focus',
      },
      {
        id: 1,
        value: 'CF_CONSUMER_FOCUS',
        label: 'Consumer focus',
      },
      {
        id: 2,
        value: 'BS_WEB_MODULE',
        label: 'Web Module/SDK',
      },
      {
        id: 3,
        value: 'BS_MOBILE_SDK',
        label: 'Mobile App SDK',
      },
      {
        id: 4,
        value: 'BS_INSTORE_CONSULTATION',
        label: 'In-Store Consultation',
      },
      {
        id: 5,
        value: 'BS_TAOBAO_MINI_PROGRAM',
        label: 'Taobao Mini-program',
      },
      {
        id: 6,
        value: 'BS_WECHAT_MINI_PROGRAM',
        label: 'WeChat Mini-program',
      },
      {
        id: 7,
        value: 'BS_AR_SHOPIFY',
        label: 'AR for Shopify',
      },
      {
        id: 8,
        value: 'BS_AR_LIVE_TRAINING',
        label: 'AR Live Training',
      },
      {
        id: 9,
        value: 'BS_AR_LIVE_CASTING',
        label: 'AR Live Casting',
      },
      {
        id: 10,
        value: 'BS_BA_1ON1',
        label: 'Beauty Advisor 1-on-1',
      },
      {
        id: 11,
        value: 'BS_AR_SNAPCHAT',
        label: 'Virtual Try-on AR on Snapchat',
      },
      {
        id: 12,
        value: 'BS_AR_YOUTUBE',
        label: 'Virtual Try-on AR on YouTube',
      },
      {
        id: 13,
        value: 'BS_AR_GOOGLE_SEARCH',
        label: 'Virtual Try-on AR on Google Search',
      },
      {
        id: 14,
        value: 'BS_DOUYIN_MINI_PROGRAM',
        label: 'Douyin Mini-program',
      },
      {
        id: 15,
        value: 'BS_YMK_APP_LISTING',
        label: 'YouCam Makeup App Listing',
      },
      {
        id: 16,
        value: 'BT_AI_AR_MAKEUP',
        label: 'AI/AR Makeup',
      },
      {
        id: 17,
        value: 'BT_AI_SKINCARE',
        label: 'AI Skincare',
      },
      {
        id: 18,
        value: 'BT_AI_SHADE_FINDER',
        label: 'AI Shade Finder',
      },
      {
        id: 19,
        value: 'BT_AI_AR_HAIR',
        label: 'AI/AR Hair',
      },
      {
        id: 20,
        value: 'BT_PARTNER_SUCCESS',
        label: 'Partner Success',
      },
      {
        id: 21,
        value: 'BT_RETAIL',
        label: 'Retail',
      },
      {
        id: 22,
        value: 'BT_ECOMMERCE',
        label: 'E-Commerce',
      },
      {
        id: 23,
        value: 'BT_FACE_TECH',
        label: 'Face Technology',
      },
      {
        id: 24,
        value: 'BT_AR_VIDEO_ON_DEMAND',
        label: 'AR Video On-Demand',
      },
      {
        id: 25,
        value: 'BT_PLATFORM_SUPPORT',
        label: 'Platform Support',
      },
      {
        id: 26,
        value: 'BT_AR_ACCESSORIES',
        label: 'AR Accessories',
      },
      {
        id: 27,
        value: 'CA_YMK',
        label: 'YouCam Makeup',
      },
      {
        id: 28,
        value: 'CA_YCP',
        label: 'YouCam Perfect',
      },
      {
        id: 29,
        value: 'CA_YCV',
        label: 'YouCam Video',
      },
      {
        id: 30,
        value: 'CT_PHOTO_EDITING',
        label: 'Photo Editing',
      },
      {
        id: 31,
        value: 'CT_VIDEO_EDITING',
        label: 'Video Editing',
      },
      {
        id: 32,
        value: 'CT_FACE_RETOUCH',
        label: 'Face Retouch/Reshape',
      },
      {
        id: 33,
        value: 'CT_SELFIE_CAM',
        label: 'Selfie Cam',
      },
      {
        id: 34,
        value: 'CT_EFFECT',
        label: 'Effect',
      },
      {
        id: 35,
        value: 'CT_HOW_TO',
        label: 'How-to',
      },
      {
        id: 36,
        value: 'CT_MAKEUP_LOOKS',
        label: 'Makeup & Looks',
      },
      {
        id: 37,
        value: 'CT_SKINCARE',
        label: 'Skincare',
      },
      {
        id: 38,
        value: 'CT_HAIR_COLOR',
        label: 'Hair Color',
      },
      {
        id: 39,
        value: 'CT_AR_FILTER',
        label: 'AR Filter',
      },
      {
        id: 40,
        value: 'CT_TOP_TRENDS',
        label: 'Top Trends',
      },
      {
        id: 41,
        value: 'CA_YCN',
        label: 'YouCam Nails',
      },
      {
        id: 42,
        value: 'CT_NAIL_DESIGN',
        label: 'Nail Design',
      },
      {
        id: 43,
        value: 'CA_YCE',
        label: 'YouCam Enhance',
      },
      {
        id: 44,
        value: 'CT_PHOTO_ENHANCE',
        label: 'Photo Enhance',
      },
      {
        id: 45,
        value: 'CT_HAIRSTYLE',
        label: 'Hairstyle',
      },
      {
        id: 46,
        value: 'CA_YCA',
        label: 'YouCam AI Pro',
      },
      {
        id: 47,
        value: 'CT_AI_IMAGE_GENERATION',
        label: 'AI Image Generation',
      },
      {
        id: 48,
        value: 'CA_YCOE',
        label: 'YouCam Online Editor',
      },
      {
        id: 49,
        value: 'YUC_YCE_USECASE',
        label: 'YCE Use Case (YCE)',
      },
      {
        id: 50,
        value: 'YC_PHOTO_EDITING',
        label: 'AI Photo Editing (YCE)',
      },
      {
        id: 51,
        value: 'YC_GENERATIVE_AI',
        label: 'AI Portrait (YCE)',
      },
      {
        id: 52,
        value: 'YC_FACE_AI',
        label: 'AI Video Editing (YCE)',
      },
      {
        id: 53,
        value: 'CT_GENERATIVE_AI',
        label: 'Generative AI',
      },
      {
        id: 54,
        value: 'CT_FACE_AI',
        label: 'Face AI',
      },
      {
        id: 55,
        value: 'CT_HALLOWEEN',
        label: 'Halloween',
      },
      {
        id: 56,
        value: 'CT_CHRISTMAS',
        label: 'Christmas',
      },
      {
        id: 57,
        value: 'CT_NEW_YEAR',
        label: 'New Year',
      },
      {
        id: 58,
        value: 'CT_CHINESE_NEW_YEAR',
        label: 'Chinese New Year',
      },
      {
        id: 59,
        value: 'CT_VALENTINES_DAY',
        label: 'Valentine’s Day',
      },
    ]),
  },
  appTypes: [
    {value: 'YMK', label: 'YMK'},
    {value: 'Y4B', label: 'Y4B'},
    {value: 'YCP', label: 'YCP'},
    {value: 'YCV', label: 'YCV'},
    {value: 'YCN', label: 'YCN'},
    {value: 'YCF', label: 'YCF'},
    {value: 'YCS', label: 'YCS'},
    {value: 'YCC', label: 'YCC'},
    {value: 'YCE', label: 'YCE'},
    {value: 'SCP', label: 'SCP'},
    {value: 'YCA', label: 'YCA'},
    {value: 'YAT', label: 'YAT'},
  ],
  sourceTypes: [
    {value: 'ANDROID', label: 'Android'},
    {value: 'IOS', label: 'iOS'},
  ],
  authorType: [
    {value: 'AUTHOR', label: 'Author'},
    {value: 'TRANSLATOR', label: 'Translator'},
  ],
  blogApps: [
    {value: '', label: ''},
    {value: 'YMK', label: 'YouCam Makeup'},
    {value: 'YCP', label: 'YouCam Perfect'},
    {value: 'YCV', label: 'YouCam Video'},
    {value: 'YCN', label: 'YouCam Nails'},
    {value: 'YCE', label: 'YouCam Enhance'},
    {value: 'YCA', label: 'YouCam AI'},
  ],
  source: [
    {value: 'Affiliate', label: 'Affiliate'},
    {value: 'SpecialProgram', label: 'SpecialProgram'},
    {value: 'Direct', label: 'Direct'},
  ],
  subscriptionStage: [
    {value: 'STAGE_0', label: 'STAGE_0'},
    {value: 'STAGE_1', label: 'STAGE_1'},
    {value: 'STAGE_2', label: 'STAGE_2'},
    {value: 'STAGE_3', label: 'STAGE_3'},
    {value: 'STAGE_100', label: 'STAGE_100'},
    {value: 'STAGE_101', label: 'STAGE_101'},
    {value: 'STAGE_102', label: 'STAGE_102'},
    {value: 'STAGE_103', label: 'STAGE_103'},
  ],
  companySize: [
    {value: '1 - 50 employees', label: '1 - 50 employees'},
    {value: '51 - 100 employees', label: '51 - 100 employees'},
    {value: '101 - 200 employees', label: '101 - 200 employees'},
    {value: '201 - 300 employees', label: '201 - 300 employees'},
    {value: '301 - 500 employees', label: '301 - 500 employees'},
    {value: '201 - 500 employees', label: '201 - 500 employees'},
    {value: '500+ employees', label: '500+ employees'},
  ],
  status: [
    {value: 'Active', label: 'Active'},
    {value: 'Expired', label: 'Expired'},
  ],
  subscriptionType: [
    {value: 'ESSENTIAL_TRIAL', label: 'ESSENTIAL_TRIAL'},
    {value: 'ESSENTIAL', label: 'ESSENTIAL'},
    {value: 'PREMIUM', label: 'PREMIUM'},
    {value: 'PREMIUM_TRIAL', label: 'PREMIUM_TRIAL'},
    {value: 'FREEMIUM_TRIAL', label: 'FREEMIUM_TRIAL'},
  ],
  recurringType: [
    {value: 'MONTH', label: 'MONTH'},
    {value: 'QUARTER', label: 'QUARTER'},
    {value: 'ANNUAL', label: 'ANNUAL'},
    {value: 'NONE', label: 'NONE'},
  ],
  functionType: [
    {value: 'MAKEUP', label: 'MAKEUP'},
    {value: 'EYEWEAR', label: 'EYEWEAR'},
    {value: 'SKINCARE_PRO', label: 'SKINCARE_PRO'},
  ],
  solutionType: [
    {value: 'MAKEUP', label: 'MAKEUP'},
    {value: 'EYEWEAR', label: 'EYEWEAR'},
    {value: 'SKIN_ANALYSIS', label: 'SKIN_ANALYSIS'},
    {value: 'AESTHETIC_SIMULATION', label: 'AESTHETIC_SIMULATION'},
  ],
};
