import React from 'react';
import {Trans} from 'react-i18next';
import {Navigate} from 'react-router-dom';
import ListPosts from './post/listposts';
import AddOrEditPost from './post/addoreditpost';
import AsyncListKeywords from './keyword/listkeywords/async';
import ListAuthors from './author/listauthor';
import AddOrEditAuthor from './author/addoreditauthor';
import ListWhitelist from './whitelist/listwhitlists';

const parent = {
  title: <Trans i18nKey="p.blog.title">Blog</Trans>,
  path: '/blog',
};
const postParent = {
  title: <Trans i18nKey="p.blog.post.title">Posts</Trans>,
  path: '/blog/post',
};
const keywordParent = {
  title: <Trans i18nKey="p.blog.keyword.title">Keywords</Trans>,
  path: '/blog/keyword',
};
const authorParent = {
  title: <Trans i18nKey="p.blog.author.title">Authors</Trans>,
  path: '/blog/author',
};

export default [
  {
    path: '/blog/post/list-posts',
    element: <ListPosts />,
    handle: {
      title: <Trans i18nKey="p.s.blog.listposts.title">Posts</Trans>,
      subtitle: <Trans i18nKey="p.s.blog.listposts.title">Posts</Trans>,
      breadcrumbs: [parent, postParent],
    },
  },
  {
    path: '/blog/post/add-post',
    element: <AddOrEditPost />,
    handle: {
      title: <Trans i18nKey="p.s.blog.addpost.title">Add Post</Trans>,
      subtitle: <Trans i18nKey="p.s.blog.addpost.title">Add Post</Trans>,
      breadcrumbs: [parent, postParent],
    },
  },
  {
    path: '/blog/post/edit-post',
    element: <AddOrEditPost />,
    handle: {
      title: <Trans i18nKey="p.s.blog.editpost.title">Edit Post</Trans>,
      subtitle: <Trans i18nKey="p.s.blog.editpost.title">Edit Post</Trans>,
      breadcrumbs: [parent, postParent],
    },
  },
  {
    path: '/blog/keyword/list-keywords',
    element: <AsyncListKeywords />,
    handle: {
      title: <Trans i18nKey="p.s.blog.listkeywords.title">Keywords</Trans>,
      subtitle: <Trans i18nKey="p.s.blog.listkeywords.title">Keywords</Trans>,
      breadcrumbs: [parent, keywordParent],
    },
  },
  {
    path: '/blog/author/list-authors',
    element: <ListAuthors />,
    handle: {
      title: <Trans i18nKey="p.s.blog.listauthors.title">Authors</Trans>,
      subtitle: <Trans i18nKey="p.s.blog.listauthors.title">Authors</Trans>,
      breadcrumbs: [parent, authorParent],
    },
  },
  {
    path: '/blog/author/add-author',
    element: <AddOrEditAuthor />,
    handle: {
      title: <Trans i18nKey="p.s.blog.addauthor.title">Add Author</Trans>,
      subtitle: <Trans i18nKey="p.s.blog.addauthor.title">Add Author</Trans>,
      breadcrumbs: [parent, authorParent],
    },
  },
  {
    path: '/blog/author/edit-author',
    element: <AddOrEditAuthor />,
    handle: {
      title: <Trans i18nKey="p.s.blog.editauthor.title">Edit Author</Trans>,
      subtitle: <Trans i18nKey="p.s.blog.editauthor.title">Edit Author</Trans>,
      breadcrumbs: [parent, authorParent],
    },
  },
  {
    path: '/blog/dead-link/list-dead-link-whitelists',
    element: <ListWhitelist />,
    handle: {
      title: (
        <Trans i18nKey="p.s.blog.listwhitelist.title">
          Dead Link Whitelist
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.s.blog.listwhitelist.title">
          Dead Link Whitelist
        </Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/blog',
    element: <Navigate to="/blog/post/list-posts" />,
  },
  {
    path: '/blog/post',
    element: <Navigate to="/blog/post/list-posts" />,
  },
  {
    path: '/blog/keyword',
    element: <Navigate to="/blog/keyword/list-keyword" />,
  },
  {
    path: '/blog/author',
    element: <Navigate to="/blog/author/list-author" />,
  },
];
