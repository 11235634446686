import _find from 'lodash/find';
import _get from 'lodash/get';

const utils = {
  getLabel: (value, list) => {
    const item = _find(list, {value});
    return _get(item, 'label', '');
  },
};

export default utils;
