import React from 'react';
import yup from '../../../../utils/yup';
import {Field, Form, Formik} from 'formik';
import {Trans} from 'react-i18next';
import InputBox from '../../../../components/formik/InputBox';
import classnames from 'classnames';
import ColorField from '../../../../components/colorpicker';
import SelectBox from '../../../../components/formik/SelectBox';
import FormRow from '../../components/FormRow';
import CheckBox from '../../../../components/formik/CheckBox';
import webUtils from '../../../../utils/webUtils';

export default function ButtonForm({setInsertContent, setOpenInsertButton}) {
  const formikConfig = {
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      buttonText: yup.string().required(),
      link: yup.string().matches(webUtils.getUrlReg()).required(),
    }),
    enableReinitialize: true,
    initialValues: {
      title: '',
      icon: 'right',
      buttonText: '',
      classes: '',
      id: '',
      link: '',
      buttonColor: '#f2f2f2',
      textColor: '#f2f2f2',
      newTab: true,
    },
    onSubmit: (values) => {
      const text =
        values.icon === 'right'
          ? values.buttonText +
            '<img alt="" src="https://plugins-media.makeupar.com/smb/story/2024-01-09/f6212f14-5b70-4375-9e10-35df9357bc84.png" width="20px" height="20px" style="margin-left: 5px;">'
          : values.buttonText;

      const btn = document.createElement('button');
      btn.setAttribute('type', 'button');
      btn.setAttribute(
        'class',
        `${values.classes} blog-post-body__main-content-cta-button`
      );
      btn.setAttribute('title', `${values.title}`);
      btn.setAttribute('id', `${values.id}`);
      btn.setAttribute(
        'style',
        `line-height: normal; color: ${values.textColor}; border-radius: 41px; display: flex; align-items: center; border: none; background-color: ${values.buttonColor}; font-size:18px; font-weight: 500; padding: 18px 40px;`
      );
      const a = document.createElement('a');
      a.setAttribute('target', values.newTab ? '_blank' : '_self');
      a.setAttribute('href', `${values.link}`);
      a.setAttribute('style', 'display: inline-block');
      a.setAttribute('class', 'blog-post-body__main-content-cta-link-button');
      a.setAttribute('rel', values.nofollow ? 'nofollow' : '');
      btn.innerHTML = text;
      a.append(btn);
      setInsertContent(a.outerHTML);
      setOpenInsertButton(false);
    },
  };

  return (
    <div>
      <div
        className={'text-center kt-mb-20 kt-font-lg kt-font-boldest'}
        style={{color: '#f23b77'}}
      >
        Buttons
      </div>
      <Formik {...formikConfig}>
        {(formikProps) => (
          <Form>
            <div className="blog-button--container">
              <FormRow
                label={<Trans i18nKey="p.b.p.addoredit.title">Title</Trans>}
                name="title"
                component={InputBox}
                isRequired={true}
              />
              <FormRow
                label={'Icon'}
                name="icon"
                component={(props) => (
                  <SelectBox
                    {...props}
                    options={[
                      {label: 'none', value: 'none'},
                      {label: 'Right Arrow', value: 'right'},
                    ]}
                  />
                )}
                isRequired={true}
              />
              <FormRow
                label={'Button Text'}
                name="buttonText"
                component={InputBox}
                isRequired={true}
              />
              <div className="row" style={{marginTop: '10px'}}>
                <label className="col-2 col-form-label">Button Color</label>
                <div className="col-6">
                  <Field name="buttonColor" component={ColorField} />
                </div>
              </div>
              <FormRow label={'Classes'} name="classes" component={InputBox} />
              <div className="row" style={{marginTop: '10px'}}>
                <label className="col-2 col-form-label">Text Color</label>
                <div className="col-6">
                  <Field name="textColor" component={ColorField} />
                </div>
              </div>
              <FormRow label={'id'} name="id" component={InputBox} />
              <FormRow
                label={'Link'}
                name="link"
                component={InputBox}
                isRequired={true}
              />
              <FormRow
                label={'No follow'}
                name="nofollow"
                component={CheckBox}
              />
              <div
                className="row"
                style={{marginTop: '10px', flex: '0 0 100%'}}
              >
                <div className="col-12">
                  <Field
                    name="newTab"
                    component={CheckBox}
                    value={true}
                    label={'Open link in the new tab'}
                  />
                </div>
              </div>
            </div>
            <button
              type={'submit'}
              style={{cursor: 'pointer'}}
              className={classnames({
                row: true,
                btn: true,
                'btn-primary': true,
                'kt-margin-r-10': true,
                'kt-margin-l-5': true,
                'kt-margin-b-10': true,
                'kt-spinner': true,
                'kt-spinner-v2': true,
                'kt-spinner--center': true,
                'kt-spinner--sm': true,
              })}
            >
              Apply
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
