import React from 'react';
import {Trans} from 'react-i18next';
import DataTable, {forceFetch} from '../../components/datatable/DataTable';
import dao from '../../dao/customerSettingsDao';
import {toast} from 'react-toastify';

function SkuAddon() {
  const onFetchData = async ({pageIndex, pageSize}) => {
    return await dao.listCustomers({pageIndex, pageSize});
  };

  const columns = [
    {
      Header: <Trans>Customer Name</Trans>,
      accessor: 'customerName',
      disableSorting: true,
    },
    {Header: <Trans>Email</Trans>, accessor: 'email', disableSorting: true},
    {
      Header: <Trans>Blacklisted</Trans>,
      accessor: 'blacklisted',
      disableSorting: true,
    },
    {
      Header: <Trans>Enable SKU Add-On</Trans>,
      accessor: 'isSkuAddonEnabled',
      Cell: ({cell: {value}, row}) => (
        <>
          <label className="kt-radio" style={{marginRight: '10px'}}>
            <input
              type="radio"
              name={row.original.customerId}
              value={true}
              checked={value}
              onChange={() =>
                dao
                  .enableSkuAddOn({
                    customerId: row.original.customerId,
                    isEnabled: true,
                  })
                  .then(res => {
                    if (res.status === 'success') {
                      toast.success('Success');
                    } else {
                      toast.warn('Failed');
                    }
                    forceFetch();
                  })
              }
            />
            <div>Yes</div>
            <span />
          </label>
          <label className="kt-radio" style={{marginRight: '10px'}}>
            <input
              type="radio"
              name={row.original.customerId}
              value={false}
              checked={!value}
              onChange={() =>
                dao
                  .enableSkuAddOn({
                    customerId: row.original.customerId,
                    isEnabled: false,
                  })
                  .then(res => {
                    if (res.status === 'success') {
                      toast.success('Success');
                    } else {
                      toast.warn('Failed');
                    }
                    forceFetch();
                  })
              }
            />
            <div>No</div>
            <span />
          </label>
        </>
      ),
      disableSorting: true,
    },
  ];

  return (
    <div>
      <DataTable columns={columns} onFetchData={onFetchData} />
    </div>
  );
}

export default SkuAddon;
