import {get, listAll, post} from './utils';

const storyDao = {
  queryStory: storyId => get('/smb-admin/story/query-story.action', storyId),
  listAllStories: params => listAll(storyDao.listStories, params),
  listStories: params => get('/smb-admin/story/list-storys.action', params),
  createStory: params => post('/smb-admin/story/create-story.action', params),
  updateStory: params => post('/smb-admin/story/update-story.action', params),
  copyStory: params => post('/smb-admin/story/copy-story.action', params),
  deleteStory: storyId =>
    get('/smb-admin/story/delete-story.action', {storyId}),
  publishStory: storyId =>
    get('/smb-admin/story/publish-story.action', {storyId}),
  takeDownStory: storyId =>
    get('/smb-admin/story/take-down-story.action', {storyId}),
  submitStory: params => post('/smb-admin/story/submit-story.action', params),
  cancelSubmit: storyId =>
    get('/smb-admin/story/cancel-story.action', {storyId}),
  reSubmit: params =>
    post('/smb-admin/story/continue-to-review-story.action', params),
  queryHeadings: () => get('/smb-admin/story/query-story-headings.action'),
  updateHeadings: params =>
    post('/smb-admin/story/update-story-headings.action', params),
  checkTakenDown: storyId =>
    get('/smb-admin/story/check-take-down.action', {storyId}),
  uploadImage: params => post('/smb-admin/story/upload-image.action', params),
};

export default storyDao;
