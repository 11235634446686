import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import projectDao from '../../../dao/projectDao';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import {toast} from 'react-toastify';
import {Trans} from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import ConfirmDialogButton from '../../blog/components/ConfirmDialogButton';
import ManualDataTable from '../../../components/manualdatatable/ManualDataTable';
import {
  DateRangeFilter,
  DropdownFilter,
  TextFilter,
} from '../../../components/manualdatatable/ManualDataFilters';
import globals from '../../../utils/config/globals';

export default function ProjectMSR() {
  const [isLoading, setIsLoading] = useState(false);
  const [processedParams, setProcessedParams] = useState(null);
  const [originalData, setOriginalData] = useState({results: [], totalSize: 0});

  function deleteProject(projectId) {
    projectDao
      .deleteProject({projectId})
      .then((r) => {
        if (r.status === 'success') {
          toast.success('Delete Successful!');
          fetchData();
        } else {
          toast.error('Delete Failed');
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error('Delete Failed.');
      });
  }

  function submitProject(projectId) {
    projectDao
      .submitProject({projectId})
      .then((r) => {
        if (r.status === 'success') {
          toast.success('Submit Successful!');
          fetchData();
        } else {
          toast.error('Submit Failed');
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error('Submit Failed.');
      });
  }

  function cancelSubmit(projectId) {
    projectDao
      .cancelProject({projectId})
      .then((r) => {
        if (r.status === 'success') {
          toast.success('Cancel Successful!');
          fetchData();
        } else {
          toast.error('Cancel Failed');
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error('Cancel Failed.');
      });
  }

  const columns = [
    {
      disableSortBy: true,
      Header: 'Creation',
      accessor: 'createdTime',
      Cell: ({cell: {value}}) => dayjs(value).format('MM/DD/YYYY'),
      Filter: DateRangeFilter,
      style: {width: '10%'},
    },
    {
      disableSortBy: true,
      Header: 'Title',
      accessor: 'projectName',
      Filter: TextFilter,
      style: {width: '15%'},
    },
    {
      disableSortBy: true,
      Header: '# of pages',
      accessor: 'projectItems',
      style: {width: '5%'},
      Cell: ({cell: {value}}) => <div>{value.length}</div>,
    },
    {
      disableSortBy: true,
      Header: 'Status',
      id: 'projectStatus',
      accessor: 'projectStatus',
      Filter: DropdownFilter,
      options: globals.blogStatus,
      style: {width: '10%'},
    },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: (d) => d,
      disableSortBy: true,
      Cell: ({cell: {value}}) => (
        <div>
          {value.projectStatus === 'REVIEWING' && (
            <ConfirmDialogButton
              className={'btn-outline-danger'}
              callback={() => cancelSubmit(value.id)}
              title="Cancel"
            >
              <i className="fa fa-times" />{' '}
              <Trans i18nKey="p.b.p.listposts.cancel">Cancel</Trans>
            </ConfirmDialogButton>
          )}
          {(value.projectStatus === 'DRAFT' ||
            value.projectStatus === 'REJECTED') && (
            <div>
              <Link
                to={'edit-project-msr?projectId=' + value.id}
                className="btn btn-sm btn-primary kt-margin-r-10 kt-margin-b-10 nowrap"
              >
                <i className="fa fa-edit" /> Edit
              </Link>
              <ConfirmDialog confirmCallback={() => deleteProject(value.id)}>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-danger kt-margin-r-10 kt-margin-b-10 nowrap"
                >
                  <i className="fa fa-times" /> Delete
                </button>
              </ConfirmDialog>
              {!_isEmpty(value.projectItems) && (
                <ConfirmDialog
                  confirmCallback={() => {
                    submitProject(value.id);
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-sm kt-margin-r-10 kt-margin-b-10 btn-outline-success nowrap"
                  >
                    <i className="fa flaticon2-paperplane" />{' '}
                    <Trans i18nKey="p.n.l.listnews.submit">Submit</Trans>
                  </button>
                </ConfirmDialog>
              )}
            </div>
          )}
        </div>
      ),
      style: {width: '15%'},
    },
  ];

  const fetchData = async () => {
    const apiParams = {};
    apiParams['pageIndex'] = processedParams.pageIndex;
    apiParams['pageSize'] = processedParams.pageSize;
    if (processedParams.createdTimeFrom) {
      apiParams['begin'] = processedParams.createdTimeFrom;
    }
    if (processedParams.createdTimeTo) {
      apiParams['end'] = processedParams.createdTimeTo;
    }
    if (processedParams.projectName) {
      apiParams['projectName'] = processedParams.projectName;
    }
    if (processedParams.projectStatus) {
      apiParams['projectStatus'] = processedParams.projectStatus;
    }

    const res = await projectDao.listProject(apiParams);

    setOriginalData({
      ...originalData,
      results: _get(res, 'results', []),
      totalSize: _get(res, 'totalSize', 0),
    });

    setIsLoading(false);
  };

  useEffect(() => {
    if (!processedParams) {
      return;
    }
    setIsLoading(true);
    fetchData();
  }, [processedParams]);

  return (
    <>
      <ManualDataTable
        isLoading={isLoading}
        columns={columns}
        data={originalData.results}
        totalSize={originalData.totalSize}
        initState={{
          pageIndex: 0,
          pageSize: 10,
        }}
        setProcessedParams={setProcessedParams}
      />
    </>
  );
}
