import React, {useEffect, useState} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import _get from 'lodash/get';
import moment from 'moment';

export const TextFilter = ({column: {filterValue, setFilter}}) => {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      className="form-control input-sm"
    />
  );
};

export const DropdownFilter = ({column: {filterValue, setFilter, options}}) => {
  if (options) {
    // manual
    return (
      <select
        onChange={(e) => setFilter(e.target.value || '')}
        value={filterValue || ''}
        className="form-control input-sm"
      >
        <option value="">{''}</option>
        {options.map((opt, i) => (
          <option key={i} value={opt.value}>
            {opt.text || opt.label}
          </option>
        ))}
      </select>
    );
  }
  return '';
};

export const DateRangeFilter = ({
  column: {filterValue, setFilter, dateRangeQuery},
}) => {
  return (
    <DateRangePicker
      onApply={(event, picker) => {
        const {startDate, endDate} = picker;
        const display = `${startDate.format('MM/DD')} - ${endDate.format(
          'MM/DD'
        )}`;
        setFilter({
          [_get(dateRangeQuery, '[0]', 'startDateFrom')]: startDate.valueOf(),
          [_get(dateRangeQuery, '[1]', 'startDateTo')]: endDate.valueOf(),
          display,
        });
      }}
      drops="down"
      opens="center"
      containerStyles={{}}
    >
      <div className="input-group">
        <div className="input-group-addon">
          <span className="glyphicon glyphicon-calendar" />
        </div>
        <input
          type="text"
          className="form-control input-sm"
          value={filterValue ? filterValue.display : ''}
          readOnly={true}
          style={{backgroundColor: '#fff'}}
        />
      </div>
    </DateRangePicker>
  );
};

export const DateRangeInputFilter = ({
  column: {filterValue, setFilter, dateRangeQuery},
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (filterValue === undefined) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [filterValue]);

  useEffect(() => {
    if (
      startDate === null ||
      endDate === null ||
      !moment(startDate).isValid() ||
      !moment(endDate).isValid() ||
      moment(startDate).valueOf() > moment(endDate).valueOf()
    ) {
      return;
    }
    setFilter({
      ...filterValue,
      [_get(dateRangeQuery, '[0]', 'startDateFrom')]: startDate,
      [_get(dateRangeQuery, '[0]', 'startDateTo')]: endDate,
    });
  }, [startDate, endDate]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          marginBottom: '18px',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <input
          type="date"
          className="form-control input-sm"
          value={startDate || ''}
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
        />
        <h6> - </h6>
        <input
          type="date"
          className="form-control input-sm"
          value={endDate || ''}
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
        />
      </div>
    </>
  );
};
