import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import _get from 'lodash/get';
import blogDao from '../../../../dao/blogDao';
import {categoryList} from '../../utils';

export default function ShortDesc({name, props}) {
  const [desc, setDesc] = useState(_get(props, 'values.post.shortDesc', ''));
  const [isDuplicate, setIsDuplicate] = useState(false);
  const id = _get(props, 'values.post.id');
  const title = _get(props, 'values.post.title');
  const languageType = _get(props, 'values.post.languageType');
  const postAttrs = _get(props, 'values.post.postAttrs', []);
  const postStatus = _get(props, 'values.post.postStatus');
  const category = _get(props, 'values.post.category', '');
  const postKey = _get(props, 'values.post.postKey', '');

  let shortDesc = '';
  if (postAttrs.includes('BF_BUSINESS_FOCUS')) {
    shortDesc += '[B2B]';
  }
  if (postAttrs.includes('CF_CONSUMER_FOCUS')) {
    shortDesc += '[B2C]';
  }
  if (postAttrs.includes('CA_YMK')) {
    shortDesc += '[YMK]';
  }
  if (postAttrs.includes('CA_YCP')) {
    shortDesc += '[YCP]';
  }
  if (postAttrs.includes('CA_YCV')) {
    shortDesc += '[YCV]';
  }
  if (postAttrs.includes('CA_YCE')) {
    shortDesc += '[YCE]';
  }
  shortDesc += `[${languageType.toUpperCase()}]`;
  if (!postStatus || postStatus === 'DRAFT') {
    if (isDuplicate) {
      shortDesc += '[Update]';
    } else {
      shortDesc += '[NewPost]';
    }
  } else {
    shortDesc += '[Update]';
  }
  shortDesc += ` Blog: ${title}`;

  useEffect(() => {
    if (!category || !postKey || !languageType) {
      return;
    }
    const cat = categoryList.find((c) => c.value === category).label;
    blogDao.post
      .listByHandleAndLanguage({
        postHandle: cat + '/' + postKey,
        languageType: languageType,
      })
      .then((results) => {
        if (results.length < 1) {
          setIsDuplicate(false);
          return;
        } else if (results.length === 1) {
          const isSelf = results.find((result) => result.id === id);
          setIsDuplicate(!isSelf);
          return;
        }
        setIsDuplicate(true);
      });
  }, [category, postKey, languageType, id]);

  useEffect(() => {
    setDesc(shortDesc);
    props.setFieldValue(name, shortDesc);
  }, [name, shortDesc]);

  return (
    <div className="row" style={{display: 'none'}}>
      <label className="col-2 col-form-label text-right">
        <Trans i18nKey="p.b.p.addoredit.short.desc">Short Description</Trans>
      </label>
      <div className="col-md-8 col-form-label">
        <input
          className="form-control"
          type="text"
          value={desc}
          readOnly={true}
        />
      </div>
    </div>
  );
}
