import React, {useEffect} from 'react';
import Header from '../../components/layout/header';
import fetch from 'isomorphic-fetch';
import {useSelector} from 'react-redux';

export default function Strapi() {
  const privileges = useSelector(
    (state) => state.components.auth.admin.privileges
  );
  const strapiAccount = privileges.includes('P120_STRAPI_ADMIN')
    ? 'strapi_admin@perfectcorp.com'
    : 'strapi_editor@perfectcorp.com';

  useEffect(() => {
    fetch(`/strapi/admin/login`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: strapiAccount,
        password: 'Perfectcorp2022',
      }),
    })
      .then((res) => {
        res.json().then((r) => {
          localStorage.setItem('userInfo', JSON.stringify(r.data.user));
          localStorage.setItem('jwtToken', '"' + r.data.token + '"');
          localStorage.setItem('strapi-admin-language', 'en');
          localStorage.setItem('STRAPI_UPDATE_NOTIF', 'true');
          localStorage.setItem('navbar-condensed', 'false');
        });
      })
      .catch(() => 'failed');
  }, []);

  return (
    <div className="kt-grid kt-grid--hor kt-grid--root">
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
          <Header />
          <iframe
            src="/strapi/admin"
            title="strapi"
            style={{height: 'calc(100vh - 70px)'}}
          />
        </div>
      </div>
    </div>
  );
}
