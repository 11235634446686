import {get, listAll, post} from './utils';

const courseDao = {
  queryCourse: (courseId) =>
    get('/smb-admin/course/query-course.action', {courseId}),
  checkCourseKey: (params) =>
      get('/smb-admin/course/check-course-key.action', params),
  listCoursesByTypes: (params) =>
      get('/smb-admin/course/list-courses-by-types.action', params),
  listAllCourses: (params) => listAll(courseDao.listCourses, params),
  listCourses: (params) => get('/smb-admin/course/list-courses.action', params),
  createCourse: (params) =>
    post('/smb-admin/course/create-course.action', params),
  updateCourse: (params) =>
    post('/smb-admin/course/update-course.action', params),
  deleteCourse: (courseId) =>
    get('/smb-admin/course/delete-course.action', {courseId}),
  pickLatestCourse: (courseId) =>
      get('/smb-admin/course/pick-latest-course.action', {courseId}),
  publishCourse: (courseId) =>
    get('/smb-admin/course/publish-course.action', {courseId}),
  querySession: (sessionId) =>
    get('/smb-admin/course/query-session.action', {sessionId}),
  listAllSessions: (params) => listAll(courseDao.listSessions, params),
  listSessions: (params) =>
    get('/smb-admin/course/list-sessions.action', params),
  createSession: (params) =>
    post('/smb-admin/course/create-session.action', params),
  updateSession: (params) =>
    post('/smb-admin/course/update-session.action', params),
  deleteSession: (sessionId) =>
    get('/smb-admin/course/delete-session.action', {sessionId}),
  publishSession: (sessionId) =>
    get('/smb-admin/course/publish-session.action', {sessionId}),
  takeDownSession: (sessionId) =>
    get('/smb-admin/course/take-down-session.action', {sessionId}),
  querySpeaker: (speakerId) =>
    get('/smb-admin/course/query-speaker.action', {speakerId}),
  listAllSpeakers: (params) => listAll(courseDao.listSpeakers, params),
  listSpeakers: (params) =>
    get('/smb-admin/course/list-speakers.action', params),
  createSpeaker: (params) =>
    post('/smb-admin/course/create-speaker.action', params),
  updateSpeaker: (params) =>
    post('/smb-admin/course/update-speaker.action', params),
  deleteSpeaker: (speakerId) =>
    get('/smb-admin/course/delete-speaker.action', {speakerId}),
  publishSpeaker: (speakerId) =>
    get('/smb-admin/course/publish-speaker.action', {speakerId}),
  takeDownSpeaker: (speakerId) =>
    get('/smb-admin/course/take-down-speaker.action', {speakerId}),
};

export default courseDao;
