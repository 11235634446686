import React, {useEffect, useState} from 'react';
import './fileInput.css';

export default function FileInput({
  form: {setFieldValue, errors, values},
  field: {name},
  accept,
  errorMessage,
  onFileChange,
  displayText,
  fileName,
  uploadButtonText,
  htmlId,
}) {
  const handleChange = (event) => {
    if (event.currentTarget.files.length !== 0) {
      const file = event.currentTarget.files[0];
      if (!file) {
        onFileChange('');
        return;
      }
      onFileChange(file);
    }
  };

  return (
    <React.Fragment>
      <div

      // onClick={handleChange}
      >
        <label
          className={'btn kt-margin-r-10 col-md-1.5 upload'}
          // style={{
          //   border: 'none',
          //   borderRadius: 0,
          //   maxHeight: 50,
          // }}
          htmlFor={htmlId}
          // onChange={handleChange}
          style={{
            color: 'black',
            marginTop: 4,
            cursor: 'pointer',
            border: 'none',
            borderRadius: 0,
            maxHeight: 50,
          }}
        >
          <i className={'fa fa-upload'} />
          {uploadButtonText}
          <input
            type="file"
            id={htmlId}
            className={'filePicker'}
            accept={accept}
            onChange={handleChange}
            style={{display: 'none'}}
          />
        </label>
      </div>
      <div className={'col '}>
        <div style={{color: 'grey', fontSize: 14}}>{displayText}</div>
        <div>
          {fileName && (
            <ul>
              <li>{fileName}</li>
            </ul>
          )}
        </div>
      </div>

      <span className="invalid-feedback">{errorMessage}</span>
    </React.Fragment>
  );
}
