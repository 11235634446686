import React, {useEffect, useRef, useState} from 'react';
import {Trans} from 'react-i18next';
import dayjs from 'dayjs';
import ManualDataTable from '../../../../components/manualdatatable/ManualDataTable';
import {Link} from 'react-router-dom';
import {
  DateRangeFilter,
  DropdownFilter,
  TextFilter,
} from '../../../../components/manualdatatable/ManualDataFilters';

import DetailModal from './components/detailmodal';
import AuthorModal from '../../components/AuthorModal';
import ImageModal from '../../../../components/modal/ImageModal';
import DisplaySetting from './components/displaysetting';
import Status from './components/Status';
import PostActionButtons from './components/ActionButtons';
import _find from 'lodash/find';
import _get from 'lodash/get';
import globals from '../../../../utils/config/globals';
import Select from 'react-select';
import dao from '../../../../dao/blogDao';
import Dotdotdot from 'react-dotdotdot';
import {categoryList} from '../../utils';
import store from 'store';
import adminUtils from '../../../../utils/adminUtils';

export default function ListPosts() {
  const [isLoading, setIsLoading] = useState(false);
  const [postAuthor, setPostAuthor] = useState(null);
  const [isAuthorOpen, setIsAuthorOpen] = useState(false);
  const [postDetail, setPostDetail] = useState(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [imageModalData, setImageModalData] = useState(null);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [languageType, setLanguageType] = useState(
    store.get('blogLanguage') ? store.get('blogLanguage') : 'enu'
  );
  const [blogFocus, setBlogFocus] = useState(
    store.get('blogFocus') ? store.get('blogFocus') : 'business'
  );
  const [mappingKeyList, setMappingKeyList] = useState([]);
  const [processedParams, setProcessedParams] = useState(null);
  const [originalData, setOriginalData] = useState({results: [], totalSize: 0});
  let callPaginationAPI = useRef(null);
  const openModal = (modalType, data) => {
    if (modalType === 'POST') {
      setPostDetail(data);
      setIsDetailOpen(true);
    }
    if (modalType === 'AUTHOR') {
      setPostAuthor(data);
      setIsAuthorOpen(true);
    }
    if (modalType === 'IMAGE') {
      setImageModalData(data);
      setIsImageOpen(true);
    }
  };
  const closeModal = (modalType) => {
    if (modalType === 'POST') {
      setPostDetail(null);
      setIsDetailOpen(false);
    }
    if (modalType === 'AUTHOR') {
      setPostAuthor(null);
      setIsAuthorOpen(false);
    }
    if (modalType === 'IMAGE') {
      setImageModalData(null);
      setIsImageOpen(false);
    }
  };

  useEffect(() => {
    dao.post.listMappingKey().then((res) => {
      const list = res.map((s) => {
        return {label: s, value: s};
      });
      setMappingKeyList(list);
    });
  }, []);

  const fetchData = async () => {
    const res = await dao.post.listByParams(processedParams);

    setOriginalData({
      ...originalData,
      results: _get(res, 'results', []),
      totalSize: _get(res, 'totalSize', 0),
    });

    setIsLoading(false);
  };

  useEffect(() => {
    if (!processedParams) {
      return;
    }
    setIsLoading(true);
    callPaginationAPI.current = adminUtils.clearPrevAPI(
      callPaginationAPI.current,
      () => fetchData(),
      500
    );
  }, [processedParams]);

  const columns = [
    {
      Header: <Trans i18nKey="p.b.p.list.cover.id">ID</Trans>,
      accessor: 'id',
      disableSortBy: true,
      style: {width: '3%'},
    },
    {
      Header: <Trans i18nKey="p.b.p.list.cover.image">Cover Image</Trans>,
      accessor: 'imageUrl',
      disableSortBy: true,
      Cell: ({cell: {value}}) => (
        <img
          alt="cover"
          src={value || require('../../../../assets/images/noimage.png')}
          style={{width: value ? '80px' : '60px', cursor: 'pointer'}}
          onClick={() =>
            openModal('IMAGE', {
              alt: 'cover',
              src: value || require('../../../../assets/images/noimage.png'),
              noImage: !value,
              title: (
                <Trans i18nKey="p.b.p.list.cover.image">Cover Image</Trans>
              ),
            })
          }
        />
      ),
      style: {width: '8%'},
    },
    {
      Header: <Trans i18nKey="p.b.p.list.title">Title</Trans>,
      accessor: 'title',
      Cell: ({cell: {value, row}}) =>
        row.original ? (
          <div>
            <div
              onClick={() => openModal('POST', row.original)}
              style={{cursor: 'pointer', color: '#5d78ff'}}
            >
              <Dotdotdot clamp={3}>
                {row.original.duplicated ? '>>>' + value : value}
              </Dotdotdot>
            </div>
            <div>
              <small>{row.original.postUrl}</small>
            </div>
          </div>
        ) : (
          value
        ),
      style: {width: '25%'},
      Filter: TextFilter,
      storeFilter: 'blogTitle',
    },
    {
      Header: <Trans i18nKey="p.b.p.list.category">Category</Trans>,
      accessor: 'category',
      Cell: ({cell: {value}}) => {
        if (
          !value ||
          !_get(
            categoryList.find((c) => c.value === value),
            'label'
          )
        )
          return '';
        return categoryList.find((c) => c.value === value).label;
      },
      style: {width: '10%'},
      Filter: DropdownFilter,
      options: categoryList,
    },
    {
      Header: <Trans i18nKey="p.b.p.list.authors">Authors</Trans>,
      id: 'authors',
      accessor: 'postAuthors',
      disableSortBy: true,
      Cell: ({cell: {value}}) =>
        value.map((ele) =>
          ele.author.authorType === 'AUTHOR' ? (
            <span
              style={{cursor: 'pointer', color: '#5d78ff'}}
              onClick={() => openModal('AUTHOR', _get(ele, 'author'))}
            >
              {ele.author.displayName}
              <br />
            </span>
          ) : (
            ''
          )
        ),
      style: {width: '10%'},
      Filter: TextFilter,
    },
    {
      Header: <Trans i18nKey="p.b.p.list.post.date">Post Date</Trans>,
      accessor: 'postDate',
      Cell: ({cell: {value}}) => dayjs(value).format('YYYY-MM-DD'),
      style: {width: '10%'},
      Filter: DateRangeFilter,
    },
    {
      Header: <Trans i18nKey="p.b.p.list.status">Status</Trans>,
      accessor: 'postStatus',
      style: {width: '10%'},
      Filter: DropdownFilter,
      options: globals.blogStatus,
      storeFilter: 'blogStatus',
      Cell: ({cell: {value, row}}) => {
        const isConverting = row.original.isConverting;
        return <Status postStatus={value} />;
      },
    },
    {
      Header: <Trans i18nKey="p.b.p.list.published.date">Published Date</Trans>,
      accessor: 'publishedDate',
      Cell: ({cell: {value}}) => {
        if (!value) return '';
        return dayjs(value).format('YYYY-MM-DD HH:MM');
      },
      style: {width: '10%'},
      Filter: DateRangeFilter,
    },
    {
      Header: <Trans i18nKey="p.b.p.list.actions">Actions</Trans>,
      id: 'actions',
      accessor: (d) => d,
      disableSortBy: true,
      Cell: ({cell: {value, row}}) => {
        const isConverting = row.original.isConverting;
        return (
          <PostActionButtons
            post={value}
            onActionComplete={fetchData}
            mappingKeyList={mappingKeyList}
            blogFocus={blogFocus}
            languageType={languageType}
            isConverting={false}
          />
        );
      },
      style: {width: '10%'},
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex">
          <Link
            className="btn btn-danger kt-margin-b-10 kt-margin-r-10"
            to="/blog/post/add-post"
          >
            <i className="fa fa-plus" />
            <Trans i18nKey="p.b.p.add.post">Add Post</Trans>
          </Link>
          <DisplaySetting language={languageType} />
        </div>
        <div style={{display: 'flex'}}>
          <div style={{float: 'left', margin: '0 10px 0 0'}}>
            <Select
              options={globals.blogFocus}
              value={_find(globals.blogFocus, {value: blogFocus})}
              onChange={(blogFocus) => {
                store.set('blogFocus', blogFocus.value);
                setBlogFocus(blogFocus.value);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  width: '155px',
                  height: '42px',
                }),
              }}
            />
          </div>
          <div style={{float: 'right'}}>
            <Select
              options={globals.blogLanguages}
              value={_find(globals.blogLanguages, {value: languageType})}
              onChange={(lang) => {
                store.set('blogLanguage', lang.value);
                setLanguageType(lang.value);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100px',
                  height: '42px',
                }),
              }}
            />
          </div>
        </div>
      </div>
      <ManualDataTable
        isLoading={isLoading}
        columns={columns}
        data={originalData.results}
        totalSize={originalData.totalSize}
        initState={{
          sortBy: {id: 'id', desc: true},
          pageSize: 10,
          pageIndex: 0,
        }}
        extParams={{
          languageType: languageType,
          postAttrs: _get(
            _find(globals.blogFocus, (t) => t.value === blogFocus),
            'code'
          ),
        }}
        setProcessedParams={setProcessedParams}
      />

      <DetailModal
        data={postDetail}
        isOpen={isDetailOpen}
        onClose={() => closeModal('POST')}
      />
      <AuthorModal
        data={postAuthor}
        isOpen={isAuthorOpen}
        onClose={() => closeModal('AUTHOR')}
      />
      <ImageModal
        {...imageModalData}
        isOpen={isImageOpen}
        onClose={() => closeModal('IMAGE')}
      />
    </div>
  );
}
