import React from 'react';

export default function Strapi() {
  return (
    <div
      className="kt-header__topbar-item kt-header__topbar-item--user"
      style={{alignItems: 'center'}}
    >
      <div className="kt-header__topbar-wrapper" style={{width: '120px'}}>
        <a
          className="kt-header__topbar-username kt-visible-desktop"
          href="/admin/strapi"
          style={{fontSize: '16px'}}
        >
          Strapi{' '}
          <img
            alt="cover"
            src={require('../../../assets/images/strapi.svg')}
            style={{width: '40%', marginLeft: '5px'}}
          />
        </a>
      </div>
    </div>
  );
}
