import fetch from 'isomorphic-fetch';
import qs from 'qs';

const handleResponse = async (r, omitHandle = false) => {
  try {
    if (r.ok) {
      return omitHandle ? r : r.json();
    }
    if (r.status === 401) {
      return Promise.reject('Unauthorized');
    }
    return Promise.reject(omitHandle ? r : await r.json());
  } catch (err) {
    return Promise.reject(err);
  }
};

export const get = async (url, params = {}, abortSignal) =>
  handleResponse(
    await fetch(url + '?' + qs.stringify(params, {arrayFormat: 'repeat'}), {
      method: 'GET',
      credentials: 'same-origin',
      signal: abortSignal
    })
  );

export const post = async (url, params = {}) =>
  handleResponse(
    await fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
  );

export const postWithoutResponseHandle = async (url, params = {}) =>
  handleResponse(
    await fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }),
    true
  );

export const listAll = async (list, params, pageSize = 100, abortSignal) => {
  let pageIndex = 1;
  let results = [];
  while (true) {
    const json = await list({...params, pageIndex, pageSize}, abortSignal);
    const r = json.results || json;
    results = results.concat(r);
    if (r.length < pageSize) break;
    pageIndex++;
  }
  return {results, totalSize: results.length};
};
