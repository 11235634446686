import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {closeMenu, switchMenu} from './reducer';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';
import _toLower from 'lodash/toLower';
import _find from 'lodash/find';
import menus from '../../../utils/router/menus';

const Sidebar = () => {
  const pathname = useLocation().pathname;
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const admin = useSelector((state) => state.components.auth.admin);
  const isOpened = useSelector((state) => state.components.layout.sidebar);
  const asideMenuOn = useSelector(
    (state) => state.components.layout.sidebar.asideMenuOn
  );

  const closeMenuHandler = () => {
    dispatch(closeMenu());
  };

  const isUnderlyingLink = (menu, pathname) => {
    if (_toLower(menu.link) === pathname) {
      return true;
    }
    if (
      menu.sublinks &&
      _find(menu.sublinks, (l) => _toLower(l) === pathname)
    ) {
      return true;
    }
    if (!menu.items) {
      return false;
    }
    for (let i = 0; i < menu.items.length; i++) {
      if (isUnderlyingLink(menu.items[i], pathname)) {
        return true;
      }
    }
  };

  const isActive = (parentMenu, menu, pathname) => {
    if (_toLower(menu.link) === pathname) {
      return true;
    }
    return (
      menu.sublinks && _find(menu.sublinks, (l) => _toLower(l) === pathname)
    );
  };

  const activeIconStyle = (menu, pathname) => {
    if (_toLower(menu.link) === pathname) {
      return {color: '#5d78ff'};
    }
    return menu.sublinks &&
      _find(menu.sublinks, (l) => _toLower(l) === pathname)
      ? {color: '#5d78ff'}
      : {color: '#cacad2'};
  };

  const filterByPrivilegeType = (allMenu) => {
    return allMenu.filter((menu) => {
      if (!menu.privilegeType) {
        return true;
      }
      const admPrivileges = admin.privileges.map((p) => p.toUpperCase());
      if (admPrivileges.includes(menu.privilegeType.toUpperCase())) {
        if (menu.items && menu.items.length > 0) {
          menu.items = filterByPrivilegeType(menu.items);
        }
        return true;
      }
      return false;
    });
  };

  const getMenu = useMemo(() => {
    let rootMenu = menus(t);
    if (!admin || !admin.privileges) {
      return [];
    }
    return filterByPrivilegeType(rootMenu);
  }, [admin]);

  return (
    <div>
      <div
        className={classnames({
          'kt-aside': true,
          'kt-aside--fixed': true,
          'kt-grid__item': true,
          'kt-grid': true,
          'kt-grid--desktop': true,
          'kt-grid--hor-desktop': true,
          'kt-aside--on': asideMenuOn,
        })}
        style={{overflow: 'scroll'}}
      >
        <button className="kt-aside-close" onClick={closeMenuHandler}>
          <i className="la la-remove" />
        </button>

        <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid">
          <div className="kt-aside-menu kt-scroll">
            <ul className="kt-menu__nav">
              {getMenu.map(
                (m, i) =>
                  m.items &&
                  m.items.map((mm, ii) => (
                    <li
                      key={'item-' + i + '-' + ii}
                      className={classnames({
                        'kt-menu__item': true,
                        'kt-menu__item--submenu': true,
                        'kt-menu__item--active':
                          mm.link === pathname ||
                          isUnderlyingLink(mm, pathname),
                        'kt-menu__item--open': mm.items && !!mm.items.length,
                        'kt-menu__item--expanded': isUnderlyingLink(
                          mm,
                          pathname
                        ),
                      })}
                      aria-haspopup="true"
                    >
                      <Link to={mm.link} className="kt-menu__link">
                        <span className="kt-menu__item-here" />
                        <span
                          className="kt-menu__link-icon"
                          style={activeIconStyle(mm, pathname)}
                        >
                          <i className={mm.icon} />
                        </span>
                        <span className="kt-menu__link-text">{mm.title}</span>
                      </Link>
                      {mm.items && !!mm.items.length && (
                        <div className="kt-menu__submenu">
                          <span className="kt-menu__arrow" />
                          <ul className="kt-menu__subnav">
                            {mm.items.map((mmm, iii) => (
                              <li
                                key={'sub-item-' + iii}
                                className={classnames({
                                  'kt-menu__item': true,
                                  'kt-menu__item--open':
                                    isOpened && mmm.items && !!mmm.items.length,
                                  'kt-menu__item--active': isActive(
                                    mm,
                                    mmm,
                                    pathname
                                  ),
                                })}
                                aria-haspopup="true"
                              >
                                <Link
                                  to={mmm.link}
                                  className="kt-menu__link"
                                  onClick={() =>
                                    mmm.items &&
                                    !!mmm.items.length &&
                                    switchMenu()
                                  }
                                >
                                  <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                                    <span />
                                  </i>
                                  <span className="kt-menu__link-text">
                                    {mmm.title}
                                  </span>
                                  {mmm.items && !!mmm.items.length && (
                                    <i className="kt-menu__ver-arrow la la-angle-right" />
                                  )}
                                </Link>
                                {mmm.items && !!mmm.items.length && (
                                  <div className="kt-menu__submenu">
                                    <span className="kt-menu__arrow" />
                                    <ul className="kt-menu__subnav">
                                      {mmm.items.map((mmmm, iiii) => (
                                        <li
                                          key={'sub-sub-item-' + iiii}
                                          className={classnames({
                                            'kt-menu__item': true,
                                            'kt-menu__item--active': isActive(
                                              mmm,
                                              mmmm,
                                              pathname
                                            ),
                                          })}
                                          aria-haspopup="true"
                                        >
                                          <Link
                                            to={mmmm.link}
                                            className="kt-menu__link"
                                          >
                                            <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
                                              <span />
                                            </i>
                                            <span className="kt-menu__link-text">
                                              {mmmm.title}
                                            </span>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </li>
                  ))
              )}
            </ul>
          </div>
        </div>
      </div>
      {asideMenuOn && (
        <div className="kt-aside-overlay" onClick={closeMenuHandler} />
      )}
    </div>
  );
};

export default Sidebar;
