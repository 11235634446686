import React, {useRef, useEffect, useCallback} from 'react';
import './index.css';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import {findDOMNode} from 'react-dom';

export default function VideoPlayer({
  videoUrl,
  videoCover,
  languageType = 'enu',
  autoPlay = false,
  fullscreen = false,
  onCloseFullscreen = () => {},
}) {
  const doPlay = !!videoCover || autoPlay;
  const playerRef = useRef(null);
  const onScreenfullChange = useCallback(() => {
    if (!screenfull.isFullscreen) onCloseFullscreen();
  }, [onCloseFullscreen]);

  useEffect(() => {
    screenfull.enabled && screenfull.on('change', onScreenfullChange);
    if (
      fullscreen &&
      screenfull.enabled &&
      !screenfull.isFullscreen &&
      playerRef &&
      playerRef.current
    )
      screenfull.request(findDOMNode(playerRef.current));
    return () => {
      screenfull.enabled && screenfull.off('change');
    };
  });

  if (languageType === 'chs') {
    return (
      <div className="video-player-wrapper">
        <div className="video-iframe-wrapper">
          <iframe src={videoUrl} title="youku-video" />
        </div>
      </div>
    );
  }

  return (
    <div className="video-player-wrapper">
      <ReactPlayer
        className="video-player-block"
        url={videoUrl}
        light={videoCover || false}
        playing={doPlay}
        autoPlay={doPlay}
        controls
        width="100%"
        height="100%"
        ref={playerRef}
      />
    </div>
  );
}
