import React from 'react';
import {Navigate} from 'react-router-dom';
import AsyncListAdmin from './privileges/listadmin/async';
import AsyncAddorEditAdmin from './privileges/addoreditadmin/async';

const parent = {
  title: 'Admin Management',
  path: '/admin/list-admin',
};

export default [
  {
    path: '/admin/list-admin',
    element: <AsyncListAdmin />,
    handle: {
      title: 'Admin Management',
      subtitle: 'Admin Management',
      breadcrumbs: [parent],
    },
  },
  {
    path: '/admin/edit-admin',
    element: <AsyncAddorEditAdmin />,
    handle: {
      title: 'Edit Account',
      subtitle: 'Edit Account',
      breadcrumbs: [parent],
    },
  },
  {
    path: '/admin/add-admin',
    element: <AsyncAddorEditAdmin />,
    handle: {
      title: 'Add Account',
      subtitle: 'Add Account',
      breadcrumbs: [parent],
    },
  },
  {
    path: '/admin',
    element: <Navigate to="/admin/list-admin" />,
  },
];
