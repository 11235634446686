import React, {useMemo, useEffect, useState} from 'react';
import {useTable, usePagination, useSortBy, useFilters} from 'react-table';
import moment from 'moment';
import _isUndefined from 'lodash/isUndefined';
import _isNull from 'lodash/isNull';
import Paginate from '../Paginate';
import {DropdownFilter, DateRangeFilter} from './DataFilter';
import Button from './ActionButton';
import classnames from 'classnames';
import store from 'store';
import _isEmpty from 'lodash/isEmpty';

export let forceFetch = null;

export default function DataTable({
  data = [],
  columns,
  loading = false,
  initState = {},
  noDataMessage,
  pageChangedCallback,
  onFetchData = null,
  storeParams = [],
  newPaginate = {},
  resetFunc = () => {},
  setFilteredData = () => {},
  getFilterParams,
}) {
  const [manualData, setManualData] = useState([]);
  const [manualTotalSize, setManualTotalSize] = useState(0);
  const [temp, setTemp] = useState(0);
  const filterable = columns.findIndex((c) => c.Filter) !== -1;
  const processedColumns = columns.map((c) => ({
    ...c,
    filter: getFilterType(c),
  }));
  forceFetch = () => setTemp(temp + 1);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    setPageSize,
    setAllFilters,
    state: {pageIndex, pageSize, filters},
  } = useTable(
    {
      columns: useMemo(() => processedColumns, [columns]),
      data: onFetchData ? manualData : data,
      filterTypes: useMemo(
        () => ({
          dateRange: (rows, id, filterValue) => {
            if (filterValue[0] === '' && filterValue[1] === '') return rows;
            return rows.filter((row) => {
              const value = row.values[id];
              return _isUndefined(value) || value === null
                ? false
                : moment(row.values[id]).isBetween(
                    filterValue[0],
                    filterValue[1],
                    null,
                    '[]'
                  );
            });
          },
          dropdown: (rows, id, filterValue) => {
            if (!filterValue) return rows;
            return rows.filter((row) => {
              const columnValue = row.values[id];
              if (!columnValue) return false;
              if (Array.isArray(columnValue)) {
                return columnValue.includes(filterValue) ? columnValue : false;
              }
              if (typeof columnValue === 'object') {
                return columnValue.value === filterValue;
              }
              return columnValue === filterValue;
            });
          },
          text: (rows, id, filterValue) => {
            if (!filterValue) return rows;
            return rows.filter((row) => {
              const cellValue = row.values[id];
              if (_isNull(cellValue) || _isUndefined(cellValue)) return false;
              if (typeof cellValue === 'object') {
                return Object.keys(cellValue).some(
                  (k) =>
                    String(cellValue[k])
                      .toLowerCase()
                      .indexOf(String(filterValue).toLowerCase()) !== -1
                );
              }
              return (
                String(cellValue)
                  .toLowerCase()
                  .indexOf(String(filterValue).toLowerCase()) !== -1
              );
            });
          },
        }),
        []
      ),
      autoResetPage: false,
      disableSortRemove: true,
      disableMultiSort: true,
      initialState: {
        ...initState,
        pageSize: initState.pageSize || 10,
        sortBy: initState.sortBy
          ? !Array.isArray(initState.sortBy)
            ? [initState.sortBy]
            : initState.sortBy
          : [],
      },
      manualPagination: !!onFetchData,
      pageCount: onFetchData ? manualTotalSize : data.length,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (onFetchData) {
      onFetchData({pageIndex: pageIndex + 1, pageSize}).then(
        ({results, totalSize}) => {
          setManualData(results);
          setManualTotalSize(totalSize);
        }
      );
    }
  }, [onFetchData, pageIndex, pageSize, temp]);

  useEffect(() => {
    setFilteredData(rows);
  }, [rows]);

  useEffect(() => {
    if (!getFilterParams) return;
    getFilterParams(filters);
  }, [filters]);

  return (
    <div>
      <div style={{overflowX: 'auto'}}>
        <table
          {...getTableProps()}
          className="table table-striped table-bordered table-hover dataTable"
        >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`head-row-${i}`}>
                {headerGroup.headers.map((column, i) => {
                  const {
                    getHeaderProps,
                    getSortByToggleProps,
                    disableSortBy,
                    isSorted,
                    isSortedDesc,
                    render,
                    headerClass,
                  } = column;
                  return column.disableSorting ? (
                    <th className={headerClass || ''}>{render('Header')}</th>
                  ) : (
                    <th
                      key={`head-col-${i}`}
                      {...getHeaderProps(getSortByToggleProps())}
                      className={classnames(
                        sortingStatus(disableSortBy, isSorted, isSortedDesc),
                        headerClass || ''
                      )}
                    >
                      {render('Header')}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {filterable && (
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={`filter-${i}`}>
                  {headerGroup.headers.map((column, ii) => {
                    const {
                      getHeaderProps,
                      canFilter,
                      id,
                      render,
                      Filter,
                    } = column;
                    return (
                      <th {...getHeaderProps()} key={`filter-col-${ii}`}>
                        {canFilter && Filter ? render('Filter') : null}
                        {id === 'actions' && (
                          <Button
                            type="reset"
                            onClick={() => {
                              resetFunc();
                              if (!_isEmpty(storeParams)) {
                                storeParams.forEach((s) => {
                                  store.set(s, '');
                                });
                              }
                              setAllFilters([]);
                            }}
                          />
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}
          <tbody {...getTableBodyProps()}>
            {loading && <Loading colSpan={columns.length} />}
            {page.length === 0 && !loading && (
              <NoData colSpan={columns.length} message={noDataMessage} />
            )}
            {page.length > 0 &&
              !loading &&
              page.map((row, i) => {
                return (
                  prepareRow(row) || (
                    <tr {...row.getRowProps()} key={`tr-${i}`}>
                      {row.cells.map((cell, ii) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              className: cell.column.className || '',
                              style: cell.column.style || {},
                            })}
                            key={`tr${i}-td${ii}`}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  )
                );
              })}
          </tbody>
        </table>
      </div>
      {!_isEmpty(newPaginate) ? (
        <Paginate
          grid={{
            pageIndex:
              newPaginate.pageSize > newPaginate.totalSize
                ? 1
                : newPaginate.page + 1,
            pages: Math.ceil(newPaginate.totalSize / newPaginate.pageSize),
            pageSize: newPaginate.pageSize,
            totalSize: newPaginate.totalSize,
          }}
          clickCallback={(e) => {
            newPaginate.setPage(e.selected);
          }}
          selectCallback={newPaginate.setPageSize}
        />
      ) : (
        <Paginate
          grid={{
            pageIndex: pageIndex + 1,
            pages: Math.ceil(
              (onFetchData ? manualTotalSize : rows.length) / pageSize
            ),
            pageSize,
            totalSize: onFetchData ? manualTotalSize : rows.length,
          }}
          clickCallback={(e) => gotoPage(e.selected)}
          selectCallback={setPageSize}
        />
      )}
    </div>
  );
}

function sortingStatus(disable, isSorted, isSortedDesc) {
  if (disable) return '';
  if (!isSorted) return 'sorting';
  return isSortedDesc ? 'sorting_desc' : 'sorting_asc';
}

function NoData({colSpan, message}) {
  return (
    <tr>
      <td colSpan={colSpan} className="text-center">
        {/*<FormattedMessage {...message} />*/}
        No data
      </td>
    </tr>
  );
}

function Loading({colSpan}) {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div>Loading...</div>
      </td>
    </tr>
  );
}

function getFilterType(col) {
  if (col.filter) return col.filter;
  switch (col.Filter) {
    case DropdownFilter:
      return 'dropdown';
    case DateRangeFilter:
      return 'dateRange';
    default:
      return 'text';
  }
}
