import React from 'react';
import _get from 'lodash/get';

export default function ModalContentHalfRow({
  left,
  right,
  labelClassName = 'col-6',
  contentClassName = 'col-6',
}) {
  return (
    <div className="row mb-3">
      <div className="col-6">
        {left && (
          <div className="row">
            <label
              className={`${labelClassName} text-right`}
              style={{fontWeight: 500}}
            >
              {left.label}:
            </label>
            <div className={contentClassName}>{left.content}</div>
          </div>
        )}
      </div>
      <div className="col-6">
        {right && (
          <div className="row">
            <label
              className={`${labelClassName} text-right`}
              style={{fontWeight: 500}}
            >
              {right.label}:
            </label>
            <div className={contentClassName}>{right.content}</div>
          </div>
        )}
      </div>
    </div>
  );
}
