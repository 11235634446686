import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {Field} from 'formik';
import Select from 'react-select';
import './selectBox.css';
import _get from 'lodash/get';

export default function ReactHeadingSelect({
  options,
  field,
  form: {errors, values, setFieldValue},
  value = undefined,
  num,
  dupArr,
  searchNews,
  lang,
  newsHeader,
  onChange,
  isClearable = true,
  ...props
}) {
  const [isError, setisError] = useState(false);
  const [optVal, setoptVal] = useState('');
  useEffect(() => {
    if (value && value.newsId) {
      if (num || num === 0) {
        setoptVal(value.newsId[num]);
      }
    }
    if (dupArr && dupArr.indexOf(field.value) !== -1 && field.value != null) {
      setisError(true);
    } else {
      setisError(false);
    }
  }, [newsHeader]);

  useEffect(() => setisError(_get(errors, field.name)), [errors, values]);

  return (
    <>
      <Field name={field.name}>
        {({field}) => (
          <span>
            <Select
              className={classnames({
                'custom-react-select--invalid': isError,
              })}
              name={field.name}
              options={options}
              isClearable={isClearable}
              value={
                optVal
                  ? options.find(option => option.value === field.value)
                  : value || null
              }
              onChange={option => {
                if (onChange) {
                  return onChange(option);
                }
                if (option) {
                  setFieldValue(field.name, option.value);
                } else {
                  setFieldValue(field.name, null);
                }
              }}
              placeholder={false}
            />
          </span>
        )}
      </Field>
    </>
  );
}
