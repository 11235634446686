import React from 'react';
import {Navigate, useOutlet} from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import {useSelector} from 'react-redux';
import Loader from '../spinner/Loader';

export const ProtectedFullLayout = () => {
  const outlet = useOutlet();
  const auth = useSelector((state) => state.components.auth.admin);
  const isAuthCheck = useSelector((state) => state.components.auth.authCheck);

  if (!isAuthCheck) {
    return <Loader />;
  }

  if (!auth || auth.admin) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <>
      <div className="kt-grid kt-grid--hor kt-grid--root">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
            <Header />
            <div>{outlet}</div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
