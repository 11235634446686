import React from 'react';
import {Navigate, useOutlet, useMatches} from 'react-router-dom';
import Header from './header';
import Sidebar from './sidebar';
import PageHeader from './pageheader';
import Footer from './footer';
import {useSelector} from 'react-redux';
import Loader from '../spinner/Loader';

export const ProtectedSingleColumnLayout = () => {
  const matches = useMatches();
  const outlet = useOutlet();
  const auth = useSelector((state) => state.components.auth.admin);
  const isAuthCheck = useSelector((state) => state.components.auth.authCheck);

  if (!isAuthCheck) {
    return <Loader />;
  }

  if (!auth || auth.admin) {
    return <Navigate to="/sign-in" />;
  }

  const title = matches
    .filter((match) => Boolean(match.handle && match.handle.title))
    .map((match) => match.handle.title)[0];

  const subtitle = matches
    .filter((match) => Boolean(match.handle && match.handle.subtitle))
    .map((match) => match.handle.subtitle)[0];

  const breadcrumbs = matches
    .filter((match) => Boolean(match.handle && match.handle.breadcrumbs))
    .map((match) => match.handle.breadcrumbs)[0];

  return (
    <>
      <div className="kt-grid kt-grid--hor kt-grid--root">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
            <Header />
            <div className="kt-container kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-grid--stretch">
              <Sidebar />
              <div className="kt-holder kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                  <PageHeader title={title} breadcrumbs={breadcrumbs} />
                  <div className="kt-content kt-grid__item kt-grid__item--fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className={`kt-portlet`}>
                          {subtitle && (
                            <div className="kt-portlet__head">
                              <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                  {subtitle}
                                </h3>
                              </div>
                            </div>
                          )}
                          <div className="kt-portlet__body">{outlet}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
