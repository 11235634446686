import React from 'react';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import {Trans} from 'react-i18next';

const Button = ({
  type,
  onClick,
  id,
  url,
  query,
  disabled = false,
  customize,
  needRefresh = false,
  text,
}) => {
  switch (type) {
    case 'reset':
      return (
        <button
          type="button"
          className="btn btn-outline btn-sm btn-default"
          onClick={() => {
            if (disabled) return;
            onClick();
          }}
          disabled={disabled}
        >
          <i className="fa fa-undo" />
          Reset
        </button>
      );
    case 'add':
      return onClick ? (
        <button
          type="button"
          className="btn btn-sm btn-danger kt-margin-r-10 kt-margin-b-10 nowrap"
          onClick={() => {
            if (disabled) return;
            onClick();
          }}
          disabled={disabled}
        >
          <i className="fa fa-plus" />{' '}
          {text || <Trans i18nKey="c.d.action.button.add">Add</Trans>}
        </button>
      ) : (
        <Link
          className="btn btn-sm btn-danger kt-margin-r-10 kt-margin-b-10 nowrap"
          disabled={disabled}
          to={`${url}`}
        >
          <i className="fa fa-plus" />{' '}
          {text || <Trans i18nKey="c.d.action.button.add">Add</Trans>}
        </Link>
      );
    case 'edit':
      return onClick ? (
        <button
          type="button"
          className="btn btn-sm btn-primary kt-margin-r-10 kt-margin-b-10 nowrap"
          onClick={() => {
            if (disabled) return;
            onClick();
          }}
          disabled={disabled}
        >
          <i className="fa fa-edit" />{' '}
          {text || <Trans i18nKey="c.d.action.button.edit">Edit</Trans>}
        </button>
      ) : (
        <Link
          className="btn btn-sm btn-primary kt-margin-r-10 kt-margin-b-10 nowrap"
          disabled={disabled}
          to={`${url}?${queryString.stringify(query)}`}
        >
          <i className="fa fa-edit" />{' '}
          <Trans i18nKey="c.d.action.button.edit">Edit</Trans>
        </Link>
      );
    case 'saveAs':
      return (
        <ConfirmDialog
          placement="top"
          key={`saveAs-${id}`}
          confirmCallback={() => {
            if (disabled) {
              return;
            }
            if (needRefresh) {
              // onClick().then(refreshFn);
              return;
            }
            onClick();
          }}
        >
          <button
            type="button"
            className="btn btn-sm btn-grey kt-margin-r-10 kt-margin-b-10 nowrap"
            disabled={disabled}
          >
            <i className="fa fa-copy" />{' '}
            <Trans i18nKey="c.d.action.button.save.as">Save As</Trans>
          </button>
        </ConfirmDialog>
      );
    case 'delete':
      return (
        <ConfirmDialog
          placement="top"
          key={`delete-${id}`}
          confirmCallback={() => {
            if (disabled) {
              return;
            }
            if (needRefresh) {
              // onClick().then(refreshFn);
              return;
            }
            onClick();
          }}
        >
          <button
            type="button"
            className="btn btn-sm btn-outline-danger kt-margin-r-10 kt-margin-b-10 nowrap"
            disabled={disabled}
          >
            <i className="fa fa-times" />{' '}
            {text || <Trans i18nKey="c.d.action.button.delete">Delete</Trans>}
          </button>
        </ConfirmDialog>
      );
    case 'discard':
      return (
        <ConfirmDialog
          placement="top"
          key={`discard-${id}`}
          confirmCallback={() => {
            if (disabled) {
              return;
            }
            if (needRefresh) {
              // onClick().then(refreshFn);
              return;
            }
            onClick();
          }}
        >
          <button
            type="button"
            className="btn btn-sm yellow-gold margin-bottom-5"
            disabled={disabled}
          >
            <i className="fa fa-trash-o" />
          </button>
        </ConfirmDialog>
      );
    case 'submit':
      return (
        <ConfirmDialog
          placement="top"
          key={`submit-${id}`}
          confirmCallback={() => {
            if (disabled) {
              return;
            }
            if (needRefresh) {
              // onClick().then(refreshFn);
              return;
            }
            onClick();
          }}
        >
          <button
            type="button"
            className="btn btn-sm btn-success kt-margin-r-10 kt-margin-b-10 nowrap"
            disabled={disabled}
          >
            <i className="fa fa-check" />{' '}
            {text || <Trans i18nKey="c.d.action.button.submit">Submit</Trans>}
          </button>
        </ConfirmDialog>
      );
    case 'publish':
      return (
        <ConfirmDialog
          placement="top"
          key={`publish-${id}`}
          confirmCallback={() => {
            if (disabled) {
              return;
            }
            if (needRefresh) {
              // onClick().then(refreshFn);
              return;
            }
            onClick();
          }}
        >
          <button
            type="button"
            className="btn btn-sm btn-outline-info kt-margin-r-10 kt-margin-b-10 nowrap"
            disabled={disabled}
          >
            <i className="fa flaticon2-paperplane" />{' '}
            <Trans i18nKey="c.d.action.button.publish">Publish</Trans>
          </button>
        </ConfirmDialog>
      );
    case 'takeDown':
      return (
        <ConfirmDialog
          placement="top"
          key={`takeDown-${id}`}
          confirmCallback={() => {
            if (disabled) {
              return;
            }
            if (needRefresh) {
              // onClick().then(refreshFn);
              return;
            }
            onClick();
          }}
        >
          <button
            type="button"
            className="btn btn-sm btn-outline-dark kt-margin-r-10 kt-margin-b-10 nowrap"
            disabled={disabled}
          >
            <i className="fa flaticon-edit-1" />{' '}
            <Trans i18nKey="c.d.action.button.takedown">TakeDown</Trans>
          </button>
        </ConfirmDialog>
      );
    case 'export':
      return (
        <button
          key={`export-${id}`}
          type="button"
          className="btn btn-sm grey-mint btn-default margin-bottom-5"
          disabled={disabled}
          onClick={() => {
            if (disabled) return;
            onClick();
          }}
        >
          <i className="fa fa-file-excel-o" />
        </button>
      );
    case 'preview':
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-sm btn-success kt-margin-r-10 kt-margin-b-10"
        >
          <i className="fa fa-eye" />{' '}
          {text || <Trans i18nKey="c.d.action.button.preview">Preview</Trans>}
        </a>
      );
    case 'customize': {
      const {className, icon} = customize;
      return (
        <button
          className={`btn btn-sm ${className}`}
          onClick={() => {
            if (disabled) return;
            onClick();
          }}
        >
          <i className={`fa ${icon}`} /> {text && text}
        </button>
      );
    }
    case 'customizeConfirm': {
      const {className, icon} = customize;
      return (
        <ConfirmDialog
          placement="top"
          key={`customize-${id}`}
          confirmCallback={() => {
            if (disabled) {
              return;
            }
            if (needRefresh) {
              // onClick().then(refreshFn);
              return;
            }
            onClick();
          }}
        >
          <button className={`btn btn-sm ${className}`}>
            <i className={`fa ${icon}`} />
          </button>
        </ConfirmDialog>
      );
    }
    default:
      return null;
  }
};

export default Button;
