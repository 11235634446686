import _get from 'lodash/get';
import InputBox from '../../../components/formik/InputBox';
import React, {useEffect} from 'react';
import {Field, FieldArray, Form, Formik} from 'formik';
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import SimpleButtonGroup from '../../../components/formik/SimpleButtonGroup';
import {Trans} from 'react-i18next';
import './index.css';
import $ from 'jquery';
import _isEmpty from 'lodash/isEmpty';
import BasicRichTextEditor from '../../../components/texteditor/basicRiceditor';

const answerButtons = [
  'source',
  '|',
  'bold',
  'strikethrough',
  'underline',
  'italic',
  '|',
  'ul',
  'ol',
  '|',
  'fontsize',
  'brush',
  'paragraph',
  '|',
  'image',
  'table',
  'link',
  '|',
  'align',
];
const answerConfig = {
  readonly: false,
  allowResizeY: false,
  height: '50px',
  buttons: answerButtons,
  buttonsMD: answerButtons,
  buttonsSM: answerButtons,
  buttonsXS: answerButtons,
};

export default function SeoFaq({
  metaInfos,
  setMetaInfos = () => {},
  setShowFaqModal = () => {},
  showFaqModal,
}) {
  useEffect(() => {
    $('.fade').removeAttr('tabindex');
  }, [$('.fade')]);

  const formikConfig = {
    enableReinitialize: true,
    initialValues: metaInfos,
    onSubmit: async (values, {setSubmitting}) => {
      if (values.serpFaqs && _get(values, 'serpFaqs', []).length > 0) {
        values.serpFaqs.forEach((item) => {
          if (item.answer.length > 2048) {
            toast.error(
              'The FAQ answer exceeds the word limit, please shorten the content.'
            );
          }
        });
      }
      setMetaInfos({...metaInfos, serpFaqs: values.serpFaqs});
      setSubmitting(false);
      setShowFaqModal(false);
    },
  };

  return (
    <Modal
      show={showFaqModal}
      size="xl"
      scrollable={true}
      id="myModal"
      tabindex={false}
    >
      <Modal.Header>
        <Modal.Title>FAQ Detail</Modal.Title>
      </Modal.Header>
      <Formik {...formikConfig}>
        {(formikProps) => (
          <Form>
            <Modal.Body style={{overflowY: 'scroll', height: '75vh'}}>
              <table className="table table-striped table-bordered table-hover dataTable">
                <thead>
                  <tr>
                    <th style={{width: '10%'}}>FAQ Order</th>
                    <th style={{width: '70%'}}>FAQ Content</th>
                    <th style={{width: '20%'}}>
                      <Trans i18nKey="p.b.p.listposts.displaysetting.action">
                        Action
                      </Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {_get(formikProps.values, 'serpFaqs') &&
                    formikProps.values.serpFaqs.map((s, i) => (
                      <FieldArray name={`serpFaqs`}>
                        {(arrayHelp) => (
                          <tr>
                            <td className="text-center">{i + 1}</td>
                            <td>
                              <div>Question</div>
                              <div>
                                <Field
                                  name={`serpFaqs[${i}].question`}
                                  className="form-control"
                                  component={InputBox}
                                  maxLength="128"
                                />
                              </div>
                              <div>Answer</div>
                              <div className="faq-desc">
                                <Field
                                  type="text"
                                  name={`serpFaqs[${i}].answer`}
                                  className="form-control"
                                  component={BasicRichTextEditor}
                                  customConfig={answerConfig}
                                  updatePostContent={s.answer}
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                className="learn-button"
                                onClick={() => {
                                  arrayHelp.push({
                                    question: '',
                                    answer: '',
                                  });
                                }}
                              >
                                Add
                              </div>
                              {!_isEmpty(formikProps.values.serpFaqs) &&
                                formikProps.values.serpFaqs.length > 1 && (
                                  <div
                                    className="learn-button"
                                    onClick={() => {
                                      arrayHelp.remove(i);
                                    }}
                                  >
                                    Del
                                  </div>
                                )}
                              {!_isEmpty(formikProps.values.serpFaqs) &&
                                formikProps.values.serpFaqs.length > 1 &&
                                i > 0 && (
                                  <div>
                                    <img
                                      alt="move up"
                                      className="mr-2"
                                      onClick={() => arrayHelp.swap(i - 1, i)}
                                      src={require('../../../assets/images/up_arrow.png')}
                                    />
                                  </div>
                                )}
                              {!_isEmpty(formikProps.values.serpFaqs) &&
                                i < formikProps.values.serpFaqs.length - 1 && (
                                  <div>
                                    <img
                                      alt="move down"
                                      onClick={() => arrayHelp.swap(i, i + 1)}
                                      src={require('../../../assets/images/down_arrow.png')}
                                    />
                                  </div>
                                )}
                            </td>
                          </tr>
                        )}
                      </FieldArray>
                    ))}
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              <SimpleButtonGroup
                onCancelClick={() => setShowFaqModal(false)}
                isUpdating={true}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
