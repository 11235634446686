import React from 'react';
import {ErrorMessage} from 'formik';
import classnames from 'classnames';
import _get from 'lodash/get';

export default function Textarea({field, form: {touched, errors}, readOnly = false, ...props}) {
  return (
    <div>
      <textarea
          readOnly={readOnly}
        {...props}
        {...field}
        className={classnames({
          'form-control': true,
          'is-invalid': _get(touched, field.name) && _get(errors, field.name),
        })}
      />
      <span className="invalid-feedback">
        <ErrorMessage name={field.name} />
      </span>
    </div>
  );
}
