import React from 'react';
import Loader from '../spinner/Loader';
import useDeadLinks from '../hooks/useDeadLinks';
import DisplayDeadLinkResults from './DisplayDeadLinkResults';

export default function DeadLinkChecker({
  postId,
  content,
  otherLinks = () => [],
  deadLinkResult,
  setDeadLinkResult,
}) {
  const {isLoading, parseResult} = useDeadLinks(postId);
  const getOtherLinks = otherLinks();

  return (
    <div className={'row'}>
      <label className="col-2 col-form-label text-right">
        <button
          type="button"
          disabled={isLoading}
          className={'btn btn-sm btn-outline-warning'}
          onClick={() =>
            parseResult(content, getOtherLinks, 'CHECK').then((result) =>
              setDeadLinkResult(result)
            )
          }
        >
          {isLoading ? <Loader /> : <div>DeadLink Check</div>}
        </button>
      </label>
      <div className="col-10">
        <DisplayDeadLinkResults result={deadLinkResult} />
      </div>
    </div>
  );
}
