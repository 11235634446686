import React from 'react';
import {Trans} from 'react-i18next';
import AsyncListNews from './listnews/async';
import AsyncAddOrEditNews from './addoreditnews/async';
import {Navigate} from 'react-router-dom';

const parent = {
  title: <Trans i18nKey="p.n.news.listnews.title">News</Trans>,
  path: '/news',
};
export default [
  {
    path: '/news/list-news',
    element: <AsyncListNews />,
    handle: {
      title: <Trans i18nKey="p.n.news.listnews.title">News</Trans>,
      subtitle: <Trans i18nKey="p.n.news.listnews.title">News</Trans>,
      breadcrumbs: [parent],
    },
  },
  {
    path: '/news/add-news',
    element: <AsyncAddOrEditNews />,
    handle: {
      title: <Trans i18nKey="p.n.news.addoreditnews.add.title">Add News</Trans>,
      subtitle: (
        <Trans i18nKey="p.n.news.addoreditnews.add.title">Add News</Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/news/edit-news',
    element: <AsyncAddOrEditNews />,
    handle: {
      title: (
        <Trans i18nKey="p.n.news.addoreditnews.edit.title">Edit News</Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.n.news.addoreditnews.edit.title">Edit News</Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/news',
    element: <Navigate to="/news/list-news" />,
  },
];
