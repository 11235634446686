import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputBox from '../../../components/formik/InputBox';
import dao from '../../../dao/blogDao';
import yup from '../../../utils/yup';
import CustomOptionReactSelect from '../../../components/formik/CustomOptionReactSelect';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

export default function RedirectKeyModal(props) {
  const {children, className, post, mappingKeyList} = props;
  const [open, setOpen] = useState(false);
  const [po, setPo] = useState({});
  const [newMappingKeyList, setNewMappingKeyList] = useState([]);

  useEffect(() => {
    if (!open) return;
    dao.post.query(post.id).then((res) => {
      setPo(res);
    });
    dao.post.listMappingKey().then((res) => {
      const list = res.map((s) => {
        return {label: s, value: s};
      });
      setNewMappingKeyList(list);
    });
  }, [post.id, open]);

  function getRedirectPreviewUrl() {
    if (!_get(po, 'postAttrs') || !_get(po, 'redirectHandle')) return '';
    const isBusiness = po.postAttrs.indexOf('BF_BUSINESS_FOCUS') !== -1;
    return `${process.env.REACT_APP_FRONTEND_URL}/${
      isBusiness ? 'business' : 'consumer'
    }/blog/${po.redirectHandle}`;
  }

  const formikConfig = {
    validationSchema: yup.object().shape({
      redirectKey: yup
        .string()
        .matches(
          /^[a-zA-Z0-9\-_]*$/,
          'Redirect key should only contain a-zA-Z0-9_-'
        ),
      mappingKey: yup
        .string()
        .matches(
          /^[a-zA-Z0-9\-_]*$/,
          'Redirect key should only contain a-zA-Z0-9_-'
        )
        .required(),
    }),
    enableReinitialize: true,
    initialValues: {
      redirectKey: po.redirectKey ? po.redirectKey : '',
      mappingKey: po.mappingKey ? po.mappingKey : '',
    },
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true);
      dao.post
        .updatePostRedirectKey({
          postId: po.id,
          redirectKey: values.redirectKey,
          mappingKey: values.mappingKey,
        })
        .then((r) => {
          r && r.id
            ? toast.success('Update Successful')
            : toast.error('Update Failed');
          setOpen(false);
        });
    },
  };

  return (
    <>
      <button
        {...props}
        onClick={() => setOpen(true)}
        type="button"
        className={`btn btn-sm nowrap kt-margin-r-10 kt-margin-b-10 ${className}`}
      >
        {children}
      </button>
      <div className="col-12">
        <Formik {...formikConfig}>
          {({handleSubmit, values}) => (
            <Modal
              size="lg"
              show={open}
              onHide={() => setOpen(false)}
              style={{position: 'absolute', top: '10px'}}
            >
              <Modal.Header>
                <Modal.Title>Article Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <div className="form-group row">
                    <label className="col-2 col-form-label text-right">
                      Mapping Key
                      <span className="required" style={{color: 'red'}}>
                        *
                      </span>
                    </label>
                    <div className="col-10">
                      <Field
                        name="mappingKey"
                        className="form-control"
                        component={CustomOptionReactSelect}
                        options={
                          !_isEmpty(newMappingKeyList)
                            ? newMappingKeyList
                            : mappingKeyList
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-2 col-form-label text-right">
                      Redirect Key
                    </label>
                    <div className="col-10">
                      <Field
                        type="text"
                        name="redirectKey"
                        className="form-control"
                        component={InputBox}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-2 col-form-label text-right">
                      Redirect URL
                    </label>
                    <div className="col-10">
                      <span
                        className="form-control"
                        style={{backgroundColor: '#ebedf2'}}
                      >
                        {getRedirectPreviewUrl()}
                      </span>
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-secondary"
                  onClick={() => setOpen(false)}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn-primary"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </div>
    </>
  );
}
