import React, {useState} from 'react';
import {Trans} from 'react-i18next';
import DisplaySettingModal from './DisplaySettingModal';

export default function DisplaySetting(props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-outline btn-default kt-margin-b-10 kt-margin-r-10"
        onClick={() => setIsOpen(true)}
      >
        <i className="fa fa-align-justify" />{' '}
        <Trans i18nKey="p.b.p.listposts.displaysetting.button.text">
          Display Setting
        </Trans>
      </button>

      <DisplaySettingModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        {...props}
      />
    </React.Fragment>
  );
}
