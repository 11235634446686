import {Field, FieldArray} from 'formik';
import React from 'react';

const FormRow = ({
  label,
  name,
  component,
  isMultiChoice,
  isRequired = false,
  comment = '',
}) => {
  return (
    <div className="row">
      <label className="col-2 col-form-label text-right">
        {label}
        {isRequired && <span style={{color: 'red'}}>*</span>}
      </label>
      <div className="col-md-8 col-form-label">
        {!isMultiChoice ? (
          <Field name={name}>{component}</Field>
        ) : (
          <FieldArray name={name}>{component}</FieldArray>
        )}
        {comment && <span className="">{comment}</span>}
      </div>
    </div>
  );
};

export default FormRow;
