import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

export default function DisplayDeadLinkResults({result}) {
  return (
    <div>
      {!_isEmpty(_get(result, 'dupIds', {})) && (
        <ul>
          Duplicate id:{' '}
          {result.dupIds.map((s) => (
            <li key={s}>{s}</li>
          ))}
        </ul>
      )}
      {!_isEmpty(_get(result, 'noAnchorLinks', {})) && (
        <ul>
          HashLink anchor occurs multiple times and not found:{' '}
          {result.noAnchorLinks.map((s) => (
            <li key={s}>{s}</li>
          ))}
        </ul>
      )}
      {!_isEmpty(_get(result, 'brokenLinks', {})) && (
        <ul>
          Broken Link:{' '}
          {result.brokenLinks.map((s) => (
            <li key={s}>"{s}"</li>
          ))}
        </ul>
      )}
      {!_isEmpty(_get(result, 'otherLinks', {})) && (
        <ul>
          CTA Button Link:{' '}
          {result.otherLinks.map((s) => (
            <li key={s}>"{s}"</li>
          ))}
        </ul>
      )}
    </div>
  );
}
