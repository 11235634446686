import React from 'react';
import {Navigate, createBrowserRouter, RouterProvider} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loadAuth} from './components/auth/reducers';
import PreviewNews from './components/news/preview';
import PreviewVideos from './components/videos/preview/index';
import PreviewStory from './components/story/preview/index';
import PreviewBanner from './components/strapi/banner/preview';
import ProductPage from './components/strapi/productpage/index';
import ProjectPage from './components/strapi/projectPage';
import {ProtectedSingleColumnLayout} from './components/layout/ProtectedSingleColumnLayout';
import {ProtectedDefaultLayout} from './components/layout/ProtectedDefaultLayout';
import {ProtectedFullLayout} from './components/layout/ProtectedFullLayout';
import AsyncSignIn from './pages/auth/signin/async';
import home from './pages/home/routes';
import routes from './pages/routes';
import strapi from './pages/strapi/routes';

export default function App() {
  const auth = useSelector((state) => state.components.auth.admin);
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const pathName = window.location.pathname;
  const newsId = params.get('newsId');
  const videoId = params.get('videoId');
  const storyId = params.get('storyId');
  const formId = params.get('formId');
  const pfFormId = params.get('pfFormId');
  const cmsId = params.get('cmsId');
  const languageType = params.get('languageType');
  const type = params.get('type');
  if (pathName === '/news/preview' && newsId) {
    return <PreviewNews newsId={newsId} />;
  }

  if (pathName === '/video/preview' && videoId) {
    return <PreviewVideos videoId={videoId} />;
  }

  if (pathName === '/story/preview' && storyId) {
    return <PreviewStory storyId={storyId} />;
  }

  if (pathName === '/banner/preview' && formId) {
    return <PreviewBanner formId={formId} />;
  }

  if (pathName === '/page/preview' && cmsId) {
    return (
      <ProductPage cmsId={cmsId} languageType={languageType} type={type} />
    );
  }

  if (pathName === '/project/preview' && pfFormId) {
    return <ProjectPage pfFormId={pfFormId} />;
  }

  if (!auth) {
    dispatch(loadAuth());
  }

  const renderRoutes = createBrowserRouter([
    {
      path: '/sign-in',
      element: <AsyncSignIn />,
    },
    {
      element: <ProtectedDefaultLayout />,
      children: [...home],
    },
    {
      element: <ProtectedFullLayout />,
      children: [...strapi],
    },
    {
      element: <ProtectedSingleColumnLayout />,
      children: [
        ...routes,
        {
          path: '*',
          element: <Navigate to="/" />,
        },
      ],
    },
  ]);

  return <RouterProvider router={renderRoutes}></RouterProvider>;
}
