import React, {useEffect, useState} from 'react';
import bannerDao from '../../../dao/bannerDao';
import './index.css';
import {getImageAttr, getLinkAttr} from '../../../utils/config/getStrapiInfo';
import {Modal} from 'react-bootstrap';
import _set from 'lodash/set';
import Loader from '../../spinner/Loader';

export default function PreviewBanner({formId}) {
  const [show, setShow] = useState(false);
  const [strapiBanner, setStrapiBanner] = useState([]);
  const [blogPost, setBlogPost] = useState([]);
  const [postId, setPostId] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const banner = (val, string, isLoaded) => {
    return (
      <div className="preview-container">
        <div
          style={{
            borderRight: string === 'Before' && 'none',
            borderLeft: string === 'After' && 'none',
          }}
        >
          {string}
        </div>
        <span>
          {val && getImageAttr(val).src ? (
            <img
              title={getImageAttr(val).title}
              style={{cursor: getLinkAttr(val).href && 'pointer'}}
              className={getImageAttr(val).class}
              alt={getImageAttr(val).alt}
              src={getImageAttr(val).src}
              onClick={() => {
                if (getLinkAttr(val).href) window.open(getLinkAttr(val).href);
              }}
            />
          ) : !isLoaded ? (
            <Loader />
          ) : (
            <img
              alt={''}
              src="https://s3-ap-northeast-1.amazonaws.com/bcm-demo-media/smb/story/2022-05-12/1ab1e1cb-8c44-4538-a637-98959630a4bf.jpg"
            />
          )}
        </span>
      </div>
    );
  };
  useEffect(() => {
    const params = {formId: formId};
    bannerDao.queryContentByFormId(params).then((val) => {
      setIsLoaded(true);
      if (val['before'] && val['after'].id === val['before'].id) {
        _set(val['before'], 'attributes.ImageFile.data.attributes.url', '');
      }
      setStrapiBanner(val);
    });
  }, []);

  useEffect(() => {
    if (!strapiBanner['after']) return;
    const params = {
      vuid: strapiBanner['after'].attributes.vuid,
      type: 'BANNER',
    };
    bannerDao.isUsedBy(params).then((val) => {
      const tmp = val.map((s) => {
        return s;
      });
      setBlogPost(tmp);
    });
  }, [strapiBanner]);

  if (!strapiBanner) return <div />;

  return (
    <div>
      <div
        className="banner-preview--container"
        style={{flexDirection: window.outerWidth <= 600 ? 'column' : 'initial'}}
      >
        <div style={{flex: `0 0 calc( 50% - 1px)`}}>
          {banner(strapiBanner['before'], 'Before', isLoaded)}
        </div>
        <div className="banner-divider" />
        <div style={{flex: `0 0 calc( 50% - 1px)`}}>
          {banner(strapiBanner['after'], 'After', isLoaded)}
        </div>
      </div>
      <div className="blog-post--title">Articles Including The Same Banner</div>

      {blogPost &&
        blogPost.map((s, index) => (
          <div
            className="post-button"
            onClick={() => {
              setPostId(s.sourceId);
              setShow(true);
            }}
          >
            {s.title}
          </div>
        ))}

      <Modal show={show} size="fullScreen" style={{padding: '0 !important'}}>
        {postId && (
          <div style={{position: 'relative'}}>
            <img
              alt=""
              onClick={() => setShow(false)}
              style={{
                position: 'absolute',
                top: '10px',
                right: '30px',
                cursor: 'pointer',
                width: '30px',
              }}
              src={require('../../../assets/images/close-button-png-30230.png')}
            />
            <iframe
              id="iframeId"
              style={{width: '100%', height: '100vh'}}
              frameBorder="0"
              scrolling="auto"
              className="iframe-full-height"
              src={`${process.env.REACT_APP_FRONTEND_URL}/business/blog/post?postId=${postId}&formId=${formId}&vuid=${strapiBanner['after'].attributes.vuid}`}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}
