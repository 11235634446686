import React, {useEffect, useState} from 'react';
import {Formik, Field, FieldArray, Form} from 'formik';
import {Trans} from 'react-i18next';
import BlogHeadingSelect from '../../../../../../components/formik/BlogHeadingSelect';
import Select from 'react-select';
import globals from '../../../../../../utils/config/globals';
import Modal from 'react-modal';
import './DisplaySettingModal.css';
import dao from '../../../../../../dao/blogDao';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _find from 'lodash/find';
import _keys from 'lodash/keys';
import _forEach from 'lodash/forEach';
import _toLower from 'lodash/toLower';
import {toast} from 'react-toastify';
import blogUtils from '../../../../utils';
import blogDao from '../../../../../../dao/blogDao';
import classnames from 'classnames';
import BlogPostConfig from '../../../addoreditpost/BlogPostConfig';
import {getDisplaySettings} from './getDisplaySettings';

export default function DisplaySettingModal(props) {
  const blogApps = ['YMK', 'YCP', 'YCV', 'YCN', 'YCE', 'YCA'];
  const {isOpen, onClose, language} = props;
  const [languageType, setLanguageType] = useState(language);
  const [selectOptions, setSelectOptions] = useState([]);
  const [blogHeadings, setBlogHeadings] = useState(null);
  const [focus, setFocus] = useState(globals.blogFocus[0].value);
  const [apps, setApps] = useState(globals.blogApps[0].value);
  const consumerAppPopular = {
    YMK: {},
    YCP: {},
    YCV: {},
    YCN: {},
    YCE: {},
    YCA: {},
  };
  const preloadDisplay = async () => {
    getDisplaySettings(setSelectOptions);
    blogDao.post.queryPostHeadings().then((data) => {
      const initialValue = [null, null, null, null, null, null];
      const businessPopular = {};
      const businessTop = {};
      const consumerPopular = {};
      const consumerTop = {};

      BlogPostConfig.blogDisplayLangs.forEach((lang) => {
        businessPopular[lang] = _get(
          data,
          `businessPopular.${lang}`,
          initialValue
        );
        consumerPopular[lang] = _get(
          data,
          `consumerPopular.${lang}`,
          initialValue
        );
        businessTop[lang] = _get(data, `businessTop.${lang}`, initialValue);
        consumerTop[lang] = _get(data, `consumerTop.${lang}`, initialValue);
        blogApps.forEach((app) => {
          consumerAppPopular[app][lang] = _get(
            data,
            `consumerAppPopular.${app}.${lang}`,
            initialValue
          );
          for (let i = 0; i < 3; i++) {
            if (consumerAppPopular[app][lang][i] === undefined) {
              consumerAppPopular[app][lang][i] = 0;
            }
          }
        });
        for (let i = 0; i < 3; i++) {
          if (businessTop[lang][i] === undefined) {
            businessTop[lang][i] = 0;
          }
          if (consumerTop[lang][i] === undefined) {
            consumerTop[lang][i] = 0;
          }
        }
        for (let i = 0; i < 6; i++) {
          if (businessPopular[lang][i] === undefined) {
            businessPopular[lang][i] = 0;
          }
          if (consumerPopular[lang][i] === undefined) {
            consumerPopular[lang][i] = 0;
          }
        }
      });

      setBlogHeadings({
        businessPopular,
        businessTop,
        consumerPopular,
        consumerTop,
        consumerAppPopular,
      });
    });
  };

  const updateDisplay = async (data) => {
    const res = await dao.post.updatePostHeadings(data);
    _toLower(res.status) === 'success'
      ? toast.success('Update Successfully')
      : toast.error('Update Failed');
  };

  const getDuplicatedErrorCheck = (values) => {
    const ids = _get(values, `${focus}Popular[${languageType}]`);
    const ids2 = _get(values, `${focus}Top[${languageType}]`);
    if (!ids || !ids.length || !ids2 || !ids2.length) {
      return null;
    }
    const counted = {};
    const errors = {};
    ids.forEach((id, index) => {
      if (!id || id === blogUtils.display.getEmptyValue()) return;
      !counted[id]
        ? (counted[id] = true)
        : _set(
            errors,
            `${focus}Popular[${languageType}][${index}]`,
            'Duplicated!'
          );
    });
    if (_get(errors, `${focus}Popular[${languageType}]`)) return errors;
    const counted2 = {};
    ids2.forEach((id, index) => {
      if (!id || id === blogUtils.displayTop.getEmptyValue()) return;
      !counted2[id]
        ? (counted2[id] = true)
        : _set(errors, `${focus}Top[${languageType}][${index}]`, 'Duplicated!');
    });
    if (_get(errors, `${focus}Top[${languageType}]`)) return errors;
  };

  const postsToIds = (posts = {}) => {
    const ids = {};
    const func = blogUtils.display.getEmptyValue();
    _forEach(_keys(posts), (lang) => {
      ids[lang] = posts[lang].map((post) => _get(post, 'postHandle', func));
    });
    return ids;
  };

  const postsToTopIds = (posts = {}) => {
    const ids = {};
    const func = blogUtils.displayTop.getEmptyValue();
    _forEach(_keys(posts), (lang) => {
      ids[lang] = posts[lang].map((post) => _get(post, 'postHandle', func));
      if (ids[lang].length > 3) {
        ids[lang] = ids[lang].slice(0, 3);
      }
    });
    return ids;
  };

  const postsToAppIds = (posts = {}) => {
    const ids = consumerAppPopular;
    const func = blogUtils.displayTop.getEmptyValue();
    _forEach(_keys(posts), (app) => {
      BlogPostConfig.blogDisplayLangs.forEach((lang) => {
        ids[app][lang] = posts[app][lang].map((post) => {
          return _get(post, 'postHandle', func);
        });
        if (ids[app][lang].length > 3) {
          ids[app][lang] = ids[app][lang].slice(0, 3);
        }
      });
    });
    return ids;
  };

  useEffect(() => {
    !isOpen && preloadDisplay();
  }, [isOpen]);

  const formikConfig = {
    initialValues: {
      businessPopular: postsToIds(_get(blogHeadings, 'businessPopular')),
      businessTop: postsToTopIds(_get(blogHeadings, 'businessTop')),
      consumerPopular: postsToIds(_get(blogHeadings, 'consumerPopular')),
      consumerTop: postsToTopIds(_get(blogHeadings, 'consumerTop')),
      consumerAppPopular: postsToAppIds(
        _get(blogHeadings, 'consumerAppPopular')
      ),
    },
    enableReinitialize: true,
    validate: (values, props) => getDuplicatedErrorCheck(values, props),
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true);
      await updateDisplay(values);
      setSubmitting(false);
      onClose();
    },
  };

  const modalCustom = {
    overlay: {
      backgroundColor: 'rgba(9, 9, 9, 0.5)',
    },
    content: {
      margin: '80px auto',
    },
  };

  return (
    <Modal
      className="Modal__Bootstrap modal-dialog displayorder-viewform___modal"
      closeTimeoutMS={150}
      isOpen={isOpen}
      style={modalCustom}
      shouldCloseOnOverlayClick={true}
      size="lg"
    >
      <Formik {...formikConfig}>
        {({values}) => (
          <Form>
            <div className="modal-content modify_____modal">
              <div className="modal-header flex__block flex__item-align-center">
                <h4 className="modal-title font-blue-hoki flex__block flex__justify-space-between flex__item-align-center">
                  <Trans i18nKey="p.b.p.listposts.displaysetting.title">
                    Display Setting
                  </Trans>
                </h4>
                <a
                  className="fa fa-times"
                  style={{margin: '5px', cursor: 'pointer'}}
                  onClick={onClose}
                />
              </div>
              <div
                className="displayorder-viewform___modal-body"
                style={{maxHeight: '63vh'}}
              >
                <div className="row">
                  <div className="col-3">
                    <Select
                      options={globals.blogFocus}
                      onChange={(opt) => setFocus(opt.value)}
                      value={_find(globals.blogFocus, {
                        value: focus,
                      })}
                    />
                  </div>
                  <div className="col-3">
                    <Select
                      options={globals.blogLanguages}
                      onChange={(opt) => setLanguageType(opt.value)}
                      value={_find(globals.blogLanguages, {
                        value: languageType,
                      })}
                    />
                  </div>
                  <div
                    className={classnames({
                      'col-3': true,
                    })}
                    style={{
                      display: focus === 'consumer' ? 'block' : 'none',
                    }}
                  >
                    <Select
                      options={globals.blogApps}
                      onChange={(opt) => setApps(opt.value)}
                      value={_find(globals.blogApps, {
                        value: apps,
                      })}
                    />
                  </div>
                </div>
                <div className="my-3">
                  <h5 className="modal-title font-blue-hoki flex__block flex__justify-space-between flex__item-align-center">
                    <Trans i18nKey="p.b.p.listposts.displaysetting.top.post">
                      Top Post
                    </Trans>
                  </h5>
                  <div className="row my-2">
                    <div className="col-12">
                      <table className="table table-striped table-bordered table-hover dataTable">
                        <thead>
                          <tr>
                            <th style={{width: '3%'}}>
                              <Trans i18nKey="p.b.p.listposts.displaysetting.display.order">
                                Display Order
                              </Trans>
                            </th>
                            <th style={{width: '25%'}}>
                              <Trans i18nKey="p.b.p.listposts.displaysetting.post.title">
                                Title
                              </Trans>
                            </th>
                            <th style={{width: '6%'}}>
                              <Trans i18nKey="p.b.p.listposts.displaysetting.action">
                                Action
                              </Trans>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {_get(
                            values,
                            `${focus}Top.[${languageType}]`,
                            []
                          ).map((post, i) => (
                            <FieldArray
                              id={`${focus}Top[${languageType}]`}
                              name={`${focus}Top.${languageType}`}
                              validateOnChange
                              key={`${focus}.topIds[${languageType}]-${i}`}
                            >
                              {(arrayHelpers) => (
                                <tr>
                                  <td className="text-center">{i + 1}</td>
                                  <td>
                                    <Field
                                      key={_get(
                                        values,
                                        `${focus}Top[${languageType}][${i}]`
                                      )}
                                      name={`${focus}Top[${languageType}][${i}]`}
                                    >
                                      {(props) => (
                                        <BlogHeadingSelect
                                          options={
                                            selectOptions[focus][languageType]
                                          }
                                          value={_find(
                                            selectOptions[focus][languageType],
                                            {
                                              value: _get(props, `field.value`),
                                            }
                                          )}
                                          onChange={(opt) => {
                                            props.form.setFieldValue(
                                              props.field.name,
                                              opt
                                                ? opt.value
                                                : blogUtils.displayTop.getEmptyValue()
                                            );
                                          }}
                                          {...props}
                                        />
                                      )}
                                    </Field>
                                  </td>
                                  <td>
                                    {i > 0 && (
                                      <img
                                        alt="move up"
                                        className="mr-2"
                                        onClick={() =>
                                          arrayHelpers.swap(i - 1, i)
                                        }
                                        src={require('../../../../../../assets/images/up_arrow.png')}
                                      />
                                    )}
                                    {i <
                                      blogUtils.displayTop.getTopMaxCount() -
                                        1 && (
                                      <img
                                        alt="move down"
                                        onClick={() =>
                                          arrayHelpers.swap(i, i + 1)
                                        }
                                        src={require('../../../../../../assets/images/down_arrow.png')}
                                      />
                                    )}
                                  </td>
                                </tr>
                              )}
                            </FieldArray>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <h5 className="modal-title font-blue-hoki flex__block flex__justify-space-between flex__item-align-center">
                  <Trans i18nKey="p.b.p.listposts.displaysetting.popular.posts">
                    Popular Posts
                  </Trans>
                </h5>
                <div className="row my-2">
                  <div className="col-12">
                    <table className="table table-striped table-bordered table-hover dataTable">
                      <thead>
                        <tr>
                          <th style={{width: '3%'}}>
                            <Trans i18nKey="p.b.p.listposts.displaysetting.display.order">
                              Display Order
                            </Trans>
                          </th>
                          <th style={{width: '25%'}}>
                            <Trans i18nKey="p.b.p.listposts.displaysetting.post.title">
                              Title
                            </Trans>
                          </th>
                          <th style={{width: '6%'}}>
                            <Trans i18nKey="p.b.p.listposts.displaysetting.action">
                              Action
                            </Trans>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_get(
                          values,
                          `${focus}Popular.[${languageType}]`,
                          []
                        ).map((post, i) => (
                          <FieldArray
                            id={`${focus}Popular[${languageType}]`}
                            name={`${focus}Popular[${languageType}]`}
                            validateOnChange
                            key={`${focus}.popularIds[${languageType}]-${i}`}
                          >
                            {(arrayHelpers) => (
                              <tr>
                                <td className="text-center">{i + 1}</td>
                                <td>
                                  <Field
                                    key={_get(
                                      values,
                                      `${focus}Popular[${languageType}][${i}]`
                                    )}
                                    name={`${focus}Popular[${languageType}][${i}]`}
                                  >
                                    {(props) => (
                                      <BlogHeadingSelect
                                        options={
                                          selectOptions[focus][languageType]
                                        }
                                        value={_find(
                                          selectOptions[focus][languageType],
                                          {
                                            value: _get(props, `field.value`),
                                          }
                                        )}
                                        onChange={(opt) =>
                                          props.form.setFieldValue(
                                            props.field.name,
                                            opt
                                              ? opt.value
                                              : blogUtils.display.getEmptyValue()
                                          )
                                        }
                                        {...props}
                                      />
                                    )}
                                  </Field>
                                </td>
                                <td>
                                  {i > 0 && (
                                    <img
                                      alt="move up"
                                      className="mr-2"
                                      onClick={() =>
                                        arrayHelpers.swap(i - 1, i)
                                      }
                                      src={require('../../../../../../assets/images/up_arrow.png')}
                                    />
                                  )}
                                  {i <
                                    blogUtils.display.getPopularMaxCount() -
                                      1 && (
                                    <img
                                      alt="move down"
                                      onClick={() =>
                                        arrayHelpers.swap(i, i + 1)
                                      }
                                      src={require('../../../../../../assets/images/down_arrow.png')}
                                    />
                                  )}
                                </td>
                              </tr>
                            )}
                          </FieldArray>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {apps && focus === 'consumer' && (
                  <>
                    <h5 className="modal-title font-blue-hoki flex__block flex__justify-space-between flex__item-align-center">
                      <Trans i18nKey="p.b.p.listposts.displaysetting.consumer.app.posts">
                        Consumer App Popular Post
                      </Trans>
                    </h5>
                    <div className="row my-2">
                      <div className="col-12">
                        <table className="table table-striped table-bordered table-hover dataTable">
                          <thead>
                            <tr>
                              <th style={{width: '3%'}}>
                                <Trans i18nKey="p.b.p.listposts.displaysetting.display.order">
                                  Display Order
                                </Trans>
                              </th>
                              <th style={{width: '25%'}}>
                                <Trans i18nKey="p.b.p.listposts.displaysetting.post.title">
                                  Title
                                </Trans>
                              </th>
                              <th style={{width: '6%'}}>
                                <Trans i18nKey="p.b.p.listposts.displaysetting.action">
                                  Action
                                </Trans>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {_get(
                              values,
                              `consumerAppPopular[${apps}][${languageType}]`,
                              []
                            ).map((post, i) => (
                              <FieldArray
                                id={`consumerAppPopular[${apps}].[${languageType}]`}
                                name={`consumerAppPopular[${apps}].[${languageType}]`}
                                validateOnChange
                                key={`consumerAppPopular[${apps}].[${languageType}]-${i}`}
                              >
                                {(arrayHelpers) => (
                                  <tr>
                                    <td className="text-center">{i + 1}</td>
                                    <td>
                                      <Field
                                        key={_get(
                                          values,
                                          `consumerAppPopular[${apps}][${languageType}][${i}]`
                                        )}
                                        name={`consumerAppPopular[${apps}][${languageType}][${i}]`}
                                      >
                                        {(props) => (
                                          <BlogHeadingSelect
                                            options={
                                              selectOptions[focus][languageType]
                                            }
                                            value={_find(
                                              selectOptions[focus][
                                                languageType
                                              ],
                                              {
                                                value: _get(
                                                  props,
                                                  `field.value`
                                                ),
                                              }
                                            )}
                                            onChange={(opt) =>
                                              props.form.setFieldValue(
                                                props.field.name,
                                                opt
                                                  ? opt.value
                                                  : blogUtils.display.getEmptyValue()
                                              )
                                            }
                                            {...props}
                                          />
                                        )}
                                      </Field>
                                    </td>
                                    <td>
                                      {i > 0 && (
                                        <img
                                          alt="move up"
                                          className="mr-2"
                                          onClick={() =>
                                            arrayHelpers.swap(i - 1, i)
                                          }
                                          src={require('../../../../../../assets/images/up_arrow.png')}
                                        />
                                      )}
                                      {i <
                                        blogUtils.display.getPopularMaxCount() -
                                          1 && (
                                        <img
                                          alt="move down"
                                          onClick={() =>
                                            arrayHelpers.swap(i, i + 1)
                                          }
                                          src={require('../../../../../../assets/images/down_arrow.png')}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </FieldArray>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="modal-footer">
                <div className="display-btn">
                  <div className="text-center margin-top-10">
                    <button
                      type="button"
                      className="btn btn-outline-secondary kt-margin-r-10 kt-margin-b-10"
                      onClick={onClose}
                    >
                      <span>Close</span>
                    </button>
                  </div>
                  <div className="text-center margin-top-10">
                    <button
                      type="submit"
                      className="btn btn-primary kt-margin-r-10 kt-margin-b-10 kt-spinner kt-spinner-v2 kt-spinner--center kt-spinner--sm"
                    >
                      <span>Save</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
