import React from 'react';
import {connect} from 'react-redux';

export default React.memo(
  connect(
    state => ({admin: state.components.auth.admin}),
    null
  )(function User({admin}) {
    return (
      <div
        className="kt-header__topbar-item kt-header__topbar-item--user"
        style={{alignItems: 'center'}}
      >
        <div className="kt-header__topbar-wrapper">
          <span className="kt-header__topbar-username kt-visible-desktop">
            {admin && <>{admin.adm.adminName}</>}
          </span>
        </div>
      </div>
    );
  })
);
