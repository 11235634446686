import React from 'react';
import {Trans} from 'react-i18next';
import configUtils from '../../../../../../utils/configUtils';
import blogUtils from '../../../../utils';
import globals from '../../../../../../utils/config/globals';
import dayjs from 'dayjs';
import _join from 'lodash/join';
import _isNaN from 'lodash/isNaN';
import Status from '../Status';

const utils = {
  getHalfRows: (data) => [
    {
      left: {
        label: (
          <Trans i18nKey="p.b.p.listposts.detail.language">Language</Trans>
        ),
        content: configUtils.getLabel(data.languageType, globals.blogLanguages),
      },
      right: {
        label: <Trans i18nKey="p.b.p.listposts.detail.status">Status</Trans>,
        content: <Status postStatus={data.postStatus} />,
      },
    },
    {
      left: {
        label: (
          <Trans i18nKey="p.b.p.listposts.detail.post.date">Post Date</Trans>
        ),
        content: dayjs(data.postDate).format('YYYY-MM-DD'),
      },
      right: {
        label: (
          <Trans i18nKey="p.b.p.listposts.detail.keywords">Keywords</Trans>
        ),
        content: blogUtils.keyword.joinKeywords(data.postKeywords, ', '),
      },
    },
    {
      left: {
        label: <Trans i18nKey="p.b.p.listposts.detail.author">Authors</Trans>,
        content: _join(
          blogUtils.author.getDisplayNames(data.postAuthors),
          ', '
        ),
      },
      right: {
        label: (
          <Trans i18nKey="p.b.p.listposts.detail.minutes.to.read">
            Minutes To Read
          </Trans>
        ),
        content: !_isNaN(data.minutesToRead)
          ? `${data.minutesToRead} minutes`
          : '',
      },
    },
    {
      left: {
        label: (
          <Trans i18nKey="p.b.p.listposts.detail.last.seven.days.view">
            Last 7 Days View
          </Trans>
        ),
        content: data.last7DaysViewCount,
      },
      right: {
        label: (
          <Trans i18nKey="p.b.p.listposts.detail.last.thirty.days.view">
            Last 30 Days View
          </Trans>
        ),
        content: data.last30DaysViewCount,
      },
    },
    {
      left: {
        label: (
          <Trans i18nKey="p.b.p.listposts.detail.creation">Creation</Trans>
        ),
        content: dayjs(data.createdTime).format('YYYY-MM-DD HH:MM'),
      },
      right: {
        label: (
          <Trans i18nKey="p.b.p.listposts.detail.modification">
            Modification
          </Trans>
        ),
        content: dayjs(data.lastModified).format('YYYY-MM-DD HH:MM'),
      },
    },
  ],
  getRows: (data) => [
    {
      label: <Trans i18nKey="p.b.p.listposts.detail.title">Title</Trans>,
      content: data.title,
    },
    {
      label: <Trans i18nKey="p.b.p.listposts.detail.post.url">Post URL</Trans>,
      content: data.postUrl,
    },
    {
      label: (
        <Trans i18nKey="p.b.p.listposts.detail.short.desc">
          Short Description
        </Trans>
      ),
      content: data.shortDesc,
    },
    {
      label: (
        <Trans i18nKey="p.b.p.listposts.detail.meta.desc">
          Meta Description
        </Trans>
      ),
      content: data.metaDesc,
    },
    {
      label: <Trans i18nKey="p.b.p.listposts.detail.image">Image</Trans>,
      content: <img alt="cover" src={data.imageUrl} style={{width: '90%'}} />,
    },
    {
      label: <Trans i18nKey="p.b.p.listposts.detail.content">Content</Trans>,
      content: (
        <a
          href={`${process.env.REACT_APP_FRONTEND_URL}/business/blog/post?postId=${data.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-sm btn-grey nowrap"
        >
          <i className="fa fa-eye" />{' '}
          <Trans i18nKey="p.b.p.listposts.detail.preview">Preview</Trans>
        </a>
      ),
    },
  ],
};

export default utils;
