const SWITCH_MENU = 'components/layout/sidebar/SWITCH_MENU';
const OPEN_MENU = 'components/layout/sidebar/OPEN_MENU';
const CLOSE_MENU = 'components/layout/sidebar/CLOSE_MENU';

const initialState = {
  isOpened: false,
  asideMenuOn: false,
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SWITCH_MENU:
      return {...state, isOpened: !state.isOpened};
    case OPEN_MENU:
      return {...state, asideMenuOn: true};
    case CLOSE_MENU:
      return {...state, asideMenuOn: false};
    default:
      return state;
  }
};

export function switchMenu() {
  return {
    type: SWITCH_MENU,
  };
}

export function openMenu() {
  return {
    type: OPEN_MENU,
  };
}

export function closeMenu() {
  return {
    type: CLOSE_MENU,
  };
}
