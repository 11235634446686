import React from 'react';
import {Trans} from 'react-i18next';
import CustomerSettings from './index';
import WhitelistSettings from './whitelistsettings/index';

export default [
  {
    path: '/customer-settings',
    element: <CustomerSettings />,
    handle: {
      title: (
        <Trans i18nKey="p.c.customer.settings.list.title">
          Customer Settings
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.c.customer.settings.list.title">
          Customer Settings
        </Trans>
      ),
      breadcrumbs: [
        [
          {
            title: (
              <Trans i18nKey="p.c.customer.settings.title">
                Customer Settings
              </Trans>
            ),
            path: '/sku-addon',
          },
        ],
      ],
    },
  },
  {
    path: '/customer-settings/whitelist-settings',
    element: <WhitelistSettings />,
    handle: {
      title: (
        <Trans i18nKey="p.c.whitelist.settings.list.title">
          Whitelist Settings
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.c.whitelist.settings.list.title">
          Whitelist Settings
        </Trans>
      ),
      breadcrumbs: [
        [
          {
            title: (
              <Trans i18nKey="p.c.customer.settings.title">
                Customer Settings
              </Trans>
            ),
            path: '/sku-addon',
          },
        ],
      ],
    },
  },
];
