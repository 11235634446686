import React from 'react';

export default function ModalContentRow({
  label,
  content,
  labelClassName = 'col-3',
  contentClassName = 'col-9',
}) {
  return (
    <div className="row mb-3">
      <div className="col-12">
        <div className="row">
          <label
            className={`${labelClassName} text-right`}
            style={{fontWeight: 500}}
          >
            {label}:
          </label>
          <div className={contentClassName}>{content}</div>
        </div>
      </div>
    </div>
  );
}
