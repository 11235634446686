import React from 'react';
import ImageEditor from '../imageeditor/ImageEditor';

export default function ImageField({
  form,
  field,
  imageUrl = undefined,
  imageFile = undefined,
  scale,
  position,
  scaleImage = false,
  imageWidth = 400,
  imageHeight = 300,
  displayWidth,
  displayHeight,
  dataUpdateCallback,
  dataClearCallback,
  imageFormat,
  displayText,
  whiteBackground = true,
  freeSize = false,
  widthText,
  heightText,
  imagePlaceholder,
}) {
  const handleImageLoaded = (file) => {
    form.setFieldValue(field.name, file);
  };

  const handleDataUrlUpdate = (dataURL) => {
    form.setFieldValue(field.name, dataURL);
    dataUpdateCallback &&
      dataUpdateCallback(
        field.name,
        'dataUrl',
        () => ({
          width: imageWidth,
          height: imageHeight,
          file: dataURL,
        }),
        (url) => {
          form.setFieldValue(field.name, url);
          dataClearCallback && dataClearCallback(field.name);
        }
      );
  };

  const handleImageClear = () => {
    form.setFieldValue(field.name, '');
    dataClearCallback && dataClearCallback(field.name);
  };

  return (
    <>
      <ImageEditor
        imageUrl={imageUrl}
        imageFile={imageFile}
        enableScale={scaleImage}
        defaultScale={scale}
        defaultPosition={position}
        onImageLoaded={handleImageLoaded}
        onDataUrlUpdate={handleDataUrlUpdate}
        onImageClear={handleImageClear}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        displayWidth={displayWidth}
        displayHeight={displayHeight}
        imageFormat={imageFormat}
        displayText={displayText}
        whiteBackground={whiteBackground}
        freeSize={freeSize}
        widthText={widthText}
        heightText={heightText}
        imagePlaceholder={imagePlaceholder}
      />
      {form.errors && form.errors[field.name] && (
        <div className="image-invalid-feedback">{form.errors[field.name]}</div>
      )}
    </>
  );
}
