import {get, listAll} from './utils';

const projectDao = {
  listProject: (params) =>
    get('/smb-admin/strapi/list-projects.action', params),
  // listAllProject: () => listAll(projectDao.listProject),
  queryProject: (projectId) =>
    get('/smb-admin/strapi/query-project.action', projectId),
  deleteProject: (projectId) =>
    get('/smb-admin/strapi/delete-project.action', projectId),
  submitProject: (projectId) =>
    get('/smb-admin/strapi/submit-project.action', projectId),
  removeProject: (projectId) =>
    get('/smb-admin/strapi/remove-from-project.action', projectId),
  getPageUrl: (projectId) =>
    get('/smb-admin/strapi/get-page-url-mappings.action', projectId),
  cancelProject: (projectId) =>
      get('/smb-admin/strapi/cancel-project.action', projectId),
};

export default projectDao;
