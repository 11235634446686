import React, {useCallback, useState} from 'react';
import {Trans} from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import VideoPlayer from '../../videoplayer';
import _isEmpty from 'lodash/isEmpty';
import './FeaturedHeader.css';

export default function FeaturedHeader({
  brandImg,
  title,
  content,
  kpi,
  ceo,
  brandName,
  cover,
  readTheStory = false,
  backgroundColor = '#f23b77',
  fontColor = 'white',
  coverText = '',
  videoUrl,
}) {
  const [show, setShow] = useState(false);
  const hideModel = useCallback(() => setShow(false), [setShow]);
  return (
    <>
      <div className="pf-success-story-title-block">
        <div className="pf-success-story-title">
          <div className="pf-success-story-feature">Featured Customer</div>
          <div className="pf-success-story-title-image">
            <img alt="" style={{width: '100%'}} src={brandImg} />
          </div>
        </div>
        <div className="pf-success-story-title-lg">{title}</div>
        <div className="pf-success-story-content">
          {!_isEmpty(kpi) ? kpi : content}
        </div>
        <div className="pf-success-story-brand">
          <div style={{fontWeight: 'bold'}}>{ceo}</div>
          {brandName}
        </div>
        {readTheStory && (
          <div className="pf-sotry-read-the-story-btn">
            <Trans i18nKey="business:p.b.home.story.read">READ STORY</Trans>
          </div>
        )}
      </div>
      <div
        className="pf-success-story-title-cover"
        style={{cursor: videoUrl && 'pointer'}}
        onClick={() => {
          if (videoUrl) setShow(true);
        }}
      >
        <div style={{position: 'relative'}}>
          <img alt="" style={{width: '100%'}} src={cover} />
          {videoUrl && (
            <div
              className="pf-story-cover-button"
              style={{backgroundColor: backgroundColor, color: fontColor}}
            >
              <div className="pf-story-cover-button-padding">
                <img
                  style={{width: '46px'}}
                  alt=""
                  src={require('../../../assets/images/btn_story_play.png')}
                />
              </div>
              <div className="pf-story-cover-text-margin">{coverText}</div>
            </div>
          )}
        </div>
      </div>
      <Modal size="lg" show={show} onHide={hideModel}>
        <Modal.Body>
          <VideoPlayer
            videoUrl={videoUrl}
            autoPlay={show}
            onCloseFullscreen={hideModel}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
