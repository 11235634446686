import {get, listAll, post} from './utils';
import _isArray from 'lodash/isArray';
import qs from 'query-string';

const newsDao = {
  _fromObject: v => ({
    id: v.id,
    categoryType: v.categoryType,
    languageType: v.languageType,
    newsNo: v.newsNo,
    newsDate: v.newsDate,
    newsTitle: v.newsTitle,
    newsSubTitle: v.newsSubtitle,
    newsContent: v.newsContent,
    createdTime: v.createdTime,
    lastModified: v.lastModified,
    isPublished: v.isPublished,
  }),
  _fromPageResult: v => ({
    totalSize: v.totalSize,
    results:
      v.results && !!v.results && v.results.map(vv => newsDao._fromObject(vv)),
  }),
  fromDb: v =>
    _isArray(v && v.results)
      ? newsDao._fromPageResult(v)
      : _isArray(v)
      ? v.map(vv => newsDao._fromObject(vv))
      : newsDao._fromObject(v),

  buildQs: (params = {}) => '?' + qs.stringify(params),
  queryNews: newsId => get('/smb-admin/news/query-news.action', newsId),
  listAllNews: params => listAll(newsDao.listNews, params),
  listNews: params => get('/smb-admin/news/list-news.action', params),
  createNews: news => post('/smb-admin/news/create-news.action', news),
  updateNews: news => post('/smb-admin/news/update-news.action', news),
  deleteNews: newsId => get('/smb-admin/news/delete-news.action', {newsId}),
  publishNews: newsId => get('/smb-admin/news/publish-news.action', {newsId}),
  takeDownNews: newsId =>
    get('/smb-admin/news/take-down-news.action', {newsId}),
  queryNewsHeading: () => get('/smb-admin/news/query-news-headings.action'),
  submitNews: params => post('/smb-admin/news/submit-news.action', params),
  cancelSubmit: newsId => get('/smb-admin/news/cancel-news.action', {newsId}),
  searchNewsNo: params => get('/smb-admin/news/search-news-no.action', params),
  searchNews: () => get('/smb-admin/news/search-news-no.action'),
  updateNewsHeadings: params =>
    post('/smb-admin/news/update-news-headings.action', params),
  reSubmit: params =>
    post('/smb-admin/news/continue-to-review-news.action', params),
  checkTakenDown: params =>
    get('/smb-admin/news/check-take-down.action', params),
};

export default newsDao;
