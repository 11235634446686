import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field} from 'formik';
import './selectBox.css';
import classnames from 'classnames';
import _get from 'lodash/get';
import CreatableSelect from 'react-select/creatable';

export default function CustomOptionReactSelect({
  options,
  field: {name},
  form: {touched, errors, setFieldValue},
}) {
  const [opt, setOpt] = useState([]);
  useEffect(() => {
    setOpt(options);
  }, []);
  const error = _get(touched, name) && _get(errors, name);

  return (
    <>
      <Field name={name}>
        {({field}) => (
          <span>
            <CreatableSelect
              className={classnames({
                'custom-react-select--invalid': error,
              })}
              name={field.name}
              options={opt}
              isClearable={true}
              value={options.find((option) => option.value === field.value)}
              onChange={(option, ActionMeta) => {
                if (ActionMeta.action === 'clear') {
                  setFieldValue(field.name, '');
                  return;
                }
                if (option && option.value)
                  setFieldValue(field.name, option.value);
              }}
              placeholder={false}
            />
          </span>
        )}
      </Field>
      <label>
        {error && (
          <div
            className={classnames({
              'invalid-feedback': true,
              'd-block': error,
            })}
          >
            <ErrorMessage name={name} />
          </div>
        )}
      </label>
    </>
  );
}
