import React from 'react';
import {Field} from 'formik';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _indexOf from 'lodash/indexOf';

export default function CheckboxPostAttrList({
  labelValueOptions,
  containerClassname,
  arrayHelpers: {name, push, remove, form},
  disabled,
  childStyle = {},
  callback = () => {},
  setFieldValue,
  featuredTopic,
  ...props
}) {
  function removeFocus(num1, num2) {
    if (num1 > num2) {
      remove(num1);
      remove(num2);
      return;
    }
    remove(num2);
    remove(num1);
  }
  const checkChanged = (e, optValue) => {
    const index = _indexOf(_get(form.values, name), optValue);
    const isExist = index !== -1 && index !== undefined;
    const deleteCF = _indexOf(_get(form.values, name), 'CF_CONSUMER_FOCUS');
    const deleteBF = _indexOf(_get(form.values, name), 'BF_BUSINESS_FOCUS');
    const deleteYce = _indexOf(_get(form.values, name), 'YUC_YCE_USECASE');
    const isFeatureTopicYce =
      featuredTopic.indexOf('YC_') !== -1 ||
      featuredTopic.indexOf('YUC_') !== -1;
    if (e.target.checked === true && optValue === 'BF_BUSINESS_FOCUS') {
      removeFocus(deleteCF, deleteYce);
      if (isFeatureTopicYce) setFieldValue('post.featuredTopic', '');
    }

    if (e.target.checked === true && optValue === 'CF_CONSUMER_FOCUS') {
      removeFocus(deleteBF, deleteYce);
      if (isFeatureTopicYce) setFieldValue('post.featuredTopic', '');
    }

    if (e.target.checked === true && optValue === 'YUC_YCE_USECASE') {
      removeFocus(deleteBF, deleteCF);
      if (!isFeatureTopicYce) setFieldValue('post.featuredTopic', '');
    }

    if (e.target.checked === true) {
      if (!isExist) push(optValue);
    }
    if (e.target.checked === false) {
      if (isExist) remove(index);
    }
    callback(optValue);
  };

  const getPostAttrGroups = () => {
    const focusGroup = [];
    const bsGroup = [];
    const btGroup = [];
    const caGroup = [];
    const ctGroup = [];
    const ycGroup = [];
    labelValueOptions &&
      labelValueOptions.forEach((lv) => {
        if (lv.value.endsWith('_FOCUS') || lv.value.endsWith('_USECASE')) {
          focusGroup.push(lv);
        }
        if (lv.value.startsWith('BS_')) {
          bsGroup.push(lv);
        }
        if (lv.value.startsWith('BT_')) {
          btGroup.push(lv);
        }
        if (lv.value.startsWith('CA_')) {
          caGroup.push(lv);
        }
        if (lv.value.startsWith('CT_')) {
          ctGroup.push(lv);
        }
        if (lv.value.startsWith('YC_')) {
          ycGroup.push(lv);
        }
      });

    return {
      focus: focusGroup,
      bs: bsGroup,
      bt: btGroup,
      ca: caGroup,
      ct: ctGroup,
      yc: ycGroup,
    };
  };

  const getAttrComponent = (groupName, opts) => {
    return (
      <div style={{padding: '0 0 10px 0'}}>
        <div style={{padding: '0 0 5px 0'}}>{groupName}:</div>
        {opts &&
          opts.map((o) => (
            <Field key={`checkbox${o.id}_${o.value}`} name={`${name}[${o.id}]`}>
              {(props) => (
                <label
                  className="kt-checkbox"
                  htmlFor={`checkbox${o.id}_${o.value}`}
                  style={{marginRight: '15px'}}
                  key={`checkbox_${o.id}_${o.value}`}
                >
                  <input
                    {...props}
                    id={`checkbox${o.id}_${o.value}`}
                    type="checkbox"
                    checked={_includes(_get(form.values, name), o.value)}
                    onChange={(e) => checkChanged(e, o.value)}
                    disabled={disabled || o.disabled}
                  />
                  {o.label}
                  <span />
                </label>
              )}
            </Field>
          ))}
      </div>
    );
  };

  return (
    <div
      className={
        containerClassname
          ? containerClassname
          : 'components-field-error-container'
      }
    >
      <div className="flex__item-start">
        {getAttrComponent('Article Type', getPostAttrGroups().focus)}
        {getAttrComponent('Business Services', getPostAttrGroups().bs)}
        {getAttrComponent('Business Topics', getPostAttrGroups().bt)}
        {getAttrComponent('Consumer Products', getPostAttrGroups().ca)}
        {getAttrComponent('Consumer Topics', getPostAttrGroups().ct)}
        {getAttrComponent('YCE Use Case Category', getPostAttrGroups().yc)}
      </div>
    </div>
  );
}
