import React from 'react';
import {Trans} from 'react-i18next';
import {Navigate} from 'react-router-dom';
import ListPosts from './index';

const parent = {
  title: <Trans i18nKey="p.s.upload.title">Upload</Trans>,
  path: '/upload',
};
export default [
  {
    path: '/upload/pdf',
    element: <ListPosts />,
    handle: {
      title: <Trans i18nKey="p.s.upload.title">Upload</Trans>,
      subtitle: <Trans i18nKey="p.s.upload.title">Upload</Trans>,
      breadcrumbs: [parent],
    },
  },
  {
    path: '/upload',
    element: <Navigate to="/upload/pdf" />,
  },
];
