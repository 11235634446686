import React from 'react';
import Ba1On1 from './async';
import {Trans} from 'react-i18next';

export default [
  {
    path: '/ba-1on1',
    element: <Ba1On1 />,
    handle: {
      title: <Trans i18nKey="p.h.ba1on1.title">BA 1 on 1</Trans>,
      subtitle: <Trans i18nKey="p.h.ba1on1.title">BA 1 on 1</Trans>,
      breadcrumbs: [],
    },
  },
];
