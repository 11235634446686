import React from 'react';
import {connect} from 'formik';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import classnames from 'classnames';
import {toast} from 'react-toastify';

export default connect(function SimpleButtonGroup({
  formik: {isSubmitting},
  submitText,
  cancelText,
  cancelLink = '/',
  onCancelClick,
  isUpdating = false,
  isResetButton = false,
  disabled = false,
  img = undefined,
  pageName = undefined,
  content = undefined,
  newsType = 'NEWS',
  lang,
}) {
  return (
    <>
      {isResetButton ? (
        <button
          type="reset"
          className="btn btn-outline-secondary kt-margin-r-10 kt-margin-b-10"
          disabled={isSubmitting}
        >
          {cancelText || <Trans i18nKey="c.f.formik.discard">Discard</Trans>}
        </button>
      ) : onCancelClick ? (
        <button
          type="button"
          className="btn btn-outline-secondary kt-margin-r-10 kt-margin-b-10"
          disabled={isSubmitting}
          onClick={onCancelClick}
        >
          {cancelText || <Trans i18nKey="c.f.formik.discard">Discard</Trans>}
        </button>
      ) : (
        <Link
          to={cancelLink}
          className="btn btn-outline-secondary kt-margin-r-10 kt-margin-b-10"
          disabled={isSubmitting}
        >
          {cancelText || <Trans i18nKey="c.f.formik.discard">Discard</Trans>}
        </Link>
      )}
      <button
        type="submit"
        className={classnames({
          btn: true,
          'btn-primary': true,
          'kt-margin-r-10': true,
          'kt-margin-b-10': true,
          'kt-spinner': true,
          'kt-spinner-v2': true,
          'kt-spinner--center': true,
          'kt-spinner--sm': true,
          'kt-spinner--warning': isSubmitting,
        })}
        disabled={isSubmitting || disabled}
        onClick={() => {
          if (pageName === 'newsAddEdit') {
            if (lang && lang.length >= 9) {
              toast.warn('Please select one language.');
            }
            if (!img) {
              toast.warn('Please add one image.');
            }
            if (newsType === 'NEWS' && !content) {
              toast.warn('Please add content.');
            }
          }
        }}
      >
        <div style={{visibility: isSubmitting ? 'hidden' : 'visible'}}>
          {submitText ||
            (isUpdating ? (
              <Trans i18nKey="c.f.formik.save">Save</Trans>
            ) : (
              <Trans i18nKey="c.f.formik.add">Add</Trans>
            ))}
        </div>
      </button>
    </>
  );
});
