import {useState, useEffect} from 'react';
import {toast} from 'react-toastify';

const DataStatus = Object.freeze({
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
});

export const useQuery = ({
  queryFunc,
  initData = null,
  initQueryKeys = {},
  isInitEnabled = true,
}) => {
  const [data, setData] = useState(initData);
  const [queryKeys, setQueryKeys] = useState(initQueryKeys);
  const [isEnabled, setIsEnabled] = useState(isInitEnabled);
  const [status, setStatus] = useState(DataStatus.LOADING);
  const [refetchNum, setRefetchNum] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setStatus(DataStatus.LOADING);
        const result = await queryFunc(queryKeys);
        await setStatus(DataStatus.SUCCESS);
        await setData(result);
      } catch (e) {
        setStatus(DataStatus.ERROR);
        toast.error('Failed');
      }
    };
    if (isEnabled) {
      fetchData();
    }
  }, [refetchNum, isEnabled]);

  const refetch = (newQueryKeys) => {
    if (newQueryKeys) {
      setQueryKeys(newQueryKeys);
    }
    setIsEnabled(true);
    setRefetchNum((prevNum) => prevNum + 1);
  };

  const isLoading = status === DataStatus.LOADING;
  const isSuccess = status === DataStatus.SUCCESS;
  const isError = status === DataStatus.ERROR;

  return {data, status, isLoading, isSuccess, isError, refetch};
};
