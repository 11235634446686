import {useState} from 'react';
import Parser from 'react-html-parser';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import blogDao from '../../dao/blogDao';
import _indexOf from 'lodash/indexOf';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';

const useDeadLinks = (postId) => {
  const [isLoading, setIsLoading] = useState(false);
  const adminId = useSelector((s) => s.components.auth.admin.adm.id);

  const parseResult = async (content, otherLinks = [], action = 'CHECK') => {
    const linkData = {
      hashtag: [],
      id: [],
      link: [],
    };
    const result = {
      dupIds: [],
      noAnchorLinks: [],
      brokenLinks: [],
      otherLinks: [],
    };
    setIsLoading(true);
    Parser(content, {
      transform: (node) => {
        const id = _get(node, 'attribs.id');
        if (id) {
          linkData.id = [...linkData.id, id];
        }
        if (node.name === 'a') {
          const link = _get(node, 'attribs.href');
          if (!link) return;
          if (link.startsWith('#')) {
            linkData.hashtag = [...linkData.hashtag, link];
            return;
          }
          linkData.link = [...linkData.link, link];
        }
      },
    });

    result.dupIds = getDupId(linkData.id);
    result.noAnchorLinks = getNoAnchorLink(linkData['hashtag'], linkData.id);

    try {
      const bl = await blogDao.checkDeadLink({links: linkData.link});
      if (_get(bl, 'errors') && !_isEmpty(_get(bl, 'errors'))) {
        result.brokenLinks = _get(bl, 'errors');
      }

      if (otherLinks && otherLinks.length > 0) {
        const bl = await blogDao.checkDeadLink({links: otherLinks});
        if (_get(bl, 'errors') && !_isEmpty(_get(bl, 'errors'))) {
          result.otherLinks = _get(bl, 'errors');
        }
      }

      // logDeadLinks(result, action);
    } catch (error) {
      toast.warn('Error checking dead links:', error);
    }
    setIsLoading(false);
    return result;
  };

  const hasDeadLinks = async (deadLinkResult) => {
    try {
      for (const [key, value] of Object.entries(deadLinkResult)) {
        if (key === 'status' && value === 'success') {
          return false;
        }
        if (value && value.length !== 0) {
          console.log(deadLinkResult);
          return true;
        }
      }

      return false;
    } catch (error) {
      console.log('Error while checking for dead links:', error);
      return true;
    }
  };

  const logDeadLinks = (result, action, postId) => {
    if (
      result.dupIds.length !== 0 ||
      result.noAnchorLinks.length !== 0 ||
      result.brokenLinks.length !== 0 ||
      result.otherLinks.length !== 0
    ) {
      blogDao
        .logDeadLinks({
          postId,
          adminId,
          deadLinkFrom: action,
          source: JSON.stringify(result),
          numOfDupIds: result.dupIds.length,
          numOfNoAnchorLinks: result.noAnchorLinks.length,
          numOfBrokenLinks:
            result.brokenLinks.length + result.otherLinks.length,
        })
        .then((msg) => console.log(msg));
    }
  };

  const getCheckLinks = (data, properties = []) => {
    const arrLinks = [];
    if (!properties || properties.length === 0) {
      return arrLinks;
    }

    properties.forEach((property) => {
      const link = _get(data, property, '');
      if (link) {
        arrLinks.push(link);
      }
    });
    return arrLinks;
  };

  const getDupId = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) !== index);
  };

  const getNoAnchorLink = (arr, ids) => {
    return arr.filter((s) => _indexOf(ids, s.split('#')[1]) === -1);
  };

  return {
    isLoading,
    parseResult,
    hasDeadLinks,
    getCheckLinks,
    logDeadLinks,
  };
};

export default useDeadLinks;
