import React from 'react';
import AsyncListAwards from './listawards/async';
import AsyncAddOrEditAwards from './addoreditawards/async';
import {Navigate} from 'react-router-dom';

const parent = {
  title: 'Awards',
  path: '/awards',
};
export default [
  {
    path: '/awards/list-awards',
    element: <AsyncListAwards />,
    handle: {
      title: 'Awards',
      subtitle: 'Awards',
      breadcrumbs: [parent],
    },
  },
  {
    path: '/awards/add-awards',
    element: <AsyncAddOrEditAwards />,
    handle: {
      title: 'Add Awards',
      subtitle: 'Add Awards',
      breadcrumbs: [parent],
    },
  },
  {
    path: '/awards/edit-awards',
    element: <AsyncAddOrEditAwards />,
    handle: {
      title: 'Edit Awards',
      subtitle: 'Edit Awards',
      breadcrumbs: [parent],
    },
  },
  {
    path: '/awards',
    element: <Navigate to="/awards/list-awards" />,
  },
];
