import React from 'react';
import {Trans} from 'react-i18next';
import AsyncListStories from './liststories/async';
import AsyncAddOrEditStory from './addoreditstory/async';
import {Navigate} from 'react-router-dom';

const parent = {
  title: <Trans i18nKey="p.s.stories.liststories.title">Stories</Trans>,
  path: '/stories',
};
export default [
  {
    path: '/stories/list-stories',
    element: <AsyncListStories />,
    handle: {
      title: <Trans i18nKey="p.s.stories.liststories.title">Stories</Trans>,
      subtitle: <Trans i18nKey="p.s.stories.liststories.title">Stories</Trans>,
      breadcrumbs: [parent],
    },
  },
  {
    path: '/stories/add-story',
    element: <AsyncAddOrEditStory />,
    handle: {
      title: (
        <Trans i18nKey="p.s.stories.addoreditstory.add.title">Add Story</Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.s.stories.addoreditstory.add.title">Add Story</Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/stories/edit-story',
    element: <AsyncAddOrEditStory />,
    handle: {
      title: (
        <Trans i18nKey="p.s.stories.addoreditstory.edit.title">
          Edit Story
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.s.stories.addoreditstory.edit.title">
          Edit Story
        </Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/stories',
    element: <Navigate to="/stories/list-stories" />,
  },
];
