import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import pages from '../../pages/reducers';
import components from '../../components/reducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    pages,
    components,
  });
