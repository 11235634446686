import React, {useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import {Trans} from 'react-i18next';
import InputBox from '../../../../components/formik/InputBox';
import yup from '../../../../utils/yup';
import dao from '../../../../dao/blogDao';
import {toast} from 'react-toastify';
import {useNavigate, useLocation} from 'react-router';
import ImageField from '../../../../components/formik/ImageField';
import FormRow from '../../components/FormRow';
import SelectBox from '../../../../components/formik/SelectBox';
import configs from '../../../../utils/config/globals';

function AddOrEditAuthor() {
  const navigate = useNavigate();
  const {search} = useLocation();
  const [isEdit, setIsEdit] = useState(!!search);
  const [initValue, setInitValue] = useState({});
  const getInitValue = (d = {}) => {
    const value = {
      author: {
        displayName: d.displayName || '',
        authorType: d.authorType || 'AUTHOR',
        position: d.position || '',
        companyName: d.companyName || '',
        shortDesc: d.shortDesc || '',
        linkedinUrl: d.linkedinUrl || '',
        emailUrl: d.emailUrl || '',
        facebookUrl: d.facebookUrl || '',
        instagramUrl: d.instagramUrl || '',
        youtubeUrl: d.youtubeUrl || '',
        twitterUrl: d.twitterUrl || '',
      },
      image: d.imageUrl || '',
    };
    if (isEdit) {
      value.author.id = d.id;
    }
    return value;
  };
  const formikConfig = {
    validationSchema: yup.object().shape({
      author: yup.object().shape({
        displayName: yup.string().required(),
        authorType: yup.string().required(),
        position: yup.string(),
        companyName: yup.string(),
        shortDesc: yup.string(),
        linkedinUrl: yup.string().url(),
        emailUrl: yup.string().url(),
        facebookUrl: yup.string().url(),
        instagramUrl: yup.string().url(),
        youtubeUrl: yup.string().url(),
        twitterUrl: yup.string().url(),
      }),
      image: yup.string(),
    }),
    enableReinitialize: true,
    initialValues: getInitValue(initValue),
    onSubmit: async (values, {setSubmitting}) => {
      const res = isEdit
        ? await dao.author.update(values)
        : await dao.author.create(values);
      if (res.id) {
        toast.success(isEdit ? 'Update Successful' : 'Create Successful');
        navigate('/blog/author/list-authors');
      }
    },
  };

  useEffect(() => {
    if (!!search) {
      const param = new URLSearchParams(search);
      const id = param.get('id');
      if (id) {
        setIsEdit(true);
        dao.author.query(id).then((res) => {
          setInitValue(res);
        });
      }
    }
  }, []);

  return (
    <div>
      <Formik {...formikConfig}>
        {(props) => (
          <Form>
            <FormRow
              label={<Trans i18nKey="p.b.w.addoredit.name">Display Name</Trans>}
              name="author.displayName"
              component={InputBox}
              isRequired={true}
            />
            <FormRow
              label={<Trans i18nKey="p.b.w.addoredit.author.type">Type</Trans>}
              name="author.authorType"
              component={(props) => (
                <SelectBox {...props} options={configs.authorType} />
              )}
              isRequired={true}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.w.addoredit.short.desc">
                  Short Description
                </Trans>
              }
              name="author.shortDesc"
              component={InputBox}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.w.addoredit.companyName">
                  Company Name
                </Trans>
              }
              name="author.companyName"
              component={InputBox}
            />
            <FormRow
              label={<Trans i18nKey="p.b.w.addoredit.position">Position</Trans>}
              name="author.position"
              component={InputBox}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.w.addoredit.email.link">Email URL</Trans>
              }
              name="author.emailUrl"
              component={InputBox}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.w.addoredit.linkedin.link">
                  LinkedIn URL
                </Trans>
              }
              name="author.linkedinUrl"
              component={InputBox}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.w.addoredit.facebook.link">
                  Facebook URL
                </Trans>
              }
              name="author.facebookUrl"
              component={InputBox}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.w.addoredit.instagram.link">
                  Instagram URL
                </Trans>
              }
              name="author.instagramUrl"
              component={InputBox}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.w.addoredit.youtube.link">
                  Youtube URL
                </Trans>
              }
              name="author.youtubeUrl"
              component={InputBox}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.w.addoredit.twitter.link">
                  Twitter URL
                </Trans>
              }
              name="author.twitterUrl"
              component={InputBox}
            />
            <FormRow
              label={<Trans i18nKey="p.b.w.addoredit.image">Image</Trans>}
              name="image"
              isRequired={true}
              component={(props) => (
                <ImageField
                  {...props}
                  imageWidth={300}
                  imageHeight={300}
                  displayWidth={300}
                  displayHeight={300}
                  imageUrl={props.field.value}
                  displayText={'PNG, JPG, ImageSize <= 300 KB'}
                />
              )}
            />
            <div className="text-center">
              <button
                className="btn btn-outline-secondary kt-margin-r-10"
                onClick={() => navigate('/blog/author/list-authors')}
              >
                Cancel
              </button>
              <button className="btn btn-primary" type="submit">
                {isEdit ? (
                  <Trans i18nKey="p.b.w.update">Update</Trans>
                ) : (
                  <Trans i18nKey="p.b.w.submit">Submit</Trans>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddOrEditAuthor;
