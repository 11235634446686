import React, {useEffect, useState} from 'react';
import projectDao from '../../../dao/projectDao';
import _get from 'lodash/get';
import DataTable from '../../../components/datatable/DataTable';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import {Trans} from 'react-i18next';
import {toast} from 'react-toastify';
import {TextFilter} from '../../../components/datatable/DataFilter';

export default function AddOrEditProject() {
  const params = new URLSearchParams(window.location.search);
  const projectId = params.get('projectId');
  const [project, setProject] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  function removeProject(type, id) {
    projectDao
      .removeProject({projectId: projectId, type: type, id: id})
      .then((r) => {
        if (r.status === 'success') {
          toast.success('Delete Successful!');
          getProjectInfo()
        } else {
          toast.error('Delete Failed');
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error('Delete Failed');
      });
  }
  const columns = [
    {
      Header: 'No',
      accessor: 'id',
      style: {width: '5%'},
      Cell: ({cell}) => <div>{Number(cell.row.id) + 1}</div>,
    },
    {
      Header: 'Page',
      id: 'page',
      Filter: TextFilter,
      accessor: 'name',
      style: {width: '10%'},
    },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: (d) => d,
      disableSortBy: true,
      Cell: ({cell: {value}}) => (
        <div>
          <ConfirmDialog
            confirmCallback={() => removeProject(value.type, value.id)}
          >
            <button
              type="button"
              className="btn btn-sm btn-outline-danger kt-margin-r-10 kt-margin-b-10 nowrap"
            >
              <i className="fa fa-times" /> Delete
            </button>
          </ConfirmDialog>
          <button
            type="button"
            className="btn btn-sm btn-grey kt-margin-r-10 kt-margin-b-10 nowrap"
            onClick={() => {
              window.open(`${value.url}?cmsId=${value.id}`);
            }}
          >
            <i className="fa fa-eye" />{' '}
            <Trans i18nKey="p.b.p.listposts.preview">Preview</Trans>
          </button>
        </div>
      ),
      style: {width: '15%'},
    },
  ];
  const getProjectInfo=()=>{
    projectDao.queryProject({projectId}).then((r) => {
      setIsLoading(false);
      setProject(_get(r, 'projectItems', []));
    });
  }
  useEffect(() => {
    setIsLoading(true);
    getProjectInfo()
  }, []);
  return (
    <div>
      <a href={'/project-msr'}>
        <img
          style={{width: '30px', cursor: 'pointer', marginBottom: '10px'}}
          alt=""
          src={require('../../../assets/images/back.png')}
        />
      </a>
      <DataTable
        loading={isLoading}
        columns={columns}
        data={project}
        // initState={{
        //   sortBy: {id: 'lastModified', desc: false},
        // }}
      />
    </div>
  );
}
