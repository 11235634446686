import React, {useState, useEffect, useCallback} from 'react';
import Select, {components} from 'react-select';
import classnames from 'classnames';
import {ErrorMessage} from 'formik';
import _get from 'lodash/get';
import './selectBox.css';

export default function SelectBox({
  form: {setFieldValue, touched, errors},
  field: {name, value},
  options = [],
  isSearchable = false,
  setVal = () => {},
  onChangeFunc = () => {},
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const error = _get(touched, name) && _get(errors, name);
  useEffect(() => {
    if (value === '') {
      setSelectedOption('');
      return;
    }
    /* find the default option object by the default value string */
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (option.value === value) {
        setSelectedOption(option);
        return;
      }

      const opts = _get(option, 'options', []);
      for (let j = 0; j < opts.length; j++) {
        const opt = opts[j];
        if (opt.value === value) {
          setSelectedOption(opt);
          return;
        }
      }
    }
  }, [value, options]);

  const handleChange = useCallback(
    (option) => {
      setVal(option && option.value);
      setSelectedOption(option);
      setFieldValue(name, option && option.value);
      onChangeFunc();
    },
    [name, setFieldValue, setSelectedOption]
  );

  const customControl = useCallback(
    ({children, ...props}) => (
      <>
        <components.Control
          {...props}
          className={classnames({
            'custom-react-select': true,
            'select-box--invalid': !!error,
          })}
        >
          {children}
        </components.Control>
        <span className="invalid-feedback">
          <ErrorMessage name={name} />
        </span>
      </>
    ),
    [name, error]
  );

  const customStyles = useCallback(
    /* do not control the border color on hover if error */
    () => ({
      control:
        error &&
        ((base) => ({
          ...base,
          '&:hover': {
            borderColor: null,
          },
        })),
    }),
    [error]
  );

  return (
    <Select
      placeholder="Select one"
      value={selectedOption}
      options={options}
      onChange={handleChange}
      isSearchable={isSearchable}
      components={{Control: customControl}}
      styles={customStyles()}
      isMulti={false}
    />
  );
}
