import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import dayjs from 'dayjs';
import ConfirmDialogButton from '../../components/ConfirmDialogButton';
import DataTable from '../../../../components/datatable/DataTable';
import AuthorModal from '../../components/AuthorModal';
import dao from '../../../../dao/blogDao';
import {toast} from 'react-toastify';
import Dotdotdot from 'react-dotdotdot';
import _get from 'lodash/get';
import _find from 'lodash/find';
import {
  DateRangeFilter,
  DropdownFilter,
  TextFilter,
} from '../../../../components/datatable/DataFilter';
import configs from '../../../../utils/config/globals';

function ListAuthors() {
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [authorModalData, setAuthorModalData] = useState(null);
  const [isAuthorOpen, setAuthorOpen] = useState(false);

  const removeAuthor = (id) => {
    dao.author
      .remove(id)
      .then((res) => {
        console.log(res);
        setRefresh(!refresh);
        toast.success('Delete Successfully');
      })
      .catch(() => {
        toast.error(
          'Delete Failed (It is referenced by posts, cannot be deleted)'
        );
      });
  };

  const openAuthorModal = (author) => {
    setAuthorModalData(author);
    setAuthorOpen(true);
  };
  const closeAuthorModal = () => {
    setAuthorModalData(null);
    setAuthorOpen(false);
  };

  const columns = [
    {
      Header: <Trans i18nKey="p.b.w.list.image">Image</Trans>,
      id: 'image',
      accessor: (d) => d,
      disableSortBy: true,
      Cell: ({cell: {value}}) => (
        <img
          alt="author"
          src={_get(value, 'imageUrl')}
          onClick={() => openAuthorModal(value)}
          width={70}
          style={{cursor: 'pointer'}}
        />
      ),
    },
    {
      Header: <Trans i18nKey="p.b.w.list.name">Display Name</Trans>,
      id: 'name',
      accessor: (d) => d,
      Cell: ({cell: {value}}) => (
        <>
          <a
            onClick={() => openAuthorModal(value)}
            style={{cursor: 'pointer', color: '#5d78ff'}}
          >
            {_get(value, 'displayName')}
          </a>
          <br />
          <span style={{fontSize: '10px', color: '#6c757d'}}>
            {_find(configs.authorType, {value: value.authorType}).label}
          </span>
        </>
      ),
      Filter: TextFilter,
    },
    {
      Header: <Trans i18nKey="p.b.w.list.short.desc">Short Description</Trans>,
      accessor: 'shortDesc',
      Cell: ({cell: {value}}) => <Dotdotdot clamp={3}>{value}</Dotdotdot>,
    },
    {
      Header: <Trans i18nKey="p.b.w.list.company.name">Company Name</Trans>,
      accessor: 'companyName',
      Filter: TextFilter,
    },
    {
      Header: <Trans i18nKey="p.b.w.list.position">Position</Trans>,
      accessor: 'position',
      Filter: TextFilter,
    },
    {
      Header: <Trans i18nKey="p.b.w.list.created">Creation</Trans>,
      accessor: 'createdTime',
      Cell: ({cell: {value}}) => dayjs(value).format('YYYY-MM-DD HH:MM'),
      Filter: DateRangeFilter,
    },
    {
      Header: <Trans i18nKey="p.b.w.list.modified">Modification</Trans>,
      accessor: 'lastModified',
      Cell: ({cell: {value}}) => dayjs(value).format('YYYY-MM-DD HH:MM'),
      Filter: DateRangeFilter,
    },
    {
      Header: <Trans i18nKey="p.b.w.list.actions">Actions</Trans>,
      id: 'actions',
      accessor: (d) => d,
      disableSortBy: true,
      Cell: ({cell: {value}}) => (
        <>
          <Link
            to={`/blog/author/edit-author?id=${value.id}`}
            className="btn btn-sm btn-primary kt-margin-r-10 kt-margin-b-10 nowrap"
          >
            <i className="fa fa-edit" />{' '}
            <Trans i18nKey="p.b.w.listauthors.edit">Edit</Trans>
          </Link>
          <ConfirmDialogButton
            className="btn-outline-danger"
            callback={() => removeAuthor(value.id)}
          >
            <i className="fa fa-times" />{' '}
            <Trans i18nKey="p.b.w.listauthors.delete">Delete</Trans>
          </ConfirmDialogButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    dao.author.listAll().then((res) => {
      if (res.results) {
        setData(res.results);
      }
    });
  }, [refresh]);

  return (
    <div>
      <Link
        className="btn btn-danger kt-margin-b-10 kt-margin-r-10"
        to="/blog/author/add-author"
      >
        <i className="fa fa-plus" />
        <Trans i18nKey="p.b.w.add.author">Add Author</Trans>
      </Link>
      <DataTable
        columns={columns}
        data={data}
        initState={{
          sortBy: {id: 'lastModified', desc: true},
        }}
      />
      <AuthorModal
        data={authorModalData}
        isOpen={isAuthorOpen}
        onClose={closeAuthorModal}
      />
    </div>
  );
}

export default ListAuthors;
