import _get from 'lodash/get';

const getImageAttr = (s) => {
  return {
    src: _get(s, 'attributes.imageFile.data.attributes.url'),
    class: _get(s, 'attributes.image.imageClass', ''),
    alt: _get(s, 'attributes.image.imageAlt', ''),
    title: _get(s, 'attributes.image.imageTitle', ''),
  };
};

const getLinkAttr = (s) => {
  return {
    href: _get(s, 'attributes.image.imageLink.url', ''),
    nofollow: _get(s, 'attributes.image.imageLink.nofollow') ? 'nofollow' : '',
    newTab: _get(s, 'attributes.image.imageLink.newTab') ? '_blank' : '_self',
  };
};

export {getImageAttr, getLinkAttr};
