import fetch from 'isomorphic-fetch';

const uploadDao = {
    uploadpdf: async formData => {
        const response = await fetch(`/smb-admin/file/upload-file.action`, {
            method: 'POST',
            body: formData,
        });
        return response.json();
    },

};

// const uploadVideoDao = {
//     uploadVideo: async formData => {
//         const response = await fetch(`/smb-admin/file/upload-video.action`, {
//             method: 'POST',
//             body: formData,
//         });
//         return response.json();
//     },
// };

export default uploadDao;