const YceConfigs = {
  buttonTools: [
    {label: 'photo enhancer', value: 'photo-enhance'},
    {label: 'object removal', value: 'object-removal'},
    {label: 'remove background', value: 'remove-background'},
    {label: 'blur background', value: 'blur-background'},
    {label: 'change background', value: 'change-background'},
    {label: 'ai colorize', value: 'colorize'},
    {label: 'ai lighting', value: 'lighting'},
    {label: 'ai image extender', value: 'out-paint'},
    {label: 'crop', value: 'crop-photo'},
    {label: 'flip & rotate', value: 'flip-and-rotate-image'},
    {label: 'avatar', value: 'avatar'},
    {label: 'ai image generator', value: 'ai-art-generator'},
    {label: 'ai hair generator', value: 'ai-hairstyle-generator'},
    {label: 'ai replace', value: 'obj-replace'},
    {label: 'ai headshot', value: 'ai-headshot-generator'},
    {label: 'aI makeup transfer', value: 'facial-makeup-transfer-filter'},
    {label: 'video enhancer', value: 'video-enhancer-ai'},
    {label: 'ai color correction', value: 'color-correction'},
    {label: 'face shape detect', value: 'face-shape-detector'},
    {label: 'face swap', value: 'face-swap'},
    {label: 'ai bang generator', value: 'bangs-filter'},
    {label: 'ai hair extension', value: 'before-and-after-hair-extensions'},
    {label: 'ai hair volume', value: 'add-hair-to-photo-with-ai'},
    {label: 'ai studio', value: 'ai-portrait-generator'},
    {label: 'face swap video', value: 'video-face-swap'},
    {label: 'ai style transfer', value: 'ai-video-filters'},
    {label: 'ai video generator', value: 'ai-video-generator'},
    {label: 'ai portrait generator', value: 'ai-portrait'},
    {label: 'ai makeup', value: 'ai-makeup'},
    {label: 'image converter', value: 'image-converter'},
  ],
  ctaAction: [
    {label: 'Upload Image', value: 'upload_image'},
    {label: 'Product Page', value: 'product_page'},
    {label: 'Result Page', value: 'tool_page'},
  ],
};

export default YceConfigs;
