import React from 'react';
import {Trans} from 'react-i18next';
import ListProjects from './listproject';
import AddOrEditProject from './addoreditproject';

const parent = {
  title: <Trans i18nKey="p.s.project.msr.title">Project MSR</Trans>,
  path: '/project-msr',
};
export default [
  {
    path: '/project-msr',
    element: <ListProjects />,
    handle: {
      title: <Trans i18nKey="p.s.project.msr.title">Project MSR</Trans>,
      subtitle: <Trans i18nKey="p.s.project.msr.title">Project MSR</Trans>,
      breadcrumbs: [parent],
    },
  },
  {
    path: '/project-msr/edit-project-msr',
    element: <AddOrEditProject />,
    handle: {
      title: <Trans i18nKey="p.s.project.msr.title">Project MSR</Trans>,
      subtitle: (
        <Trans i18nKey="p.s.edit.project.msr.title">Edit Project</Trans>
      ),
      breadcrumbs: [parent],
    },
  },
];
