import React, {useRef} from 'react';
import JoditEditor from 'jodit-react';
import 'jodit/build/jodit.min.css';
import './fieldset.css';

export default function BasicRichTextEditor({
  lang = 'enu',
  form: {setFieldValue},
  field: {name, value},
  customConfig = {
    direction: lang === 'ara' && 'rtl',
    editorCssClass: lang === 'ara' && 'text-right',
    fullsize: false,
    readonly: false,
    allowResizeY: false,
    height: '500px',
    buttons: [
      'source',
      '|',
      'bold',
      'strikethrough',
      'underline',
      'italic',
      '|',
      'ul',
      'ol',
      '|',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'table',
      'link',
      '|',
      'align',
    ],
    buttonsMD: [
      'source',
      '|',
      'bold',
      'strikethrough',
      'underline',
      'italic',
      '|',
      'ul',
      'ol',
      '|',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'table',
      'link',
      '|',
      'align',
    ],
    buttonsSM: [
      'source',
      '|',
      'bold',
      'strikethrough',
      'underline',
      'italic',
      '|',
      'ul',
      'ol',
      '|',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'table',
      'link',
      '|',
      'align',
    ],
    buttonsXS: [
      'source',
      '|',
      'bold',
      'strikethrough',
      'underline',
      'italic',
      '|',
      'ul',
      'ol',
      '|',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'table',
      'link',
      '|',
      'align',
    ],
    image: {
      openOnDblClick: true,
      editSrc: true,
      useImageEditor: true,
      editTitle: true,
      editAlt: true,
      editLink: true,
      editSize: true,
      editBorderRadius: true,
      editMargins: true,
      editClass: true,
      editStyle: true,
      editId: true,
      editAlign: true,
      showPreview: true,
      selectImageAfterClose: true,
    },
    url: '',
    draggableTags: ['img'],
    uploader: {
      url: '',
      insertImageAsBase64URI: true,
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
    },
  },
}) {
  const textArea = useRef(null);

  return (
    <div>
      <JoditEditor
        editorRef={textArea}
        config={customConfig}
        name={name}
        value={value === undefined ? '' : value}
        onChange={(val) => {
          setFieldValue(name, val);
        }}
      />
    </div>
  );
}
