import React from 'react';
import globals from '../utils/config/globals';

export default function FormattedLang({lang}) {
  function getLabel(status) {
    let label = '';
    globals.newsLanguages.forEach((nl) => {
      if (nl.value === status) {
        label = nl.label;
      }
    });
    return label;
  }

  return <span>{getLabel(lang)}</span>;
}
