import React, {useEffect, useState} from 'react';
import courseDao from '../../../../dao/courseDao';
import {TextFilter} from '../../../../components/datatable/DataFilter';
import globals from '../../../../utils/config/globals';
import {Link, NavLink} from 'react-router-dom';
import Select from 'react-select';
import _find from 'lodash/find';
import DataTable from '../../../../components/datatable/DataTable';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import {useNavigate} from 'react-router';
import _filter from 'lodash/filter';
import {toast} from 'react-toastify';
import store from 'store';

export default function ListCourses() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [courseType, setCourseType] = useState(
    store.get('courseType') || 'WEBINAR'
  );
  const [languageType, setLanguageType] = useState(
    store.get('msLanguage') || 'enu'
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (store.get('courseType')) {
      setCourseType(store.get('courseType'));
    }
    if (store.get('msLanguage')) {
      setLanguageType(store.get('msLanguage'));
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    courseDao.listCourses({courseType}).then((r) => {
      setCourses(_filter(r, {languageType}));
      setIsLoading(false);
    });
  }, [courseType, languageType]);

  function deleteCourse(courseId) {
    courseDao
      .deleteCourse(courseId)
      .then((r) => {
        if (r.status === 'success') {
          navigate('/masterseries');
        } else {
          toast.error('Delete Failed');
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(
          'Delete Failed. Please cancel related courses before delete'
        );
      });
  }

  function setLatestCourse(courseId) {
    courseDao.pickLatestCourse(courseId).then((r) => navigate('/masterseries'));
  }

  const columns = [
    {
      Header: 'Course Name',
      accessor: 'courseName',
      disableSortBy: false,
      Cell: ({cell: {value}}) => value,
      style: {width: '20%', wordBreak: 'break-all'},
      Filter: TextFilter,
    },
    {
      Header: 'Course Desc',
      accessor: 'courseDesc',
      disableSortBy: false,
      Cell: ({cell: {value}}) => {
        return value ? value.replace(/<\/?.+?>/g, '') : value;
      },
      style: {width: '30%', wordBreak: 'break-all'},
      Filter: TextFilter,
    },
    {
      Header: 'Course Type',
      accessor: 'courseType',
      style: {width: '10%'},
    },
    {
      Header: '# of Sessions Included',
      accessor: (d) => d,
      Cell: ({cell: {value}}) => (
        <Link to={'list-sessions?courseId=' + value.id}>
          {value.numOfSessions}
        </Link>
      ),
      style: {width: '15%'},
    },
    {
      Header: 'Is Latest',
      accessor: 'isLatest',
      Cell: ({cell: {value}}) =>
        value && (
          <div className="text-center">
            <i className="fa fa-check" />
          </div>
        ),
      style: {width: '10%'},
    },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: (d) => d,
      disableSortBy: true,
      Cell: ({cell: {value}}) => (
        <div>
          <Link
            to={'/masterseries/edit-course?courseId=' + value.id}
            className="btn btn-sm btn-primary kt-margin-r-10 kt-margin-b-10 nowrap"
          >
            <i className="fa fa-edit" /> Edit
          </Link>
          {value.numOfSessions === 0 && (
            <ConfirmDialog confirmCallback={() => deleteCourse(value.id)}>
              <button
                type="button"
                className="btn btn-sm btn-outline-danger kt-margin-r-10 kt-margin-b-10 nowrap"
              >
                <i className="fa fa-times" /> Delete
              </button>
            </ConfirmDialog>
          )}
          <ConfirmDialog confirmCallback={() => setLatestCourse(value.id)}>
            <button
              type="button"
              className="btn btn-sm btn-success kt-margin-r-10 kt-margin-b-10 nowrap"
            >
              <i className="fa fa-check" /> Set Latest
            </button>
          </ConfirmDialog>
        </div>
      ),
      style: {width: '15%'},
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex">
          <Link
            className="btn btn-danger kt-margin-b-10 kt-margin-r-10"
            to="/masterseries/add-course"
          >
            <i className="fa fa-plus" /> Add Course
          </Link>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{float: 'left', margin: '0 10px 0 0'}}>
            <Select
              options={globals.courseType}
              value={_find(globals.courseType, {value: courseType})}
              onChange={(courseType) => {
                store.set('courseType', courseType.value);
                setCourseType(courseType.value);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  width: '150px',
                }),
              }}
            />
          </div>
          <div style={{float: 'right'}}>
            <Select
              options={globals.blogLanguages}
              value={_find(globals.courseLanguages, {value: languageType})}
              onChange={(lang) => {
                store.set('msLanguage', lang.value);
                setLanguageType(lang.value);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  width: '150px',
                }),
              }}
            />
          </div>
        </div>
      </div>
      <DataTable
        loading={isLoading}
        columns={columns}
        data={courses}
        initState={{
          sortBy: {id: 'lastModified', desc: true},
        }}
      />
    </div>
  );
}
