import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {toast} from 'react-toastify';
import ConfirmDialogButton from '../../../components/ConfirmDialogButton';
import RedirectKeyModal from '../../../components/RedirectKeyModal';
import dao from '../../../../../dao/blogDao';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import ActionButtonGroups from '../../../../../components/buttongroups/ActionButtonGroups';
import {Dropdown, Modal} from 'react-bootstrap';
import webUtils from '../../../../../utils/webUtils';
import utils from '../../../utils';
import useDeadLinks from '../../../../../components/hooks/useDeadLinks';
import SubmitModal from './SubmitModal';
import {Navigate, useNavigate} from 'react-router';

export default function PostActionButtons(props) {
  const {
    post = {},
    onActionComplete = () => {},
    mappingKeyList,
    blogFocus,
    languageType,
    isConverting,
  } = props;
  const navigate = useNavigate();
  const adminId = useSelector((state) => state.components.auth.admin.adm.id);
  const {parseResult, hasDeadLinks, getCheckLinks} = useDeadLinks(post.id);

  const onComplete = async (actionName, res) => {
    const status =
      _get(res, 'status') || _get(res, 'result') || _get(res, 'message');
    _toLower(status) === 'success'
      ? toast.success(actionName ? `${actionName} Successfully` : 'Succeeded')
      : toast.error(
          actionName
            ? actionName === 'Take Down'
              ? `${actionName} Failed. Please remove this post from "Top Posts" or "Popular Posts" in the Display Setting before taking down.`
              : `${actionName} Failed`
            : 'Failed'
        );
    await onActionComplete();
  };

  const copyPost = async (postId) => {
    const res = await dao.post.copy({postId, adminId});
    await onComplete('Copy', res);
  };

  const checkHasDeadLinks = async () => {
    const res = await dao.post.query(post.id);

    const checkProperties = [];
    const ctaValues = JSON.parse(_get(res, 'ctaButtons', ''));
    if (_get(ctaValues, 'mobile.isShowCoverButton', false)) {
      checkProperties.push('mobile.coverButtonLink');
    }
    if (_get(ctaValues, 'mobile.isShowFloatButton', false)) {
      checkProperties.push('mobile.floatButtonLink');
    }
    if (_get(ctaValues, 'desktop.floatButtonTitle', '').trim().length !== 0) {
      checkProperties.push('desktop.floatButtonLink');
    }

    const otherLinks = getCheckLinks(
      JSON.parse(_get(res, 'ctaButtons', '')),
      checkProperties
    );

    const msg = await parseResult(
      _get(res, 'postContent', ''),
      otherLinks,
      'SUBMIT'
    );

    return await hasDeadLinks(msg);
  };

  const submitPost = async (postId) => {
    const res = await dao.post.submit({postId, adminId});
    await onComplete('Submit', res);
  };

  const reSubmitPost = async (postId) => {
    const res = await dao.post.reSubmit({postId, adminId});
    await onComplete('Resubmit', res);
  };
  const cancelSubmit = async (postId) => {
    const res = await dao.post.cancel(postId);
    await onComplete('Cancel', res);
  };
  const takeDownPost = async (postId) => {
    const res = await dao.post
      .takeDown(postId)
      .catch((e) => ({status: 'failed'}));
    await onComplete('Take Down', res);
  };
  const deletePost = async (postId) => {
    const res = await dao.post.remove(postId);
    await onComplete('Delete', res);
  };
  const langs = {
    cht: '/zh-tw',
    chs: '/zh-cn',
    fra: '/fr',
    esp: '/es',
    ptb: '/pt',
    ita: '/it',
    deu: '/de',
    jpn: '/ja',
    kor: '/ko',
    enu: '',
  };
  const dropdown = [
    true,
    false,
    false,
    false,
    true,
    true,
    true,
    true,
    true,
    true,
  ];

  const getPreview = () => {
    if (blogFocus === 'yce')
      return `${webUtils.getYceDomain()}/${utils.getYceLang(
        languageType
      )}/use-case/post?postId=${post.id}`;
    return `${process.env.REACT_APP_FRONTEND_URL}${
      langs[post.languageType]
    }/${blogFocus}/blog/post?postId=${post.id}`;
  };
  const value = [
    {
      availability: post.ableToEdit,
      item: !dropdown[0] ? (
        <Link
          className={
            !dropdown[0]
              ? 'btn btn-sm btn-primary kt-margin-r-10 kt-margin-b-10 nowrap'
              : 'kt-margin-r-10 kt-margin-b-10 nowrap'
          }
          to={`/blog/post/edit-post?id=${post.id}`}
        >
          <i className="fa fa-edit" />{' '}
          <Trans i18nKey="p.b.p.listposts.edit">Edit</Trans>
        </Link>
      ) : (
        <Dropdown.Item href={`edit-post?id=${post.id}`}>
          <Link to={`/blog/post/edit-post?id=${post.id}`}>
            <i className="fa fa-edit" />{' '}
            <Trans i18nKey="p.b.p.listposts.edit">Edit</Trans>
          </Link>
        </Dropdown.Item>
      ),
    },
    {
      availability: true,
      item: !dropdown[1] ? (
        <a
          href={getPreview()}
          target="_blank"
          rel="noopener noreferrer"
          className={
            !dropdown[1]
              ? 'btn btn-sm btn-grey kt-margin-r-10 kt-margin-b-10 nowrap'
              : ''
          }
        >
          <i className="fa fa-eye" />{' '}
          <Trans i18nKey="p.b.p.listposts.preview">Preview</Trans>
        </a>
      ) : (
        <Dropdown.Item
          href={getPreview()}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-eye" />{' '}
          <Trans i18nKey="p.b.p.listposts.preview">Preview</Trans>
        </Dropdown.Item>
      ),
    },
    {
      availability: true,
      item: !dropdown[2] ? (
        <RedirectKeyModal
          className={
            !dropdown[2]
              ? 'btn btn-sm btn-warning kt-margin-r-10 kt-margin-b-10 nowrap'
              : ''
          }
          post={post}
          mappingKeyList={mappingKeyList}
        >
          <i className="fa fa-link" />{' '}
          <Trans i18nKey="p.b.p.listposts.article.settings">
            Article Settings
          </Trans>
        </RedirectKeyModal>
      ) : (
        <Dropdown.Item
          href={getPreview()}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-eye" />{' '}
          <Trans i18nKey="p.b.p.listposts.preview">Preview</Trans>
        </Dropdown.Item>
      ),
    },
    {
      availability: post.ableToCopy && !isConverting,
      item: (
        <ConfirmDialogButton
          className={!dropdown[3] ? 'btn-grey' : ''}
          callback={() => copyPost(post.id)}
          dropdown={dropdown[3]}
          title="Save As"
        >
          <i className="fa fa-copy" />{' '}
          <Trans i18nKey="p.b.p.listposts.save.as">Save As</Trans>
        </ConfirmDialogButton>
      ),
    },
    {
      availability: post.ableToSubmit,
      item: (
        <SubmitModal
          checkCallback={() => checkHasDeadLinks()}
          submitCallback={() => submitPost(post.id)}
          goBackCallback={() => navigate(`/blog/post/edit-post?id=${post.id}`)}
          dropdown={dropdown}
        />
      ),
    },
    {
      availability: post.ableToCancel,
      item: (
        <ConfirmDialogButton
          className={!dropdown[5] ? 'btn-outline-danger' : 'text-danger'}
          callback={() => cancelSubmit(post.id)}
          dropdown={dropdown[5]}
          title="Cancel"
        >
          <i className="fa fa-times" />{' '}
          <Trans i18nKey="p.b.p.listposts.cancel">Cancel</Trans>
        </ConfirmDialogButton>
      ),
    },
    {
      availability: post.ableToContinueToReview,
      item: (
        <ConfirmDialogButton
          className={!dropdown[6] ? 'btn-outline-info' : 'text-info'}
          callback={() => reSubmitPost(post.id)}
          dropdown={dropdown[6]}
          title="Resubmit"
        >
          <i className="fa flaticon2-paperplane" />{' '}
          <Trans i18nKey="p.b.p.listposts.resubmit">Resubmit</Trans>
        </ConfirmDialogButton>
      ),
    },
    {
      availability: post.ableToTakeDown,
      item: (
        <ConfirmDialogButton
          className={!dropdown[7] ? 'btn-outline-dark' : 'text-dark'}
          callback={() => takeDownPost(post.id)}
          dropdown={dropdown[7]}
          title="Takedown"
        >
          <i className="fa flaticon-edit-1" />{' '}
          <Trans i18nKey="p.b.p.listposts.takedown">TakeDown</Trans>
        </ConfirmDialogButton>
      ),
    },
    {
      availability: post.ableToDelete,
      item: (
        <ConfirmDialogButton
          className={!dropdown[8] ? 'btn-outline-danger' : 'text-danger'}
          callback={() => deletePost(post.id)}
          dropdown={dropdown[8]}
          title="Delete"
        >
          <i className="fa fa-times" />{' '}
          <Trans i18nKey="p.b.p.listposts.delete">Delete</Trans>
        </ConfirmDialogButton>
      ),
    },
  ];

  return (
    <ActionButtonGroups
      dropdown={dropdown}
      value={value}
      isConverting={isConverting}
    />
  );
}
