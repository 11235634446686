import React from 'react';
import dayjs from 'dayjs';

export default function FormattedDate({time, type}) {
  /*
   * time:
   * type: short/long/full
   */
  function getFormat(type) {
    if (type === 'newsIndex') {
      return 'MMMM DD, YYYY';
    } else if (type === 'newsDetailY') {
      return 'YYYY';
    } else if (type === 'newsDetailMD') {
      return 'MMMM DD';
    } else if (type === 'newsList') {
      return 'YYYY MMMM DD';
    } else if (type === 'newsCard') {
      return 'MMM DD, YYYY';
    }
    return 'MM/DD/YYYY';
  }

  if (!time) {
    return <div />;
  }
  return <span>{dayjs(time).format(getFormat(type))}</span>;
}
