import authDao from '../../dao/authDao';
import {push} from 'react-router-redux';

const LOAD_AUTH = 'components/auth/LOAD_AUTH';
const LOAD_AUTH_SUCCESS = 'components/auth/LOAD_AUTH_SUCCESS';
const LOAD_AUTH_FAIL = 'components/auth/LOAD_AUTH_FAIL';
const SET_ADMIN_SUMMARY = 'components/auth/SET_ADMIN_SUMMARY';
const SIGN_OUT = 'components/auth/SIGN_OUT';
const SIGN_OUT_SUCCESS = 'components/auth/SIGN_OUT_SUCCESS';

const initialState = {
  admin: null,
  authCheck: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_AUTH:
      return {authCheck: false, ...initialState};
    case SIGN_OUT_SUCCESS:
      return {...initialState};
    case LOAD_AUTH_SUCCESS:
      return {
        ...state,
        authCheck: true,
        admin: action.admin,
      };
    case SET_ADMIN_SUMMARY:
      return {
        ...state,
        authCheck: true,
        admin: action.admin,
      };
    case LOAD_AUTH_FAIL:
      return {
        ...state,
        authCheck: true,
      };
    default:
      return state;
  }
};

export function loadAuth() {
  return (dispatch) => {
    dispatch({type: LOAD_AUTH});

    return authDao
      .loadAuth()
      .then((json) => dispatch(loadAuthSuccess(json)))
      .catch(() => dispatch(loadAuthFail()));
  };
}

function loadAuthSuccess(adm) {
  return (dispatch) => {
    dispatch({
      type: LOAD_AUTH_SUCCESS,
      admin: adm,
    });
    return adm;
  };
}

function loadAuthFail() {
  return (dispatch) => {
    dispatch({type: LOAD_AUTH_FAIL});
    return dispatch(push({pathname: '/sign-in'}));
  };
}

export function setAdminSummary(adm) {
  return {
    type: SET_ADMIN_SUMMARY,
    admin: adm,
  };
}

export function signOut() {
  return (dispatch) => {
    dispatch({type: SIGN_OUT});

    return authDao.signOut().then(() => dispatch(signOutSuccess()));
  };
}

function signOutSuccess() {
  return (dispatch) => {
    dispatch({type: SIGN_OUT_SUCCESS});
  };
}
