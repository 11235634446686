import {Field} from 'formik';
import classnames from 'classnames';
import React, {useEffect, useState} from 'react';
import FileBox from './FileBox';
import _get from 'lodash/get';
import {toast} from 'react-toastify';
import uploadDao from '../../dao/uploadDao';

export default function FileContainer({
  form: {setFieldValue, errors, values},
  field: {name},
  accept,
  errorMessage,
  onFileChange,
  displayText,
  fileName,
  maxSize,
  pathType,
  uploadButtonText,
  htmlId = 'filePicker',
  showCover,
  videoWidth,
  videoHeight,
  disableButton,
  ...props
}) {
  const [videoCover, setVideoCover] = useState('');
  useEffect(() => {
    if (showCover) setVideoCover(values[name]);
  }, [values]);

  const handleClearFile = () => {
    setFieldValue(name, '');
    setVideoCover('');
  };
  const fileObjectOrUrl = _get(values, name);
  const [submitting, setSubmitting] = useState(false);
  return (
    <React.Fragment>
      <Field
        {...props}
        name={name}
        component={FileBox}
        accept={accept}
        onFileChange={onFileChange}
        maxSize={maxSize}
        fileName={fileName}
        displayText={displayText}
        uploadButtonText={uploadButtonText}
        htmlId={htmlId}
        videoWidth={videoWidth}
        videoHeight={videoHeight}
      />

      {!fileObjectOrUrl.name && fileObjectOrUrl ? (
        <button
          className={'btn btn-danger kt-margin-b-10 kt-margin-r-10'}
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            borderColor: 'lightgrey',
          }}
          type="button"
          onClick={handleClearFile}
        >
          <i className="la la-remove" style={{color: 'red'}} />
          {fileObjectOrUrl.substring(fileObjectOrUrl.lastIndexOf('/') + 1)}
        </button>
      ) : (
        ''
      )}
      {(!fileObjectOrUrl || fileObjectOrUrl.name) && !disableButton ? (
        <div
          style={{cursor: 'pointer'}}
          className={classnames({
            row: true,
            btn: true,
            'btn-primary': true,
            'kt-margin-r-10': true,
            'kt-margin-l-5': true,
            'kt-margin-b-10': true,
            'kt-spinner': true,
            'kt-spinner-v2': true,
            'kt-spinner--center': true,
            'kt-spinner--sm': true,
            'kt-spinner--warning': submitting,
          })}
          onClick={() => {
            if (!fileObjectOrUrl) {
              toast.error('please upload a file first', {autoClose: 3000});
            } else {
              setSubmitting(true);
              const formData = new FormData();
              formData.append('pathType', pathType);
              formData.append('file', fileObjectOrUrl);
              uploadDao
                .uploadpdf(formData)
                .then((res) => {
                  setFieldValue(name, res);
                })
                .then(() => {
                  document.getElementById(htmlId).value = '';
                  setSubmitting(false);
                });
            }
          }}
        >
          <div
            style={{
              visibility: submitting ? 'hidden' : 'visible',
            }}
          >
            Submit
          </div>
        </div>
      ) : (
        ''
      )}
      {videoCover && showCover && (
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{width: '100%', maxWidth: '500px', display: 'block'}}
        >
          <source src={videoCover} type="video/mp4" />
        </video>
      )}
    </React.Fragment>
  );
}
