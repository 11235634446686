import {get, listAll, post} from './utils';

const seoDao = {
  queryMeta: (params) => get('/smb-admin/seo/query-meta.action', params),
  listAllMetas: (params) => listAll(seoDao.listMetas, params),
  listMetas: (params) => get('/smb-admin/seo/list-metas.action', params),
  updateMeta: (params) => post('/smb-admin/seo/update-meta.action', params),
};

export default seoDao;
