import React from 'react';
import {Navigate} from 'react-router-dom';
import ListCourses from './course/listcourses';
import AddOrEditCourse from './course/addoreditcourse';
import ListSessions from './session/listsessions';
import AddOrEditSession from './session/addoreditsession';
import ListSpeakers from './speaker/listspeakers';
import AddOrEditSpeaker from './speaker/addoreditspeaker';

const courseParent = {
  title: 'Course',
  path: '/masterseries/list-courses',
};
const speakerParent = {
  title: 'Speaker',
  path: '/masterseries/list-speakers',
};
const sessionParent = {
  title: 'Session',
  path: '/masterseries/list-sessions',
};

export default [
  {
    path: '/masterseries/list-courses',
    element: <ListCourses />,
    handle: {
      title: 'Course',
      subtitle: 'Course',
      breadcrumbs: [courseParent],
    },
  },
  {
    path: '/masterseries/add-course',
    element: <AddOrEditCourse />,
    handle: {
      title: 'Add Course',
      subtitle: 'Add Course',
      breadcrumbs: [courseParent],
    },
  },
  {
    path: '/masterseries/edit-course',
    element: <AddOrEditCourse />,
    handle: {
      title: 'Edit Course',
      subtitle: 'Edit Course',
      breadcrumbs: [courseParent],
    },
  },
  {
    path: '/masterseries/list-speakers',
    element: <ListSpeakers />,
    handle: {
      title: 'Speakers',
      subtitle: 'Speakers',
      breadcrumbs: [speakerParent],
    },
  },
  {
    path: '/masterseries/add-speaker',
    element: <AddOrEditSpeaker />,
    handle: {
      title: 'Add Speaker',
      subtitle: 'Add Speaker',
      breadcrumbs: [speakerParent],
    },
  },
  {
    path: '/masterseries/edit-speaker',
    element: <AddOrEditSpeaker />,
    handle: {
      title: 'Edit Speaker',
      subtitle: 'Edit Speaker',
      breadcrumbs: [speakerParent],
    },
  },
  {
    path: '/masterseries/list-sessions',
    element: <ListSessions />,
    handle: {
      title: 'Sessions',
      subtitle: 'Sessions',
      breadcrumbs: [sessionParent],
    },
  },
  {
    path: '/masterseries/add-session',
    element: <AddOrEditSession />,
    handle: {
      title: 'Add Session',
      subtitle: 'Add Session',
      breadcrumbs: [sessionParent],
    },
  },
  {
    path: '/masterseries/edit-session',
    element: <AddOrEditSession />,
    handle: {
      title: 'Edit Session',
      subtitle: 'Edit Session',
      breadcrumbs: [sessionParent],
    },
  },
  {
    path: '/masterseries',
    element: <Navigate to="/masterseries/list-courses" />,
  },
];
