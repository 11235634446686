import {get} from './utils';

const dao = {
  loadAuth: () => get('/smb-admin/auth/auth.action'),
  signIn: (params) => get('/smb-admin/auth/sign-in.action', params),
  signOut: () => get('/smb-admin/auth/sign-out.action'),
  listAdmin: () => get('/smb-admin/admin/list-admin.action'),
  queryAdmin: (id) => get('/smb-admin/admin/query-admin.action', {id}),
  addAdmin: (params) => get('/smb-admin/admin/create-admin.action', params),
  updateAdmin: (params) => get('/smb-admin/admin/update-admin.action', params),
  removeAdmin: (id) => get('/smb-admin/admin/delete-admin.action', {id}),
  checkEmail: (email) => get('/smb-admin/admin/check-admin.action', {email}),
};

export default dao;
