import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Trans} from 'react-i18next';
import {signOut} from '../../auth/reducers';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default React.memo(
  connect(
    null,
    dispatch => bindActionCreators({signOut}, dispatch)
  )(function Logout({signOut}) {
    const [show, setShow] = useState(false);
    return (
      <div className="kt-header__topbar-item">
        <div className="kt-header__topbar-wrapper">
          <span
            className="kt-header__topbar-icon"
            onClick={() => setShow(true)}
          >
            <i className="flaticon-logout" />
          </span>
        </div>
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header>
            <Modal.Title>
              <Trans i18nKey="c.l.h.header.logout">Logout</Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Trans i18nKey="c.l.h.header.logout.desc">
              Do you really want to logout?
            </Trans>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              <Trans i18nKey="c.l.h.header.no">No</Trans>
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                signOut().catch(() => {
                  setShow(false);
                  window.location.href = '/sign-in';
                })
              }
            >
              <Trans i18nKey="c.l.h.header.yes">Yes</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  })
);
