import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import DisplayDeadLinkResults from './DisplayDeadLinkResults';
import Loader from '../spinner/Loader';

export default function DeadLinkCheckerModal({
  isOpen,
  SetIsOpen,
  deadLinkResult,
  handleSubmit,
  isSubmitting,
}) {
  const [isClick, setIsClick] = useState(false);
  return (
    <Modal size="lg" show={isOpen} onHide={() => {}} enforceFocus>
      <Modal.Header>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DisplayDeadLinkResults result={deadLinkResult} />
      </Modal.Body>
      <Modal.Footer>
        <div className="text-center">
          <button
            className="btn btn-outline-secondary kt-margin-r-10"
            onClick={() => SetIsOpen(false)}
          >
            Cancel
          </button>
          <button
            disabled={isClick}
            className="btn btn-primary"
            onClick={() => {
              setIsClick(true);
              handleSubmit();
            }}
          >
            {isSubmitting ? <Loader /> : <> Keep Saving</>}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
