import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import ManualDataTable from '../../../components/manualdatatable/ManualDataTable';
import dao from '../../../dao/customerSettingsDao';
import {toast} from 'react-toastify';
import _get from 'lodash/get';
import dayjs from 'dayjs';
import {
  DateRangeFilter,
  TextFilter,
} from '../../../components/manualdatatable/ManualDataFilters';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import AddOrEditWhitelistModal from './addoreditwhitelistmodal';

function WhitelistSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const [processedParams, setProcessedParams] = useState(null);
  const [originalData, setOriginalData] = useState({results: [], totalSize: 0});

  const fetchData = async () => {
    const res = await dao.listWhitelist(processedParams);

    setOriginalData({
      ...originalData,
      results: _get(res, 'results', []),
      totalSize: _get(res, 'totalSize', 0),
    });

    setIsLoading(false);
  };

  const deleteWhitelistAPI = async (id) => {
    await dao.deleteWhitelist({id: id}).catch(() => {
      toast.error('Delete Failed');
    });
    await fetchData();
  };

  useEffect(() => {
    if (!processedParams) {
      return;
    }
    setIsLoading(true);
    fetchData();
  }, [processedParams]);

  const columns = [
    {
      Header: <Trans i18nKey="p.b.p.list.email">Email</Trans>,
      accessor: 'email',
      style: {width: '25%'},
      disableSorting: true,
      Filter: TextFilter,
    },
    {
      Header: <Trans i18nKey="p.b.p.list.created">Creation</Trans>,
      accessor: 'createdTime',
      Cell: ({cell: {value}}) => dayjs(value).format('MM/DD/YYYY'),
      style: {width: '25%'},
      Filter: DateRangeFilter,
    },
    {
      Header: <Trans i18nKey="p.b.p.list.modified">Modification</Trans>,
      accessor: 'lastModified',
      Cell: ({cell: {value}}) => dayjs(value).format('MM/DD/YYYY'),
      style: {width: '25%'},
      Filter: DateRangeFilter,
    },
    {
      Header: <Trans i18nKey="p.b.p.list.actions">Actions</Trans>,
      accessor: 'id',
      id: 'actions',
      disableSortBy: true,
      style: {width: '25%'},
      Cell: ({cell: {value, row}}) => (
        <>
          <ConfirmDialog confirmCallback={() => deleteWhitelistAPI(value)}>
            <button className="btn btn-sm btn-outline-danger kt-margin-r-10 kt-margin-b-10 nowrap">
              <i className="fa fa-times" />{' '}
              <Trans i18nKey="p.b.k.listkeywords.actions.delete">Delete</Trans>
            </button>
          </ConfirmDialog>
        </>
      ),
    },
  ];

  return (
    <div>
      <AddOrEditWhitelistModal listAPI={fetchData} />
      <ManualDataTable
        isLoading={isLoading}
        columns={columns}
        data={originalData.results}
        totalSize={originalData.totalSize}
        initState={{
          sortBy: {id: 'lastModified', desc: true},
          pageIndex: 0,
          pageSize: 10,
        }}
        setProcessedParams={setProcessedParams}
      />
    </div>
  );
}

export default WhitelistSettings;
