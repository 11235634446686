import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import Breadcrumbs from '../breadcrumbs';

export default function PageHeader({title = '', breadcrumbs = []}) {
  const pathname = useLocation().pathname;

  return (
    <div className="kt-subheader kt-grid__item">
      <div className="kt-subheader__main">
        <h3 className="kt-subheader__title">{title} </h3>
        <span className="kt-subheader__separator kt-hidden" />
        {pathname !== '/' && pathname !== '/partner' && (
          <div className="kt-subheader__breadcrumbs">
            <Link to="/" className="kt-subheader__breadcrumbs-home">
              <i className="flaticon2-shelter" />
            </Link>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        )}
      </div>
    </div>
  );
}
