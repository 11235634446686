import React, {useEffect, useState} from 'react';
import {Formik, Field, Form, FieldArray} from 'formik';
import courseDao from '../../../../dao/courseDao';
import {useNavigate, useLocation} from 'react-router';
import yup from '../../../../utils/yup';
import InputBox from '../../../../components/formik/InputBox';
import globals from '../../../../utils/config/globals';
import RadioButton from '../../../../components/formik/RadioButton';
import Loader from '../../../../components/spinner/Loader';
import SimpleButtonGroup from '../../../../components/formik/SimpleButtonGroup';
import {toast} from 'react-toastify';
import RichTextEditor from '../../../../components/texteditor/RichTextEditor';
import SelectBox from '../../../../components/formik/SelectBox';
import ImageField from '../../../../components/formik/ImageField';
import _get from 'lodash/get';
import {Trans} from 'react-i18next';
import BlogHeadingSelect from '../../../../components/formik/BlogHeadingSelect';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import CheckBox from '../../../../components/formik/CheckBox';

const titleButtons = [
  'bold',
  'strikethrough',
  'underline',
  'italic',
  '|',
  'fontsize',
  'brush',
  'paragraph',
];

const DescButtons = [
  'bold',
  'strikethrough',
  'underline',
  'italic',
  '|',
  'fontsize',
  'brush',
  'paragraph',
  '|',
  'link',
];

const ticketButtons = ['source', '|', 'bold', 'link', '|', 'ul', 'ol'];

const titleConfig = {
  readonly: false,
  allowResizeY: false,
  height: '200px',
  buttons: titleButtons,
  buttonsMD: titleButtons,
  buttonsSM: titleButtons,
  buttonsXS: titleButtons,
};

const DescConfig = {
  readonly: false,
  allowResizeY: false,
  height: '250px',
  buttons: DescButtons,
  buttonsMD: DescButtons,
  buttonsSM: DescButtons,
  buttonsXS: DescButtons,
};

const ticketConfig = {
  readonly: false,
  allowResizeY: false,
  height: '200px',
  buttons: ticketButtons,
  buttonsMD: ticketButtons,
  buttonsSM: ticketButtons,
  buttonsXS: ticketButtons,
};

export default function AddOrEditCourse() {
  const navigate = useNavigate();
  const {search} = useLocation();
  const param = new URLSearchParams(search);
  const courseId = param.get('courseId');
  const [isEdit, setIsEdit] = useState(!!search);
  const [isLoading, setIsLoading] = useState(false);
  const [course, setCourse] = useState({});
  const [originalCourseKey, setOriginalCourseKey] = useState(null);
  const [courseType, setCourseType] = useState('WEBINAR');
  const [pageType, setPageType] = useState('REPLAY_PAGE');
  const [languageType, setLanguageType] = useState('enu');
  const [courseTypesList, setCourseTypesList] = useState([]);
  const [checkStatus, setCheckStatus] = useState(false);
  const ticketType = [
    {type: 'physical', label: 'Physical Event'},
    {type: 'webinar', label: 'Webinar'},
  ];
  useEffect(() => {
    setIsLoading(true);
    if (!courseId) {
      setIsLoading(false);
    } else {
      courseDao.queryCourse(courseId).then((r) => {
        setCourse({
          id: r.id || '',
          courseDesc: r.courseDesc || '',
          courseKey: r.courseKey || '',
          courseName: r.courseName || '',
          courseTitle: r.courseTitle || '',
          courseType: r.courseType || '',
          courseDate: r.attrs['META_COURSES']
            ? JSON.parse(r.attrs['META_COURSES']).courseDate
            : '',
          courseTime: r.attrs['META_COURSES']
            ? JSON.parse(r.attrs['META_COURSES']).courseTime
            : '',
          courseLocation: r.attrs['META_COURSES']
            ? JSON.parse(r.attrs['META_COURSES']).courseLocation
            : '',
          desktopBanner: r.attrs['META_COURSES']
            ? JSON.parse(r.attrs['META_COURSES']).desktopBanner
            : '',
          mobileBanner: r.attrs['META_COURSES']
            ? JSON.parse(r.attrs['META_COURSES']).mobileBanner
            : '',
          pageType: r.pageType || '',
          isLatest: r.isLatest || '',
          languageType: r.languageType || '',
          numOfSessions: r.numOfSessions || '',
          attrs: r.attrs['RELATED_COURSES']
            ? JSON.parse(r.attrs['RELATED_COURSES'])
            : [],
          speakerOrders:
            r.attrs['SPEAKER_ORDER'] &&
            !_isEmpty(JSON.parse(r.attrs['SPEAKER_ORDER']))
              ? JSON.parse(r.attrs['SPEAKER_ORDER'])
              : [0],
          showTicket: r.attrs['EVENT_TICKETS']
            ? JSON.parse(r.attrs['EVENT_TICKETS']).showTicket
            : false,
          ticketDesc: r.attrs['EVENT_TICKETS']
            ? JSON.parse(r.attrs['EVENT_TICKETS']).ticketDesc
            : '',
          ticket: r.attrs['EVENT_TICKETS']
            ? JSON.parse(r.attrs['EVENT_TICKETS']).ticket &&
              JSON.parse(r.attrs['EVENT_TICKETS']).ticket.length > 0 &&
              JSON.parse(r.attrs['EVENT_TICKETS']).ticket.map((c) => {
                return {
                  type: c.type,
                  buttonText: c.buttonText || '',
                  buttonLink: c.buttonLink || '',
                  isDynamicLinks: c.isDynamicLinks || false,
                  dynamicLinks: c.dynamicLinks || [{utm: '', link: ''}],
                };
              })
            : ticketType.map((t) => {
                return {
                  type: t.type,
                  buttonText: '',
                  buttonLink: '',
                  isDynamicLinks: false,
                  dynamicLinks: [{utm: '', link: ''}],
                };
              }),
        });
        setOriginalCourseKey(r.courseKey);
        setIsLoading(false);
        r.courseType && setCourseType(r.courseType);
        r.pageType && setPageType(r.pageType);
        r.languageType && setLanguageType(r.languageType);
      });
    }
  }, [courseId]);

  useEffect(() => {
    courseDao.listCoursesByTypes({courseType, languageType}).then((r) => {
      const list = [{label: '', value: ''}];
      r.forEach((item) => {
        list.push({label: item.courseName, value: item.courseKey});
      });
      setCourseTypesList(list);
    });
  }, [courseId, courseType, languageType]);

  const addCourse = (val) => {
    let tmp = {...val.attrs};
    tmp[Object.keys(tmp).length + 1] = {};
    setCourse({
      ...val,
      attrs: tmp,
    });
  };

  const delCourse = (val) => {
    let tmp = {...val.attrs};
    if (Object.keys(tmp).length <= 0) return;
    let keys = Object.keys(tmp);
    delete tmp[keys[keys.length - 1]];
    setCourse({
      ...val,
      attrs: tmp,
    });
  };

  const formikConfig = {
    validationSchema: yup.object().shape({
      courseName: yup.string().max(500).required(),
      courseDesc: yup.string(),
      languageType: yup.string().required(),
      courseType: yup.string().required(),
      pageType: yup.string().required(),
      courseDate: yup.string(),
      courseTime: yup.string(),
      courseLocation: yup.string(),
      desktopBanner: yup.string(),
      mobileBanner: yup.string(),
      courseKey: yup
        .string()
        .matches(/^[a-zA-Z0-9\-_]*$/)
        .required()
        .test(
          'my-test',
          'Check Failed. Course Key & Language Type already exists.',
          async function (value) {
            courseDao
              .checkCourseKey({
                courseType: courseType,
                languageType: languageType,
                courseKey: value,
              })
              .then((r) => {
                if (
                  r.status === 'ok' ||
                  (r.status === 'existed' && originalCourseKey === value)
                ) {
                  setCheckStatus(true);
                } else {
                  toast.error('Course Key & Language Type already exists.');
                  setCheckStatus(false);
                }
              });
            return checkStatus;
          }
        ),
      courseTitle: yup.string(),
    }),
    enableReinitialize: true,
    initialValues: {
      languageType: 'enu',
      courseType: 'WEBINAR',
      pageType: 'REPLAY_PAGE',
      ticket:
        course.ticket && course.ticket.length > 0
          ? course.ticket.map((c) => {
              return {
                type: c.type,
                buttonText: c.buttonText || '',
                buttonLink: c.buttonLink || '',
                isDynamicLinks: c.isDynamicLinks || false,
                dynamicLinks: c.dynamicLinks || [{utm: '', link: ''}],
              };
            })
          : ticketType.map((t) => {
              return {
                type: t.type,
                buttonText: '',
                buttonLink: '',
                isDynamicLinks: false,
                dynamicLinks: [{utm: '', link: ''}],
              };
            }),
      speakerOrders:
        course.attrs && course.attrs.speakerOrders
          ? course.attrs.speakerOrders
          : [0],
      ...course,
    },
    onSubmit: (values, {setSubmitting}) => {
      if (!values.courseTitle) {
        toast.warn('Please add course title.');
        setSubmitting(false);
        return;
      }
      if (!values.courseDesc) {
        toast.warn('Please add course description.');
        setSubmitting(false);
        return;
      }
      setSubmitting(true);
      const tmp = values.speakerOrders.filter((s) => s !== 0);
      values.attrs = {
        RELATED_COURSES:
          values.attrs && values.attrs !== []
            ? JSON.stringify(Object.values(values.attrs))
            : [],
        META_COURSES: JSON.stringify({
          courseDate: values.courseDate || '',
          courseTime: values.courseTime || '',
          courseLocation: values.courseLocation || '',
          desktopBanner: '',
          mobileBanner: '',
        }),
        SPEAKER_ORDER: JSON.stringify(tmp),
        EVENT_TICKETS: JSON.stringify({
          showTicket: values.showTicket,
          ticketDesc: values.ticketDesc,
          ticket: values.ticket,
        }),
      };
      if (isEdit) {
        courseDao
          .updateCourse({
            course: {
              id: values.id,
              courseName: values.courseName,
              courseDesc: values.courseDesc,
              courseKey: values.courseKey,
              courseTitle: values.courseTitle,
              courseType: values.courseType,
              pageType: values.pageType,
              attrs: values.attrs,
            },
            desktopBanner: values.desktopBanner,
            mobileBanner: values.mobileBanner,
          })
          .then((r) => {
            if (r.id) {
              toast.success('Update Successful');
            } else {
              toast.error('Update Failed');
            }
            navigate('/masterseries/list-courses');
          });
      } else {
        courseDao
          .createCourse({
            course: {...values},
            desktopBanner: values.desktopBanner,
            mobileBanner: values.mobileBanner,
          })
          .then((r) => {
            if (r.id) {
              toast.success('Create Successful');
            } else {
              toast.error('Create Failed');
            }
            navigate('/masterseries/list-courses');
          });
      }
      navigate('/masterseries/list-courses');
    },
  };

  const [speakers, setSpeakers] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    courseDao.listSpeakers().then((r) => {
      let tmp = [];
      r.forEach((s) => {
        tmp.push({label: s.speakerName, value: s.id});
      });
      setSpeakers(tmp);
      setIsLoading(false);
    });
  }, []);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Formik {...formikConfig}>
        {(formikProps) => (
          <Form>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Course Name
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field
                  type="text"
                  name="courseName"
                  className="form-control"
                  component={InputBox}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Course Key
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field
                  type="text"
                  name="courseKey"
                  className="form-control"
                  component={InputBox}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Course Title
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field
                  type="text"
                  name="courseTitle"
                  className="form-control"
                  component={RichTextEditor}
                  customConfig={titleConfig}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Course Description
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field
                  type="text"
                  name="courseDesc"
                  className="form-control"
                  component={RichTextEditor}
                  customConfig={DescConfig}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Language
              </label>
              <div className="col-10">
                {globals.courseLanguages.map((x) => (
                  <Field
                    key={x.value}
                    name="languageType"
                    value={x.value}
                    label={x.label}
                    setLabelLength={true}
                    component={(props) => (
                      <RadioButton
                        {...props}
                        onClick={(e) => {
                          setLanguageType(e.target.value);
                        }}
                      />
                    )}
                    disabled={!!courseId}
                  />
                ))}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Course Type
              </label>
              <div className="col-10" style={{paddingTop: '10px'}}>
                {globals.courseType.map((x) => (
                  <Field
                    key={x.value}
                    name="courseType"
                    value={x.value}
                    label={x.label}
                    setLabelLength={true}
                    component={RadioButton}
                    disable={!!courseId}
                  />
                ))}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Page Type
              </label>
              <div className="col-10" style={{paddingTop: '10px'}}>
                {globals.pageType.map((x) => (
                  <Field
                    key={x.value}
                    name="pageType"
                    value={x.value}
                    label={x.label}
                    setLabelLength={true}
                    component={(props) => (
                      <RadioButton
                        {...props}
                        onClick={(e) => {
                          setPageType(e.target.value);
                        }}
                      />
                    )}
                  />
                ))}
              </div>
            </div>
            {pageType === 'EVENT_PAGE' && (
              <>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right">
                    Course Date
                  </label>
                  <div className="col-10">
                    <Field
                      type="text"
                      name="courseDate"
                      className="form-control"
                      component={InputBox}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right">
                    Course Time
                  </label>
                  <div className="col-10">
                    <Field
                      type="text"
                      name="courseTime"
                      className="form-control"
                      component={InputBox}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right">
                    Course Location
                  </label>
                  <div className="col-10">
                    <Field
                      type="text"
                      name="courseLocation"
                      className="form-control"
                      component={InputBox}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right">
                    Desktop Banner
                  </label>
                  <div className="col-10">
                    <Field name="desktopBanner">
                      {(props) => (
                        <ImageField
                          {...props}
                          imageUrl={_get(formikProps.values, 'desktopBanner')}
                          imageWidth={2732}
                          imageHeight={1160}
                          displayWidth={683}
                          displayHeight={290}
                          imageFormat="jpg"
                          displayText={'PNG, JPG, ImageSize <= 300 KB'}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right">
                    Mobile Banner
                  </label>
                  <div className="col-10">
                    <Field name="mobileBanner">
                      {(props) => (
                        <ImageField
                          {...props}
                          imageUrl={_get(formikProps.values, 'mobileBanner')}
                          imageWidth={828}
                          imageHeight={788}
                          displayWidth={414}
                          displayHeight={394}
                          imageFormat="jpg"
                          displayText={'PNG, JPG, ImageSize <= 300 KB'}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right">
                    Tickets
                  </label>
                  <div className="col-10 col-form-label">
                    <div className="row kt-margin-t-5 kt-margin-b-10 kt-margin-l-0">
                      <Field
                        name="showTicket"
                        className="form-control"
                        component={CheckBox}
                        label={'Show Ticket'}
                      />
                    </div>
                    {formikProps.values.showTicket && (
                      <>
                        {formikProps.values.ticket.map((t, index) => (
                          <>
                            <label style={{paddingTop: index > 0 && '10px'}}>
                              {
                                ticketType.find(
                                  (t) =>
                                    t.type ===
                                    formikProps.values.ticket[index].type
                                ).label
                              }
                            </label>
                            <div className="row">
                              <label className="col-1 col-form-label">
                                Button Text
                              </label>
                              <div className="col-10">
                                <Field
                                  type="text"
                                  name={`ticket[${index}].buttonText`}
                                  className="form-control"
                                  component={InputBox}
                                />
                              </div>
                            </div>
                            <div className="row" style={{paddingTop: '10px'}}>
                              <label className="col-1 col-form-label">
                                Button Links
                              </label>
                              <div className="col-10">
                                <Field
                                  type="text"
                                  name={`ticket[${index}].buttonLink`}
                                  className="form-control"
                                  component={InputBox}
                                />
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{paddingTop: '10px', paddingLeft: '10px'}}
                            >
                              <Field
                                name={`ticket[${index}].isDynamicLinks`}
                                className="form-control"
                                component={CheckBox}
                                label={'Dynamic Links'}
                              />
                            </div>
                            {formikProps.values.ticket[index]
                              .isDynamicLinks && (
                              <FieldArray
                                name={`ticket[${index}].dynamicLinks`}
                              >
                                {(arrayHelp) => (
                                  <>
                                    <div
                                      className="form-group row"
                                      style={{marginBottom: '10px'}}
                                    >
                                      <div className="col-12">
                                        <div className="row">
                                          <div className="col-1" />
                                          <div className="col-11">
                                            <div className="row">
                                              <div className="col-3">
                                                utm_medium
                                              </div>
                                              <div className="col-9">Link</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {_get(
                                      formikProps.values,
                                      `ticket[${index}].dynamicLinks`,
                                      []
                                    ).map((s, i) => (
                                      <div
                                        className="form-group row"
                                        style={{marginBottom: '10px'}}
                                      >
                                        <div className="col-12">
                                          <div className="row">
                                            <div
                                              className="col-1"
                                              style={{textAlign: 'right'}}
                                            >
                                              {i + 1}
                                            </div>
                                            <div className="col-11">
                                              <div className="row">
                                                <div className="col-3">
                                                  <Field
                                                    name={`ticket[${index}].dynamicLinks[${i}].utm`}
                                                    className="form-control"
                                                    component={InputBox}
                                                  />
                                                </div>
                                                <div className="col-9">
                                                  <Field
                                                    name={`ticket[${index}].dynamicLinks[${i}].link`}
                                                    className="form-control"
                                                    component={InputBox}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    <div className="row kt-margin-t-20">
                                      <div className="col-1" />
                                      <div className="col-11">
                                        <div className="row">
                                          <div
                                            className="learn-button kt-padding-l-20 kt-padding-r-20 kt-margin-l-10"
                                            onClick={() => {
                                              arrayHelp.push({
                                                utm: '',
                                                link: '',
                                              });
                                            }}
                                          >
                                            Add
                                          </div>
                                          <div
                                            className="learn-button kt-padding-l-20 kt-padding-r-20 kt-margin-l-10"
                                            onClick={() => {
                                              if (
                                                formikProps.values.ticket[index]
                                                  .dynamicLinks.length > 1
                                              )
                                                arrayHelp.pop();
                                            }}
                                          >
                                            Del
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </FieldArray>
                            )}
                          </>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right">
                    Ticket Description
                  </label>
                  <div className="col-10">
                    <Field
                      type="text"
                      name="ticketDesc"
                      className="form-control"
                      component={RichTextEditor}
                      customConfig={ticketConfig}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Related course
              </label>
              <div className="col-1">
                <div
                  className="learn-button"
                  onClick={() => addCourse(formikProps.values)}
                >
                  Add
                </div>
                <div
                  className="learn-button"
                  onClick={() => delCourse(formikProps.values)}
                >
                  Del
                </div>
              </div>
              <div className="col-9">
                {course.attrs &&
                  Object.entries(course.attrs).map(([key, value]) => (
                    <>
                      <div style={{marginBottom: '10px'}}>
                        <Field
                          key={key}
                          name={`attrs[${key}].key`}
                          className="form-control"
                          component={(props) => (
                            <SelectBox {...props} options={courseTypesList} />
                          )}
                          placeholder="Please select a course key for button which can redirect to other course page"
                          maxLength="500"
                          rows="3"
                        />
                      </div>
                      <div style={{marginBottom: '10px'}}>
                        <Field
                          key={key}
                          name={`attrs[${key}].text`}
                          className="form-control"
                          placeholder="Please provide text for button which can redirect to other course page"
                          component={InputBox}
                          maxLength="500"
                          rows="3"
                        />
                      </div>
                    </>
                  ))}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Speakers Order
              </label>
              <div className="col-10">
                <table className="table table-striped table-bordered table-hover dataTable">
                  <thead>
                    <tr>
                      <th style={{width: '3%'}}>Speakers Order</th>
                      <th style={{width: '10%'}}>Speakers</th>
                      <th style={{width: '6%'}}>
                        <Trans i18nKey="p.b.p.listposts.displaysetting.action">
                          Action
                        </Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_get(formikProps.values, `speakerOrders`, []).map(
                      (post, i) => (
                        <FieldArray
                          id={`speakerOrders`}
                          name={`speakerOrders`}
                          validateOnChange
                          key={`speakerOrders-${i}`}
                        >
                          {(arrayHelpers) => (
                            <tr>
                              <td className="text-center">{i + 1}</td>
                              <td>
                                <Field name={`speakerOrders[${i}]`}>
                                  {(props) => (
                                    <BlogHeadingSelect
                                      options={speakers}
                                      value={_find(speakers, {
                                        value: _get(props, `field.value`),
                                      })}
                                      onChange={(opt) => {
                                        props.form.setFieldValue(
                                          props.field.name,
                                          opt ? opt.value : 0
                                        );
                                      }}
                                      {...props}
                                    />
                                  )}
                                </Field>
                              </td>
                              <td>
                                <div
                                  onClick={() => {
                                    arrayHelpers.push(0);
                                  }}
                                  className="learn-button learn-button--speakerbtn"
                                >
                                  add
                                </div>
                                <div
                                  onClick={() => {
                                    if (
                                      formikProps.values.speakerOrders.length >
                                      1
                                    )
                                      arrayHelpers.remove(i);
                                  }}
                                  className="learn-button learn-button--speakerbtn"
                                >
                                  Del
                                </div>
                                {i > 0 && (
                                  <img
                                    alt="move up"
                                    className="mr-2"
                                    onClick={() => arrayHelpers.swap(i - 1, i)}
                                    src={require('../../../../assets/images/up_arrow.png')}
                                  />
                                )}
                                {i <
                                  formikProps.values.speakerOrders.length -
                                    1 && (
                                  <img
                                    alt="move down"
                                    onClick={() => arrayHelpers.swap(i, i + 1)}
                                    src={require('../../../../assets/images/down_arrow.png')}
                                  />
                                )}
                              </td>
                            </tr>
                          )}
                        </FieldArray>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="text-center">
              <SimpleButtonGroup
                pageName={'courseAddEdit'}
                isUpdating={!!courseId}
                onCancelClick={() => navigate('/masterseries/list-courses')}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
