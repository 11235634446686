import React from 'react';
import {ErrorMessage} from 'formik';
import classnames from 'classnames';
import _get from 'lodash/get';

export default function InputBox({
  field,
  form: {touched, errors},
  errorMessage,
  onBlur,
  disabled=false,
  ...props
}) {
  return (
    <div>
      <input
        {...props}
        {...field}
        className={classnames({
          'form-control': true,
          'is-invalid': _get(touched, field.name) && _get(errors, field.name),
        })}
        onBlur={() => {
          if (onBlur) onBlur();
        }}
        disabled={disabled}
      />
      <span className="invalid-feedback">
        <ErrorMessage name={field.name}>
          {msg => errorMessage || msg}
        </ErrorMessage>
      </span>
    </div>
  );
}
