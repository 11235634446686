import React, {useMemo, useRef, useState} from 'react';
import {useFilters, usePagination, useSortBy, useTable} from 'react-table';
import {
  DateRangeFilter,
  DropdownFilter,
  DateRangeInputFilter,
} from './ManualDataFilters';
import classnames from 'classnames';
import Button from '../datatable/ActionButton';
import * as _ from 'lodash';
import Paginate from '../Paginate';
/**
 * ManualDataTable component displays a table with manual pagination, sorting, and filtering capabilities. It means all operations are through the API
 *
 * @param {boolean} isLoading - Flag indicating if data is currently loading.
 * @param {array} columns - (react table parameters) An array of objects representing the columns of the table.
 * @param {array} data - (react table parameters) An array of objects representing the rows of the table.
 * @param {number} totalSize - Total number of records available for data.
 * @param {object} initState - Initial state for the table component, including pageIndex ,pageSize, sortBy: {id: columnName, desc: true}, and filter.
 * @param {object} extParams - Extra parameters that can be passed to the component.(Not set in the columns)
 * @param {function} setProcessedParams - A function to set the processed parameters after filtering, sorting, and pagination.(set in useState Hook), and return object.
 * If set up DateRangeFilter in columns, the parameters will be converted from {$`columnName`From:1680308882000, $`columnName`To:1690619282000} and assuming that the API must accept these two parameters.
 *
 * Refer to \smb-admin\src\pages\blog\post\listposts\index.js
 */

export default function ManualDataTable({
  isLoading = false,
  columns,
  data,
  totalSize = 0,
  initState = {pageSize: 10, pageIndex: 0},
  extParams = {},
  setProcessedParams,
}) {
  const filterable = columns.findIndex((c) => c.Filter) !== -1;
  const [pageIndex, setPageIndex] = useState(initState.pageIndex);
  const processedColumns = columns.map((c) => ({
    ...c,
    filter: getFilterType(c),
  }));
  const refParams = useRef(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    setAllFilters,
    state: {pageSize, sortBy, filters},
  } = useTable(
    {
      columns: useMemo(() => processedColumns, [columns]),
      data,
      manualPagination: true,
      manualSorting: true,
      manualFilters: true,
      disableSortRemove: true,
      disableMultiSort: true,
      initialState: {
        ...initState,
        pageIndex: pageIndex || 1,
        pageSize: initState.pageSize || 10,
        sortBy: initState.sortBy
          ? !Array.isArray(initState.sortBy)
            ? [initState.sortBy]
            : initState.sortBy
          : [],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  refParams.current = useMemo(() => {
    const reducedFilters = filters.reduce((acc, filter) => {
      if (typeof filter.value === 'object') {
        const dateFrom = filter.id + 'From';
        acc[dateFrom] = filter.value['startDateFrom'];
        const dateTo = filter.id + 'To';
        acc[dateTo] = filter.value['startDateTo'];
      } else {
        acc[filter.id] = filter.value;
      }
      return acc;
    }, {});

    const reducedSort = sortBy.reduce((acc, sortBy) => {
      acc['sortBy'] = sortBy.id;
      acc['asc'] = !sortBy.desc;
      return acc;
    }, {});

    initState.sortBy = sortBy;

    let params = {
      ...initState,
      ...extParams,
      ...reducedSort,
      ...reducedFilters,
      pageSize,
      pageIndex: pageIndex + 1,
    };

    if (data.length === 0 && totalSize !== 0) {
      params = {
        ...params,
        pageIndex: 1,
      };
    }

    if (_.isEqual(params, refParams.current)) {
      return refParams.current;
    }

    if (data.length === 0 && totalSize !== 0) {
      setPageIndex(0);
    }

    setProcessedParams(params);

    return params;
  }, [initState, extParams, sortBy, filters, pageSize, pageIndex]);

  return (
    <>
      <div style={{overflowX: 'auto'}}>
        <table
          {...getTableProps()}
          className="table table-striped table-bordered table-hover dataTable"
        >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`head-row-${i}`}>
                {headerGroup.headers.map((column, i) => {
                  const {
                    getHeaderProps,
                    getSortByToggleProps,
                    disableSortBy,
                    isSorted,
                    isSortedDesc,
                    render,
                    headerClass,
                  } = column;
                  return column.disableSorting ? (
                    <th
                      className={headerClass || ''}
                      style={{textAlign: 'center'}}
                    >
                      {render('Header')}
                    </th>
                  ) : (
                    <th
                      key={`head-col-${i}`}
                      {...getHeaderProps(getSortByToggleProps())}
                      className={classnames(
                        sortingStatus(disableSortBy, isSorted, isSortedDesc),
                        headerClass || ''
                      )}
                      style={{textAlign: 'center'}}
                    >
                      {render('Header')}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {filterable && (
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={`filter-${i}`}>
                  {headerGroup.headers.map((column, ii) => {
                    const {
                      getHeaderProps,
                      canFilter,
                      id,
                      render,
                      Filter,
                    } = column;
                    return (
                      <th {...getHeaderProps()} key={`filter-col-${ii}`}>
                        {canFilter && Filter ? render('Filter') : null}
                        {id === 'actions' && (
                          <div style={{textAlign: 'center'}}>
                            <Button
                              type="reset"
                              onClick={() => {
                                setAllFilters([]);
                              }}
                            />
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}
          <tbody {...getTableBodyProps()}>
            {page.length === 0 && isLoading && (
              <Loading colSpan={columns.length} />
            )}
            {page.length === 0 && !isLoading && (
              <NoData colSpan={columns.length} />
            )}
            {page.length > 0 &&
              page.map((row, i) => {
                return (
                  prepareRow(row) || (
                    <tr {...row.getRowProps()} key={`tr-${i}`}>
                      {row.cells.map((cell, ii) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              className: cell.column.className || '',
                              style: cell.column.style || {textAlign: 'center'},
                            })}
                            key={`tr${i}-td${ii}`}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  )
                );
              })}
          </tbody>
        </table>
      </div>
      <Paginate
        grid={{
          pageIndex: pageIndex + 1,
          pages: Math.ceil(totalSize / pageSize),
          pageSize: pageSize,
          totalSize: totalSize,
        }}
        clickCallback={(e) => {
          setPageIndex(e.selected);
        }}
        selectCallback={setPageSize}
      />
    </>
  );
}

function sortingStatus(disable, isSorted, isSortedDesc) {
  if (disable) return '';
  if (!isSorted) return 'sorting';
  return isSortedDesc ? 'sorting_desc' : 'sorting_asc';
}

function getFilterType(col) {
  if (col.filter) return col.filter;
  switch (col.Filter) {
    case DropdownFilter:
      return 'dropdown';
    case DateRangeFilter:
      return 'dateRange';
    case DateRangeInputFilter:
      return 'dateRangeInput';
    default:
      return 'text';
  }
}

function NoData({colSpan, message}) {
  return (
    <tr>
      <td colSpan={colSpan} className="text-center">
        {/*<FormattedMessage {...message} />*/}
        No data
      </td>
    </tr>
  );
}

function Loading({colSpan}) {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div>Loading...</div>
      </td>
    </tr>
  );
}
