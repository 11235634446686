import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import InputBox from '../../../../components/formik/InputBox';
import SimpleButtonGroup from '../../../../components/formik/SimpleButtonGroup';
import {useNavigate, useLocation} from 'react-router';
import courseDao from '../../../../dao/courseDao';
import yup from '../../../../utils/yup';
import {toast} from 'react-toastify';
import Loader from '../../../../components/spinner/Loader';
import ImageField from '../../../../components/formik/ImageField';
import _get from 'lodash/get';

export default function AddOrEditSpeakers() {
  const navigate = useNavigate();
  const {search} = useLocation();
  const param = new URLSearchParams(search);
  const speakerId = param.get('speakerId');
  const [isEdit, setIsEdit] = useState(!!search);
  const [isLoading, setIsLoading] = useState(false);
  const [speaker, setSpeaker] = useState({});

  useEffect(() => {
    setIsLoading(true);
    if (!speakerId) {
      setIsLoading(false);
      return;
    }
    courseDao.querySpeaker(speakerId).then((r) => {
      setSpeaker(r);
      setIsLoading(false);
    });
  }, [speakerId]);

  const formikConfig = {
    validationSchema: yup.object().shape({
      speakerName: yup.string().max(200).required(),
      speakerPosition: yup.string().max(200).required(),
      brandName: yup.string().max(200).required(),
      speakerImageUrl: yup.string().required(),
      brandImageUrl: yup.string().required(),
    }),
    enableReinitialize: true,
    initialValues: {
      speakerName: '',
      speakerPosition: '',
      brandName: '',
      speakerImageUrl: '',
      brandImageUrl: '',
      ...speaker,
    },
    onSubmit: (values, {setSubmitting}) => {
      setSubmitting(true);
      if (isEdit) {
        courseDao
          .updateSpeaker({
            speaker: {...values},
            speakerImage: values.speakerImageUrl,
            brandImage: values.brandImageUrl,
          })
          .then((r) => {
            if (r.id) {
              toast.success('Update Successful');
            } else {
              toast.success('Update Failed');
            }
            navigate('/masterseries/list-speakers');
          });
      } else {
        courseDao
          .createSpeaker({
            speaker: {...values},
            speakerImage: values.speakerImageUrl,
            brandImage: values.brandImageUrl,
          })
          .then((r) => {
            if (r.id) {
              toast.success('Create Successful');
            } else {
              toast.success('Create Failed');
            }
            navigate('/masterseries/list-speakers');
          });
      }
    },
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Formik {...formikConfig}>
        {(formikProps) => (
          <Form>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Speaker Name
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field
                  type="text"
                  name="speakerName"
                  className="form-control"
                  component={InputBox}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Speaker Position
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field
                  name="speakerPosition"
                  className="form-control"
                  component={InputBox}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Brand Name
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field
                  name="brandName"
                  className="form-control"
                  component={InputBox}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Speaker Image
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field name="speakerImageUrl">
                  {(props) => (
                    <ImageField
                      {...props}
                      imageUrl={_get(formikProps.values, 'speakerImageUrl')}
                      imageWidth={216}
                      imageHeight={216}
                      displayWidth={216} //css
                      displayHeight={216} //css
                      imageFormat="jpg"
                      displayText={'PNG, JPG, ImageSize <= 300 KB'}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Speaker Logo
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field name="brandImageUrl">
                  {(props) => (
                    <ImageField
                      {...props}
                      imageUrl={_get(formikProps.values, 'brandImageUrl')}
                      displayWidth={616}
                      displayHeight={100}
                      imageFormat="png"
                      displayText={'PNG, ImageSize <= 300 KB'}
                      freeSize={{width: 616, height: 100}}
                      whiteBackground={false}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="text-center">
              <SimpleButtonGroup
                pageName={'speakerAddEdit'}
                isUpdating={!!speakerId}
                onCancelClick={() => navigate('/masterseries/list-speakers')}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
