import React from 'react';
import {Field} from 'formik';
import _get from 'lodash/get';
import FileInput from './fileInput';
import {toast} from 'react-toastify';

export default function FileBox({
  form: {setFieldValue, errors, values},
  field: {name},
  accept,
  maxSize,
  displayText,
  onFileChange = null,
  fileName,
  uploadButtonText,
  htmlId,
  videoWidth = null,
  videoHeight = null,
  ...props
}) {
  const handleChangeFile = (file) => {
    setFieldValue(name, '');
    if (file.size > maxSize) {
      toast.error('Please upload the video under 2MB.', {autoClose: 3000});
    } else {
      setFieldValue(name, file);
    }
    console.log('#file', file);
  };
  const videoOnFileChange = async (file) => {
    if (file.size > maxSize) {
      toast.error('檔案過大', {autoClose: 3000});
      return;
    }

    //perform video dimensions check
    const video = await document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.onloadedmetadata = async () => {
      const currVideoWidth = video.videoWidth;
      const currVideoHeight = video.videoHeight;
      if (videoWidth && !videoHeight && videoWidth !== currVideoWidth) {
        toast.error('Invalid Video Width');
        return;
      } else if (
        videoHeight &&
        !videoWidth &&
        videoHeight !== currVideoHeight
      ) {
        toast.error('Invalid Video Height');
        return;
      } else if (videoWidth && videoHeight) {
        if (currVideoWidth !== videoWidth || currVideoHeight !== videoHeight) {
          toast.error('Invalid Video Dimensions');
          return;
        }
      }
      setFieldValue(name, file);
    };
  };
  return (
    <div className={'col'}>
      <div className={'form-group row'}>
        <Field
          {...props}
          name={name}
          component={FileInput}
          accept={accept}
          errorMessage={_get(errors, name)}
          onFileChange={
            onFileChange
              ? onFileChange
              : !videoHeight && !videoWidth
              ? handleChangeFile
              : videoOnFileChange
          }
          displayText={displayText}
          uploadButtonText={uploadButtonText}
          fileName={fileName}
          htmlId={htmlId}
        />
      </div>
    </div>
  );
}
