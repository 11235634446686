import {get} from './utils';
import qs from 'query-string';

const blogDao = {
  buildQs: (params = {}) => '?' + qs.stringify(params),
  getStrapiBanner: (params) =>
    get(`/smb-admin/strapi/list-contents.action`, params),
  getStrapiBannerByVuid: (params) =>
    get(`/smb-admin/strapi/query-content-by-vuid.action`, params),
  queryContentByFormId: (params) =>
    get(`/smb-admin/strapi/query-content-by-form-id.action`, params),
  isUsedBy: (params) => get('/smb-admin/strapi/is-used-by.action', params),
};

export default blogDao;
