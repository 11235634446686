import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router';
import courseDao from '../../../../dao/courseDao';
import yup from '../../../../utils/yup';
import {toast} from 'react-toastify';
import {Field, FieldArray, Form, Formik} from 'formik';
import Select from 'react-select';
import InputBox from '../../../../components/formik/InputBox';
import SimpleButtonGroup from '../../../../components/formik/SimpleButtonGroup';
import Textarea from '../../../../components/formik/Textarea';
import _find from 'lodash/find';
import dayjs from 'dayjs';
import Loader from '../../../../components/spinner/Loader';
import ImageField from '../../../../components/formik/ImageField';
import _get from 'lodash/get';
import globals from '../../../../utils/config/globals';
import RadioButton from '../../../../components/formik/RadioButton';
import './index.css';
import RichTextEditor from '../../../../components/texteditor/RichTextEditor';
import _isEmpty from 'lodash/isEmpty';
import CheckBox from '../../../../components/formik/CheckBox';

const introButtons = [
  'bold',
  'strikethrough',
  'underline',
  'italic',
  '|',
  'fontsize',
  'brush',
  'paragraph',
];

const introConfig = {
  readonly: false,
  allowResizeY: false,
  height: '200px',
  buttons: introButtons,
  buttonsMD: introButtons,
  buttonsSM: introButtons,
  buttonsXS: introButtons,
};
export default function AddOrEditSession() {
  const navigate = useNavigate();
  const {search} = useLocation();
  const param = new URLSearchParams(search);
  const sessionId = param.get('sessionId');
  const [isEdit, setIsEdit] = useState(!!search);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpeakerLoading, setIsSpeakerLoading] = useState(false);
  const [session, setSession] = useState({});
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [speakers, setSpeakers] = useState([]);
  const [speakerIds, setSpeakerIds] = useState([]);
  const [courseType, setCourseType] = useState('VIRTUAL_EVENT');
  const [registerType, setRegisterType] = useState('register');
  const courseLanguages = [
    {value: 'enu', label: 'English', i18n: 'en'},
    {value: 'jpn', label: '日本語', i18n: 'jp'},
    {value: 'fra', label: 'Français', i18n: 'fr'},
  ];
  useEffect(() => {
    setIsLoading(true);
    if (!sessionId) {
      setIsLoading(false);
    }
    if (sessionId) {
      courseDao.querySession(sessionId).then((r) => {
        let tmp = {};
        if (!!Object.keys(r.learns).length) {
          Object.entries(r.learns).forEach((s, index) => {
            let num = index + 1;
            tmp[num] = s[1];
          });
        }
        setSession({
          ...r,
          learns: tmp,
          isDynamicLinks:
            r.attrs &&
            r.attrs['DYNAMIC_LINK'] &&
            !_isEmpty(JSON.parse(r.attrs['DYNAMIC_LINK']))
              ? JSON.parse(r.attrs['DYNAMIC_LINK']).isDynamicLinks
              : false,
          dynamicLinks:
            r.attrs &&
            r.attrs['DYNAMIC_LINK'] &&
            !_isEmpty(JSON.parse(r.attrs['DYNAMIC_LINK']))
              ? JSON.parse(r.attrs['DYNAMIC_LINK']).dynamicLinks
              : [{utm: '', link: ''}],
        });
        setSpeakerIds(r.sessionSpeakers.map((rr) => rr.speaker.id));
        setCourseType(r.course.courseType);
        courseDao.listCourses({courseType: r.course.courseType}).then((rr) => {
          setCourses(rr.map((rrr) => ({value: rrr.id, label: rrr.courseName})));
          setSelectedCourse(_find(rr, {id: r.course.id}));
          setIsLoading(false);
        });
      });
    } else {
      setSession({...session, learns: {1: ''}});
    }
  }, []);

  useEffect(() => {
    if (!isEdit) {
      courseDao.listCourses({courseType: courseType}).then((rr) => {
        setCourses(rr.map((rrr) => ({value: rrr.id, label: rrr.courseName})));
        setIsLoading(false);
      });
    }
  }, [courseType]);

  useEffect(() => {
    setIsSpeakerLoading(true);
    courseDao.listSpeakers().then((r) => {
      setSpeakers(r.map((rr) => ({value: rr.id, label: rr.speakerName})));
      setIsSpeakerLoading(false);
    });
  }, []);

  useEffect(() => {
    setRegisterType(session && session.formActionUrl ? 'pardot' : 'register');
  }, [session.registerPageUrl]);

  function getCurrentDate(t) {
    const newTime = new Date(t);
    const difference = isEdit
      ? new Date().getTimezoneOffset() / 60
      : new Date().getTimezoneOffset() / 60;
    newTime.setTime(newTime.setHours(newTime.getHours() - difference));
    return newTime;
  }

  function getNowDate(t) {
    const newTime = new Date(t);
    const difference = new Date().getTimezoneOffset() / 60;
    newTime.setTime(newTime.setHours(newTime.getHours() + difference));
    return newTime;
  }

  const addLearn = (val) => {
    let tmp = {...val.session.learns};
    tmp[Object.keys(tmp).length + 1] = '';
    setSession({
      ...val.session,
      sessionStartDate: getCurrentDate(val.session.sessionStartDate),
      sessionEndDate: getCurrentDate(val.session.sessionEndDate),
      learns: tmp,
    });
  };
  const delLearn = (val) => {
    let tmp = {...val.session.learns};
    if (Object.keys(tmp).length <= 1) return;
    let keys = Object.keys(tmp);
    delete tmp[keys[keys.length - 1]];
    setSession({
      ...val.session,
      sessionStartDate: getCurrentDate(val.session.sessionStartDate),
      sessionEndDate: getCurrentDate(val.session.sessionEndDate),
      learns: tmp,
    });
  };

  const formikConfig = {
    validationSchema: yup.object().shape({
      session: yup.object().shape({
        sessionTitle: yup.string().max(128),
        sessionSubTitle: yup.string().required(),
        sessionStartDate: yup.date(),
        sessionEndDate: yup.date(),
        sessionImageUrl: yup.string(),
        languageType: yup.string().required(),
        sessionNumber: yup.number().min(0).required(),
        registerPageUrl: yup.string().max(500),
        formActionUrl: yup.string().max(500),
        actionTag: yup.string().max(200),
        sessionSpeakers: yup.array().min(0),
      }),
    }),
    enableReinitialize: true,
    initialValues: {
      session: {
        ...session,
        languageType: session.languageType || 'enu',
        sessionNumber: session.sessionNumber || 0,
        sessionStartDate: session.sessionStartDate
          ? dayjs(getNowDate(session.sessionStartDate)).format(
              'YYYY-MM-DDTHH:mm'
            )
          : dayjs(new Date()).add(1, 'day').format('YYYY-MM-DDTHH:mm'),
        sessionEndDate: session.sessionEndDate
          ? dayjs(getNowDate(session.sessionEndDate)).format('YYYY-MM-DDTHH:mm')
          : dayjs(new Date()).add(1, 'day').format('YYYY-MM-DDTHH:mm'),
        videoUrl: session.videoUrl ? session.videoUrl : '',
        videoDuration: session.videoDuration ? session.videoDuration : 0,
        registerPageUrl: !session.registerPageUrl
          ? ''
          : session.registerPageUrl,
        formActionUrl: !session.formActionUrl ? '' : session.formActionUrl,
        actionTag: !session.actionTag ? '' : session.actionTag,
        course: {
          courseType: courseType,
        },
        learns: session.learns ? session.learns : {1: ''},
        registerType: session
          ? session.formActionUrl
            ? 'pardot'
            : 'register'
          : 'register',
        isDynamicLinks: session.isDynamicLinks ? session.isDynamicLinks : false,
        dynamicLinks:
          session.dynamicLinks && session.dynamicLinks.length > 0
            ? session.dynamicLinks
            : [{utm: '', link: ''}],
      },
    },
    onSubmit: (values, {setSubmitting}) => {
      setSubmitting(true);
      const params = {
        ...values,
        sessionImage: values.session.sessionImageUrl,
        speakerIds: speakerIds,
        courseId: selectedCourse.value,
        session: {
          ...values.session,
          sessionStartDate: getCurrentDate(values.session.sessionStartDate),
          sessionEndDate: getCurrentDate(values.session.sessionEndDate),
          formActionUrl:
            values.session.registerType !== 'pardot'
              ? ''
              : values.session.formActionUrl,
          actionTag:
            values.session.registerType !== 'pardot'
              ? ''
              : values.session.actionTag,
          attrs: {
            DYNAMIC_LINK: JSON.stringify({
              isDynamicLinks: values.session.isDynamicLinks,
              dynamicLinks: values.session.dynamicLinks,
            }),
          },
        },
      };
      if (isEdit) {
        delete params.session.registerType;
        delete params.session.course;
        courseDao.updateSession(params).then((r) => {
          if (r.id) {
            toast.success('Update Successful');
          } else {
            toast.success('Update Failed');
          }
          navigate('/masterseries/list-sessions');
        });
      } else {
        if (!selectedCourse.value) {
          toast.warn('Please select belong to course.');
          setSubmitting(false);
          return;
        }
        params['id'] = values.session.id;
        delete params.session.registerType;
        delete params.session.course;
        courseDao
          .createSession(params)
          .then((r) => {
            if (r.id) {
              toast.success('Create Successful');
            } else {
              toast.success('Create Failed');
            }
            navigate('/masterseries/list-sessions');
          })
          .catch((error) => {
            toast.error('Create Failed');
          });
      }
    },
  };

  if (isLoading || isSpeakerLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Formik {...formikConfig}>
        {(formikProps) => (
          <Form>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Course type
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div
                className="col-10"
                style={{display: 'flex', alignItems: 'end'}}
              >
                {globals.courseType.map((x) => (
                  <Field
                    key={x.value}
                    name="session.course.courseType"
                    value={x.value}
                    label={x.label}
                    setLabelLength={true}
                    component={RadioButton}
                    disabled={sessionId}
                    onClick={() => {
                      setCourseType(x.value);
                      setRegisterType(null);
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Belong to Course
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Select
                  options={courses}
                  value={_find(courses, {
                    value: selectedCourse.id,
                  })}
                  onChange={(course) => setSelectedCourse(course)}
                  isDisabled={!!sessionId}
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: '100%',
                    }),
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Session Title
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field
                  type="text"
                  name="session.sessionTitle"
                  className="form-control"
                  component={InputBox}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Session Intro
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field
                  type="text"
                  name="session.sessionSubTitle"
                  className="form-control"
                  component={RichTextEditor}
                  customConfig={introConfig}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Session No.
              </label>
              <div className="col-10">
                <Field
                  type="number"
                  name="session.sessionNumber"
                  className="form-control"
                  component={InputBox}
                />
              </div>
            </div>
            <div>
              <div className="form-group row">
                <label className="col-2 col-form-label text-right">
                  Register Type
                </label>
                <div
                  className="col-10"
                  style={{display: 'flex', alignItems: 'end'}}
                >
                  {globals.registerType.map((x) => (
                    <Field
                      key={x.value}
                      name="session.registerType"
                      value={x.value}
                      label={x.label}
                      setLabelLength={true}
                      component={RadioButton}
                      onClick={() => {
                        setRegisterType(x.value);
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-2 col-form-label text-right">
                  {registerType === 'pardot'
                    ? 'Pardot Form URL'
                    : 'RegisterPage Url'}
                  <span className="required" style={{color: 'red'}}>
                    *
                  </span>
                </label>
                <div className="col-10">
                  <Field
                    name="session.registerPageUrl"
                    className="form-control"
                    component={InputBox}
                    required={courseType !== 'EDUCATION'}
                  />
                  <div className="row" style={{paddingTop: '20px'}}>
                    <div className="col-12">
                      <Field
                        name="session.isDynamicLinks"
                        className="form-control"
                        component={CheckBox}
                        label={'Dynamic Links'}
                      />
                    </div>
                  </div>
                  {formikProps.values.session.isDynamicLinks && (
                    <FieldArray name={`session.dynamicLinks`}>
                      {(arrayHelp) => (
                        <>
                          <div
                            className="form-group row"
                            style={{marginBottom: '10px'}}
                          >
                            <div className="col-12">
                              <div className="row">
                                <div className="col-1" />
                                <div className="col-11">
                                  <div className="row">
                                    <div className="col-3">utm_medium</div>
                                    <div className="col-9">Link</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {_get(
                            formikProps.values,
                            `session.dynamicLinks`,
                            []
                          ).map((s, i) => (
                            <div
                              className="form-group row"
                              style={{marginBottom: '10px'}}
                              key={`session-dynamicLinks${i}`}
                            >
                              <div className="col-12">
                                <div className="row">
                                  <div
                                    className="col-1"
                                    style={{textAlign: 'right'}}
                                  >
                                    {i + 1}
                                  </div>
                                  <div className="col-11">
                                    <div className="row">
                                      <div className="col-3">
                                        <Field
                                          name={`session.dynamicLinks[${i}].utm`}
                                          className="form-control"
                                          component={InputBox}
                                        />
                                      </div>
                                      <div className="col-9">
                                        <Field
                                          name={`session.dynamicLinks[${i}].link`}
                                          className="form-control"
                                          component={InputBox}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="row kt-margin-t-20">
                            <div className="col-1" />
                            <div className="col-11">
                              <div className="row">
                                <div
                                  className="learn-button kt-padding-l-20 kt-padding-r-20 kt-margin-l-10"
                                  onClick={() => {
                                    arrayHelp.push({
                                      utm: '',
                                      link: '',
                                    });
                                  }}
                                >
                                  Add
                                </div>
                                <div
                                  className="learn-button kt-padding-l-20 kt-padding-r-20 kt-margin-l-10"
                                  onClick={() => {
                                    if (
                                      formikProps.values.session.dynamicLinks
                                        .length > 1
                                    )
                                      arrayHelp.pop();
                                  }}
                                >
                                  Del
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </FieldArray>
                  )}
                </div>
              </div>
            </div>
            {registerType === 'pardot' && (
              <div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right">
                    Pardot Form Handler URL
                    <span className="required" style={{color: 'red'}}>
                      *
                    </span>
                  </label>
                  <div className="col-10">
                    <Field
                      name="session.formActionUrl"
                      className="form-control"
                      component={InputBox}
                      required={registerType === 'pardot'}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-2 col-form-label text-right">
                    Action Tag
                  </label>
                  <div className="col-10">
                    <Field
                      name="session.actionTag"
                      className="form-control"
                      component={InputBox}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Start Date
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field
                  type="datetime-local"
                  name="session.sessionStartDate"
                  className="form-control"
                  component={InputBox}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                End Date
              </label>
              <div className="col-10">
                <Field
                  type="datetime-local"
                  name="session.sessionEndDate"
                  className="form-control"
                  component={InputBox}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Session Cover Image
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div className="col-10">
                <Field name="session.sessionImageUrl">
                  {(props) => (
                    <ImageField
                      {...props}
                      imageUrl={_get(
                        formikProps.values,
                        'session.sessionImageUrl'
                      )}
                      imageWidth={450}
                      imageHeight={250}
                      displayWidth={450} //css
                      displayHeight={250} //css
                      imageFormat="jpg"
                      displayText={'PNG, JPG, ImageSize <= 300 KB'}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Language
                <span className="required" style={{color: 'red'}}>
                  *
                </span>
              </label>
              <div
                className="col-10"
                style={{display: 'flex', alignItems: 'end'}}
              >
                {courseLanguages.map((x) => (
                  <Field
                    key={x.value}
                    name="session.languageType"
                    value={x.value}
                    label={x.label}
                    setLabelLength={true}
                    component={RadioButton}
                  />
                ))}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Video Replay URL
              </label>
              <div className="col-10">
                <Field
                  name="session.videoUrl"
                  className="form-control"
                  component={InputBox}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Session Learns
              </label>
              <div className="col-1">
                <div
                  className="learn-button"
                  onClick={() => addLearn(formikProps.values)}
                >
                  Add
                </div>
                <div
                  className="learn-button"
                  onClick={() => delLearn(formikProps.values)}
                >
                  Del
                </div>
              </div>
              <div className="col-9">
                {session.learns &&
                  Object.entries(session.learns).map(([key, value]) => (
                    <div style={{marginBottom: '10px'}} key={key}>
                      <Field
                        name={`session.learns['${key}']`}
                        className="form-control"
                        component={Textarea}
                        maxLength="500"
                        rows="3"
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-2 col-form-label text-right">
                Speakers
              </label>
              <div className="col-10">
                <Select
                  name="session.sessionSpeakers"
                  defaultValue={speakers.filter((s) =>
                    speakerIds.includes(s.value)
                  )}
                  options={speakers}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  onChange={(e) => setSpeakerIds(e.map((ee) => ee.value))}
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: '100%',
                    }),
                  }}
                />
              </div>
            </div>
            <div className="text-center">
              <SimpleButtonGroup
                pageName={'sessionAddEdit'}
                isUpdating={!!sessionId}
                // onCancelClick={() =>
                //   history.push('/masterseries/list-sessions')
                // }
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
