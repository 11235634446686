import React from 'react';
import _get from 'lodash/get';
import classnames from 'classnames';
import {ErrorMessage} from 'formik';

export default function CheckBox({field, form, id, label}) {
  const isInvalid =
    _get(form.touched, field.name) && _get(form.errors, field.name);

  return (
    <label className="kt-checkbox">
      <input type="checkbox" {...field} id={id} checked={field.value} />
      {label}
      <span />
      {isInvalid && (
        <div
          className={classnames({
            'invalid-feedback': true,
            'd-block': isInvalid,
          })}
        >
          <ErrorMessage name={field.name} />
        </div>
      )}
    </label>
  );
}
