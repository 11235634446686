import React, {useEffect, useRef, useMemo} from 'react';
import JoditEditor from 'jodit-react';
import 'jodit/build/jodit.min.css';
import './fieldset.css';

export default function RichTextEditor({
  lang = 'enu',
  form: {setFieldValue},
  field: {name, value},
  insertHtml,
  customConfig = {
    direction: lang === 'ara' && 'rtl',
    editorCssClass: lang === 'ara' && 'text-right',
    fullsize: false,
    readonly: false,
    allowResizeY: false,
    height: '500px',
    buttons: [
      'source',
      '|',
      'bold',
      'strikethrough',
      'underline',
      'italic',
      '|',
      'ul',
      'ol',
      '|',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'table',
      'link',
      '|',
      'align',
    ],
    buttonsMD: [
      'source',
      '|',
      'bold',
      'strikethrough',
      'underline',
      'italic',
      '|',
      'ul',
      'ol',
      '|',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'table',
      'link',
      '|',
      'align',
    ],
    buttonsSM: [
      'source',
      '|',
      'bold',
      'strikethrough',
      'underline',
      'italic',
      '|',
      'ul',
      'ol',
      '|',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'table',
      'link',
      '|',
      'align',
    ],
    buttonsXS: [
      'source',
      '|',
      'bold',
      'strikethrough',
      'underline',
      'italic',
      '|',
      'ul',
      'ol',
      '|',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'table',
      'link',
      '|',
      'align',
    ],
    image: {
      openOnDblClick: true,
      editSrc: true,
      useImageEditor: true,
      editTitle: true,
      editAlt: true,
      editLink: true,
      editSize: true,
      editBorderRadius: true,
      editMargins: true,
      editClass: true,
      editStyle: true,
      editId: true,
      editAlign: true,
      showPreview: true,
      selectImageAfterClose: true,
    },
    url: '',
    draggableTags: ['img'],
    uploader: {
      url: '',
      insertImageAsBase64URI: true,
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
    },
  },
  updatePostContent,
  setInsertHTML,
}) {
  const textArea = useRef(null);
  const element = textArea.current;
  useEffect(() => {
    if (element && insertHtml) {
      element.component.selection.insertHTML(`${insertHtml}`);
      setInsertHTML('');
    }
  }, [element, insertHtml]);

  useEffect(() => {
    if (element && updatePostContent) {
      element.component.value = updatePostContent;
    }
  }, [updatePostContent]);

  useEffect(() => {
    if (
      (element && element.component && !element.component.value) ||
      (element && element.component && !value)
    ) {
      element.component.value = value;
    }
  }, [value]);

  return useMemo(
    () => (
      <JoditEditor
        value={value}
        ref={textArea}
        config={customConfig}
        name={name}
        onChange={(val) => {
          setFieldValue(name, val);
        }}
      />
    ),
    [updatePostContent, lang]
  );
}
