import {get, listAll, post, postWithoutResponseHandle} from './utils';
import qs from 'query-string';

const blogDao = {
  buildQs: (params = {}) => '?' + qs.stringify(params),
  author: {
    create: (params) =>
      post('/smb-admin/blog/author/create-author.action', params),
    update: (params) =>
      post('/smb-admin/blog/author/update-author.action', params),
    remove: (authorId) =>
      postWithoutResponseHandle('/smb-admin/blog/author/delete-author.action', {
        authorId,
      }),
    query: (authorId) =>
      post('/smb-admin/blog/author/query-author.action', {authorId}),
    list: (params) => get('/smb-admin/blog/author/list-authors.action', params),
    listAll: (params) => listAll(blogDao.author.list, params),
  },
  keyword: {
    create: (keyword) =>
      post('/smb-admin/blog/keyword/create-keyword.action', {keyword}),
    update: (keyword) =>
      post('/smb-admin/blog/keyword/update-keyword.action', {keyword}),
    remove: (keywordId) =>
      postWithoutResponseHandle(
        '/smb-admin/blog/keyword/delete-keyword.action',
        {keywordId}
      ),
    query: (keywordId) =>
      post('/smb-admin/blog/keyword/query-keyword.action', {keywordId}),
    list: (params) =>
      get('/smb-admin/blog/keyword/list-keywords.action', params),
    listAll: (params) => listAll(blogDao.keyword.list, params),
  },
  post: {
    create: (params) =>
      post('/smb-admin/blog/post/create-post.action', params).catch((e) => {
        return e === 'Unauthorized'
          ? {
              status: 'Unauthorized',
            }
          : {
              status: 'failed',
            };
      }),
    update: (params) =>
      post('/smb-admin/blog/post/update-post.action', params).catch((e) => {
        return e === 'Unauthorized'
          ? {
              status: 'Unauthorized',
            }
          : {
              status: 'failed',
            };
      }),
    remove: (postId) =>
      post('/smb-admin/blog/post/delete-post.action', {postId}),
    query: (postId) => post('/smb-admin/blog/post/query-post.action', {postId}),
    queryPostByPostKey: (postKey) =>
      post('/smb-admin/blog/post/query-posts-by-post-key.action', {postKey}),
    list: (params, abortSignal) =>
      get('/smb-admin/blog/post/list-posts.action', params, abortSignal),
    listAll: (params, abortSignal) =>
      listAll(blogDao.post.list, params, 100, abortSignal),
    listByParams: (params) =>
      get('/smb-admin/blog/post/list-posts-by-page.action', params),
    copy: (params) => post('/smb-admin/blog/post/copy-post.action', params),
    submit: (params) => post('/smb-admin/blog/post/submit-post.action', params),
    reSubmit: (params) =>
      post('/smb-admin/blog/post/continue-to-review-post.action', params),
    cancel: (postId) =>
      post('/smb-admin/blog/post/cancel-post.action', {postId}),
    publish: (postId) =>
      post('/smb-admin/blog/post/publish-post.action', {postId}),
    takeDown: (postId) =>
      post('/smb-admin/blog/post/take-down-post.action', {postId}),
    queryDisplay: () => get('/smb-admin/blog/post/query-post-display.action'),
    updateDisplay: (params) =>
      post('/smb-admin/blog/post/update-post-display.action', params),
    queryPostHeadings: (
      params = {languageType: 'enu', focus: 'BF_BUSINESS_FOCUS'}
    ) => post('/smb-admin/blog/post/query-post-headings.action', params),
    queryAllPosts: (languages) =>
      post('/smb-admin/blog/post/query-all-posts.action', {languages}),
    updatePostHeadings: (params) =>
      post('/smb-admin/blog/post/update-post-headings.action', params),
    updatePostRedirectKey: (params) =>
      post('/smb-admin/blog/post/update-post-fields.action', params),
    listMappingKey: (params) =>
      get('/smb-admin/blog/post/list-mapping-keys.action', params),
    listByHandleAndLanguage: (params) =>
      get(
        '/smb-admin/blog/post/list-post-by-handle-and-language.action',
        params
      ),
  },
  checkDeadLink: (params) =>
    post('/smb-admin/blog/post/check-broken-link.action', params),
  logDeadLinks: (params) =>
    post('/smb-admin/blog/post/log-dead-links.action', params),
  listDeadLinkWhitelists: (params) =>
    get('/smb-admin/blog/post/list-dead-link-whitelists.action', params),
  createDeadLinkWhitelist: (params) =>
    post('/smb-admin/blog/post/create-dead-link-whitelist.action', params),
  deleteDeadLinkWhitelist: (id) =>
    get('/smb-admin/blog/post/delete-dead-link-whitelist.action', {id}),
};

export default blogDao;
