import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {Trans} from 'react-i18next';
import ModalContentRow from './ModalContentRow';
import dayjs from 'dayjs';

export default function AuthorModal({data = {}, isOpen, onClose}) {
  return (
    <Modal size="md" show={isOpen} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          <Trans i18nKey="p.b.p.listposts.author.modal.title">Author</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isOpen && (
          <div className="py-3 px-4">
            {getContentRows(data).map(row => (
              <ModalContentRow
                labelClassName="col-4"
                contentClassName="col-8"
                {...row}
              />
            ))}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

function getContentRows(author = {}) {
  return [
    {
      label: (
        <Trans i18nKey="p.b.p.listposts.authormodal.display.name">
          Display Name
        </Trans>
      ),
      content: author.displayName,
    },
    {
      label: (
          <Trans i18nKey="p.b.p.listposts.authormodal.author.type">
            Author Type
          </Trans>
      ),
      content: author.authorType,
    },
    {
      label: (
        <Trans i18nKey="p.b.p.listposts.authormodal.company.name">
          Company Name
        </Trans>
      ),
      content: author.companyName,
    },
    {
      label: (
        <Trans i18nKey="p.b.p.listposts.authormodal.position">Position</Trans>
      ),
      content: author.position,
    },
    {
      label: <Trans i18nKey="p.b.p.listposts.authormodal.image">Image</Trans>,
      content: (
        <img alt="author" src={author.imageUrl} style={{width: '150px'}} />
      ),
    },
    {
      label: (
        <Trans i18nKey="p.b.p.listposts.authormodal.short.desc">
          Short Description
        </Trans>
      ),
      content: author.shortDesc,
    },
    {
      label: (
        <Trans i18nKey="p.b.p.listposts.authormodal.linkedin.url">
          LinkedIn URL
        </Trans>
      ),
      content: author.linkedinUrl && (
        <a href={author.linkedinUrl} target="_blank" rel="noreferrer noopener">
          {author.linkedinUrl}
        </a>
      ),
    },
    {
      label: (
        <Trans i18nKey="p.b.p.listposts.authormodal.creation">Creation</Trans>
      ),
      content: dayjs(author.createdTime).format('YYYY-MM-DD HH:MM'),
    },
    {
      label: (
        <Trans i18nKey="p.b.p.listposts.authormodal.modification">
          Modification
        </Trans>
      ),
      content: dayjs(author.lastModified).format('YYYY-MM-DD HH:MM'),
    },
  ];
}
