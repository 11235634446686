import React from 'react';
import {Trans} from 'react-i18next';
import AsyncListReports from './listreports/async';
import AsyncAddOrEditReport from './addoreditreport/async';
import {Navigate} from 'react-router-dom';

const parent = {
  title: <Trans i18nKey="p.s.reports.listreports.title">Reports</Trans>,
  path: '/reports',
};
export default [
  {
    path: '/reports/list-reports',
    element: <AsyncListReports />,
    handle: {
      title: <Trans i18nKey="p.s.reports.listreports.title">Reports</Trans>,
      subtitle: <Trans i18nKey="p.s.reports.listreports.title">Reports</Trans>,
      breadcrumbs: [parent],
    },
  },
  {
    path: '/reports/add-report',
    element: <AsyncAddOrEditReport />,
    handle: {
      title: (
        <Trans i18nKey="p.s.reports.addoreditreport.add.title">
          Add Report
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.s.reports.addoreditreport.add.title">
          Add Report
        </Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/reports/edit-report',
    element: <AsyncAddOrEditReport />,
    handle: {
      title: (
        <Trans i18nKey="p.s.reports.addoreditreport.edit.title">
          Edit Report
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.s.reports.addoreditreport.edit.title">
          Edit Report
        </Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/reports',
    element: <Navigate to="/reports/list-reports" />,
  },
];
