import React, {useState, useRef, useEffect} from 'react';
import {Trans} from 'react-i18next';
import {toast} from 'react-toastify';
import dayjs from 'dayjs';
import {useQuery} from '../../../../components/hooks/useQuery';
import ManualDataTable from '../../../../components/manualdatatable/ManualDataTable';
import ActionButton from '../../../../components/datatable/ActionButton';
import {TextFilter} from '../../../../components/manualdatatable/ManualDataFilters';
import AddOrEditWhitelist from '../addoreditwhitelist';
import dao from '../../../../dao/blogDao';
import _get from 'lodash/get';
import adminUtils from '../../../../utils/adminUtils';

export default function ListWhitelist() {
  const [processedParams, setProcessedParams] = useState(null);
  let callPaginationAPI = useRef(null);
  const {data, isLoading, refetch} = useQuery({
    queryFunc: (params) => dao.listDeadLinkWhitelists(params),
    initData: {results: [], totalSize: 0},
    isInitEnabled: false,
  });

  const columns = [
    {
      Header: <Trans i18nKey="p.b.p.list.domain">Domain</Trans>,
      accessor: 'domain',
      style: {width: '25%'},
      disableSorting: true,
      Filter: TextFilter,
    },
    {
      Header: <Trans i18nKey="p.b.p.list.created">Creation</Trans>,
      accessor: 'createdTime',
      Cell: ({cell: {value}}) => dayjs(value).format('MM/DD/YYYY'),
      style: {width: '25%'},
      disableSortBy: true,
    },
    {
      Header: <Trans i18nKey="p.b.p.list.modified">Modification</Trans>,
      accessor: 'lastModified',
      Cell: ({cell: {value}}) => dayjs(value).format('MM/DD/YYYY'),
      style: {width: '25%'},
      disableSortBy: true,
    },
    {
      Header: <Trans i18nKey="p.b.p.list.actions">Actions</Trans>,
      accessor: 'id',
      id: 'actions',
      disableSortBy: true,
      style: {width: '25%'},
      Cell: ({cell: {value}}) => (
        <>
          <ActionButton
            type="delete"
            id={value}
            onClick={() => {
              try {
                dao.deleteDeadLinkWhitelist(value).then((res) => {
                  if (_get(res, 'status', null) !== 'success') {
                    return toast.error('Failed');
                  } else {
                    refetch(processedParams);
                    toast.success('Delete Successfully');
                  }
                });
              } catch (e) {
                toast.error('Failed');
              }
            }}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!processedParams) {
      return;
    }
    callPaginationAPI.current = adminUtils.clearPrevAPI(
      callPaginationAPI.current,
      () => refetch(processedParams),
      500
    );
  }, [processedParams]);

  return (
    <div>
      <AddOrEditWhitelist
        listAPI={(processedParams) => refetch(processedParams)}
      />
      <ManualDataTable
        loading={isLoading}
        columns={columns}
        data={data.results}
        totalSize={data.totalSize}
        initState={{
          pageSize: 10,
          pageIndex: 0,
        }}
        setProcessedParams={setProcessedParams}
      />
    </div>
  );
}
