import React from 'react';
import utils from '../../../utils';
import _get from 'lodash/get';

export default function Status({postStatus}) {
  const statusConfig = utils.post.getStatusConfig(postStatus);
  return (
    <span style={{color: _get(statusConfig, 'color', 'inherit')}}>
      {_get(statusConfig, 'label')}
    </span>
  );
}
