import React from 'react';
import {Trans} from 'react-i18next';
import {Navigate} from 'react-router-dom';
import AsyncListCustomers from './customers/listcustomers/async';
import AsyncListSubscriptions from './subscriptions/listsubscriptions/async';
import AsyncTechnicalSupport from './technicalsupport/async';

const parent = {
  title: (
    <Trans i18nKey="p.onlineservice.listcustomers.title">Online Service</Trans>
  ),
  path: '/onlineservice',
};
const customerParent = {
  title: (
    <Trans i18nKey="p.onlineservice.listcustomers.customers.title">
      Customers
    </Trans>
  ),
  path: '/onlineservice/list-customers',
};
const subscriptionParent = {
  title: (
    <Trans i18nKey="p.onlineservice.listsubscriptions.title">
      Subscriptions
    </Trans>
  ),
  path: '/onlineservice/list-subscriptions',
};

export default [
  {
    path: '/onlineservice/list-customers',
    element: <AsyncListCustomers />,
    handle: {
      title: (
        <Trans i18nKey="p.onlineservice.listcustomers.customers.title">
          Customers
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.onlineservice.listcustomers.customers.title">
          Customers
        </Trans>
      ),
      breadcrumbs: [parent, customerParent],
    },
  },
  {
    path: '/onlineservice/list-subscriptions',
    element: <AsyncListSubscriptions />,
    handle: {
      title: (
        <Trans i18nKey="p.onlineservice.listsubscriptions.title">
          Subscriptions
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.onlineservice.listsubscriptions.title">
          Subscriptions
        </Trans>
      ),
      breadcrumbs: [parent, subscriptionParent],
    },
  },
  {
    path: '/onlineservice/technical-support',
    element: <AsyncTechnicalSupport />,
    handle: {
      title: (
        <Trans i18nKey="p.onlineservice.listtechnicalsupport.title">
          Technical Support
        </Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/onlineservice',
    element: <Navigate to="/onlineservice/list-customers" />,
  },
];
