import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import '../../assets/styles/components/imageeditor.css';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

const tryRemoveUrlParameter = (img) => {
  const isUrl = typeof img === 'string' && img.startsWith('http');
  const idx = isUrl ? img.indexOf('?p=') : -1;
  return idx < 0 ? img : img.substring(0, idx);
};

export default function ImageEditor({
  imageUrl = undefined,
  imageFile = undefined,
  enableScale = false,
  defaultScale = 1,
  onDataUrlUpdate = () => {},
  defaultPosition = {x: 0.5, y: 0.5},
  onImageClear = () => {},
  imageWidth = 400,
  imageHeight = 300,
  displayWidth,
  displayHeight,
  imageFormat = 'png',
  whiteBackground = true,
  displayText,
  freeSize = false,
  widthText,
  heightText,
  imagePlaceholder = 'Drop an image here or click to upload',
}) {
  const {t} = useTranslation();
  const [image, setImage] = useState('');
  const [position, setPosition] = useState(defaultPosition);
  const [scale, setScale] = useState(defaultScale);
  const [dragging, setDragging] = useState(false);
  const [freeImageSize, setFreeImageSize] = useState({width: 637, height: 100});
  const editorRef = useRef(null);
  const maxWidth = displayWidth ? displayWidth : imageWidth;
  const maxHeight = displayHeight ? displayHeight : imageHeight;
  const addWhiteBackground = (whiteBackground, canvas) => {
    const destCanvas = document.createElement('canvas');
    destCanvas.width = canvas.width;
    destCanvas.height = canvas.height;
    const destCtx = destCanvas.getContext('2d');
    destCtx.fillStyle = whiteBackground ? '#FFFFFF' : 'rgba(255, 255, 255, 0)';
    destCtx.fillRect(0, 0, canvas.width, canvas.height);
    destCtx.drawImage(canvas, 0, 0);
    return destCanvas;
  };

  useEffect(() => {
    if (freeSize && !!image) {
      const img = new Image();
      img.src = image;
      img.onload = function () {
        setFreeImageSize({width: this.width, height: this.height});
        // alert(this.width + 'x' + this.height);
      };
    }
  }, [image]);

  useEffect(() => {
    const reqImgUrl = imageUrl && `${imageUrl}?p=${new Date().getTime()}`;
    imageUrl ? setImage(imageUrl) : setImage('');
    imageFile ? setImage(imageFile) : reqImgUrl && setImage(reqImgUrl);
  }, [imageUrl, imageFile]);

  const updateImage = () => {
    const ref = editorRef.current;
    if (ref) {
      const canvas = ref.getImageScaledToCanvas();
      const format = imageFormat === 'jpg' ? 'image/jpeg' : 'image/png';
      const dataURL = addWhiteBackground(whiteBackground, canvas).toDataURL(
        format
      );
      onDataUrlUpdate(dataURL, ref.props.scale, ref.props.position);
    }
  };

  const handleImageChange = useCallback(
    _debounce(updateImage, 500, {
      leading: false,
    }),
    []
  );

  const handleLoadSuccess = () => {
    const isUrl = typeof image === 'string' && image.startsWith('http');
    const imgUrl = isUrl && tryRemoveUrlParameter(image);
    if (isUrl && !_isEqual(imgUrl, imageUrl)) {
      setScale(defaultScale);
      setPosition(defaultPosition);
      updateImage();
    } else if (!isUrl && image) {
      setScale(defaultScale);
      setPosition(defaultPosition);
      updateImage();
    }
  };

  const handlePositionChange = (pos) => {
    setPosition(pos);
  };

  const handleChangeScale = (e) => {
    const scale = e.target.value;
    setScale(Number(scale));
  };

  const handleImageClear = (e) => {
    e.stopPropagation();
    setImage('');
    onImageClear();
    if (freeSize) {
      setFreeImageSize(freeSize);
    }
  };

  const handleMouseMove = (e) => {
    setDragging(true);
  };

  const handleDropImage = useCallback((files) => {
    const file = files[0];
    if (!file || !file.type.includes('image')) {
      toast.error(
        t(
          'c.i.image.editor.invalid.file',
          'Invalid file format. Please choose an image file.'
        )
      );
      return;
    }
    if (file && file.size > 307200) {
      //300kb
      toast.error(
        t(
          'c.i.image.editor.large.file',
          'This file is too large. Please choose a smaller image file.'
        )
      );
      return;
    }
    if (freeSize) {
      saveSize(file).then((r) => {
        if (r) {
          toast.error(
            <>
              Invalid image size.
              <br />
              Please upload the image with required width and height.
            </>
          );
        } else {
          setImage('');
          setImage(file);
        }
      });
    }
    if (file && !enableScale && !freeSize) {
      //restrict image dimension
      invalidImageDimension(file).then((r) => {
        if (r) {
          toast.error(
            t(
              'c.i.image.editor.invalid.dimension',
              'Invalid image dimension. Please choose the correct dimension image file.'
            )
          );
        } else {
          setImage(file);
        }
      });
    } else if (!freeSize) {
      setImage(file);
    }
  }, []);

  async function invalidImageDimension(file) {
    return await new Promise((resolve) => {
      const image = new Image();
      image.addEventListener('load', () => {
        return resolve(
          image.width !== imageWidth || image.height !== imageHeight
        );
      });
      image.src = URL.createObjectURL(file);
    });
  }

  async function saveSize(file) {
    return await new Promise((resolve) => {
      const image = new Image();
      image.addEventListener('load', () => {
        if (image.height === freeSize.height) {
          setFreeImageSize({width: image.width, height: image.height});
        }
        return resolve(image.height !== freeSize.height);
      });
      image.src = URL.createObjectURL(file);
    });
  }

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: handleDropImage,
  });

  return (
    <div className="image-editor-block">
      <div
        {...getRootProps({
          className: 'image-editor-dropzone-block',
          style: {
            height: freeSize ? freeImageSize.width : imageHeight + 'px',
            width: freeSize ? freeImageSize.width : imageWidth + 'px',
            maxHeight: maxHeight + 'px',
            maxWidth: maxWidth + 'px',
          },
          onClick: (e) => {
            if (dragging) {
              e.stopPropagation();
              setDragging(false);
            }
          },
        })}
      >
        <input {...getInputProps()} />
        {!!image && (
          <div>
            <AvatarEditor
              ref={editorRef}
              onLoadSuccess={handleLoadSuccess}
              onImageChange={handleImageChange}
              onMouseMove={handleMouseMove}
              onPositionChange={handlePositionChange}
              image={image}
              color={[230, 230, 230, 0.6]}
              style={{
                maxHeight: `${freeSize ? freeImageSize.height : maxHeight}px`,
                maxWidth: `${freeSize ? freeImageSize.width : maxWidth}px`,
              }}
              scale={scale}
              border={3}
              width={freeSize ? freeImageSize.width : imageWidth}
              height={freeSize ? freeImageSize.height : imageHeight}
              crossOrigin="anonymous"
            />
            <button
              type="button"
              className="image-editor-close btn btn-outline-secondary btn-icon btn-sm"
              onClick={handleImageClear}
            >
              <i className="la la-remove" />
            </button>
          </div>
        )}
        {!image && (
          <table className="image-editor-hint">
            <tbody>
              <tr>
                <td style={{verticalAlign: 'middle'}}>
                  {!freeSize ? (
                    <>
                      <h5>{imagePlaceholder}</h5>(
                      {widthText ? widthText : imageWidth}W x{' '}
                      {heightText ? heightText : imageHeight}H)
                    </>
                  ) : (
                    <>
                      <h5>{imagePlaceholder}</h5>(Width: 100-
                      {freeSize.width} px ; Height: {freeSize.height}
                      px)
                    </>
                  )}
                  <div> {displayText}</div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      {enableScale && (
        <div className="image-editor-image-scale-block">
          <i className="fa fa-image" />
          <input
            className="image-editor-image-scale"
            type="range"
            step="0.01"
            min="0.1"
            max="2"
            defaultValue={defaultScale}
            onChange={handleChangeScale}
            style={{
              minWidth: '40px',
              maxWidth: '130px',
              width: `${maxWidth / 3}px`,
            }}
          />
          <i className="fa fa-image fa-2x" />
        </div>
      )}
    </div>
  );
}

ImageEditor.propTypes = {
  imageUrl: PropTypes.string,
  enableScale: PropTypes.bool,
  defaultScale: PropTypes.number,
  onDataUrlUpdate: PropTypes.func,
  onImageClear: PropTypes.func,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
};
