import React from 'react';
import Dotdotdot from 'react-dotdotdot';
import './index.css';
import FormattedDate from '../../components/FormattedDate';
import classnames from 'classnames';

export default function Card({
  img,
  title,
  moreText,
  news = false,
  type,
  mediaSource,
  brandImg,
  date,
}) {
  return (
    <div className="pf-home-card">
      <div style={{paddingBottom: '25px'}}>
        <img alt="" src={img} style={{width: '100%'}} />
      </div>
      {news ? (
        <div className="pf-home-card-logo">
          <div> {type === 'NEWS' ? 'PERFECT' : mediaSource}</div>
          <div className="pf-home-card-date">
            <FormattedDate type="newsCard" time={date} />
          </div>
        </div>
      ) : (
        <div className="pf-home-card-logo" style={{paddingBottom: '20px'}}>
          <img src={brandImg} alt="" style={{width: '100%'}} />
        </div>
      )}
      <div
        className={classnames({
          'pf-card-item-text': true,
          'pf-card-item-text-news': news,
        })}
      >
        <Dotdotdot clamp={5}>{title}</Dotdotdot>
      </div>
      <div className="pf-home-read">
        <span>{moreText}</span>
        <span className="pf-home-slick-image">
          <img
            alt=""
            className="pf-home-more-img"
            src={require('../../assets/images/news_arrow.png')}
          />
        </span>
      </div>
    </div>
  );
}
