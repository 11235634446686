import React from 'react';
import {Trans} from 'react-i18next';
import {Navigate} from 'react-router-dom';
import AsyncListVideos from './listvideos/async';
import AsyncListByCode from './listbycode/async';
import AsyncAddOrEditVideo from './addoreditvideo/async';

const parent = {
  title: <Trans i18nKey="p.s.videos.listvideos.title">Videos</Trans>,
  path: '/videos',
};
export default [
  {
    path: '/videos/list-videos',
    element: <AsyncListVideos />,
    handle: {
      title: <Trans i18nKey="p.s.vidieos.listvideos.title">Videos</Trans>,
      subtitle: <Trans i18nKey="p.s.vidieos.listvideos.title">Videos</Trans>,
      breadcrumbs: [parent],
    },
  },
  {
    path: '/videos/list-published',
    element: <AsyncListByCode />,
    handle: {
      title: (
        <Trans i18nKey="p.s.vidieos.listvideos.published.title">
          Published Videos
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.s.vidieos.listvideos.published.title">
          Published Videos
        </Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/videos/list-featured',
    element: <AsyncListByCode />,
    handle: {
      title: (
        <Trans i18nKey="p.s.vidieos.listvideos.featured.title">
          Featured Videos
        </Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.s.vidieos.listvideos.featured.title">
          Featured Videos
        </Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/videos/add-video',
    element: <AsyncAddOrEditVideo />,
    handle: {
      title: (
        <Trans i18nKey="p.s.videos.addoreditvideo.add.title">Add Video</Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.s.videos.addoreditvideo.add.title">Add Video</Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/videos/edit-video',
    element: <AsyncAddOrEditVideo />,
    handle: {
      title: (
        <Trans i18nKey="p.s.videos.addoreditvideo.edit.title">Edit Video</Trans>
      ),
      subtitle: (
        <Trans i18nKey="p.s.videos.addoreditvideo.edit.title">Edit Video</Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/videos',
    element: <Navigate to="/videos/list-videos" />,
  },
];
