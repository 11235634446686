import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import './ConfirmDialog.css';

export default function ConfirmDialogModal({
  title = 'Are you sure?',
  confirmCallback,
  open,
  setOpen,
  content = 'Are you sure you want to do this?',
}) {
  const handleYes = () => {
    confirmCallback();
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal show={open} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-sm btn-danger"
          variant="secondary"
          onClick={handleClose}
        >
          <i className="fa fa-times-circle" /> No
        </Button>
        <Button
          className="btn btn-sm btn-success"
          variant="primary"
          onClick={handleYes}
        >
          <i className="fa fa-check-circle" /> Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
