import React, {useEffect} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import store from 'store';
import _isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

// filter component
export const TextFilter = ({column: {filterValue, setFilter, storeFilter}}) => {
  useEffect(() => {
    setFilter(store.get(storeFilter));
  }, []);

  return (
    <input
      value={
        store.get(storeFilter) ? store.get(storeFilter) : filterValue || ''
      }
      onChange={(e) => {
        if (storeFilter) {
          store.set(storeFilter, e.target.value);
        }
        setFilter(e.target.value || undefined);
      }}
      className="form-control input-sm"
    />
  );
};

// filter component
export const DateRangeFilter = ({column: {filterValue, setFilter, query}}) => {
  return (
    <DateRangePicker
      onApply={(event, picker) => {
        const {startDate, endDate} = picker;
        const display = `${startDate.format('MM/DD')} - ${endDate.format(
          'MM/DD'
        )}`;
        setFilter([startDate.toISOString(), endDate.toISOString(), display]);
      }}
      drops="down"
      opens="center"
    >
      <div className="input-group">
        <div className="input-group-addon">
          <span className="glyphicon glyphicon-calendar" />
        </div>
        <input
          type="text"
          className="form-control input-sm"
          value={
            filterValue ? (query ? filterValue.display : filterValue[2]) : ''
          }
          readOnly={true}
          style={{backgroundColor: '#fff'}}
        />
      </div>
    </DateRangePicker>
  );
};

// filter component
export const DropdownFilter = ({
  column: {
    filterValue,
    setFilter,
    options,
    preFilteredRows,
    id,
    storeFilter,
    isAll = true,
  },
}) => {
  let opts = options
    ? new Set(options)
    : retrieveOptionsFromRows(preFilteredRows, id);
  useEffect(() => {
    setFilter(store.get(storeFilter));
  }, []);
  return (
    <select
      onChange={(e) => {
        if (storeFilter) {
          store.set(storeFilter, e.target.value);
        }
        setFilter(e.target.value || '');
      }}
      className="form-control input-sm"
      value={
        store.get(storeFilter) ? store.get(storeFilter) : filterValue || ''
      }
    >
      {isAll && <option value="">{''}</option>}

      {!_isEmpty(opts) &&
        [...opts].map((opt, i) => (
          <option key={i} value={opt.value && opt.value}>
            {opt.label && opt.label}
          </option>
        ))}
    </select>
  );
};

export const ApiTextFilter = ({text, setText}) => {
  return (
    <input
      value={text || ''}
      onChange={(e) => {
        setText(e.target.value || undefined);
      }}
      className="form-control input-sm"
    />
  );
};

export const ApiDropdownFilter = ({filter, setFilter, options}) => {
  return (
    <select
      onChange={(e) => {
        setFilter(e.target.value || '');
      }}
      className="form-control input-sm"
      value={filter || ''}
    >
      <option value="">{''}</option>
      {!_isEmpty(options) &&
        [...options].map((opt, i) => (
          <option key={i} value={opt.value && opt.value}>
            {opt.label && opt.label}
          </option>
        ))}
    </select>
  );
};

export const ApiDateRangeFilter = ({setBegin, begin, end, setEnd}) => {
  return (
    <DateRangePicker
      onApply={(event, picker) => {
        const {startDate, endDate} = picker;
        const start = new Date(startDate.toISOString());
        const startTimestamp = start.getTime();
        const end = new Date(endDate.toISOString());
        const endTimestamp = end.getTime();
        setBegin(startTimestamp);
        setEnd(endTimestamp);
      }}
      drops="down"
      opens="center"
    >
      <div className="input-group">
        <div className="input-group-addon">
          <span className="glyphicon glyphicon-calendar" />
        </div>
        <input
          type="text"
          className="form-control input-sm"
          value={
            begin
              ? `${dayjs(begin).format('MM/DD/YYYY')} - ${dayjs(end).format(
                  'MM/DD/YYYY'
                )}`
              : ''
          }
          readOnly={true}
          style={{backgroundColor: '#fff'}}
        />
      </div>
    </DateRangePicker>
  );
};

function retrieveOptionsFromRows(rows, id) {
  let optLabel = new Set();
  let optValue = new Set();
  rows.forEach((row, i) => {
    const columnValue = row.values && row.values[id];
    if (
      typeof columnValue === 'object' &&
      columnValue &&
      columnValue.value &&
      (columnValue.label || columnValue.text)
    ) {
      optLabel.add(columnValue.label);
      optValue.add(columnValue.value);
      return;
    }
    if (Array.isArray(columnValue)) {
      columnValue.forEach((opt, i) => optValue.add(opt));
      return;
    }
    if (columnValue !== '') {
      optValue.add(columnValue);
    }
  });
  const valueArr = Array.from(optValue);
  const labelArr = Array.from(optLabel);
  return valueArr.map((opt, i) => ({
    value: opt,
    label: labelArr[i] ? labelArr[i] : opt,
  }));
}
