import React from 'react';
import {Formik, Form, Field} from 'formik';
import yup from '../../../../utils/yup/index';
import dao from '../../../../dao/blogDao';
import {toast} from 'react-toastify';
import InputBox from '../../../../components/formik/InputBox';
import {Trans} from 'react-i18next';

export default function AddOrEditWhitelist({listAPI}) {
  const formikConfig = {
    validationSchema: yup.object().shape({
      domain: yup.string().required(),
    }),
    initialValues: {
      domain: '',
    },
    enableReinitialize: true,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setSubmitting(true);
      dao.createDeadLinkWhitelist(values).then((res) => {
        if (res.status === 'success') {
          toast.success('Create whitelist success');
          listAPI();
          resetForm();
        } else {
          toast.error('Failed to create whitelist');
        }
        setSubmitting(false);
      });
    },
  };

  return (
    <Formik {...formikConfig}>
      {(props) => (
        <Form>
          <div className="row">
            <label className="col-2 col-form-label">
              <Trans i18nKey="p.b.p.list.domain">Domain</Trans>
              <span className="text-danger required">*</span>
            </label>
            <div className="col-8 col-form-label">
              <Field
                type="text"
                name="domain"
                className="form-control"
                component={InputBox}
              />
            </div>
            <div className="col-2 text-center">
              <button className="btn btn-primary" type="submit">
                Add
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
