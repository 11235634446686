import React from 'react';
import {Navigate} from 'react-router-dom';
import AsyncListMeta from './listmeta/async';

const parent = {
  title: 'SEO Meta',
  path: '/seo',
};
export default [
  {
    path: '/seo/list-meta',
    element: <AsyncListMeta />,
    handle: {
      title: 'Meta',
      subtitle: 'Meta',
      breadcrumbs: [parent],
    },
  },
  {
    path: '/seo',
    element: <Navigate to="/seo/list-meta" />,
  },
];
