import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik';
import {Trans} from 'react-i18next';
import yup from '../../../../utils/yup';
import {useNavigate, useLocation} from 'react-router';
import dayjs from 'dayjs';
import {toast} from 'react-toastify';
import ImageField from '../../../../components/formik/ImageField';
import InputBox from '../../../../components/formik/InputBox';
import RichTextEditor from '../../../../components/texteditor/RichTextEditor';
import FormRow from '../../components/FormRow';
import SelectBox from '../../../../components/formik/SelectBox';
import CheckboxPostAttrList from '../../../../components/formik/CheckboxPostAttrList';
import Loader from '../../../../components/spinner/Loader';
import dao from '../../../../dao/blogDao';
import bannerDao from '../../../../dao/bannerDao';
import configs from '../../../../utils/config/globals';
import {categoryList} from '../../utils';
import _set from 'lodash/set';
import $ from 'jquery';
import CustomOptionReactSelect from '../../../../components/formik/CustomOptionReactSelect';
import _isEmpty from 'lodash/isEmpty';
import SignInModal from '../../../../components/auth/SignInModal';
import ShortDesc from './ShortDesc';
import './index.css';
import BlogPostConfig from './BlogPostConfig';
import Parser from 'react-html-parser';
import _get from 'lodash/get';
import Select from 'react-select';
import Paginate from '../../../../components/Paginate';
import uniqBy from 'lodash/uniqBy';
import {
  getLinkAttr,
  getImageAttr,
} from '../../../../utils/config/getStrapiInfo';
import _indexOf from 'lodash/indexOf';
import FileContainer from '../../../../components/filebox/FileContainer';
import CheckBox from '../../../../components/formik/CheckBox';
import ColorField from '../../../../components/colorpicker';
import BlogHeadingSelect from '../../../../components/formik/BlogHeadingSelect';
import _find from 'lodash/find';
import {getDisplaySettings} from '../listposts/components/displaysetting/getDisplaySettings';
import uploadDao from '../../../../dao/uploadDao';
import storyDao from '../../../../dao/storyDao';
import classnames from 'classnames';
import SimpleButtonGroup from '../../../../components/formik/SimpleButtonGroup';
import ButtonForm from './buttonForm';
import DeadLinkChecker from '../../../../components/deadlinkchecker';
import YceConfigs from './YceConfigs';
import useDeadLinks from '../../../../components/hooks/useDeadLinks';
import DeadLinkCheckerModal from '../../../../components/deadlinkchecker/DeadLinkCheckModal';
import SeoFaq from '../../../seo/components/seoFaq';
import SeoDao from '../../../../dao/seoDao';
import seoUtils from '../../../../utils/seoUtils';
import _isNull from 'lodash/isNull';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import _orderBy from 'lodash/orderBy';

async function fetchInputOptions() {
  const authors = [];
  const translators = [];

  await dao.author.listAll().then(
    (res) =>
      res.results.forEach((a) => {
        if (a.authorType === 'AUTHOR') {
          authors.push({label: a.displayName, value: a.id});
        }
        if (a.authorType === 'TRANSLATOR') {
          translators.push({label: a.displayName, value: a.id});
        }
      }),
    (err) => []
  );

  return {authors, translators};
}

export default function AddOrEditPost() {
  const [updatePostContent, setUpdatePostContent] = useState('');
  const [strapiPageInfo, setStrapiPageInfo] = useState({});
  const [strapiPage, setStrapiPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchStrapi, setSearchStrapi] = useState('');
  const [updateStrapi, setUpdateStrapi] = useState([]);
  const [insertType, setInsertType] = useState('content');
  const [insertContent, setInsertContent] = useState('');
  const [insertAnnotation, setInsertAnnotation] = useState('');
  const [strapiType, setStrapiType] = useState('banners');
  const [selectOptions, setSelectOptions] = useState([]);
  const navigate = useNavigate();
  const {search} = useLocation();
  const [formLoaded, setFormLoaded] = useState(false);
  const [isEdit, setIsEdit] = useState(!!search);
  const [authors, setAuthors] = useState([]);
  const [translators, setTranslators] = useState([]);
  const [initValue, setInitValue] = useState({});
  const [mappingKeyList, setMappingKeyList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [showStrapiImg, setShowStrapiImg] = useState(false);
  const [strapiData, setStrapiData] = useState([]);
  const [openVideo, setOpenVideo] = useState(false);
  const [openInsertButton, setOpenInsertButton] = useState(false);
  const [lang, setLang] = useState('');
  const [isCheckedModal, setIsCheckedModal] = useState(false);
  const [deadLinkResult, setDeadLinkResult] = useState({
    dupIds: [],
    noAnchorLinks: [],
    brokenLinks: [],
    otherLinks: [],
  });
  const [showFaqModal, setShowFaqModal] = useState(false);
  const [metaInfos, setMetaInfos] = useState({
    metaType: 'BLOG',
    dateFrom: moment().subtract(3, 'month').valueOf(),
    dateTo: moment().valueOf(),
    serpFaqs: [
      {
        seoMetaType: 'BLOG',
        order: 0,
        question: '',
        answer: '',
      },
    ],
    title: '',
    description: '',
    ogTitle: '',
    ogDescription: '',
  });
  const signInId = useSelector((s) => s.components.auth.admin.adm.id);
  const param = new URLSearchParams(search);
  const {parseResult, hasDeadLinks, getCheckLinks, logDeadLinks} = useDeadLinks(
    param.get('id')
  );
  useEffect(() => {
    getDisplaySettings(setSelectOptions);
  }, []);
  const getPopularList = (val) => {
    if (_isEmpty(val) || !val) return ['', '', ''];
    let tmp = [];
    for (let i = 0; i <= 2; i++) {
      if (!val[i]) {
        tmp.push('');
      } else {
        tmp.push(val[i]);
      }
    }
    return tmp;
  };
  const getInitValue = (d = {}) => {
    return {
      post: {
        id: d.id || '',
        languageType: d.languageType || 'enu',
        title: d.title || '',
        featuredTopic: d.featuredTopic || '',
        postAuthors:
          d.postAuthors && d.postAuthors[0]
            ? d.postAuthors
            : [
                {
                  author: {id: '', displayOrder: 0},
                },
              ],
        shortDesc: d.shortDesc || '',
        postDate: d.postDate
          ? dayjs(d.postDate).format('YYYY-MM-DD')
          : dayjs().add(1, 'day').format('YYYY-MM-DD'),
        postContent: d.postContent || '',
        ctaButtons: {
          mobile: {
            isShowCoverButton: _get(d, 'ctaButtons')
              ? _get(
                  JSON.parse(d.ctaButtons),
                  'mobile.isShowCoverButton',
                  false
                )
              : false,
            coverButtonTitle: d.ctaButtons
              ? _get(JSON.parse(d.ctaButtons), 'mobile.coverButtonTitle', '')
              : '',
            coverButtonLink: d.ctaButtons
              ? _get(JSON.parse(d.ctaButtons), 'mobile.coverButtonLink', '')
              : '',
            coverButtonColor: d.ctaButtons
              ? _get(
                  JSON.parse(d.ctaButtons),
                  'mobile.coverButtonColor',
                  '#000000'
                )
              : '#000000',
            coverTextColor: d.ctaButtons
              ? _get(
                  JSON.parse(d.ctaButtons),
                  'mobile.coverTextColor',
                  '#000000'
                )
              : '#000000',
            isShowFloatButton: d.ctaButtons
              ? _get(
                  JSON.parse(d.ctaButtons),
                  'mobile.isShowFloatButton',
                  false
                )
              : false,
            floatButtonTitle: d.ctaButtons
              ? _get(JSON.parse(d.ctaButtons), 'mobile.floatButtonTitle', '')
              : '',
            floatButtonLink: d.ctaButtons
              ? _get(JSON.parse(d.ctaButtons), 'mobile.floatButtonLink', '')
              : '',
            floatButtonColor: d.ctaButtons
              ? _get(
                  JSON.parse(d.ctaButtons),
                  'mobile.floatButtonColor',
                  '#000000'
                )
              : '#000000',
            floatTextColor: d.ctaButtons
              ? _get(
                  JSON.parse(d.ctaButtons),
                  'mobile.floatTextColor',
                  '#000000'
                )
              : '#000000',
          },
          desktop: {
            floatButtonTitle: d.ctaButtons
              ? _get(JSON.parse(d.ctaButtons), 'desktop.floatButtonTitle', '')
              : '',
            floatButtonLink: d.ctaButtons
              ? _get(JSON.parse(d.ctaButtons), 'desktop.floatButtonLink', '')
              : '',
            floatButtonColor: d.ctaButtons
              ? _get(
                  JSON.parse(d.ctaButtons),
                  'desktop.floatButtonColor',
                  '#000000'
                )
              : '#000000',
            floatTextColor: d.ctaButtons
              ? _get(
                  JSON.parse(d.ctaButtons),
                  'desktop.floatTextColor',
                  '#000000'
                )
              : '#000000',
          },
          yce: {
            buttonText: d.ctaButtons
              ? _get(JSON.parse(d.ctaButtons), 'yce.buttonText', '')
              : '',
            buttonTools: d.ctaButtons
              ? _get(JSON.parse(d.ctaButtons), 'yce.buttonTools', '')
              : '',
            ctaInteraction: d.ctaButtons
              ? _get(JSON.parse(d.ctaButtons), 'yce.ctaInteraction', '')
              : '',
            isShowFloatButton: d.ctaButtons
              ? _get(JSON.parse(d.ctaButtons), 'yce.isShowFloatButton', false)
              : false,
          },
        },
        minutesToRead: d.minutesToRead || 3,
        annotation: d.annotation || '',
        category: d.category || '',
        postKey: d.postKey || '',
        redirectKey: d.redirectKey || '',
        metaDesc: d.metaDesc || '',
        postAttrs: d.postAttrs || ['BF_BUSINESS_FOCUS'],
        contentVersion: d.contentVersion || 'v1',
        imageAlt: d.imageAlt || '',
        mappingKey: d.mappingKey || d.postKey || '',
        postStatus: d.postStatus || '',
        popularPostIdsList: getPopularList(d.popularPostIdsList),
        showFaqList: d.showFaqList || false,
        isBa: d.isBa || false,
      },
      videoUrl: d.videoUrl || '',
      insertVideo: '',
      insertPoster: '',
      image: d.imageUrl || '',
      smallImage: d.smallImageUrl || '',
      squareImage: d.squareImageUrl || '',
      afterImageUrl: d.afterImageUrl || '',
    };
  };
  const closeImageModal = () => {
    setShowStrapiImg(false);
  };
  const closeModal = () => {
    setIsError(false);
  };
  const closeVideoModal = () => {
    setOpenVideo(false);
  };
  const closeInsertButtonModal = () => {
    setOpenInsertButton(false);
  };
  const formikConfig = {
    validationSchema: yup.object().shape({
      post: yup.object().shape({
        languageType: yup.string().required(),
        title: yup.string().max(200).required(),
        featuredTopic: yup.string().required(),
        postAuthors: yup
          .array()
          .of(
            yup.object().shape({
              author: yup.object().shape({
                id: yup.string().required(),
                displayOrder: yup.number(),
              }),
            })
          )
          .required(),
        shortDesc: yup.string().max(300),
        postDate: yup.date().required(),
        postContent: yup.string().required(),
        annotation: yup.string(),
        postAttrs: yup.array(),
        minutesToRead: yup.number().min(1).required(),
        category: yup.string().required(),
        postKey: yup
          .string()
          .matches(/^[a-zA-Z0-9\-_]*$/)
          .required(),
        redirectKey: yup.string().matches(/^[a-zA-Z0-9\-_]*$/),
        metaDesc: yup.string().max(500).required(),
        mappingKey: yup
          .string()
          .matches(/^[a-zA-Z0-9\-_]*$/)
          .required(),
      }),
      image: yup.string().required(),
      smallImage: yup.string().required(),
      squareImage: yup.string(),
      attrs: yup.array(),
      afterImageUrl: yup.string().when('isBA', {
        is: true,
        then: yup
          .string()
          .required('afterImageUrl is required when isBA is true'),
        otherwise: yup.string().notRequired(),
      }),
    }),
    enableReinitialize: true,
    initialValues: getInitValue(initValue),
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true);
      const checkProperties = [];
      if (_get(values, 'post.ctaButtons.mobile.isShowCoverButton', false)) {
        checkProperties.push('post.ctaButtons.mobile.coverButtonLink');
      }
      if (_get(values, 'post.ctaButtons.mobile.isShowFloatButton', false)) {
        checkProperties.push('post.ctaButtons.mobile.floatButtonLink');
      }
      if (
        _get(values, 'post.ctaButtons.desktop.floatButtonTitle', '').trim()
          .length !== 0
      ) {
        checkProperties.push('post.ctaButtons.desktop.floatButtonLink');
      }
      const otherLinks = getCheckLinks(values, checkProperties);

      const postDeadLinkResult = await parseResult(
        values.post.postContent,
        otherLinks,
        'SAVE'
      );
      if (!isCheckedModal) {
        const isDeadLink = await hasDeadLinks(postDeadLinkResult);
        if (isDeadLink) {
          setDeadLinkResult(postDeadLinkResult);
          setSubmitting(false);
          setIsCheckedModal(true);
          return;
        }
      }

      if (
        _indexOf(values.post.postAttrs, 'BF_BUSINESS_FOCUS') === -1 &&
        _indexOf(values.post.postAttrs, 'CF_CONSUMER_FOCUS') === -1 &&
        _indexOf(values.post.postAttrs, 'YUC_YCE_USECASE') === -1
      ) {
        toast.warn(
          "The 'article type' field must be filled in before updating."
        );
        setSubmitting(false);
        return;
      }
      const isYce = _indexOf(values.post.postAttrs, 'YUC_YCE_USECASE') === -1;
      let appType = 'NONE';
      const appAttr = _get(values, 'post.postAttrs', []).find((attr) =>
        attr.startsWith('CA')
      );
      if (appAttr) {
        appType = appAttr.split('_')[1].toUpperCase();
      }
      const params = {
        ...values,
        post: {
          ...values.post,
          category: isYce ? values.post.category : 'GENERAL',
          ctaButtons: JSON.stringify(values.post.ctaButtons),
          appType: appType,
          focusType:
            _indexOf(values.post.postAttrs, 'BF_BUSINESS_FOCUS') !== -1
              ? 'BF_BUSINESS_FOCUS'
              : _indexOf(values.post.postAttrs, 'CF_CONSUMER_FOCUS') !== -1
              ? 'CF_CONSUMER_FOCUS'
              : 'YUC_YCE_USECASE',
        },
      };
      _set(params, 'post.modifierId', signInId);
      !isEdit && _set(params, 'post.creatorId', signInId);
      delete params['insertVideo'];
      delete params['insertPoster'];
      const response = isEdit
        ? await dao.post.update(params).catch(() => {
            setIsError(true);
          })
        : await dao.post.create(params).catch(() => {
            setIsError(true);
          });

      if (response.status === 'Unauthorized') {
        setSubmitting(false);
        setIsError(true);
      }

      if (response.status === 'failed') {
        toast.error(isEdit ? 'Update Failed!' : 'Create Failed!');
        setSubmitting(false);
      }
      try {
        await SeoDao.updateMeta(
          seoUtils.getCleanedValues(
            {...metaInfos, sourceId: response.id},
            isYce
          )
        );
      } catch (e) {
        toast.error('update FAQ Failed');
      }

      if (response.id) {
        logDeadLinks(postDeadLinkResult, 'SAVE', response.id);
        toast.success(isEdit ? 'Update Successful' : 'Create Successful');
        navigate('/blog/post/list-posts');
      }
    },
  };

  const getSeoMeta = (id) => {
    SeoDao.queryMeta({
      sourceId: id,
      metaType: 'BLOG',
    }).then((res) => {
      if (_isNull(res.serpFaqs) || _isEmpty(res.serpFaqs)) {
        res.serpFaqs = [
          {
            seoMetaType: 'BLOG',
            order: 0,
            question: '',
            answer: '',
          },
        ];
      }
      res.serpFaqs = _orderBy(res.serpFaqs, ['order'], ['asc']);
      setMetaInfos(res);
    });
  };
  useEffect(() => {
    if (!!search) {
      const param = new URLSearchParams(search);
      const id = param.get('id');
      if (id) {
        setIsEdit(true);
        dao.post.query(id).then((res) => {
          setLang(res.languageType);
          let tmp = [];
          setInitValue(res);
          Parser(_get(res, 'postContent', ''), {
            transform: (node, index) => {
              if (node.name === 'p' && _get(node, 'attribs.data-strapi-vuid')) {
                const strapiType = _get(node, 'attribs.data-strapi-type');
                const vuid = _get(node, 'attribs.data-strapi-vuid');
                const updateData = {
                  vuid: vuid,
                  type: strapiType,
                };
                tmp.push(updateData);
              }
              BlogPostConfig.transformPostContent(node, index);
            },
          });
          if (!_isEmpty(tmp)) getLatestStrapiData(tmp);
          getSeoMeta(res.id);
        });
      }
    }
    fetchInputOptions().then((options) => {
      setAuthors(options.authors);
      const tmp = [{label: '', value: ''}];
      setTranslators(tmp.concat(options.translators));
      setFormLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (!lang) return;
    dao.post.listMappingKey({languageType: lang}).then((res) => {
      const list = res.map((s) => {
        return {label: s, value: s};
      });
      setMappingKeyList(list);
    });
  }, [lang]);

  const getLatestStrapiData = async (data) => {
    const updateBanner = await Promise.all(
      uniqBy(data, 'vuid').map(async (s) => {
        return await bannerDao
          .getStrapiBannerByVuid({
            type: s.type === 'banners' && 'BANNER',
            vuid: s.vuid,
          })
          .then((res) => {
            return res && res.attributes
              ? res
              : {
                  attributes: {
                    vuid: s.vuid,
                    status: {
                      status: 'TakenDown',
                    },
                  },
                };
          });
      })
    );
    setUpdateStrapi(updateBanner);
  };

  useEffect(() => {
    if (_isEmpty(updateStrapi)) return;
    updateStrapiData(_get(initValue, 'postContent', ''), updateStrapi);
  }, [updateStrapi]);

  function updateStrapiData(string, updateStrapi) {
    const p = $('<div class="strapi-container">');
    p.append(string);
    if (!_isEmpty(updateStrapi)) {
      updateStrapi.forEach((s) => {
        if (
          !!$(p).find(`[data-strapi-vuid=${s.attributes.vuid}]`).children()
            .length
        ) {
          const res = $(p).find(`[data-strapi-vuid=${s.attributes.vuid}]`);
          Array(res.length)
            .fill('')
            .forEach((c, i) => {
              const el = res[i];
              if (s.attributes.status.status === 'Published') {
                const imageParams = getImageAttr(s);
                const linkParams = getLinkAttr(s);
                $(el).children('a').attr('href', linkParams.href);
                $(el).children('a').attr('rel', linkParams.nofollow);
                $(el).children('a').attr('target', linkParams.newTab);
                $(el)
                  .children('a')
                  .children('img')
                  .attr('src', imageParams.src);
                $(el)
                  .children('a')
                  .children('img')
                  .attr('class', imageParams.class);
                $(el)
                  .children('a')
                  .children('img')
                  .attr('alt', imageParams.alt);
                $(el)
                  .children('a')
                  .children('img')
                  .attr('title', imageParams.title);
              } else {
                $(el)
                  .children('a')
                  .children('img')
                  .attr(
                    'src',
                    'https://s3-ap-northeast-1.amazonaws.com/bcm-demo-media/smb/story/2022-05-12/1ab1e1cb-8c44-4538-a637-98959630a4bf.jpg'
                  );
                $(el).children('strong').html('TakenDown');
              }
            });
        }
      });
    }

    $(p).find('.strapi-container');
    const ttt = p
      .prop('outerHTML')
      .split('<div class="strapi-container">')
      .join('');
    setUpdatePostContent(ttt);
  }

  const [tags, setTags] = useState([
    {label: '', value: '*'},
    {label: '', value: '*'},
    {label: '', value: '*'},
    {label: 'Bottom-Download', value: 'Bottom-Download'},
  ]);
  const [tagsName, setTagsName] = useState(0);
  useEffect(() => {
    setLoading(true);
    const search = searchStrapi
      ? `&filters[title][$contains]=${searchStrapi}`
      : '';
    const B2BorB2C =
      tags[0].value !== '*'
        ? `&filters[b2BorB2C][b2BorB2C][$eq]=${tags[0].value}`
        : '';
    const Languages =
      tags[1].value !== '*'
        ? `&filters[languages][languages][$eq]=${tags[1].value}`
        : '';
    const Products =
      tags[2].value !== '*'
        ? `&filters[products][products][$eq]=${tags[2].value}`
        : '';
    const ButtonType =
      tags[3].value !== '*'
        ? `&filters[buttonType][buttonType][$eq]=${tags[3].value}`
        : '';
    const str = `populate=deep&pagination[pageSize]=10&pagination[withCount]=true&sort[0]=updatedAt:desc${search}${B2BorB2C}${Languages}${Products}${ButtonType}&pagination[page]=${strapiPage}`;
    bannerDao
      .getStrapiBanner({
        type: 'BANNER',
        params: str,
      })
      .then((res) => {
        setLoading(false);
        if (res && res.meta) setStrapiPageInfo(res.meta);
        if (res && res.data) setStrapiData(res.data);
      });
  }, [tagsName, strapiType, searchStrapi, strapiPage]);

  const topicsList = (post) => {
    const isYce = _indexOf(post.postAttrs, 'YUC_YCE_USECASE') !== -1;
    const type = configs.blog.blogTypes.filter(
      (bt) =>
        !bt.value.startsWith('BF_') &&
        !bt.value.startsWith('CF_') &&
        !bt.value.startsWith('BS_') &&
        !bt.value.startsWith('CA_')
    );
    if (isYce)
      return type.filter(
        (s) => s.value.startsWith('YC_') || s.value.startsWith('YUC_')
      );
    return type.filter(
      (s) => !s.value.startsWith('YC_') && !s.value.startsWith('YUC_')
    );
  };

  if (!formLoaded) {
    return <Loader />;
  }

  const postContentConfig = {
    ...BlogPostConfig.postContent,
    controls: {
      ...BlogPostConfig.controls,
      insertTableOfContent: {
        iconURL: require('../../../../assets/images/book-solid.svg'),
        name: 'insertTableOfContent',
        tooltip: 'Insert Table Of Content',
        exec: function (editor) {
          let selector = '';
          for (let i = 1; i <= 6; i++) {
            selector += `.post-content-editor h${i}`;
            if (i < 6) selector += ', ';
          }

          const $table = $('<table>');
          const $tbody = $('<tbody>');
          const $trTitle = $('<tr>');
          const $trContent = $('<tr>');
          const $tdTitle = $('<th>');
          const $tdContent = $('<td>');
          $table.css({
            width: '100%',
            'border-collapse': 'separate',
            'border-spacing': 0,
          });
          $tdTitle.css({
            border: '2px solid #f745e6',
            padding: '0 10px',
            'background-color': '#f745e6',
            color: '#fff',
            'border-top-left-radius': '10px',
            'border-top-right-radius': '10px',
          });
          $tdContent.css({
            border: '2px solid #f745e6',
            padding: '30px 10px 10px 0',
            'background-color': '#ffebfd',
            'border-bottom-left-radius': '10px',
            'border-bottom-right-radius': '10px',
          });

          $table.append($tbody);
          $tbody.append($trTitle);
          $tbody.append($trContent);
          $trTitle.append($tdTitle);
          $trContent.append($tdContent);
          $tdTitle.append('Table of Contents');

          const group = [];
          let currentTag;
          let currentGroup;
          const $headers = $(selector);

          $headers.each(function (i) {
            const now = Date.now();
            const $header = $(this);
            let id = $header.attr('id');
            const $sameIdHeaders = $(`[id=${id}]`);

            // handling if copy header manually, don't update first element with same id
            if (
              !id ||
              ($sameIdHeaders.length > 1 && $sameIdHeaders.index($header) > 0)
            ) {
              id = now + '-' + i;
            }
            $header.attr('id', id);
            const tagName = $header.prop('tagName').toLowerCase();
            const changed = currentTag !== tagName;
            const last = $headers.length === i + 1;
            if (changed) {
              if (currentGroup) {
                group.push([...currentGroup]);
              }
              currentTag = tagName;
              currentGroup = [];
            }
            currentGroup.push($header);
            if (last) {
              if (currentGroup) {
                group.push([...currentGroup]);
              }
            }
          });

          group.forEach((g) => {
            const $header = g[0];
            const tagName = $header.prop('tagName').toLowerCase();
            const indentIdx = parseInt(tagName.replace('h', ''));

            let $ulol = $('<ul>');
            if (tagName === 'h1') {
              $ulol = $('<ul>');
              $ulol.css({'list-style-type': 'none'});
            } else if (tagName === 'h2') {
              $ulol = $('<ul>');
              $ulol.css({'list-style-type': 'disc'});
            } else if (tagName === 'h3') {
              $ulol = $('<ol>');
              $ulol.css({'margin-left': `${(indentIdx - 2) * 20}px`});
            } else {
              $ulol = $('<ul>');
              $ulol.css({
                'list-style-type': 'circle',
                'margin-left': `${(indentIdx - 2) * 20}px`,
              });
            }
            g.forEach(($header) => {
              const title = $header.text();
              const id = $header.attr('id');
              const $a = $('<a>');
              $a.attr('href', `#${id}`);
              $a.append(title);

              const $li = $('<li>');
              $li.append($a);
              $ulol.append($li);
            });
            $tdContent.append($ulol);
          });

          editor.selection.insertHTML($table.prop('outerHTML'));
        },
      },
      openStrapi: {
        iconURL:
          'https://s3-ap-northeast-1.amazonaws.com/bcm-demo-media/smb/story/2022-04-28/920c27cd-ee3c-46f3-9518-683c05deb49f.png',
        name: 'openStrapi',
        tooltip: 'Open Strapi image',
        exec: function () {
          setInsertType('content');
          setShowStrapiImg(true);
        },
      },
      uploadVideo: {
        iconURL:
          'https://plugins-media.makeupar.com/smb/story/2022-07-05/49c0a105-a021-4570-ab6c-85c995787210.png',
        name: 'uploadVideo',
        tooltip: 'upload video',
        exec: function () {
          setOpenVideo(true);
        },
      },
      insertButton: {
        iconURL:
          'https://plugins-media.makeupar.com/smb/story/2024-01-09/4236c0e7-6644-4beb-b50a-3a061e93998b.png',
        name: 'insertButton',
        tooltip: 'insert button',
        exec: function () {
          setOpenInsertButton(true);
        },
      },
      insertPros: {
        iconURL:
          'https://plugins-media.makeupar.com/smb/story/2024-04-01/a37879c9-a0ae-4c32-bfb6-e52ee26e938f.png',
        name: 'insertPros',
        tooltip: 'insert Pros',
        exec: function () {
          const pros =
            '<div class="fx">\n' +
            '    <fieldset class="rounded-div green">\n' +
            '        <legend style="font-size: 22px;/* box-sizing: border-box; *//* display: inline; */width: initial;">Pros<img alt="pros" src="https://plugins-media.makeupar.com/smb/story/2024-04-01/fbb90a03-36fb-4d20-9651-c6d8d58fe379.png"> </legend>\n' +
            '        <ul class="pro_bullet">\n' +
            '            <li>ex1</li>\n' +
            '        </ul>\n' +
            '    </fieldset>\n' +
            '    <fieldset class="rounded-div red">\n' +
            '        <legend style="font-size: 22px;">Cons<img alt="cons" src="https://plugins-media.makeupar.com/smb/story/2024-04-01/97c5b2fa-fc26-4126-9b7a-e0df58377344.png"></legend>\n' +
            '        <ul class="con_bullet">\n' +
            '            <li>ex1</li>\n' +
            '        </ul>\n' +
            '    </fieldset>\n' +
            '</div>';
          setInsertContent(pros);
        },
      },
    },
    buttons: BlogPostConfig.contentButtons,
    buttonsMD: BlogPostConfig.contentButtons,
    buttonsSM: BlogPostConfig.contentButtons,
    buttonsXS: BlogPostConfig.contentButtons,
  };
  const annotationConfig = {
    ...BlogPostConfig.annotation,
    controls: {
      openStrapi: {
        name: 'openStrapi',
        icon: '',
        tooltip: 'Open Strapi image',
        exec: function () {
          setInsertType('annotation');
          setShowStrapiImg(true);
        },
      },
    },
    buttons: BlogPostConfig.annotationButtons,
    buttonsMD: BlogPostConfig.annotationButtons,
    buttonsSM: BlogPostConfig.annotationButtons,
    buttonsXS: BlogPostConfig.annotationButtons,
  };

  return (
    <div>
      <Modal size={'lg'} show={isError} onHide={closeModal} centered>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <SignInModal closeModal={closeModal} />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size={'lg'}
        show={openInsertButton}
        onHide={closeInsertButtonModal}
        centered
      >
        <Modal.Body>
          <ButtonForm
            setInsertContent={setInsertContent}
            setOpenInsertButton={setOpenInsertButton}
          />
        </Modal.Body>
      </Modal>
      <Formik {...formikConfig}>
        {(formikProps) => (
          <Form>
            <Modal
              size={'lg'}
              show={openVideo}
              onHide={closeVideoModal}
              centered
            >
              <Modal.Body>
                <FormRow
                  label={'Update Video'}
                  name="insertVideo"
                  component={(props) => (
                    <FileContainer
                      {...props}
                      accept={'video/mp4'}
                      maxSize={'2097152'}
                      pathType={'BLOG_VIDEO'}
                      fileName={
                        props.field.value.name ? props.field.value.name : ''
                      }
                      uploadButtonText={'Upload Video'}
                      displayText={'Limitation: Video file size <= 2 MB'}
                      htmlId={'filePicker2'}
                      disableButton={true}
                    />
                  )}
                />
                <FormRow
                  label={'Update Poster'}
                  name="insertPoster"
                  component={(props) => (
                    <FileContainer
                      {...props}
                      accept={'image/jpeg, image/png'}
                      maxSize={'2097152'}
                      pathType={'BLOG_VIDEO'}
                      fileName={
                        props.field.value.name ? props.field.value.name : ''
                      }
                      uploadButtonText={'Upload Poster'}
                      displayText={'Limitation: Image file size <= 2 MB'}
                      htmlId={'filePicker3'}
                      disableButton={true}
                    />
                  )}
                />
                <div
                  style={{cursor: 'pointer'}}
                  className={classnames({
                    row: true,
                    btn: true,
                    'btn-primary': true,
                    'kt-margin-r-10': true,
                    'kt-margin-l-5': true,
                    'kt-margin-b-10': true,
                    'kt-spinner': true,
                    'kt-spinner-v2': true,
                    'kt-spinner--center': true,
                    'kt-spinner--sm': true,
                  })}
                  onClick={async () => {
                    if (
                      !formikProps.values.insertVideo ||
                      !formikProps.values.insertPoster
                    ) {
                      toast.warn('please upload both video and poster');
                      return;
                    }
                    const formData = new FormData();
                    formData.append('pathType', 'BLOG_VIDEO');
                    formData.append('file', formikProps.values.insertVideo);
                    const reader = new FileReader();
                    reader.readAsDataURL(formikProps.values.insertPoster);
                    const v = await uploadDao.uploadpdf(formData);
                    const p = await storyDao.uploadImage({
                      uploadImage: reader.result,
                    });
                    const video = document.createElement('img');
                    video.setAttribute('videoUrl', v);
                    video.setAttribute('src', p);
                    video.setAttribute('poster', p);
                    video.setAttribute('id', Date.now().toString());
                    video.setAttribute(
                      'style',
                      'max-width:100%;max-height:100%;'
                    );
                    setInsertContent(video.outerHTML);
                    setOpenVideo(false);
                    formikProps.setFieldValue('insertVideo', '');
                    formikProps.setFieldValue('insertPoster', '');
                  }}
                >
                  insert Video
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              size={'full'}
              show={showStrapiImg}
              onHide={closeImageModal}
              centered
            >
              <Modal.Body>
                <div style={{position: 'relative'}}>
                  <img
                    alt=""
                    onClick={() => setShowStrapiImg(false)}
                    style={{
                      position: 'absolute',
                      right: 0,
                      cursor: 'pointer',
                      width: '30px',
                    }}
                    src={require('../../../../assets/images/btn_close_tag@2x.png')}
                  />
                  <div className="stripe-image--filter">
                    {!_isEmpty(BlogPostConfig.strapiTypes) &&
                      BlogPostConfig.strapiTypes.map((s) => (
                        <div
                          style={{
                            flex: `0 0 calc( 100% / ${BlogPostConfig.strapiTypes.length} )`,
                            color: strapiType === s.value && '#f23b77',
                          }}
                          id={`strapiType-${s}`}
                          onClick={() => setStrapiType(s.value)}
                          key={`strapiType-${s}`}
                        >
                          {s.label}
                        </div>
                      ))}
                  </div>
                  <div
                    style={{width: '100%', borderBottom: '1px solid #f2f2f2'}}
                  >
                    <div className="strapi-modal-container">
                      <div className="row" style={{width: '75%'}}>
                        {!_isEmpty(BlogPostConfig.tagsList) &&
                          BlogPostConfig.tagsList.map((s, index) => (
                            <div className="strapi-filter--list">
                              <div className="strapi-filter--title">
                                {s.title}
                              </div>
                              <Select
                                options={s.data}
                                value={tags[index]}
                                onChange={(e) => {
                                  setTagsName(e.label + e.value + s.title);
                                  let tmp = tags;
                                  tmp[index] = e;
                                  setTags(tmp);
                                }}
                              />
                            </div>
                          ))}
                      </div>
                      <div className="strapi-search-header__search">
                        <input
                          value={searchStrapi}
                          maxLength="100"
                          className="strapi-search-header__input"
                          onChange={(e) => {
                            if (e.target.value) {
                              setStrapiPage(1);
                            }
                            setSearchStrapi(e.target.value);
                          }}
                        />
                        <i className="blog-search-header__icon" />
                      </div>
                    </div>
                  </div>
                  <div className="stripe-image--modal">
                    {loading && (
                      <div className="text-center" style={{margin: '0 auto'}}>
                        Loading....
                      </div>
                    )}
                    {!loading &&
                      !_isEmpty(strapiData) &&
                      strapiData.map((s, index) => {
                        const imageParams = getImageAttr(s);
                        const linkParams = getLinkAttr(s);
                        return (
                          <div
                            key={`strapiData-${index}`}
                            className="stripe-image--container"
                            onClick={() => {
                              const strapi = document.createElement('p');
                              strapi.setAttribute(
                                'data-strapi-vuid',
                                s.attributes.vuid
                              );
                              strapi.setAttribute(
                                'class',
                                'strapi-banner-border'
                              );
                              strapi.setAttribute(
                                'style',
                                'border:1px solid #000;'
                              );
                              strapi.setAttribute(
                                'data-strapi-type',
                                strapiType
                              );
                              strapi.setAttribute('data-strapi-id', s.id);
                              const cover = document.createElement('img');
                              cover.setAttribute(
                                'data-strapi-image',
                                s.attributes.vuid
                              );
                              cover.setAttribute('src', imageParams.src);
                              cover.setAttribute('alt', imageParams.alt);
                              cover.setAttribute('title', imageParams.title);
                              cover.setAttribute('class', imageParams.class);
                              const title = document.createElement('strong');
                              title.setAttribute('class', 'strapi-not-show');
                              title.setAttribute(
                                'style',
                                'display:block;margin-bottom:15px;'
                              );
                              title.innerHTML =
                                'Shared Materials by Strapi<br><span style="font-weight: normal; font-size: 12px">*Adjust the size of images ONLY. Please go to Strapi to edit the materials info.</span>';
                              strapi.append(title);
                              const link = document.createElement('a');
                              link.setAttribute(
                                'data-strapi-link',
                                s.attributes.vuid
                              );
                              link.setAttribute('href', linkParams.href);
                              link.setAttribute('rel', linkParams.nofollow);
                              link.setAttribute('target', linkParams.newTab);
                              link.append(cover);
                              strapi.append(link);
                              if (insertType !== 'content') {
                                setInsertAnnotation(strapi.outerHTML);
                              } else {
                                setInsertContent(strapi.outerHTML);
                              }
                              setShowStrapiImg(false);
                            }}
                          >
                            <div>{s.attributes.title}</div>
                            <span>
                              <img
                                alt=""
                                src={
                                  s.attributes.imageFile &&
                                  s.attributes.imageFile.data.attributes.url
                                }
                              />
                            </span>
                          </div>
                        );
                      })}
                    {!loading && _isEmpty(strapiData) && (
                      <div className="text-center" style={{margin: '0 auto'}}>
                        No Data
                      </div>
                    )}
                  </div>
                  {!_isEmpty(strapiPageInfo) && (
                    <Paginate
                      selectShow={false}
                      classname={'strapi-modal--footer'}
                      grid={{
                        pageIndex: strapiPage,
                        pages: Math.ceil(
                          strapiPageInfo.pagination.total /
                            strapiPageInfo.pagination.pageSize
                        ),
                        pageSize: strapiPageInfo.pagination.pageSize,
                        totalSize: strapiPageInfo.pagination.total,
                      }}
                      clickCallback={(e) => setStrapiPage(e.selected + 1)}
                      selectCallback={setStrapiPage}
                    />
                  )}
                </div>
              </Modal.Body>
            </Modal>
            <FormRow
              label={<Trans i18nKey="p.b.p.addoredit.language">Language</Trans>}
              name="post.languageType"
              component={(props) => (
                <SelectBox
                  setVal={setLang}
                  onChangeFunc={() => {
                    formikProps.setFieldValue('post.popularPostIdsList', [
                      '',
                      '',
                      '',
                    ]);
                  }}
                  {...props}
                  options={configs.blogLanguages}
                />
              )}
              isRequired={true}
            />
            <FormRow
              label={<Trans i18nKey="p.b.p.addoredit.title">Title</Trans>}
              name="post.title"
              component={InputBox}
              isRequired={true}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.feature.topic">
                  Featured Topic
                </Trans>
              }
              name="post.featuredTopic"
              component={(props) => (
                <SelectBox
                  {...props}
                  options={topicsList(formikProps.values.post)}
                />
              )}
              isRequired={true}
            />
            <ShortDesc name="post.shortDesc" props={formikProps} />
            <FormRow
              label={<Trans i18nKey="p.b.p.addoredit.author">Author</Trans>}
              name="post.postAuthors[0].author.id"
              component={(props) => <SelectBox {...props} options={authors} />}
              isRequired={true}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.translator">Translator</Trans>
              }
              name="post.postAuthors[1].author.id"
              component={(props) => (
                <SelectBox {...props} options={translators} />
              )}
              isRequired={false}
            />
            <FormRow
              label={<Trans i18nKey="p.b.p.addoredit.category">Category</Trans>}
              name="post.category"
              component={(props) => (
                <SelectBox {...props} options={categoryList} />
              )}
              isRequired={true}
            />
            <FormRow
              label={<Trans i18nKey="p.b.p.addoredit.postKey">Post Key</Trans>}
              name="post.postKey"
              component={(props) => (
                <InputBox
                  {...props}
                  errorMessage="Post key should only contain a-zA-Z0-9_-"
                  onBlur={() => {
                    if (!formikProps.values.post.mappingKey) {
                      const tmp = mappingKeyList;
                      tmp.push({
                        label: props.field.value,
                        value: props.field.value,
                      });
                      setMappingKeyList(tmp);
                      props.form.setFieldValue(
                        'post.mappingKey',
                        props.field.value
                      );
                    }
                  }}
                />
              )}
              isRequired={true}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.mappingKey">Mapping Key</Trans>
              }
              name="post.mappingKey"
              component={(props) => (
                <CustomOptionReactSelect
                  {...props}
                  options={!_isEmpty(mappingKeyList) ? mappingKeyList : []}
                />
              )}
              isRequired={true}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.redirectKey">
                  Redirect Key
                </Trans>
              }
              name="post.redirectKey"
              component={(props) => (
                <InputBox
                  {...props}
                  errorMessage="Post key should only contain a-zA-Z0-9_-"
                />
              )}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.postAttr">
                  Post Attributes
                </Trans>
              }
              name="post.postAttrs"
              isMultiChoice={true}
              component={(props) => (
                <CheckboxPostAttrList
                  labelValueOptions={configs.blog.blogTypes}
                  arrayHelpers={props}
                  setFieldValue={formikProps.setFieldValue}
                  featuredTopic={formikProps.values.post.featuredTopic}
                />
              )}
              isRequired={true}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.meta.desc">
                  Meta Description
                </Trans>
              }
              name="post.metaDesc"
              component={InputBox}
              isRequired={true}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.post.date">Post Date</Trans>
              }
              name="post.postDate"
              component={(props) => <InputBox {...props} type="date" />}
              isRequired={true}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.minutes.to.read">
                  Minutes To Read
                </Trans>
              }
              name="post.minutesToRead"
              component={(props) => (
                <InputBox {...props} type="number" value={3} />
              )}
              isRequired={true}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.content.version">
                  Content Version
                </Trans>
              }
              name="post.contentVersion"
              component={(props) => (
                <SelectBox
                  {...props}
                  options={[
                    {value: 'v1', label: 'v1'},
                    {value: 'v2', label: 'v2'},
                  ]}
                />
              )}
              isRequired={true}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.image.alt">
                  Image Alternative text
                </Trans>
              }
              name="post.imageAlt"
              component={InputBox}
            />
            <FormRow
              label={<Trans i18nKey="p.b.p.addoredit.video">Video Cover</Trans>}
              name="videoUrl"
              component={(props) => (
                <FileContainer
                  {...props}
                  accept={'video/mp4'}
                  maxSize={'2097152'}
                  pathType={'BLOG_VIDEO'}
                  videoWidth={1148}
                  videoHeight={646}
                  fileName={
                    props.field.value.name !== null
                      ? props.field.value.name
                      : ''
                  }
                  uploadButtonText={'Upload Video'}
                  displayText={
                    <div>
                      {' '}
                      Limitation: Video file size {'<='} 2 MB <br /> (1148W x
                      646H){' '}
                    </div>
                  }
                  showCover={true}
                />
              )}
            />
            <FormRow
              label={<Trans i18nKey="p.b.p.addoredit.image">Image Cover</Trans>}
              name="post.isBa"
              component={CheckBox}
              comment={'Before/After Cover'}
            />
            <FormRow
              label={''}
              name="image"
              component={(props) => (
                <ImageField
                  {...props}
                  imageWidth={1148}
                  imageHeight={646}
                  displayWidth={574}
                  displayHeight={323}
                  imageFormat="jpg"
                  imageUrl={props.field.value}
                  displayText={'PNG, JPG, ImageSize <= 300 KB'}
                  imagePlaceholder={
                    _get(formikProps, 'values.post.isBa')
                      ? 'Drop a “Before" image here or click to upload'
                      : 'Drop an image here or click to upload'
                  }
                />
              )}
            />
            {_get(formikProps, 'values.post.isBa') && (
              <FormRow
                label={''}
                name="afterImageUrl"
                component={(props) => (
                  <ImageField
                    {...props}
                    imageWidth={1148}
                    imageHeight={646}
                    displayWidth={574}
                    displayHeight={323}
                    imageFormat="jpg"
                    imageUrl={props.field.value}
                    displayText={'PNG, JPG, ImageSize <= 300 KB'}
                    imagePlaceholder={
                      'Drop a “After" image here or click to upload'
                    }
                  />
                )}
              />
            )}
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.small.image">Small Image</Trans>
              }
              name="smallImage"
              component={(props) => (
                <ImageField
                  {...props}
                  imageWidth={528}
                  imageHeight={352}
                  displayWidth={528}
                  displayHeight={352}
                  imageFormat="jpg"
                  imageUrl={props.field.value}
                  scaleImage={true}
                  displayText={'PNG, JPG, ImageSize <= 300 KB'}
                />
              )}
              isRequired={true}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.square.image">
                  Square Image
                </Trans>
              }
              name="squareImage"
              component={(props) => (
                <ImageField
                  {...props}
                  imageWidth={200}
                  imageHeight={200}
                  displayWidth={200}
                  displayHeight={200}
                  imageFormat="jpg"
                  imageUrl={props.field.value}
                  scaleImage={true}
                  displayText={'PNG, JPG, ImageSize <= 300 KB'}
                />
              )}
            />
            {_indexOf(formikProps.values.post.postAttrs, 'YUC_YCE_USECASE') ===
            -1 ? (
              <>
                <FormRow
                  label={
                    <Trans i18nKey="p.b.p.addoredit.cta.buttons">
                      CTA Buttons
                      <br />
                      (Mobile Only)
                    </Trans>
                  }
                  name="post.ctaButtons"
                  component={(props) => (
                    <>
                      <div>
                        <Field
                          name="post.ctaButtons.mobile.isShowCoverButton"
                          className="form-control"
                          component={CheckBox}
                          label={'Cover'}
                        />
                      </div>
                      {_get(props, 'field.value.mobile.isShowCoverButton') && (
                        <>
                          <div>
                            <div className="row">
                              <label className="col-2 col-form-label">
                                Button Title
                              </label>
                              <div className="col-6">
                                <Field
                                  name="post.ctaButtons.mobile.coverButtonTitle"
                                  className="form-control"
                                  component={InputBox}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row" style={{marginTop: '10px'}}>
                            <label className="col-2 col-form-label">
                              Button Link
                            </label>
                            <div className="col-6">
                              <Field
                                name="post.ctaButtons.mobile.coverButtonLink"
                                className="form-control"
                                component={InputBox}
                              />
                            </div>
                          </div>
                          <div className="row" style={{marginTop: '10px'}}>
                            <label className="col-2 col-form-label">
                              Button Color
                            </label>
                            <div className="col-6">
                              <Field
                                name="post.ctaButtons.mobile.coverButtonColor"
                                component={ColorField}
                              />
                            </div>
                          </div>
                          <div className="row" style={{marginTop: '10px'}}>
                            <label className="col-2 col-form-label">
                              Text Color
                            </label>
                            <div className="col-6">
                              <Field
                                name="post.ctaButtons.mobile.coverTextColor"
                                component={ColorField}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div style={{marginTop: '10px'}}>
                        <Field
                          name="post.ctaButtons.mobile.isShowFloatButton"
                          className="form-control"
                          component={CheckBox}
                          label={'Button Float'}
                        />
                      </div>
                      {props.field.value.mobile.isShowFloatButton && (
                        <>
                          <div>
                            <div className="row">
                              <label className="col-2 col-form-label">
                                Button Title
                              </label>
                              <div className="col-6">
                                <Field
                                  name="post.ctaButtons.mobile.floatButtonTitle"
                                  className="form-control"
                                  component={InputBox}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row" style={{marginTop: '10px'}}>
                            <label className="col-2 col-form-label">
                              Button Link
                            </label>
                            <div className="col-6">
                              <Field
                                name="post.ctaButtons.mobile.floatButtonLink"
                                className="form-control"
                                component={InputBox}
                              />
                            </div>
                          </div>
                          <div className="row" style={{marginTop: '10px'}}>
                            <label className="col-2 col-form-label">
                              Button Color
                            </label>
                            <div className="col-6">
                              <Field
                                name="post.ctaButtons.mobile.floatButtonColor"
                                component={ColorField}
                              />
                            </div>
                          </div>
                          <div className="row" style={{marginTop: '10px'}}>
                            <label className="col-2 col-form-label">
                              Text Color
                            </label>
                            <div className="col-6">
                              <Field
                                name="post.ctaButtons.mobile.floatTextColor"
                                component={ColorField}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                />
                <FormRow
                  label={
                    <Trans i18nKey="p.b.p.addoredit.cta.buttons.desktop">
                      CTA Buttons
                      <br />
                      (Desktop Only)
                    </Trans>
                  }
                  name="post.ctaButtons"
                  component={(props) => (
                    <>
                      <div>
                        <div className="row">
                          <label className="col-2 col-form-label">
                            Button Title
                          </label>
                          <div className="col-6">
                            <Field
                              name="post.ctaButtons.desktop.floatButtonTitle"
                              className="form-control"
                              component={InputBox}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{marginTop: '10px'}}>
                        <label className="col-2 col-form-label">
                          Button Link
                        </label>
                        <div className="col-6">
                          <Field
                            name="post.ctaButtons.desktop.floatButtonLink"
                            className="form-control"
                            component={InputBox}
                          />
                        </div>
                      </div>
                      <div className="row" style={{marginTop: '10px'}}>
                        <label className="col-2 col-form-label">
                          Button Color
                        </label>
                        <div className="col-6">
                          <Field
                            name="post.ctaButtons.desktop.floatButtonColor"
                            component={ColorField}
                          />
                        </div>
                      </div>
                      <div className="row" style={{marginTop: '10px'}}>
                        <label className="col-2 col-form-label">
                          Text Color
                        </label>
                        <div className="col-6">
                          <Field
                            name="post.ctaButtons.desktop.floatTextColor"
                            component={ColorField}
                          />
                        </div>
                      </div>
                    </>
                  )}
                />
              </>
            ) : (
              <FormRow
                label={
                  <dev>
                    CTA Buttons
                    <br />
                    (yce Only)
                  </dev>
                }
                name="post.ctaButtons"
                component={(props) => (
                  <>
                    <div>
                      <div className="row">
                        <label className="col-2 col-form-label">
                          Button Text
                        </label>
                        <div className="col-6">
                          <Field
                            name="post.ctaButtons.yce.buttonText"
                            className="form-control"
                            component={InputBox}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row" style={{marginTop: '10px'}}>
                        <label className="col-2 col-form-label">
                          Button Tools
                        </label>
                        <div className="col-6">
                          <Field
                            name="post.ctaButtons.yce.buttonTools"
                            className="form-control"
                            component={SelectBox}
                            options={YceConfigs.buttonTools}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row" style={{marginTop: '10px'}}>
                        <label className="col-2 col-form-label">
                          CTA Interaction
                        </label>
                        <div className="col-6">
                          <Field
                            name="post.ctaButtons.yce.ctaInteraction"
                            className="form-control"
                            component={SelectBox}
                            options={YceConfigs.ctaAction}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{marginTop: '10px'}}>
                      <Field
                        name="post.ctaButtons.yce.isShowFloatButton"
                        className="form-control"
                        component={CheckBox}
                        label={'Button Float'}
                      />
                    </div>
                  </>
                )}
              />
            )}
            <DeadLinkChecker
              postId={formikProps.values.post.id}
              content={formikProps.values.post.postContent}
              otherLinks={() => {
                const checkProperties = [];
                if (
                  _get(
                    formikProps.values,
                    'post.ctaButtons.mobile.isShowCoverButton',
                    false
                  )
                ) {
                  checkProperties.push(
                    'post.ctaButtons.mobile.coverButtonLink'
                  );
                }
                if (
                  _get(
                    formikProps.values,
                    'post.ctaButtons.mobile.isShowFloatButton',
                    false
                  )
                ) {
                  checkProperties.push(
                    'post.ctaButtons.mobile.floatButtonLink'
                  );
                }
                if (
                  _get(
                    formikProps.values,
                    'post.ctaButtons.desktop.floatButtonTitle',
                    ''
                  ).trim().length !== 0
                ) {
                  checkProperties.push(
                    'post.ctaButtons.desktop.floatButtonLink'
                  );
                }
                return getCheckLinks(formikProps.values, checkProperties);
              }}
              deadLinkResult={deadLinkResult}
              setDeadLinkResult={setDeadLinkResult}
            />
            <FormRow
              label={
                <div>
                  <Trans i18nKey="p.b.p.addoredit.content">Content</Trans>
                </div>
              }
              name="post.postContent"
              component={(props) => (
                <div className="post-content-editor">
                  <RichTextEditor
                    {...props}
                    customConfig={postContentConfig}
                    key="content-editor"
                    insertHtml={insertContent}
                    updatePostContent={updatePostContent}
                    setInsertHTML={setInsertContent}
                  />
                  <span className="invalid-feedback d-block">
                    <ErrorMessage name={props.field.name} />
                  </span>
                </div>
              )}
              isRequired={true}
            />
            <FormRow
              label={
                <Trans i18nKey="p.b.p.addoredit.annotation">Annotation</Trans>
              }
              name="post.annotation"
              component={(props) => (
                <div>
                  <RichTextEditor
                    {...props}
                    key="annotation-editor"
                    customConfig={annotationConfig}
                  />
                  <span className="invalid-feedback d-block">
                    <ErrorMessage name={props.field.name} />
                  </span>
                </div>
              )}
            />
            <FormRow
              label={'Show FAQ List'}
              name="post.showFaqList"
              component={CheckBox}
            />
            {formikProps.values.post.showFaqList && (
              <div className={'row'}>
                <label className="col-2 col-form-label text-right" />
                <label className="col-2 col-form-label">
                  <button
                    className={'btn btn-sm btn-primary'}
                    onClick={() => {
                      setShowFaqModal(true);
                    }}
                    type={'button'}
                  >
                    <i className="fa fa-edit" /> Update Faq
                  </button>
                </label>
              </div>
            )}
            <FormRow
              label={
                <div>
                  <Trans i18nKey="p.b.p.listposts.displaysetting.popular.posts">
                    Popular Posts
                  </Trans>
                </div>
              }
              name="post.popularPostIdsList"
              component={(props) => (
                <div className="row my-2">
                  <div className="col-12">
                    <table className="table table-striped table-bordered table-hover dataTable">
                      <thead>
                        <tr>
                          <th style={{width: '3%'}}>
                            <Trans i18nKey="p.b.p.listposts.displaysetting.display.order">
                              Display Order
                            </Trans>
                          </th>
                          <th style={{width: '25%'}}>
                            <Trans i18nKey="p.b.p.listposts.displaysetting.post.title">
                              Title
                            </Trans>
                          </th>
                          <th style={{width: '6%'}}>
                            <Trans i18nKey="p.b.p.listposts.displaysetting.action">
                              Action
                            </Trans>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_get(
                          formikProps.values,
                          `post.popularPostIdsList`
                        ).map((post, i) => (
                          <FieldArray
                            id={`popularPostIdsList`}
                            name={`post.popularPostIdsList`}
                            validateOnChange
                            key={`popularPostIdsList-${i}`}
                          >
                            {(arrayHelpers) => (
                              <tr>
                                <td className="text-center">{i + 1}</td>
                                <td>
                                  <Field
                                    key={_get(
                                      formikProps.values,
                                      `post.popularPostIdsList[${i}]`
                                    )}
                                    name={`post.popularPostIdsList[${i}]`}
                                  >
                                    {(props) => (
                                      <BlogHeadingSelect
                                        options={_get(
                                          selectOptions,
                                          `${
                                            _indexOf(
                                              formikProps.values.post.postAttrs,
                                              'BF_BUSINESS_FOCUS'
                                            ) !== -1
                                              ? 'business'
                                              : 'consumer'
                                          }.${
                                            formikProps.values.post.languageType
                                          }`
                                        )}
                                        value={_find(
                                          _get(
                                            selectOptions,
                                            `${
                                              _indexOf(
                                                formikProps.values.post
                                                  .postAttrs,
                                                'BF_BUSINESS_FOCUS'
                                              ) !== -1
                                                ? 'business'
                                                : 'consumer'
                                            }.${
                                              formikProps.values.post
                                                .languageType
                                            }`
                                          ),
                                          {
                                            value: _get(props, `field.value`),
                                          }
                                        )}
                                        onChange={(opt) => {
                                          props.form.setFieldValue(
                                            props.field.name,
                                            opt ? opt.value : 0
                                          );
                                        }}
                                        {...props}
                                      />
                                    )}
                                  </Field>
                                </td>
                                <td>
                                  {i > 0 && (
                                    <img
                                      alt="move up"
                                      className="mr-2"
                                      onClick={() =>
                                        arrayHelpers.swap(i - 1, i)
                                      }
                                      src={require('../../../../assets/images/up_arrow.png')}
                                    />
                                  )}
                                  {i < 2 && (
                                    <img
                                      alt="move down"
                                      onClick={() =>
                                        arrayHelpers.swap(i, i + 1)
                                      }
                                      src={require('../../../../assets/images/down_arrow.png')}
                                    />
                                  )}
                                </td>
                              </tr>
                            )}
                          </FieldArray>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            />
            <div className="text-center">
              <SimpleButtonGroup
                pageName={'addOrEditPost'}
                isUpdating={!!isEdit}
                onCancelClick={() => navigate('/blog/post/list-posts')}
              />
            </div>
            <DeadLinkCheckerModal
              isOpen={isCheckedModal}
              SetIsOpen={setIsCheckedModal}
              deadLinkResult={deadLinkResult}
              handleSubmit={formikProps.handleSubmit}
              isSubmitting={formikProps.isSubmitting}
            />
          </Form>
        )}
      </Formik>
      {showFaqModal && (
        <SeoFaq
          metaInfos={metaInfos}
          setMetaInfos={setMetaInfos}
          setShowFaqModal={setShowFaqModal}
          showFaqModal={showFaqModal}
        />
      )}
    </div>
  );
}
