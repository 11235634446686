import React from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router';
import _get from 'lodash/get';
import AsyncHome from './async';

const Title = () => {
  const admin = useSelector((state) => state.components.auth.admin);
  const firstName = _get(admin, 'adm.adminName', '');
  return (
    <Trans i18nKey="p.h.home.title">
      Welcome to PERFECT Admin, {{firstName}}
    </Trans>
  );
};

export default [
  {
    path: '/',
    element: <AsyncHome />,
    handle: {
      title: <Title />,
      breadcrumbs: [],
    },
  },
  {
    path: '/home',
    element: <Navigate to="/" />,
  },
];
