import React, {useState} from 'react';
import ConfirmDialogButton from '../../../components/ConfirmDialogButton';
import {Trans} from 'react-i18next';
import ConfirmDialogModal from '../../../../../components/dialogs/ConfirmDialogModal';

export default function SubmitModal(props) {
  const {checkCallback, submitCallback, goBackCallback, dropdown} = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <ConfirmDialogButton
        className={!dropdown[4] ? 'btn-outline-success' : 'text-success'}
        callback={async () => {
          const checkRes = await checkCallback();
          if (checkRes) {
            setOpen(true);
            return;
          }
          submitCallback();
        }}
        dropdown={dropdown[4]}
        title="Submit"
      >
        <i className="fa flaticon2-paperplane" />{' '}
        <Trans i18nKey="p.b.p.listposts.submit">Submit</Trans>
      </ConfirmDialogButton>
      <ConfirmDialogModal
        confirmCallback={goBackCallback}
        title={'Warning'}
        open={open}
        setOpen={setOpen}
        content={
          'There are some deadlinks in your post. Do you want to revise those links now?'
        }
      />
    </>
  );
}
