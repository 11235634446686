import React, {useState} from 'react';
import {Popover, OverlayTrigger} from 'react-bootstrap';
import './ConfirmDialog.css';

export default function ConfirmDialog({
  placement = 'top',
  title = 'Are you sure?',
  confirmCallback,
  children,
}) {
  const [show, setShow] = useState(false);

  const handleToggle = () => {
    setShow((prev) => !prev);
  };

  function handleYes() {
    confirmCallback();
    handleToggle();
  }

  function handleNo() {
    handleToggle();
  }

  return (
    <OverlayTrigger
      trigger="click"
      show={show}
      onToggle={handleToggle}
      placement={placement}
      rootClose={true}
      overlay={
        <Popover id="confirmation">
          <Popover.Header>{title}</Popover.Header>
          <Popover.Body>
            <div className="d-flex btn-group">
              <button
                type="button"
                className="btn btn-sm btn-success"
                onClick={() => handleYes()}
              >
                <i className="fa fa-check-circle" /> Yes
              </button>{' '}
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={() => handleNo()}
              >
                <i className="fa fa-times-circle" /> No
              </button>
            </div>
          </Popover.Body>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
}
