import React, {useEffect, useState} from 'react';
import Dotdotdot from 'react-dotdotdot';
import FormattedLang from '../../FormattedLang';
import videoDao from '../../../dao/videoDao';
import VideoPlayer from '../../videoplayer';
import '../../news/preview/preview.css';
import './index.css';
import Parser from 'react-html-parser';

export default function PreviewVideos({videoId}) {
  const [video, setVideo] = useState({});
  const getReadMore = (languageType) => {
    switch (languageType) {
      case 'jpn':
        return '動画を見る';
      default:
        return 'Watch Video';
    }
  };

  useEffect(() => {
    videoDao.query({videoId}).then((val) => {
      setVideo(val);
    });
  }, []);

  if (!video) {
    return (
      <div className="pf-news__preview">
        <div className="row">
          <div className="col-md-12 text-center">No Data</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {video && video.id && (
        <div className="pf-news__preview" style={{backgroundColor: '#fff'}}>
          <div className="row">
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Video Title:</div>{' '}
                  <div className="pf-news__preview-val">{video.videoName}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Video Desc:</div>{' '}
                  <div className="pf-news__preview-val">
                    {Parser(video.videoDesc)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Language:</div>{' '}
                  <div className="pf-news__preview-val">
                    <FormattedLang lang={video.languageType} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Type:</div>{' '}
                  <div className="pf-news__preview-val">{video.videoType}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Preview:</div>{' '}
                  <div className="pf-news__preview-val">
                    <div className="pf-news__preview-card">
                      <div className="pf-video-feature-card">
                        <div>
                          <img
                            alt=""
                            src={video.videoImageUrl}
                            style={{width: '100%'}}
                          />
                        </div>
                        <div className="pf-video-card-title">
                          <Dotdotdot clamp={2}>
                            <p>{video.videoName}</p>
                          </Dotdotdot>
                        </div>
                        <div className="pf-video-card-content">
                          {Parser(video.videoDesc)}
                        </div>
                        <div className="pf-video-card-watch">
                          <span>{getReadMore()}</span>
                          <span className="pf-video-card-arrow">
                            <img
                              style={{
                                width: '25px',
                                height: '14px',
                                marginLeft: '15px',
                              }}
                              alt=""
                              src={require('../../../assets/images/news_arrow.png')}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container">
                <VideoPlayer
                  videoUrl={video.videoUrl}
                  languageType={video.languageType}
                />
                <div style={{display: 'flex'}}>
                  <div className="pf-video-detail-left-content">
                    <div className="pf-video-detail-title">
                      {video.videoName}
                    </div>
                    <div className="pf-video-detail-content">
                      {Parser(video.videoDesc)}
                    </div>
                  </div>
                  <div className="pf-video-detail-right-content">
                    {video.productTypeNames && !!video.productTypeNames.length && (
                      <div>
                        <div className="pf-video-detail-right-content-title">
                          Product
                        </div>
                        {video.productTypeNames.map((s) => (
                          <div className="pf-video-detail-right-content-subtitle">
                            <div className="pf-video-detail-link">{s}</div>
                          </div>
                        ))}
                      </div>
                    )}
                    {video.serviceTypeNames && !!video.serviceTypeNames.length && (
                      <div>
                        <div className="pf-video-detail-right-content-title">
                          Service
                        </div>
                        <div>
                          {video.serviceTypeNames.map((s) => (
                            <div className="pf-video-detail-right-content-subtitle">
                              <div className="pf-video-detail-link">{s}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
