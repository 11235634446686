import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {Trans} from 'react-i18next';
import ModalContentRow from '../../../../components/ModalContentRow';
import ModalContentHalfRow from '../../../../components/ModalContentHalfRow';
import utils from './utils';

export default function DetailModal({data = {}, isOpen, onClose}) {
  return (
    <Modal size="lg" show={isOpen} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          <Trans i18nKey="p.b.p.listposts.detail.title">Detail</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isOpen && (
          <div className="py-3 px-4">
            {utils.getHalfRows(data).map((row) => (
              <ModalContentHalfRow {...row} />
            ))}
            {utils.getRows(data).map((row) => (
              <ModalContentRow {...row} />
            ))}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
