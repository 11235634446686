import React, {useEffect, useState} from 'react';
import FormattedLang from '../../FormattedLang';
import FormattedDate from '../../../components/FormattedDate';
import Parser from 'react-html-parser';
import newsDao from '../../../dao/newsDao';
import Card from '../../card';
import './preview.css';

export default function PreviewNews({newsId}) {
  const [news, setNews] = useState({});
  const getReadMore = (languageType) => {
    switch (languageType) {
      case 'jpn':
        return '詳細はこちら';
      default:
        return 'READ MORE';
    }
  };

  useEffect(() => {
    newsDao.queryNews({newsId}).then((val) => {
      setNews(val);
    });
  }, []);

  if (!news || !news.newsTitle) {
    return (
      <div className="pf-news__preview">
        <div className="row">
          <div className="col-md-12 text-center">No Data</div>
        </div>
      </div>
    );
  }

  return (
    <div className="pf-news__preview" style={{backgroundColor: '#fff'}}>
      <div className="row">
        <div className="col-md-12" style={{backgroundColor: '#fff'}}>
          <div className="pf-news__preview-container-top">
            <div className="pf-news__preview-pair">
              <div className="pf-news__preview-key">News Title:</div>{' '}
              <div className="pf-news__preview-val">{news.newsTitle}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12" style={{backgroundColor: '#fff'}}>
          <div className="pf-news__preview-container-top">
            <div className="pf-news__preview-pair">
              <div className="pf-news__preview-key">Language:</div>{' '}
              <div className="pf-news__preview-val">
                <FormattedLang lang={news.languageType} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12" style={{backgroundColor: '#fff'}}>
          <div className="pf-news__preview-container-top">
            <div className="pf-news__preview-pair">
              <div className="pf-news__preview-key">Type:</div>{' '}
              <div className="pf-news__preview-val">{news.newsType}</div>
            </div>
          </div>
        </div>
      </div>
      {news.imageUrl && (
        <div className="row">
          <div className="col-md-12" style={{backgroundColor: '#fff'}}>
            <div className="pf-news__preview-container-top">
              <div className="pf-news__preview-pair">
                <div className="pf-news__preview-key">Preview:</div>{' '}
                <div className="pf-news__preview-val">
                  <div className="pf-news__preview-card">
                    <a
                      href={news.sourceLink}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Card
                        moreText={getReadMore()}
                        img={news.imageUrl}
                        title={news.newsTitle}
                        type={news.newsType}
                        mediaSource={news.mediaSource}
                        news={true}
                        date={news.newsDate}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        {news.newsType === 'NEWS' ? (
          <div className="col-md-12" style={{backgroundColor: '#fff'}}>
            <div className="pf-news__preview-container">
              <div className="pf-news-detail-page">
                <div className="pf-content-text2">
                  <div className="news-top-block">
                    <div className="news-date">
                      <div className="news-date-top">
                        <FormattedDate
                          type="newsDetailY"
                          time={news.newsDate}
                        />
                      </div>
                      <div className="news-date-bottom">
                        <FormattedDate
                          type="newsDetailMD"
                          time={news.newsDate}
                        />
                      </div>
                    </div>
                    <div className="news-title">{news.newsTitle}</div>
                  </div>
                  <div className="clear" />
                  <div
                    className="text-center"
                    style={{
                      padding: '20px 0',
                      lineHeight: '28px',
                      fontSize: '16px',
                      fontWeight: '500',
                    }}
                  >
                    <em>{news.newsSubtitle}</em>
                  </div>
                  <div className="pf-news-content">
                    <div className="news-article-main">
                      {news.newsContent && Parser(news.newsContent)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Media Source:</div>{' '}
                  <div className="pf-news__preview-val">{news.mediaSource}</div>
                </div>
              </div>
            </div>
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Source Link:</div>{' '}
                  <div className="pf-news__preview-val">
                    <a
                      href={news.sourceLink}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {news.sourceLink}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
