import React from 'react';
import {Trans} from 'react-i18next';
import {Navigate} from 'react-router-dom';
import AsyncListFAQs from './listfaqs/async';
import AsyncAddOrEditFAQ from './addoreditfaq/async';

const parent = {
  title: <Trans i18nKey="p.f.faqs.listfaqs.title">FAQs</Trans>,
  path: '/faqs',
};
export default [
  {
    path: '/faqs/list-faqs',
    element: <AsyncListFAQs />,
    handle: {
      title: <Trans i18nKey="p.f.faqs.listfaqs.title">FAQs</Trans>,
      subtitle: <Trans i18nKey="p.f.faqs.listfaqs.title">FAQs</Trans>,
      breadcrumbs: [parent],
    },
  },
  {
    path: '/faqs/add-faq',
    element: <AsyncAddOrEditFAQ />,
    handle: {
      title: <Trans i18nKey="p.f.faqs.addoreditfaq.add.title">Add FAQ</Trans>,
      subtitle: (
        <Trans i18nKey="p.f.faqs.addoreditfaq.add.title">Add FAQ</Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/faqs/edit-faq',
    element: <AsyncAddOrEditFAQ />,
    handle: {
      title: <Trans i18nKey="p.f.faqs.addoreditfaq.edit.title">Edit FAQ</Trans>,
      subtitle: (
        <Trans i18nKey="p.f.faqs.addoreditfaq.edit.title">Edit FAQ</Trans>
      ),
      breadcrumbs: [parent],
    },
  },
  {
    path: '/faqs',
    element: <Navigate to="/faqs/list-faqs" />,
  },
];
