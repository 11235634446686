import React from 'react';
import Modal from 'react-bootstrap/Modal';

export default function ImageModal(props) {
  const {
    size = 'lg',
    title = 'Preview',
    src,
    isOpen = false,
    onClose = () => {},
    alt = props.title,
    noImage = false,
  } = props;

  return (
    <Modal size={size} show={isOpen} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center">
          {noImage ? (
            <img
              alt={alt}
              src={require('../../assets/images/noimage.png')}
              style={{width: '40%'}}
            />
          ) : (
            <img alt={alt} src={src} style={{width: '90%'}} />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
