import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import courseDao from '../../../dao/courseDao';
import FormattedLang from '../../FormattedLang';
import FormattedDate from '../../FormattedDate';
import Parser from "react-html-parser";
import _isEmpty from 'lodash/isEmpty';


export default function ViewSession({sessionId, sessionTitle}) {
  const [session, setSession] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!show) return;
    courseDao.querySession(sessionId).then(val => {
      setSession(val);
    });
  }, [show, sessionId]);

  return (
    <div>
      <a
        href="javascript:;"
        data-role={session.id}
        onClick={() => setShow(!show)}
      >
        {sessionTitle}
      </a>
      <Modal
        size="xl"
        show={show}
        onHide={() => setShow(false)}
        style={{position: 'absolute', top: '10px'}}
      >
        <Modal.Header>
          <Modal.Title>
            <div>View Detail</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="col-12 col-form-label">
                <a
                  className="btn btn-sm btn-grey kt-margin-r-10 kt-margin-b-10 nowrap"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: '#fff'}}
                  href={`${process.env.REACT_APP_FRONTEND_URL}/business/beauty-tech-forum-master-class/detail/${session.id}`}
                >
                  <i className="fa fa-eye" /> Preview
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label
                className="col-2 col-form-label text-right"
                style={{fontWeight: '500'}}
              >
                Title:
              </label>
              <span className="col-10 col-form-label">
                {session.sessionTitle}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label
                className="col-2 col-form-label text-right"
                style={{fontWeight: '500'}}
              >
                Description:
              </label>
              <span className="col-10 col-form-label">
                {Parser(session.sessionSubTitle)}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label
                className="col-4 col-form-label text-right"
                style={{fontWeight: '500'}}
              >
                Language:
              </label>
              <span className="col-8 col-form-label">
                <FormattedLang lang={session.languageType} />
              </span>
            </div>
            <div className="col-md-6">
              <label
                className="col-4 col-form-label text-right"
                style={{fontWeight: '500'}}
              >
                Status:
              </label>
              <span className="col-8 col-form-label">
                {session.sessionStatus}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label
                className="col-4 col-form-label text-right"
                style={{fontWeight: '500'}}
              >
                Session No:
              </label>
              <span className="col-8 col-form-label">
                {session.sessionNumber}
              </span>
            </div>
            <div className="col-md-6">
              <label
                className="col-4 col-form-label text-right"
                style={{fontWeight: '500'}}
              >
                News Date:
              </label>
              <span className="col-8 col-form-label">
                <FormattedDate time={session.sessionStartDate} type={'short'} />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label
                className="col-2 col-form-label text-right"
                style={{fontWeight: '500'}}
              >
                {session.formActionUrl ? 'Pardot Form:' : 'Register Form:'}
              </label>
              <span className="col-10 col-form-label">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={session.registerPageUrl}
                >
                  {session.registerPageUrl}
                </a>
              </span>
            </div>
          </div>
          {session.formActionUrl && (
            <div className="row">
              <div className="col-md-12">
                <label
                  className="col-2 col-form-label text-right"
                  style={{fontWeight: '500'}}
                >
                  Pardot Form Handler:
                </label>
                <span className="col-10 col-form-label">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={session.formActionUrl}
                  >
                    {session.formActionUrl}
                  </a>
                </span>
              </div>
            </div>
          )}
          {session.formActionUrl && (
            <div className="row">
              <div className="col-md-12">
                <label
                  className="col-2 col-form-label text-right"
                  style={{fontWeight: '500'}}
                >
                  Action Tag:
                </label>
                <span className="col-10 col-form-label">
                  {session.actionTag}
                </span>
              </div>
            </div>
          )}
          {!_isEmpty(session.learns) && session.learns[1] && (
            <div className="row">
              <div className="col-md-12">
                <label
                  className="col-2 col-form-label text-right"
                  style={{fontWeight: '500'}}
                >
                  Session Learns:
                </label>
                <span className="col-10 col-form-label">
                  <ol>
                    {Object.entries(session.learns).map(([key, value]) => (
                      <li>{value}</li>
                    ))}
                  </ol>
                </span>
              </div>
            </div>
          )}
          {session.videoUrl && (
            <div className="row">
              <div className="col-md-12">
                <label
                  className="col-2 col-form-label text-right"
                  style={{fontWeight: '500'}}
                >
                  Video URL:
                </label>
                <span className="col-10 col-form-label">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={session.videoUrl}
                  >
                    {session.videoUrl} Duration: {session.videoDuration}
                  </a>
                </span>
              </div>
            </div>
          )}
          {session.sessionImageUrl && (
            <div className="row">
              <div className="col-md-12">
                <label
                  className="col-2 col-form-label text-right"
                  style={{fontWeight: '500'}}
                >
                  Session Image:
                </label>
                <span className="col-10">
                  <img
                    alt="cover"
                    src={session.sessionImageUrl}
                    style={{width: '30%'}}
                  />
                </span>
              </div>
            </div>
          )}
          {session.sessionSpeakers && (
            <div className="row">
              <div className="col-md-12">
                <label
                  className="col-2 col-form-label text-right"
                  style={{fontWeight: '500'}}
                >
                  Speakers:
                </label>
                <span className="col-10">
                  {session.sessionSpeakers
                    .map(sp => sp.speaker.speakerName)
                    .join(', ')}
                </span>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShow(false)}
            style={{margin: 'auto'}}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
