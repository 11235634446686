import React, {useEffect, useState} from 'react';
import FormattedLang from '../../FormattedLang';
import storyDao from '../../../dao/storyDao';
import Card from '../../card';
import FeaturedHeader from './FeaturedHeader';
import StoryCard from './StoryCard';
import classnames from 'classnames';
import Parser from 'react-html-parser';
import '../../news/preview/preview.css';
import './index.css';
import globals from '../../../utils/config/globals';

export default function PreviewStory({storyId}) {
  const [width, setWidth] = useState(window.innerWidth);
  const handleResize = () => setWidth(window.innerWidth);
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [story, setStory] = useState({});
  const getReadMore = (languageType) => {
    switch (languageType) {
      case 'jpn':
        return '続きを読む';
      default:
        return 'READ STORY';
    }
  };

  useEffect(() => {
    storyDao.queryStory({storyId}).then((val) => {
      setStory(val);
    });
  }, []);

  if (!story) {
    return (
      <div className="pf-news__preview">
        <div className="row">
          <div className="col-md-12 text-center">No Data</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {story && story.id && (
        <div className="pf-news__preview" style={{backgroundColor: '#fff'}}>
          <div className="row">
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Story Title:</div>{' '}
                  <div className="pf-news__preview-val">{story.storyTitle}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Story Desc:</div>
                  <div className="pf-news__preview-val">
                    {story.storySubTitle}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Story KPI:</div>
                  <div className="pf-news__preview-val">{story.storyKpi}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Language:</div>{' '}
                  <div className="pf-news__preview-val">
                    <FormattedLang lang={story.languageType} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Type:</div>
                  <div className="pf-news__preview-val">{story.storyType}</div>
                </div>
              </div>
            </div>
          </div>
          {story && !!story.productTypes.length && (
            <div className="row">
              <div className="col-md-12" style={{backgroundColor: '#fff'}}>
                <div className="pf-news__preview-container-top">
                  <div className="pf-news__preview-pair">
                    <div className="pf-news__preview-key">Products Type:</div>
                    <div className="pf-news__preview-val">
                      {story.productTypes.map((s) => {
                        const tmp =
                          globals.story.productTypes.filter(
                            (c) => c.value === s
                          )[0] &&
                          globals.story.productTypes.filter(
                            (c) => c.value === s
                          )[0].label;
                        return <div>{tmp}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {story && !!story.serviceTypes.length && (
            <div className="row">
              <div className="col-md-12" style={{backgroundColor: '#fff'}}>
                <div className="pf-news__preview-container-top">
                  <div className="pf-news__preview-pair">
                    <div className="pf-news__preview-key">Service Type:</div>
                    <div className="pf-news__preview-val">
                      {story.serviceTypes.map((s) => {
                        const tmp =
                          globals.story.serviceTypes.filter(
                            (c) => c.value === s
                          )[0] &&
                          globals.story.serviceTypes.filter(
                            (c) => c.value === s
                          )[0].label;
                        return <div>{tmp}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12" style={{backgroundColor: '#fff'}}>
              <div className="pf-news__preview-container-top">
                <div className="pf-news__preview-pair">
                  <div className="pf-news__preview-key">Preview:</div>{' '}
                  <div className="pf-news__preview-val">
                    <div className="pf-news__preview-card">
                      <Card
                        img={story.coverListImageUrl}
                        title={story.storyTitle}
                        moreText={getReadMore()}
                        brandImg={story.brandImageUrl}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{backgroundColor: '#fff'}}>
            <div className="pf-layout-container pf-layout-container-success">
              <div className="pf-success-story__back">
                <div
                  className={classnames({
                    'pf-success-story-block': width > 800,
                    'pf-success-story-block-mobile': width < 801,
                  })}
                  // ref={leftRef1}
                >
                  <FeaturedHeader
                    brandImg={story.brandImageUrl}
                    title={story.storyTitle}
                    content={story.storySubTitle}
                    kpi={story.storyKpi}
                    ceo={story.customerName}
                    brandName={story.customerPosition}
                    cover={story.detailImageUrl}
                    readTheStory={false}
                    videoUrl={story.videoUrl}
                    backgroundColor={story.videoDescColor}
                    coverText={story.videoDesc}
                  />
                </div>
                <div className="pf-success-story-situation-block">
                  <StoryCard
                    width={width}
                    img={story.brandImageUrl}
                    checkBox={
                      story.productTypes &&
                      story.productTypes.concat(
                        story.serviceTypes && story.serviceTypes
                      )
                    }
                    ul={story.benefits && story.benefits}
                    type={story.storyType && story.storyType.toLowerCase()}
                  />
                  <div
                    className={classnames({
                      'pf-success-story-situation': true,
                      'pf-success-story-situation-ja':
                        story.languageType === 'jpn',
                    })}
                    // ref={leftRef}
                  >
                    {Parser(story.storyContent)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
