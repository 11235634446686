import {get, post} from './utils';
import fetch from 'isomorphic-fetch';

const path = '/smb-admin/video';
const videoDao = {
  query: videoId => get(`${path}/query-video.action`, videoId),
  list: params => get(`${path}/list-videos.action`, params),
  create: video => post(`${path}/create-video.action`, video),
  update: video => post(`${path}/update-video.action`, video),
  delete: async videoId =>
    await fetch(`${path}/delete-video.action`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({videoId}),
    })
      .then(res => {
        if (res.ok || res.status === 200) {
          return 'success';
        }
      })
      .catch(() => 'failed'),
  listByCode: params => get(`${path}/list-video-displays.action`, params),
  addToList: ({displayCode, videoId}) =>
    post(`${path}/create-video-display.action`, {
      displayCode,
      videoId,
    }),
  removeFromList: async ({displayCode, videoId}) =>
    await fetch(`${path}/delete-video-display.action`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({displayCode, videoId}),
    })
      .then(res => {
        if (res.ok || res.status === 200) {
          return 'success';
        } else {
          return 'failed';
        }
      })
      .catch(() => 'failed'),
  createDisplayOrder: async ({displayCode, displayOrders}) =>
    await fetch(`${path}/create-video-displays.action`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({displayCode, displayOrders}),
    })
      .then(res => {
        if (res.ok || res.status === 200) {
          return 'success';
        }
      })
      .catch(() => 'failed'),
  updateDisplayOrder: async ({displayCode, displayOrders}) =>
    await fetch(`${path}/update-video-displays.action`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({displayCode, displayOrders}),
    })
      .then(res => {
        if (res.ok || res.status === 200) {
          return 'success';
        }
      })
      .catch(() => 'failed'),
  submitVideo: params => post('/smb-admin/video/submit-video.action', params),
  cancelSubmit: videoId =>
    get('/smb-admin/video/cancel-video.action', {videoId}),
  takenDown: (videoId) =>
    get('/smb-admin/video/take-down-video.action', {videoId}),
  copyVideo: (params) => post('/smb-admin/video/copy-video.action', params),
  reSubmit: (params) =>
    post('/smb-admin/video/continue-to-review-video.action', params),
};

export default videoDao;
