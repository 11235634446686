import React from 'react';
import {Link} from 'react-router-dom';

export default function Breadcrumbs({breadcrumbs = []}) {
  if (breadcrumbs.length === 0) {
    return <></>;
  }

  return (
    <div className="kt-subheader__breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          <span className="kt-subheader__breadcrumbs-separator" />
          <Link
            to={breadcrumb.path || '/'}
            className="kt-subheader__breadcrumbs-link"
          >
            {breadcrumb.title || ''}
          </Link>
        </React.Fragment>
      ))}
    </div>
  );
}
