import auth from './auth/routes';
import faqs from './faqs/routes';
import news from './news/routes';
import partners from './partners/routes';
import stories from './stories/routes';
import videos from './videos/routes';
import reports from './reports/routes';
import appfaqs from './appfaqs/routes';
import shopify from './shopify/routes';
import blog from './blog/routes';
import ba1on1 from './ba1on1/routes';
import skuAddon from './customersettings/routes';
import seo from './seo/routes';
import awards from './awards/routes';
import masterseries from './masterseries/routes';
import upload from './upload/routes';
import projectmsr from './projectmsr/routes';
import onlineservice from './onlineservice/routes';

export default [
  ...faqs,
  ...news,
  ...auth,
  ...partners,
  ...stories,
  ...videos,
  ...reports,
  ...appfaqs,
  ...shopify,
  ...blog,
  ...ba1on1,
  ...skuAddon,
  ...seo,
  ...awards,
  ...masterseries,
  ...upload,
  ...projectmsr,
  ...onlineservice,
];
